import { Container, Graphics } from '@/pixi';
import { chunk } from 'lodash';
import animate from 'gsap';
import { Spine } from 'pixi-spine';
import { state } from './State';
import { Symbol } from './Symbol';
import { ReelStack } from './ReelStack';

export class ReelsCashLink {
  constructor() {
    this.container = new Container();
    this.container.name = 'ReelsCashLink';
    this.reelsContainer = new Container();
    this.reelsContainer.name = 'ReelsCashLinkContainer';

    /* Spines */
    this.cashLinkx2 = new Spine(state.options.assets.cashLinkx2.resource.spineData);
    this.symbolWait = new Spine(state.options.assets.symbolWait.resource.spineData);

    /* Fixed values */
    this.maxWidth = state.reels.maxWidth;
    this.symbolBoxSize = state.reels.symbolBoxSize;

    /* Symbols arrays */
    this.reelWindowCashSymbols = []; // Flat
    this.reelWindowMultiplierSymbols = []; // Flat
    this.reelWindowPreservedSymbols = []; // Matrix
    this.reelWindowSymbols = []; // Flat
    this.reelWindowSymbolContainers = []; // Flat

    /* Other arrays */
    this.elementsToRemove = [];
    this.reelMasksPositions = [];
    this.reelMasks = [];
    this.stackSize = [];
    this.spinReels = [];

    this.setup();
  }

  get reels() {
    return chunk(this.reelWindowSymbols, state.options.config.rows);
  }

  get x2AnimationAvailable() {
    return this.reelWindowPreservedSymbols.flat().filter((symbol) => symbol === undefined).length === 0;
  }

  setup() {
    this.container.addChild(this.reelsContainer, this.cashLinkx2);
    this.container.sortableChildren = true;
    this.container.interactiveChildren = false;
    this.container.visible = false;
    this.container.zIndex = 5;

    this.reelsContainer.y = state.reels.container.getLocalBounds().top;
    this.reelsContainer.sortableChildren = true;

    this.symbolWait.visible = false;

    this.reelWindowPreservedSymbols = chunk(Array.from({ length: state.reels.numberOfPositions }, undefined), state.options.config.rows);
    this.stackSize = chunk(Array.from({ length: state.reels.numberOfPositions }, (_, i) => 2 * (i + 1)), state.options.config.rows);

    this.calculateReelMasksPositions();
    this.createReelMasks();
  }

  calculateReelMasksPositions() {
    const { reelJackpotHeight, reelPadding } = state.options;
    const symbolsGap = 10;

    for (let reelIndex = 0; reelIndex < state.options.config.reels; reelIndex++) {
      for (let rowIndex = 0; rowIndex < state.options.config.rows; rowIndex++) {
        const x = reelIndex * this.symbolBoxSize + reelPadding[1];
        const y = rowIndex * this.symbolBoxSize + reelPadding[0] + reelJackpotHeight + symbolsGap / 2;
        const width = this.symbolBoxSize - symbolsGap;
        const height = this.symbolBoxSize - symbolsGap;

        this.reelMasksPositions.push({ x, y, width, height });
      }
    }
  }

  createReelMasks() {
    this.reelMasksPositions.forEach((maskPositions) => {
      const { x, y, width, height } = maskPositions;
      const reelMaskCashLink = new Graphics().beginFill(0x000000).drawRect(x, y, width, height).endFill();

      reelMaskCashLink.alpha = 0;
      reelMaskCashLink.cacheAsBitmap = true;

      this.reelMasks.push(reelMaskCashLink);
    });

    this.reelMasks = chunk(this.reelMasks, state.options.config.rows);
  }

  createRevealReels(reelWindow, reelWindowValues) {
    reelWindow.forEach((reel, reelIndex) => {
      reel.forEach((symbolValue, rowIndex) => {
        /* Get cash/multiplier values */
        const cashValue = reelWindowValues && reelWindowValues.cash.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
        const multiplierValue = reelWindowValues && reelWindowValues.multiplier?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
        const symbolValueAdjusted = state.options.config.cashLinkSymbols.includes(symbolValue) ? symbolValue : state.options.config.symbolMap.empty;

        /* Create symbol */
        const symbol = new Symbol({
          value: symbolValueAdjusted,
          mask: this.reelMasks[reelIndex][rowIndex],
          maxWidth: this.maxWidth,
          numOfStackedRows: 0,
          symbolBoxSize: this.symbolBoxSize,
          multiplierValue,
          cashValue,
          reelIndex,
          rowIndex,
        });

        /* Preserve symbol */
        if (symbol.isPreservableInCashLink) {
          this.reelWindowPreservedSymbols[reelIndex][rowIndex] = symbol;
        }

        if (symbol.isCash) {
          this.reelWindowCashSymbols.push(symbol);
        }

        if (symbol.isMultiplier) {
          this.reelWindowMultiplierSymbols.push(symbol);
        }

        symbol.disableMask();
        symbol.setAnimation('symbolIdle', true);

        this.reelWindowSymbols.push(symbol);
        this.reelWindowSymbolContainers.push(symbol.container);

        this.reelsContainer.addChild(symbol.container, this.reelMasks[reelIndex][rowIndex]);
      });
    });
  }

  createSpinReels(reelWindow, reelWindowValues) {
    const reelCopy = this.reels;

    /* Reset states from previous spin */
    this.reelWindowMultiplierSymbols = [];
    this.reelWindowSymbolContainers = [];
    this.reelWindowSymbols = [];
    this.spinReels = [];

    reelWindow.forEach((reel, reelIndex) => {
      reel.forEach((symbolValue, rowIndex) => {
        const preservedSymbol = this.reelWindowPreservedSymbols[reelIndex][rowIndex];

        /* Don't create new stack/symbol for positions that have preserved symbol */
        if (preservedSymbol) {
          this.reelWindowSymbolContainers.push(preservedSymbol.container);
          this.reelWindowSymbols.push(preservedSymbol);
          this.spinReels.push([undefined, undefined, undefined]);
        }

        /* Create new stack/symbol for positions that don't have preserved symbol */
        if (preservedSymbol === undefined) {
          /* Get symbol that is not preserved and is on reels from last spin. */
          const reelSymbol = reelCopy[reelIndex][rowIndex];

          reelSymbol.enableMask();

          /* Add stack symbols */
          const reelStack = new ReelStack({
            availableSymbols: state.options.config.cashLinkSymbols,
            biasedNumber: state.options.config.symbolMap.empty,
            symbolBoxSize: this.symbolBoxSize,
            stackSize: this.stackSize[reelIndex][rowIndex],
            mask: this.reelMasks[reelIndex][rowIndex],
            numOfStackedRows: state.options.config.rows + 1,
            rowIndex: state.options.config.rows + rowIndex,
            reelIndex,
          });

          this.reelsContainer.addChild(reelStack.container);

          /* Add symbol from spin result */
          const cashValue = reelWindowValues.cash.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
          const multiplierValue = reelWindowValues.multiplier?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];

          const symbol = new Symbol({
            value: symbolValue,
            mask: this.reelMasks[reelIndex][rowIndex],
            maxWidth: this.maxWidth,
            numOfStackedRows: this.stackSize[reelIndex][rowIndex] + 1,
            symbolBoxSize: this.symbolBoxSize,
            multiplierValue,
            cashValue,
            reelIndex,
            rowIndex,
          });

          symbol.enableMask();
          symbol.setAnimation('symbolSpin', true);

          if (symbol.isCash) {
            this.reelWindowCashSymbols.push(symbol);
          }

          if (symbol.isMultiplier) {
            this.reelWindowMultiplierSymbols.push(symbol);
          }

          this.reelWindowSymbols.push(symbol);
          this.reelWindowSymbolContainers.push(symbol.container);
          this.elementsToRemove.push(...[reelSymbol.container, reelStack.container]);
          this.spinReels.push([reelSymbol.container, reelStack.container, symbol.container]);

          this.reelsContainer.addChild(symbol.container);
        }
      });
    });

    this.spinReels = chunk(this.spinReels, state.options.config.rows);
  }

  preserveSymbols() {
    this.reels.forEach((reel, reelIndex) => {
      reel.forEach((symbol, rowIndex) => {
        if (symbol.isPreservableInCashLink) {
          this.reelWindowPreservedSymbols[reelIndex][rowIndex] = symbol;
        }
      });
    });

    /* To make sure symbols are always sorted correctly */
    this.reelWindowSymbols.forEach((symbol, symbolIndex) => {
      symbol.setZIndex(symbol.zIndexAboveEverything + symbolIndex);
    });
  }

  checkSymbolWait() {
    if (this.symbolWait.visible || state.spinResult.isBonusEnd) return;

    const symbolWaitPosition = this.reelWindowPreservedSymbols.flatMap((reel, reelIndex) => reel.map((symbol, rowIndex) => !symbol && [reelIndex, rowIndex]).filter((item) => item));

    if (symbolWaitPosition.length === 1) {
      const reelIndex = symbolWaitPosition[0][0];
      const rowIndex = symbolWaitPosition[0][1];
      const position = this.reels[reelIndex][rowIndex].container;

      this.symbolWait.visible = true;
      this.symbolWait.state.setAnimation(0, 'symbolWin', true);
      this.symbolWait.x = position.x;
      this.symbolWait.y = position.y;

      this.reelsContainer.addChild(this.symbolWait);

      state.reelsWrapper.logo.logoCashLinkWaitingAnimation();
    }
  }

  removeElements() {
    this.elementsToRemove.forEach((element) => {
      element.parent.removeChild(element);
      element.destroy({ children: true, texture: false, baseTexture: false });
    });

    this.elementsToRemove = [];
  }

  resetReelsTimeline() {
    this.reelsTimeline.kill();
    this.reelsTimeline = undefined;
  }

  async spin() {
    const {
      isBonusEnd,
      reelWindow,
      reelWindowValues,
    } = state.spinResult;

    this.createSpinReels(reelWindow, reelWindowValues);

    this.reelsTimeline = animate.timeline({
      onComplete: () => {
        this.removeElements();
        this.checkSymbolWait();
        this.resetReelsTimeline();
      },
    });

    this.spinReels.forEach((reel, reelIndex) => {
      reel.forEach((subReel, rowIndex) => {
        /* Don't animate if symbol is preserved in bonus game */
        if (this.reelWindowPreservedSymbols[reelIndex][rowIndex]) return;

        const reelTimeline = animate.timeline();

        subReel.forEach((symbol) => {
          const reelSpinDuration = state.reelSpinBaseDuration + (((reelIndex * reel.length) + rowIndex) * state.reelSpinStopDelay);
          const reelStackHeight = this.symbolBoxSize * (this.stackSize[reelIndex][rowIndex] + 1) + state.reelSpinBounceAmount * 2;
          const symbolsBounceDirection = this.reelWindowSymbolContainers.includes(symbol) ? `-=${state.reelSpinBounceAmount}` : `+=${this.symbolBoxSize * 2}`;
          const symbolRef = this.reels[reelIndex][rowIndex];

          const symbolTimeline = animate.timeline();

          symbolTimeline.to(symbol, {
            duration: state.reelSpinBounceDuration,
            ease: 'Sine.easeInOut',
            pixi: {
              y: `-=${state.reelSpinBounceAmount}`,
            },
          });

          symbolTimeline.to(symbol, {
            duration: reelSpinDuration,
            ease: 'none',
            pixi: {
              y: `+=${reelStackHeight}`,
            },
          });

          symbolTimeline.to(symbol, {
            duration: state.reelSpinBounceDuration,
            ease: 'Sine.easeInOut',
            pixi: {
              y: symbolsBounceDirection,
            },
          });

          /* Add symbolSpinStop animation */
          symbolTimeline.add(this.createSpinStopTimeline(symbolRef), '<');

          reelTimeline.add(symbolTimeline, 0);
        });

        this.reelsTimeline.add(reelTimeline, 0);
      });
    });

    /* Preserve cash and jackpot symbols till bonus game end */
    this.preserveSymbols();

    /* Apply multiplier symbol to cash symbols values */
    this.reelsTimeline.add(this.createApplyMultiplierTimeline());

    /* If reels are full run x2 multiplier on all cash values */
    this.reelsTimeline.add(this.createReelsFilledAnimationsTimeline());

    /* Run collect animations on bonus game end */
    if (isBonusEnd) {
      const preservedSymbols = this.reelWindowPreservedSymbols.flat().filter((symbol) => symbol !== undefined);

      /* Symbol bonus end animation */
      this.reelsTimeline.add(this.createBonusEndTimeline(preservedSymbols));

      /* Run logo collect animations */
      this.reelsTimeline.add(state.reelsWrapper.logo.createLogoCollectTimeline());

      /* Run symbols collect animation */
      this.reelsTimeline.add(this.createCollectTimeline(preservedSymbols));

      /* Bonus end wait timeline */
      this.reelsTimeline.add(this.createWaitTimeline(1));
    }

    await this.reelsTimeline.play();
  }

  /* Animations timeline */
  createSpinStopTimeline(symbol) {
    if (!symbol.hasAnimation('symbolSpinStop')) return undefined;

    const timeline = animate.timeline();

    timeline.to({}, {
      duration: symbol.getAnimationDuration('symbolSpinStop'),
      onStart: () => {
        state.playSound('cashLinkLand', false, 0.7);
        symbol.setAnimationToIdle('symbolSpinStop');
        symbol.disableMask();
      },
    });

    return timeline;
  }

  createApplyMultiplierTimeline() {
    const timeline = animate.timeline();

    this.reelWindowMultiplierSymbols.forEach((symbol) => {
      timeline.to({}, {
        duration: symbol.getAnimationDuration('symbolWin'),
        onStart: () => {
          state.playSound('cashlinkCherryx2');
          symbol.setAnimation('symbolWin');
        },
      });

      timeline.add(this.createMultiplyCashSymbolsTimeline(symbol.multiplierValue), '<');
    });

    return timeline;
  }

  createReelsFilledAnimationsTimeline() {
    if (!this.x2AnimationAvailable) return undefined;

    const duration = state.reelsWrapper.logo.logoCashLinkX2AnimationDuration;

    const timeline = animate.timeline();

    timeline.to({}, {
      duration,
      onStart: () => state.playSound('cashlinkCovered'),
    });

    timeline.to({}, {
      duration,
      onStart: () => {
        this.cashLinkx2.state.setAnimation(0, 'cashLinkOverlayX2', false);
        state.reels.reelsBackground.state.setAnimation(0, 'reelCashLinkX2', false);
        state.reelsWrapper.logo.logoCashLinkX2Animation();
        state.playSound('x2Hit');
      },
    });

    timeline.add(this.createMultiplyAllCashTimeline());

    return timeline;
  }

  createBonusEndTimeline(symbols) {
    const timeline = animate.timeline();

    symbols.forEach((symbol) => {
      timeline.to({}, {
        duration: symbol.getAnimationDuration('symbolWin'),
        onStart: () => {
          symbol.setAnimation('symbolWin');
          this.symbolWait.visible = false;
        },
      }, 0);
    });

    timeline.to({}, {
      onStart: () => {
        state.playSound('cashlinkEnd');
      },
    }, '<');

    return timeline;
  }

  createCollectTimeline(symbols) {
    const timeline = animate.timeline();

    symbols.forEach((symbol) => {
      const animationsGap = 0.075;
      const animationsOverlap = 0.75; /* Percentage || 0.75 = 25% || (1 - x) * 100 */
      const animationDuration = symbol.getAnimationDuration('symbolCollect') * animationsOverlap;

      timeline.to({}, {
        duration: animationDuration,
        onStart: () => {
          this.createLogoWinAmountTimeline(symbol, animationDuration).play();
          symbol.setAnimation('symbolCollect');
          state.playSound('cashCollect');
        },
      }, `+=${animationsGap}`);
    });

    return timeline;
  }

  createLogoWinAmountTimeline(symbol, duration) {
    const symbolAmount = symbol.isJackpot ? state.reels.jackpotValues[symbol.jackpotName].getCurrentAmount() : symbol.cashLabel.getCurrentAmount();
    const amount = state.reelsWrapper.logo.cashLabel.getCurrentAmount() + symbolAmount;
    const cashAmountAnimateTimeline = state.reelsWrapper.logo.cashLabel.createAmountAnimateTimeline(amount, duration);

    return cashAmountAnimateTimeline;
  }

  createMultiplyAllCashTimeline() {
    const timeline = animate.timeline();

    const duration = this.reelWindowPreservedSymbols.flat().find((symbol) => symbol.hasAnimation('symbolX2')).getAnimationDuration('symbolX2');
    const multiplier = state.bonus.finishMultiplier;

    /* Multiply cash symbol values */
    timeline.add(this.createMultiplyCashSymbolsTimeline(multiplier, duration), 0);

    /* Multiply jackpots cash timeline */
    timeline.add(this.createMultiplyJackpotsTimeline(multiplier, duration), 0);

    return timeline;
  }

  createMultiplyCashSymbolsTimeline(multiplierValue, multiplyDuration) {
    const timeline = animate.timeline();

    this.reelWindowCashSymbols.forEach((symbol) => {
      const duration = multiplyDuration || symbol.getAnimationDuration('symbolX2');

      timeline.to({}, {
        duration,
        onStart: () => {
          const amount = symbol.cashLabel.getCurrentAmount() * multiplierValue;
          symbol.cashLabel.createAmountAnimateTimeline(amount, duration).play();
          symbol.setAnimationToIdle('symbolX2');
        },
      }, 0);
    });

    return timeline;
  }

  createMultiplyJackpotsTimeline(multiplierValue, multiplyDuration) {
    const timeline = animate.timeline();

    const jackpotCashLabels = Object.values(state.reels.jackpotValues);

    jackpotCashLabels.forEach((jackpotCashLabel) => {
      const amount = jackpotCashLabel.getCurrentAmount() * multiplierValue;
      timeline.add(jackpotCashLabel.createAmountAnimateTimeline(amount, multiplyDuration), 0);
    });

    return timeline;
  }

  createWaitTimeline(duration) {
    const waitTimeline = animate.timeline();

    waitTimeline.to({}, { duration });

    return waitTimeline;
  }
}
