// Fork from SlotDialogBuy.js
import gsap from 'gsap';
import { Container, Sprite } from '@/pixi';
import { callApiWithRetries } from '@/utility/Utility';
import * as api from '@/api/casino';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { Stepper } from '@/components/shared/Stepper';
import { Button } from './Button';
import { state } from '../State';

export class DialogContentStore {
  #container;
  #buyOptions;
  #scrollBox;
  #buyOptionsList = [];
  #stepper;
  #timeline;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'DialogContentStore';

    this.#buyOptions = [];

    if (state.options.collectBuyEnabled) {
      this.#buyOptions.push({
        priceAction: 'calculateCollectPrice',
        buyAction: 'buyCollect',
        assetName: 'storeCollectBuyOption',
      });
    }

    if (state.options.bonusBuyEnabled) {
      this.#buyOptions.push({
        priceAction: 'calculateBonusPrices',
        buyAction: 'buyBonus',
        assetName: 'storeBonusBuyOption',
        symbolsCount: 3,
      });
    }

    this.#stepper = new Stepper({
      fontName: state.options.fontNameNormal,
      highlightColor: state.options.colors.theme,
      highlightTextColor: state.options.colors.themeText,
      values: this.#getBetAmounts(),
      isEnabled: false,
      onIncrease: (betAmount) => { this.#onStepperChange(true, betAmount); },
      onDecrease: (betAmount) => { this.#onStepperChange(false, betAmount); },
      playTapSound: () => { state.playTapSound(); },
    });
    this.#container.addChild(this.#stepper.container);

    this.#createTimelineAndBuyOptions();
  }

  get container() {
    return this.#container;
  }

  #getBetAmounts() {
    return state.options.predefinedBetAmounts.map((value) => ({
      value,
      label: state.getMoneyLabel(value, state.options.currencyDisplayEnabled),
    }));
  }

  #createTimelineAndBuyOptions() {
    this.#timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        this.#stepper.setEnabled(false);
      },
      onComplete: () => {
        this.#getPrices();
      },
    });

    const buttonMargin = state.options.uiPadding / 2;

    this.#buyOptions.forEach((option) => {
      const item = new Container();

      const backgroundAsset = state.options.assets[option.assetName];
      const background = new Sprite(backgroundAsset.resource);

      const button = new Button({
        width: 344,
        height: 112,
        radius: 20,
        isEnabled: false,
        isVisible: false,
        onClick: () => { this.#onBuyOptionClick(button, option); },
      });

      button.container.x = background.width - button.container.width - buttonMargin;
      button.container.y = background.height - button.container.height - (buttonMargin * 2);
      button.container.$ref = button;

      item.addChild(background, button.container);
      this.#buyOptionsList.push(item);
    });

    this.#scrollBox = new ScrollBox({
      items: this.#buyOptionsList,
      verticalPadding: state.options.uiDialogPadding,
      itemsMargin: state.options.uiDialogPadding,
    });
    this.#container.addChild(this.#scrollBox.container);
  }

  async #onStepperChange(onIncrease, betAmount) {
    this.#stepper.setEnabled(false);
    await this.#getPrices(betAmount);
    state.setBetAmount(betAmount);

    if (onIncrease) {
      this.#stepper.increaseValue();
    } else {
      this.#stepper.decreaseValue();
    }

    this.#stepper.setEnabled(true);
  }

  async #onBuyOptionClick(button, option) {
    this.#setButtonsState(false);
    await this.buy(button, option.buyAction, option.symbolsCount);
  }

  #setButtonsState(enable) {
    this.#stepper.setEnabled(enable);

    this.#buyOptionsList.forEach((option) => {
      const button = option.children[1].$ref;
      button.setEnabled(enable);
    });
  }

  async #getPrices(betAmount) {
    await this.#buyOptions.reduce(async (prevPromise, option) => {
      await prevPromise;

      const result = await this.#calculatePrice(betAmount, option.priceAction);

      if (result) {
        const priceObject = result.prices ? result.prices[option.symbolsCount] : result;

        this.#addPriceToButton(priceObject, option.priceAction);
      }
    }, Promise.resolve());

    this.#stepper.setEnabled(true);
  }

  async #calculatePrice(betAmount, action) {
    const props = [state.options.tenantGameId, state.options.playerToken, {
      betAmount: betAmount || this.#stepper.value.value,
    }, action];

    const result = await callApiWithRetries(api.calculateBonusPrices, props, 5);

    if (result.isError) {
      state.setErrorDetails(result);
      return { price: 0 };
    }

    return result;
  }

  async #addPriceToButton(priceObject, priceAction) {
    const listIndex = this.#buyOptions.findIndex((o) => o.priceAction === priceAction);
    const buttonContainer = this.#buyOptionsList[listIndex].children[1];
    const button = buttonContainer.$ref;
    const price = priceObject?.discountPrice || priceObject?.price;
    const buttonEnabled = state.balanceAmount >= price && price > 0;

    button.setValue(price);
    button.setText(state.getMoneyLabel(price, state.options.currencyDisplayEnabled));
    button.setEnabled(buttonEnabled);

    await button.show(0.125);
  }

  async buy(button, action, bonusSymbols) {
    state.disableInSpin();
    const result = await api.buyBonus(state.options.tenantGameId, state.options.playerToken, action, {
      betAmount: this.#stepper.value.value,
      ...(bonusSymbols && { bonusSymbols }),
    });

    if (result.isError) {
      this.#setButtonsState(true);
      state.enableAfterSpin();
      state.setErrorDetails(result);
    } else {
      this.dialog.hide();
      state.components.content.controls.spin({ ...result, buyAmount: button.value });
    }
  }

  show() {
    this.#stepper.setValue(state.betAmount);
    this.#timeline.play(0, true);
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const scrollBoxScale = aspectRatio < 1.65 ? aspectRatio * 0.55 : 1;
    const headHeight = state.components.dialogStore.getFullHeadHeight();
    const dialogHeadHeight = headHeight * state.appScale;
    const stepperBounds = this.#stepper.container.getBounds();
    const stepperHeight = headHeight + (stepperBounds.height * state.appScale);
    const itemMargin = state.options.uiDialogPadding;
    const itemWidth = state.appWidth - (itemMargin * 2);

    this.#timeline.clear();

    // Adjust stepper
    this.#stepper.container.x = (state.appWidth / 2) - (this.#stepper.container.width / 2);
    this.#stepper.container.y = itemMargin * 1.5;

    // Adjust buy options
    this.#buyOptionsList.forEach((item, index) => {
      const sprite = item.children[0];
      const button = item.children[1];

      sprite.scale.set(itemWidth / item.children[0].texture.width);

      const itemAnimationOffset = 200;
      const yEnd = (itemMargin * index) + (sprite.height * index);
      const yStart = yEnd + itemAnimationOffset;

      Object.assign(item, {
        alpha: 0,
        x: 0,
        y: yEnd,
      });

      this.#timeline.fromTo(item, {
        pixi: {
          alpha: 0,
          y: yStart,
        },
      }, {
        delay: index === 0 ? 0.35 : 0,
        duration: 0.25,
        pixi: {
          alpha: 1,
          y: yEnd,
        },
        onStart() {
          button.$ref.hide();
        },
      }, '=-0.175');

      // Play timeline only if dialog is visible
      if (!state.components.dialogStore.container.visible) {
        this.#timeline.pause();
      }
    });

    // Adjust scrollBox
    if (aspectRatio < 1.65) {
      this.#scrollBox.container.scale.set(scrollBoxScale);
      this.#scrollBox.container.x = state.appWidth / 2 - this.#scrollBox.container.width / 2;
    } else {
      this.#scrollBox.container.scale.set(1);
      this.#scrollBox.container.x = 0;
    }
    this.#scrollBox.setDimensions({
      height: (state.appHeight - dialogHeadHeight - stepperHeight - itemMargin * 2) / scrollBoxScale,
      horizontalPadding: aspectRatio < 1.65 ? 0 : itemMargin,
    });
    this.#scrollBox.container.y = stepperHeight + itemMargin;
  }
}
