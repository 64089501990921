import { Notification } from './shared/Notification';
import { slotState } from './SlotState';
import { triggerEvent } from '../utility/Utility';
import { SlotButton } from './SlotButton';

export class SlotNotification extends Notification {
  constructor(params) {
    super({
      buttonComponent: SlotButton,
      fontName: slotState.options.fontNameNormal,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      iconCloseAsset: slotState.options.assets.iconClose,
      onClose: () => { this.closeNotification(); },
      playTapSound: () => { slotState.playTapSound(); },
      reloadBackgroundAsset: slotState.options.assets.reloadBackground,
      reloadLogoAsset: slotState.options.assets.reloadLogo,
      errorHappenedText: slotState.options.translations.invalidStateError,
      reloadGameToPlayText: slotState.options.translations.reloadGameToPlay,
      reloadText: slotState.options.translations.reload,
      ...params,
    });
  }

  closeNotification() {
    slotState.notification = undefined;
    if (slotState.errorDetails?.isErrorContinuable && !slotState.isPromotionLastSpin) {
      if (slotState.isInFreeRounds && !slotState.controls.controlsMain.isSpinning) {
        triggerEvent('ToggleFreeRounds');
      }
    }

    slotState.errorDetails = undefined;

    if (slotState.options.boostTenantId) {
      triggerEvent('EnableBoostBadge');
    }
  }
}
