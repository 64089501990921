import { ControlsHeader } from '@/components/shared/ControlsHeader';
import { state } from './State';

export class Header extends ControlsHeader {
  constructor() {
    super({
      menuAsset: state.options.assets.uiMenu,
      onMenuClick: () => {
        state.components.dialogMenu.show();
      },
      lobbyEnabled: state.options.lobbyEnabled,
      lobbyAsset: state.options.assets.uiLobby,
      lobbyParams: {
        locale: state.options.locale,
        tenantGameId: state.options.tenantGameId,
        isDemo: state.options.isDemo,
        boostTenantId: state.options.boostTenantId,
        currency: state.options.currency,
        playerToken: state.options.playerToken,
        // These options are only sent from casino-client-slots application
        platform: state.options.platform,
        token: state.options.token,
      },
      boostParams: {
        boostTenantId: state.options.boostTenantId,
        locale: state.options.locale,
        profileId: state.options.player?.partnerPlayerId,
        // This option is only sent from casino-client-slots application
        authorizationToken: state.options.token,
      },
      appWidth: state.options.calcWidth,
      padding: state.options.uiPadding,
      playTapSound: () => { state.playTapSound(); },
    });

    this.container.name = 'Header';
  }

  disableInSpin() {
    this.disableMenuClick();
  }

  enableAfterSpin() {
    this.enableMenuClick();
  }
}
