import * as api from '@/api/casino';
import { state } from './State';

class GameUtils {
  getMoneyLabel(amount, displayCurrency) {
    const formattedValue = amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return displayCurrency ? `${formattedValue} ${state.options.currency}` : formattedValue;
  }

  getTextScaleX(textComponent, maxWidth) {
    if (textComponent.width > maxWidth) {
      return maxWidth / (textComponent.width / textComponent.scale.x);
    }
    return textComponent.scale.x;
  }

  getTextScaleY(textComponent, maxHeight) {
    if (textComponent.height > maxHeight) {
      return maxHeight / (textComponent.height / textComponent.scale.y);
    }
    return textComponent.scale.y;
  }

  async makeApiRequest(action, payload, withLoaderOverlay = false, loaderTimeoutDuration = 2500) {
    const loaderTimeout = withLoaderOverlay
      ? setTimeout(() => state.components.loaderOverlay.show(), loaderTimeoutDuration)
      : undefined;

    const response = await api[action](...payload);

    if (loaderTimeout) {
      clearTimeout(loaderTimeout);
      state.components.loaderOverlay.hide();
    }

    return response;
  }
}

export const gameUtils = new GameUtils();
