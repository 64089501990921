import { Notification as BaseNotification } from '@/components/shared/Notification';
import { state } from './State';
import { Button } from './Button';

export class Notification extends BaseNotification {
  constructor(params) {
    super({
      getAppHeight: () => state.appHeight,
      getAppWidth: () => state.appWidth,
      buttonComponent: Button,
      iconCloseAsset: state.options.assets.iconClose,
      reloadBackgroundAsset: state.assets.reloadModalBackground,
      reloadLogoAsset: state.assets.reloadModalLogo,
      fontName: state.options.fontNameNormal,
      errorHappenedText: state.options.translations.invalidStateError,
      reloadText: state.options.translations.reload,
      reloadGameToPlayText: state.options.translations.reloadGameToPlay,
      onClose: () => { this.closeNotification(); },
      playTapSound: () => { state.playTapSound(); },
      ...params,
    });
  }

  closeNotification() {
    state.notification = undefined;
    state.errorDetails = undefined;
    state.header.uiBoost?.enable();

    // Continue bonus game?
    if (state.bonusGame.numberOfSpins > 0) {
      state.content.controls.spin();
    }
  }
}
