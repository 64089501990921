import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, BitmapText } from '@/pixi';
import { Cash } from './Cash';
import { state } from './State';

export class BonusGameScreen {
  #container;
  #background;
  #content;
  #transition;
  #customText;
  #pressAnywhereText;
  #screenType;
  #transitionTimeSpot = 1300;

  constructor(screenType) {
    this.#screenType = screenType;

    const { screenBackground, bonusScreenContent, screenTransition } = state.assets;

    this.#container = new Container();
    this.#container.name = screenType === 'intro' ? 'BonusGameIntroScreen' : 'BonusGameOutroScreen';

    this.#background = new Spine(screenBackground.resource.spineData);
    this.#content = new Spine(bonusScreenContent.resource.spineData);
    this.#transition = new Spine(screenTransition.resource.spineData);
    this.#container.addChild(this.#background, this.#content, this.#transition);

    // Remove default spine texts
    const introScreenBonusRoundsContainer = this.#content.slotContainers.find((container) => container.children[0]?.attachment?.name === 'number');
    introScreenBonusRoundsContainer.removeChildren();
    const outroScreenWinAmountContainer = this.#content.slotContainers.find((container) => container.children[0]?.attachment?.name === 'winAmount');
    outroScreenWinAmountContainer.removeChildren();

    // Intro screen
    // Add custom number text inside introScreenBonusRoundsContainer
    if (this.#screenType === 'intro') {
      this.#customText = new BitmapText('', {
        fontName: state.options.customFont,
        fontSize: 350,
      });
      this.#customText.anchor.set(0.5);
      this.#customText.angle = 6.5;
      this.#customText.scale.y = -1; // Adjust to introScreenBonusRoundsContainer

      introScreenBonusRoundsContainer.addChild(this.#customText);
    // Outro screen
    } else {
      this.#customText = new Cash(0, this.#content.width, 280, true);
      outroScreenWinAmountContainer.addChild(this.#customText.container);

      // Remove fishy spins text on outro screen
      const fishySpinsText = this.#content.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Spins');
      fishySpinsText.removeChildren();
    }

    this.#pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.customFont,
      fontSize: 60,
    });
    this.#pressAnywhereText.anchor.set(0.5);
    this.#pressAnywhereText.alpha = 0;
    this.#container.addChild(this.#pressAnywhereText);

    this.#setContainerVisibilityState(false);

    this.#setListeners();
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#container.eventMode = 'none';
    this.#container.cursor = 'pointer';
    this.#container.on('pointertap', () => {
      state.playTapSound();
      this.#setClickState(false);
      this.#runOutroAnimation();

      // Start/end bonus game right when transition is made
      setTimeout(() => {
        state.playSound('soundBonusScreenTransition');
        state.setSoundVolume(this.#screenType === 'intro' ? 'soundAmbient' : 'soundBonusAmbient', 1); // Reset sounds volume

        // Intro screen
        if (this.#screenType === 'intro') {
          state.startBonusGame();

          state.stopSound('soundBonusIntro');
          state.pauseSoundAmbient();
          state.playSound('soundBonusAmbient', true);
        // Outro screen
        } else {
          state.endBonusGame();

          state.stopSound('soundBonusOutro');
          state.stopSound('soundBonusAmbient');
          state.playSoundAmbient();
        }
      }, this.#transitionTimeSpot);
    });

    this.#background.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'intro') {
          this.#runIdleAnimation();
        } else if (trackEntry.animation.name === 'outro') {
          this.#setContainerVisibilityState(false);
          state.components.header.uiBoost?.enable();
        }
      },
    });
  }

  #runIntroAnimation() {
    this.#background.state.setAnimation(0, 'intro');
    this.#content.state.setAnimation(0, 'intro');
    this.#transition.state.setAnimation(0, 'intro');
    this.#setPressAnywhereTextState(true);

    state.setSoundVolume(this.#screenType === 'intro' ? 'soundAmbient' : 'soundBonusAmbient', 0.5);
    state.playSound('soundBonusScreenTransition');
    state.playSound(this.#screenType === 'intro' ? 'soundBonusIntro' : 'soundBonusOutro');

    // Enable click after 0.75sec. Intro duration is 2sec
    setTimeout(() => { this.#setClickState(true); }, 750);
  }

  #runIdleAnimation() {
    this.#background.state.setAnimation(0, 'idle', true);
    this.#content.state.setAnimation(0, 'idle', true);
    this.#transition.state.setAnimation(0, 'idle', true);
  }

  #runOutroAnimation() {
    this.#background.state.setAnimation(0, 'outro');
    this.#content.state.setAnimation(0, 'outro');
    this.#transition.state.setAnimation(0, 'outro');
    this.#setPressAnywhereTextState(false);
  }

  #setClickState(enable) {
    this.#container.eventMode = enable ? 'static' : 'none';
  }

  #setContainerVisibilityState(visible) {
    this.#container.visible = visible;
    // It doesn't work well without this for some reason
    this.#container.children.forEach((c) => { c.visible = visible; }); // eslint-disable-line no-param-reassign
  }

  #setPressAnywhereTextState(show) {
    gsap.timeline().fromTo(this.#pressAnywhereText, {
      pixi: {
        alpha: show ? 0 : 1,
      },
    }, {
      pixi: {
        alpha: show ? 1 : 0,
      },
      delay: 0.5,
      duration: 1,
    });
  }

  show(text) {
    // Intro screen
    if (this.#screenType === 'intro') {
      this.#customText.text = text;
    // Outro screen
    } else {
      this.#customText.setCash(text);
    }
    this.#setContainerVisibilityState(true);
    state.components.header.uiBoost?.disable('0.5');
    this.#runIntroAnimation();
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAttachment = this.#background.spineData.defaultSkin.attachments[0].background;
    const backgroundAttachmentWidth = backgroundAttachment.width * backgroundAttachment.scaleX;
    const backgroundAttachmentHeight = backgroundAttachment.height * backgroundAttachment.scaleY;
    const backgroundAspectRatio = backgroundAttachmentHeight / backgroundAttachmentWidth;
    const pressAnywhereTextMargin = 75;
    const bottomHeight = this.#pressAnywhereText.height + pressAnywhereTextMargin * 2;
    const contentMaxHeight = state.appHeight - bottomHeight;
    const contentHeight = this.#content.height / this.#content.scale.y;

    // If app aspect ratio is larger then background aspect ratio then set larger background/transition scale
    if (aspectRatio > backgroundAspectRatio) {
      this.#background.scale.set(state.appHeight / backgroundAttachmentHeight);
      this.#transition.scale.set(this.#background.scale.y);
    } else {
      this.#background.scale.set(1);
      this.#transition.scale.set(1);
    }

    // Set background/transition to center
    this.#background.x = state.appWidth / 2;
    this.#background.y = state.appHeight / 2;
    this.#transition.x = this.#background.x;
    this.#transition.y = this.#background.y;

    // Set content scale
    if (contentHeight > contentMaxHeight) {
      this.#content.scale.set(contentMaxHeight / contentHeight);
    } else {
      this.#content.scale.set(1);
    }

    // Set content to center above pressAnywhereText
    this.#content.x = state.appWidth / 2 - this.#content.width / 2;
    this.#content.y = contentMaxHeight / 2 - this.#content.height / 2;

    // Scale pressAnywhereText to fit to screen?
    const pressAnywhereMaxWidth = state.appWidth - 40; // Padding is 20
    const pressAnywhereWidth = this.#pressAnywhereText.width / this.#pressAnywhereText.scale.y;
    if (pressAnywhereWidth > pressAnywhereMaxWidth) {
      this.#pressAnywhereText.scale.set(pressAnywhereMaxWidth / pressAnywhereWidth);
    } else {
      this.#pressAnywhereText.scale.set(1);
    }

    this.#pressAnywhereText.x = state.appWidth / 2;
    this.#pressAnywhereText.y = state.appHeight - this.#pressAnywhereText.height / 2 - pressAnywhereTextMargin;
  }
}
