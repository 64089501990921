import { Spine } from 'pixi-spine';
import { Container, BitmapText, Text, Sprite, Texture } from '@/pixi';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { Symbol } from '../Symbol';
import { state } from '../State';

export class DialogContentMenuGameInfo {
  #container;
  #scrollBox;
  #items = [];
  #maxWidth = state.options.calcWidth - state.options.uiDialogPadding * 2;
  #itemsMargin = 50;
  #symbolTitleMargin = 35;
  #cashPrizes = [3, 10, 20, 38, 50, 2000];

  constructor() {
    this.#container = new Container();
    this.#container.name = 'DialogContentMenuGameInfo';

    // Game info
    this.#items.push(this.#createTitle(state.options.translations.aboutTheGameTitle));
    this.#items.push(this.#createDescription(state.options.translations.fishyGuyGameInfo.replace('${rtp}', state.options.rtp)));

    this.#items.push(this.#createLineSeparator());

    // Paytable
    this.#items.push(this.#createTitle(state.options.translations.paytable));
    this.#items.push(this.#createMainSymbolsPaytable());
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.wild[0], 'Wild', state.options.translations.fishyGuyWildSymbolDescription));
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.wildMultiplier, 'Wild multiplier', state.options.translations.fishyGuyWildMultiplierSymbolDescription));
    this.#items.push(this.#createCashAndTrashSymbolsWithDescription());
    this.#items.push(this.#createSpecialFishSymbolWithDescription());
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.collectBase, 'Fisherman', state.options.translations.fishyGuyFishermanSymbolDescription));
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.collectExtra, 'Super Fisherman', state.options.translations.fishyGuySuperFishermanSymbolDescription));
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.bonus, 'Bonus symbol', state.options.translations.fishyGuyBonusSymbolDescription));
    this.#items.push(this.#createSymbolWithDescription(state.options.symbols.upgrade, 'Upgrade Level symbol', state.options.translations.fishyGuyUpgradeLevelSymbolDescription));

    this.#items.push(this.#createLineSeparator());

    // Bonus features
    this.#items.push(this.#createTitle(state.options.translations.bonusFeaturesTitle));
    this.#items.push(this.#createDescription(state.options.translations.fishyGuyBonusFeaturesDescription));
    this.#items.push(this.#createBonusGameCollectLevelWithDescription(1, this.#getLevelTranslation('first'), state.options.translations.fishyGuyFirstLevelDescription));
    this.#items.push(this.#createBonusGameCollectLevelWithDescription(2, this.#getLevelTranslation('second'), state.options.translations.fishyGuySecondLevelDescription));
    this.#items.push(this.#createBonusGameCollectLevelWithDescription(3, this.#getLevelTranslation('third'), state.options.translations.fishyGuyThirdLevelDescription));
    this.#items.push(this.#createBonusGameCollectLevelWithDescription(4, this.#getLevelTranslation('fourth'), state.options.translations.fishyGuyFourthLevelDescription));
    this.#items.push(this.#createBonusGameCollectLevelWithDescription(5, this.#getLevelTranslation('fifth'), state.options.translations.fishyGuyFifthLevelDescription));

    this.#items.push(this.#createLineSeparator());

    // Store
    this.#items.push(this.#createTitle(state.options.translations.dialogStoreTitle));
    this.#items.push(this.#createDescription(
      state.options.translations.fishyGuyStoreDescription
        .replace('${bonusBuyRtp}', state.options.bonusBuyRtp)
        .replace('${bonusBuyPrice}', state.options.bonusBuyPrice)
        .replace('${collectBuyRtp}', state.options.collectBuyRtp),
    ));

    this.#items.push(this.#createLineSeparator());

    // How to play
    this.#items.push(this.#createTitle(state.options.translations.howToPlayTitle));
    this.#items.push(this.#createDescription(state.options.translations.fishyGuyHowToPlayDescription));

    this.#scrollBox = new ScrollBox({
      items: this.#items,
      itemsMargin: this.#itemsMargin,
      padding: state.options.uiDialogPadding,
    });
    this.#container.addChild(this.#scrollBox.container);

    this.#setWatchers();
  }

  get container() {
    return this.#container;
  }

  #setWatchers() {
    state.watch('betAmount', () => {
      const mainSymbolsPaytableIndex = 4;
      const mainSymbolsNewContainer = this.#createMainSymbolsPaytable();
      const [mainSymbolsOldContainer] = this.#items.splice(mainSymbolsPaytableIndex, 1, mainSymbolsNewContainer);
      mainSymbolsOldContainer.destroy({ children: true });

      const cashAndTrashSymbolsIndex = 7;
      const cashAndTrashNewContainer = this.#createCashAndTrashSymbolsWithDescription();
      const [cashAndTrashOldContainer] = this.#items.splice(cashAndTrashSymbolsIndex, 1, cashAndTrashNewContainer);
      cashAndTrashOldContainer.destroy({ children: true });

      const specialFishSymbolIndex = 8;
      const specialFishNewContainer = this.#createSpecialFishSymbolWithDescription();
      const [specialFishOldContainer] = this.#items.splice(specialFishSymbolIndex, 1, specialFishNewContainer);
      specialFishOldContainer.destroy({ children: true });

      this.#scrollBox.removeAllItems();
      this.#scrollBox.addItems(this.#items);
    });
  }

  #getLevelTranslation(level) {
    return `${state.options.translations[level]} ${state.options.translations.level}`;
  }

  #createTitle(text) {
    return new BitmapText(text, {
      fontName: state.options.fontNameBold,
      fontSize: 48,
      tint: state.options.colors.text,
    });
  }

  #createDescription(text, maxWidth = this.#maxWidth) {
    return new Text(text, {
      fontFamily: state.options.defaultFontFamily,
      fontSize: 40,
      fontWeight: 300,
      lineHeight: 48,
      fill: state.options.colors.text,
      wordWrap: true,
      wordWrapWidth: maxWidth,
    });
  }

  #createLineSeparator() {
    const line = new Sprite(Texture.WHITE);

    line.width = this.#maxWidth;
    line.height = 6;
    line.alpha = 0.1;

    return line;
  }

  #createMainSymbolsPaytable() {
    const container = new Container();
    const columnsMargin = 20;
    const rowsMargin = 50;
    const symbolMaxWidth = 175;

    state.options.symbols.main.forEach((symbol, index) => {
      const symbolContainer = new Container();
      const symbolPaytable = state.options.paytable.find((p) => p.symbol === symbol);

      const leftSideText = this.#createPaytableSymbolLeftSideText(symbolPaytable.hits);
      leftSideText.anchor.set(0, 0.5);
      symbolContainer.addChild(leftSideText);

      const symbolComponent = new Symbol({ symbolNumber: symbol, isStatic: true });
      symbolComponent.container.scale.set(0.75);
      symbolComponent.container.x = leftSideText.width + symbolMaxWidth / 2;
      symbolContainer.addChild(symbolComponent.container);

      const rightSideText = this.#createPaytableSymbolRightSideText(symbolPaytable.hits);
      rightSideText.x = symbolComponent.container.x + symbolMaxWidth / 2;
      rightSideText.anchor.set(0, 0.5);
      symbolContainer.addChild(rightSideText);

      const rightSideMaxWidth = this.#maxWidth / 2 - columnsMargin - leftSideText.width - symbolMaxWidth;
      if (rightSideText.width > rightSideMaxWidth) {
        rightSideText.scale.set(rightSideMaxWidth / rightSideText.width);
      }

      symbolContainer.x = index % 2 === 0 ? 0 : this.#maxWidth / 2;

      if (index < 2) {
        symbolContainer.y = symbolContainer.height / 2;
      } else {
        const containerAbove = container.getChildAt(index - 2);
        symbolContainer.y = containerAbove.y + containerAbove.height / 2 + rowsMargin + symbolContainer.height / 2;
      }

      container.addChild(symbolContainer);
    });

    return container;
  }

  #createPaytableSymbolLeftSideText(list) {
    const text = list.map((i) => Object.keys(i)[0]).join('\n');

    return new BitmapText(text, {
      fontName: state.options.fontNameBold,
      fontSize: 36,
      tint: state.options.colors.text,
      align: 'right',
    });
  }

  #createPaytableSymbolRightSideText(list) {
    const text = list.map((i) => state.getMoneyLabel(Object.values(i)[0] * state.betAmount)).join('\n');

    return new BitmapText(text, {
      fontName: state.options.fontNameBold,
      fontSize: 36,
      tint: state.options.colors.text,
      align: 'left',
    });
  }

  #createSymbolWithDescription(symbol, titleText, descriptionText) {
    const container = new Container();
    const symbolRightMargin = 10;

    const title = this.#createTitle(titleText);
    container.addChild(title);

    const symbolComponent = new Symbol({
      symbolNumber: symbol,
      isStatic: true,
      multiplier: state.options.symbols.wildMultiplier === symbol ? 3 : undefined,
      numOfBonusRounds: state.options.symbols.bonus === symbol ? 3 : undefined,
      prize: state.options.symbols.cash === symbol ? this.#cashPrizes[5] * state.betAmount : undefined,
      cashLevel: state.options.symbols.cash === symbol ? 6 : undefined,
      symbolBoxSize: 200,
    });
    symbolComponent.container.x = -symbolComponent.container.getLocalBounds().left;
    symbolComponent.container.y = title.height + this.#symbolTitleMargin + symbolComponent.container.height / 2;
    container.addChild(symbolComponent.container);

    const descriptionMaxWidth = this.#maxWidth - symbolComponent.container.width - symbolRightMargin;
    const description = this.#createDescription(descriptionText, descriptionMaxWidth);
    description.anchor.set(0, 0.5);
    description.x = symbolComponent.container.x + symbolComponent.container.getLocalBounds().right + symbolRightMargin;
    container.addChild(description);

    if (symbolComponent.container.height > description.height) {
      description.y = symbolComponent.container.y;
    } else {
      description.y = title.height + this.#symbolTitleMargin + description.height / 2;
    }

    return container;
  }

  #createSpecialFishSymbolWithDescription() {
    return this.#createSymbolWithDescription(state.options.symbols.cash, 'Special Fish symbol', state.options.translations.fishyGuySpecialFishSymbolDescription);
  }

  #createBonusGameCollectLevelWithDescription(level, titleText, descriptionText) {
    const container = new Container();
    const symbolRightMargin = 30;

    const title = this.#createTitle(titleText);
    container.addChild(title);

    const symbolSpine = new Spine(state.assets[`collectLevel${level}`].resource.spineData);
    symbolSpine.state.setAnimation(0, 'idle');
    symbolSpine.x = symbolSpine.width / 2;
    symbolSpine.y = title.height + this.#symbolTitleMargin + symbolSpine.height / 2;
    container.addChild(symbolSpine);

    const descriptionMaxWidth = this.#maxWidth - symbolSpine.width - symbolRightMargin;
    const description = this.#createDescription(descriptionText, descriptionMaxWidth);
    description.anchor.set(0, 0.5);
    description.x = symbolSpine.x + symbolSpine.width / 2 + symbolRightMargin;
    container.addChild(description);

    if (symbolSpine.height > description.height) {
      description.y = symbolSpine.y;
    } else {
      description.y = title.height + this.#symbolTitleMargin + description.height / 2;
    }

    return container;
  }

  #createCashAndTrashSymbolsWithDescription() {
    const container = new Container();

    const title = this.#createTitle('Fish symbols');
    container.addChild(title);

    const cashAndTrashSymbolsContainer = new Container();
    cashAndTrashSymbolsContainer.y = title.height + this.#symbolTitleMargin;

    for (let cashLevel = 1; cashLevel <= 5; cashLevel++) {
      const symbolComponent = new Symbol({
        symbolNumber: state.options.symbols.cash,
        isStatic: true,
        prize: this.#cashPrizes[cashLevel - 1] * state.betAmount,
        cashLevel,
        symbolBoxSize: 200,
      });
      cashAndTrashSymbolsContainer.addChild(symbolComponent.container);
    }

    const trashSymbol = new Symbol({ symbolNumber: state.options.symbols.trash, isStatic: true });
    cashAndTrashSymbolsContainer.addChild(trashSymbol.container);

    cashAndTrashSymbolsContainer.children.forEach((symbol, index) => {
      const containerAbove = cashAndTrashSymbolsContainer.children[index - 3];
      const marginY = index === 5 ? 30 : 20;
      symbol.x = (this.#maxWidth / 4) * ((index % 3) + 1); // eslint-disable-line no-param-reassign
      symbol.y = index > 2 ? containerAbove.y + containerAbove.height + marginY : symbol.height / 2; // eslint-disable-line no-param-reassign
    });

    container.addChild(cashAndTrashSymbolsContainer);

    const multiplier1 = 0.2;
    const multiplier2 = 50;
    const multiplier3 = 2000;
    const translation = state.options.translations.fishyGuyFishSymbolsDescription
      .replace('${multiplier1}', multiplier1).replace('${multiplier2}', multiplier2).replace('${multiplier3}', multiplier3);
    const description = this.#createDescription(translation);
    description.y = cashAndTrashSymbolsContainer.y + cashAndTrashSymbolsContainer.height;
    container.addChild(description);

    return container;
  }

  scrollToTop() {
    this.#scrollBox.scrollToTop();
  }

  setPosition() {
    const dialogHeadHeight = state.components.dialogMenu.getFullHeadHeight();
    const height = state.appHeight - dialogHeadHeight;

    this.#scrollBox.setDimensions({ height });
  }
}
