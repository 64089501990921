import { Container } from '@/pixi';
import { Tabs } from '../shared/Tabs';
import { state } from './State';
import { DialogMenuSettings } from './DialogMenuSettings';
import { DialogContentMenuHistory } from './DialogMenuHistory';
import { DialogContentMenuGameInfo } from './DialogContentMenuGameInfo';

export class DialogMenu {
  constructor() {
    this.options = state.options;
    this.container = new Container();
    this.tabs = undefined;

    this.setup();
  }

  setup() {
    const contentHistory = new DialogContentMenuHistory();
    const settingsContent = new DialogMenuSettings();
    const contentGameInfo = new DialogContentMenuGameInfo();

    this.tabs = new Tabs({
      items: [
        {
          iconAsset: this.options.assets.iconSettings,
          content: settingsContent,
          title: this.options.translations.dialogSettingsTitle,
        },
        {
          iconAsset: this.options.assets.iconInfo,
          content: contentGameInfo,
          title: this.options.translations.dialogMenuGameInfoTitle,
          onHide: contentGameInfo.scrollToTop.bind(contentGameInfo),
        },
        {
          iconAsset: this.options.assets.iconHistory,
          content: contentHistory,
          title: this.options.translations.dialogMenuHistoryTitle,
          onShow: contentHistory.onShow.bind(contentHistory),
        },
      ],
      playTapSound: () => { state.playTapSound(); },
      onChange: (item) => {
        state.dialogMenu.updateTitle(item.title);
      },
      colorActive: this.options.colors.theme,
      padding: this.options.uiDialogPadding,
    });

    this.container.addChild(this.tabs.container);
  }

  setPosition(data) {
    this.tabs.setPosition(data);
  }

  show() {
    this.tabs.showTab(0);
  }

  hide() {
    this.tabs.hideActiveTab();
  }
}
