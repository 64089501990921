import { Container, BitmapText } from '@/pixi';
import * as api from '@/api/casino';
import { triggerEvent, registerEventListener } from '@/utility/Utility';
import { Loader } from './Loader';
import { Select } from './Select';

export class DialogContentMenuHistory {
  #roundModalComponent;
  #rootContainer;
  #isDemo;
  #tenantGameId;
  #playerToken;
  #uiDialogPadding;
  #textColor;
  #fontNameNormal;
  #getAppHeight;
  #getDialogMenuFullHeadHeight;

  #container;
  #textMessage;
  #loader;
  #select;
  #table;
  #modal;
  #fullWidth;
  #centerX;
  #page;
  #maxPages;
  #horizontalPadding;
  #selectOptions;
  #selectedOption;

  constructor({
    RoundsTableComponent,
    RoundModalComponent, // Optional, Modal that opens on row click
    rootContainer,
    isDemo,
    tenantGameId,
    playerToken,
    translations,
    width = 1080,
    uiDialogPadding = 48,
    textColor = 0xdfe0e5,
    fontNameNormal = 'RobotoNormal',
    fontNameBold = 'RobotoBold',
    highlightColor = 0xffe843,
    loaderColor = 0xffe843,
    getAppWidth,
    getAppHeight,
    getDialogMenuFullHeadHeight,
    playTapSound,
  }) {
    this.#roundModalComponent = RoundModalComponent;
    this.#rootContainer = rootContainer;
    this.#isDemo = isDemo;
    this.#tenantGameId = tenantGameId;
    this.#playerToken = playerToken;
    this.#uiDialogPadding = uiDialogPadding;
    this.#textColor = textColor;
    this.#fontNameNormal = fontNameNormal;
    this.#getAppHeight = getAppHeight;
    this.#getDialogMenuFullHeadHeight = getDialogMenuFullHeadHeight;

    this.#fullWidth = width;
    this.#centerX = this.#fullWidth / 2;
    this.#page = 1;
    this.#horizontalPadding = this.#uiDialogPadding * 1.5;
    this.#selectOptions = [{
      displayName: translations.historySelectToday,
      value: 0,
    }, {
      displayName: translations.historySelectYesterday,
      value: -1,
    }];
    this.#selectedOption = this.#selectOptions[0].value;

    // Setup

    this.#container = new Container();

    if (this.#isDemo) {
      this.#textMessage = this.#createTextMessage(translations.historyDemoModeMessage);

      this.#container.addChild(this.#textMessage);
    } else {
      this.#select = new Select({
        width: width - this.#horizontalPadding * 2,
        options: this.#selectOptions,
        selectedOption: this.#selectedOption,
        textFontName: this.#fontNameNormal,
        playTapSound,
        rootContainer: this.#rootContainer,
        modalParams: {
          getAppWidth,
          getAppHeight,
          textColor: this.#textColor,
          highlightColor,
          titleFontName: fontNameBold,
          titleText: translations.historySelectModalDateRange,
          acceptButtonText: translations.historySelectModalAccept,
          cancelButtonText: translations.historySelectModalCancel,
        },
      });

      this.#table = new RoundsTableComponent({
        onRowClickCallback: this.#roundModalComponent ? this.#openRound.bind(this) : null,
        columnPadding: this.#horizontalPadding,
        summaryItems: [],
      });
      this.#table.container.visible = false;

      this.#textMessage = this.#createTextMessage(translations.historyNoTicketsMessage);
      this.#textMessage.visible = false;

      this.#loader = new Loader({ color: loaderColor });
      this.#loader.container.x = this.#centerX;

      this.#container.addChild(this.#select.container, this.#table.container, this.#textMessage, this.#loader.container);

      // Set actions

      this.#select.onChange = this.#onFilterChange.bind(this);

      // Set listeners

      // Sent from casino-client-slots application
      registerEventListener('UpdateOptions', (event) => {
        const token = event.detail?.playerToken;

        if (token && token !== this.#playerToken) {
          this.#playerToken = token;
        }
      }, 'DialogContentMenuHistory');
    }
  }

  get container() {
    return this.#container;
  }

  #createTextMessage(text) {
    const textMessage = new BitmapText(text, {
      fontName: this.#fontNameNormal,
      fontSize: 42,
      color: this.#textColor,
      align: 'center',
    });

    textMessage.anchor.set(0.5);
    textMessage.x = this.#centerX;
    textMessage.maxWidth = this.#fullWidth - this.#uiDialogPadding * 2;

    return textMessage;
  }

  #getRounds() {
    return api.getRounds({
      tenantGameId: this.#tenantGameId,
      playerToken: this.#playerToken,
      dateFrom: this.#getStartOfDayISODate(this.#selectedOption),
      dateTo: this.#getEndOfDayISODate(this.#selectedOption),
      page: this.#page,
    });
  }

  async #getRoundsFirstPage() {
    this.#page = 1;

    this.#select.disable();
    this.#table.container.visible = false;
    this.#textMessage.visible = false;
    this.#loader.container.visible = true;

    const response = await this.#getRounds();

    this.#select.enable();
    this.#loader.container.visible = false;

    if (response.isError) {
      triggerEvent('ShowNotification', response);
    } else if (response.rounds.length) {
      this.#table.addRoundsSummary(response.totals);

      this.#table.removeAllRows();
      this.#table.addRoundsRows(response.rounds);

      this.#table.container.visible = true;

      if (response.pagination.pages > 1) {
        this.#maxPages = response.pagination.pages;

        this.#table.onScrollToBottom = this.#onScrollToBottom.bind(this);
      }
    } else {
      this.#textMessage.visible = true;
    }
  }

  #onScrollToBottom() {
    if (!this.#loader.container.visible && this.#page < this.#maxPages) {
      this.#page += 1;
      this.#getRoundsNextPage();
    }
  }

  async #getRoundsNextPage() {
    this.#loader.container.visible = true;

    const response = await this.#getRounds();

    if (response.isError) {
      triggerEvent('ShowNotification', response);
      this.#page -= 1;
    } else if (response.rounds.length) {
      this.#table.addRoundsRows(response.rounds);
    }

    this.#loader.container.visible = false;
  }

  #onFilterChange(value) {
    this.#selectedOption = value;

    this.#getRoundsFirstPage();
  }

  #openRound({ round }) {
    this.#modal = new this.#roundModalComponent({
      roundId: round.id,
      onClose: this.#onRoundClose.bind(this),
    });

    this.#rootContainer.addChild(this.#modal.container);
  }

  #onRoundClose() {
    this.#modal = undefined;
  }

  #getStartOfDayISODate(day = 0) {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + day);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate.toISOString();
  }

  #getEndOfDayISODate(day = 0) {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + day);
    currentDate.setHours(23, 59, 59, 999);

    return currentDate.toISOString();
  }

  onShow() {
    if (!this.#isDemo) {
      this.#selectedOption = this.#selectOptions[0].value;
      this.#select.selectedOption = this.#selectedOption;

      this.#getRoundsFirstPage();
    }
  }

  setPosition() {
    const maxHeight = this.#getAppHeight() - this.#getDialogMenuFullHeadHeight();
    const centerY = maxHeight / 2.5;

    this.#textMessage.y = centerY;

    if (this.#loader) {
      this.#loader.container.y = centerY;
    }

    if (this.#select && this.#table) {
      const selectVerticalPadding = this.#uiDialogPadding / 1.5;

      this.#select.container.x = this.#horizontalPadding;
      this.#select.container.y = selectVerticalPadding;
      this.#select.setPosition();

      this.#table.container.y = this.#select.container.height + selectVerticalPadding * 2;
      this.#table.setPosition(maxHeight - this.#table.container.y);
    }

    this.#modal?.setPosition();
  }
}
