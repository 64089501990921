import { Container } from '@/pixi';
import { Reels } from './Reels';
import { Logo } from './Logo';

export class ReelsWithLogo {
  #container;
  #reels;
  #logo;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'ReelsWithLogo';

    this.#reels = new Reels();
    this.#logo = new Logo();

    this.#container.addChild(
      this.#reels.container,
      this.#logo.container,
    );
  }

  get container() {
    return this.#container;
  }

  get reels() {
    return this.#reels;
  }

  get logo() {
    return this.#logo;
  }

  getBottomPosition() {
    return this.container.y + this.container.getLocalBounds().bottom * this.container.scale.y;
  }

  setPositionY(value) {
    this.#container.y = -this.container.getLocalBounds().top * this.container.scale.y + value;
  }

  runIntroAnimation() {
    this.#reels.runIntroAnimation();
    this.#logo.runIntroAnimation();
  }
}
