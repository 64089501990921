import { first } from 'lodash';
import { Promotion } from '@/components/shared/Promotion';
import { slotState } from './SlotState';
import { triggerEvent, sleep } from '../utility/Utility';
import { SlotButton } from './SlotButton';

export class SlotPromotion extends Promotion {
  constructor(type) {
    super({
      backgroundAsset: slotState.options.assets.promotionBackground,
      buttonComponent: SlotButton,
      currency: slotState.options.currency,
      customFontName: 'PromoNumbers',
      fontBold: slotState.options.fontNameBold,
      fontNormal: slotState.options.fontNameNormal,
      promotion: slotState.activePromotion,
      promotionWinAmount: slotState.getMoneyLabel(slotState.activePromotion?.amountWon),
      type,
      /* translations */
      claimText: slotState.options.translations.promotionClaim,
      closeText: slotState.options.translations.promotionClose,
      continueText: slotState.options.translations.promotionContinue,
      youReceivedText: slotState.options.translations.promotionYouReceived,
      freeSpinsText: slotState.options.translations.promotionFreeSpins,
      daysText: slotState.options.translations.promotionDays,
      hoursText: slotState.options.translations.promotionHours,
      minutesText: slotState.options.translations.promotionMinutes,
      secondsText: slotState.options.translations.promotionSeconds,
      endsInText: slotState.options.translations.promotionEndsIn,
      youWonText: slotState.options.translations.promotionYouWon,
      endedText: slotState.options.translations.promotionEnded,
      operatorEndedText: slotState.options.translations.promotionOperatorEnded,
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      onShow() { this.onShowDialog(); },
      onStart: () => {
        this.hideDialog();
        triggerEvent('ToggleFreeRounds', {
          betAmount: slotState.activePromotion.amountPerPlayer,
          isPromotion: true,
          promotion: slotState.activePromotion,
        });
      },
      onEnd: () => {
        this.resetPromotion();
        this.hideDialog();
      },
    });
  }

  async resetPromotion() {
    slotState.activePromotion = undefined;
    slotState.isPromotion = false;
    slotState.isPromotionLastSpin = false;
    slotState.isPromotionStopped = undefined;
    slotState.isFree = false;
    slotState.endPromotion = undefined;
    slotState.promotionType = undefined;

    slotState.setBetAmountState();

    const amount = !slotState.promotionAfterPromotion
      ? first(slotState.options.settings.predefinedBetAmounts)
      : slotState.promotionAfterPromotion.amountPerPlayer;

    slotState.setBetAmount(amount);
    slotState.controls.enableAfterSpin();
    slotState.resetFeatures();

    if (slotState.promotionAfterPromotion) {
      const isPromotionFinished = new Date() > new Date(slotState.promotionAfterPromotion.endTime);

      if (isPromotionFinished) {
        slotState.promotionAfterPromotion = undefined;
        return;
      }

      slotState.activePromotion = slotState.promotionAfterPromotion;
      slotState.promotionAfterPromotion = undefined;
      await sleep(500);
      slotState.controls.openPromotionDialog('intro');
    }
  }

  hideDialog() {
    slotState.hideDialog();

    const numberOfPromotionLeft = slotState.activePromotion?.prizeCountLeft || slotState.activePromotion?.prizeCountPerPlayer;

    if (numberOfPromotionLeft === 1) {
      slotState.isPromotionLastSpin = true;
    }

    /* control boost badge when promotion dialog is closed */
    if (slotState.options.boostTenantId) {
      triggerEvent('ShowBoostBadge');
      if (slotState.activePromotion) {
        triggerEvent('DisableBoostBadge');
      } else {
        triggerEvent('EnableBoostBadge');
      }
    }
  }

  onShowDialog() {
    /* hide boost badge when promotion dialog is active */
    if (slotState.options.boostTenantId) {
      triggerEvent('HideBoostBadge');
    }
  }
}
