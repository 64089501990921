import { triggerAnalyticsEvent } from '@/utility/Utility';
import { Graphics } from '@/pixi';
import animate, { Sine } from 'gsap';
import { ControlsFooter2 } from '@/components/shared/ControlsFooter2';
import { Tooltip } from '@/components/shared/Tooltip';
import { state } from './State';

export class Footer extends ControlsFooter2 {
  constructor() {
    super({
      balanceTranslation: state.options.translations.balance,
      balanceAmount: state.getBalanceAmountMoneyLabel(),
      betTranslation: state.options.translations.bet,
      betAmount: state.getBetAmountMoneyLabel(),
      winTranslation: state.options.translations.win,
      currency: state.options.currency,
      currencyDisplayEnabled: state.options.currencyDisplayEnabled,
      appWidth: state.options.calcWidth,
      getAppHeight: () => state.appHeight,
      padding: state.options.uiPadding,
      balanceTitleColor: '0x7e5a2c',
      betTitleColor: '0x7e5a2c',
      winColor: state.options.colors.theme,
      titleFontName: state.options.fontNameLight,
      amountFontName: state.options.customFont3,
      onBetAmountClick: () => {
        if (!state.background.isIntroAnimationFinished
        || !state.options.state.state.buy.isResolved
        || state.options.state.availableFreeRounds) {
          return;
        }

        state.dialogBetAmount.show();
        triggerAnalyticsEvent({ name: 'BetAmountButtonClick' });
      },
      playTapSound: () => { state.playTapSound(); },
    });
    this.container.name = 'Footer';

    this.hide();
    this.setWatchers();
    this.overrideBetAmountPosition();
  }

  animateBalanceAmount(amountTo) {
    const counter = { value: state.balanceAmount };
    animate.to(counter, {
      value: amountTo,
      duration: 1.5,
      onUpdate: () => {
        this.setBalanceAmount(state.getMoneyLabel(counter.value));
      },
      onComplete: () => {
        this.enableBetAmountClick();
        this.hideBetUiOverlay();
        state.updateBalance({
          amount: amountTo,
        });
        state.content.controls.enableAfterSpin();
      },
      ease: Sine.easeIn,
    });
  }

  disableInSpin() {
    this.disableBetAmountClick();
  }

  enableAfterSpin() {
    if (!state.isBetAmountDisabled) {
      this.enableBetAmountClick();
    }

    this.setBetAmount(state.getBetAmountLabel());
  }

  // override bet container position since there is offset when used position from shared component
  overrideBetAmountPosition() {
    this.betContainer.children[1].x += 15;
    this.betContainer.children[1].y += 15;
  }

  setTooltip() {
    this.setBetContainerOverlay();
    this.tooltip = new Tooltip({
      element: this.betUiOverlay,
      parentContainer: this.container,
      text: state.options.translations.goldOfEgyptDialogBetAmountDisabledTooltip,
      position: 'top-end',
      offset: 5,
      name: 'uiBetAmountDisabledTooltip',
    });
  }

  setBetContainerOverlay() {
    this.betUiOverlay = new Graphics();
    this.betUiOverlay.beginFill(0x00000, 0.1);
    this.betUiOverlay.drawRect(
      this.betContainer.x - this.betContainer.width,
      this.betContainer.y,
      this.betContainer.width,
      this.betContainer.height,
    );
    this.betUiOverlay.endFill();
    this.betUiOverlay.eventMode = 'static';
    this.betUiOverlay.visible = false;

    this.container.addChild(this.betUiOverlay);
  }

  showBetUiOverlay() {
    this.betUiOverlay.visible = true;
  }

  hideBetUiOverlay() {
    this.betUiOverlay.visible = false;
  }

  hide() {
    this.container.alpha = 0;
  }

  show() {
    this.container.alpha = 1;
  }

  setWatchers() {
    state.watch('betAmount', () => {
      this.setBetAmount(state.getBetAmountMoneyLabel());
    });
    state.watch('balanceAmount', () => {
      this.setBalanceAmount(state.getBalanceAmountMoneyLabel());
    });
  }
}
