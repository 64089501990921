import { assign, get, isNil } from 'lodash';
import numeral from 'numeral';
import { makeAutoObservable, reaction } from 'mobx';
import { getErrorParams } from '@/utility/Utility';
import { audio } from './Audio';

class State {
  constructor() {
    // Passed options into component
    this.options = undefined;
    // State elements accessible in in entire component
    this.activeDialog = undefined;
    this.background = undefined;
    this.container = undefined;
    this.content = undefined;
    this.dialog = undefined;
    this.dialogAutoplay = undefined;
    this.dialogBetAmount = undefined;
    this.dialogBonusBuy = undefined;
    this.dialogMenu = undefined;
    this.notification = undefined;
    // Data state
    this.betAmount = undefined;
    this.balanceFromSocket = undefined;
    this.errorDetails = undefined;
    this.isBetAmountDisabled = false;
    this.isOnboardActive = true;
    this.isDialogOpen = false;
    this.lastRound = undefined;
    this.moneyFormat = '0,0.00';
    this.topPrizeAmountStart = undefined;
    this.topWinIncreaseTotal = undefined;
    this.topPrize = {
      amount: 0,
    };
    this.bonusGame = {
      numberOfSpins: 0,
      enabled: false,
    };
    this.cashPot = {
      amount: 0,
    };
    this.player = undefined;
    this.autoplay = {
      enabled: false,
      numberOfSpins: 0,
      stopOnAnyWin: false,
      currentBalance: 0,
      lossLimit: {
        enabled: false,
        amount: 0,
      },
      winLimit: {
        enabled: false,
        amount: 0,
      },
    };
    makeAutoObservable(this);
  }

  watch(path, callback) {
    reaction(() => get(this, path), callback);
  }

  create(options) {
    this.options = options;
    if (this.options.isDemo) {
      // Dummy player
      this.player = {
        currency: this.options.currency,
        balance: {
          amount: 1000,
        },
      };
    } else {
      this.player = this.options.state.player;
    }
    this.setBetAmount(options.state.state.betAmount);
    this.updateCashpot(options.state.state.cashPot);
    this.setTopPrizeValue();
  }

  get assets() {
    return this.options.assets;
  }

  get appScale() {
    return this.container.scale.y;
  }

  get appWidth() {
    return this.options.size().width / this.appScale;
  }

  get appHeight() {
    return this.options.size().height / this.appScale;
  }

  get balanceAmount() {
    return this.player.balance.amount;
  }

  get sufficientFunds() {
    return this.balanceAmount >= this.betAmount;
  }

  checkAutoplaySettings() {
    if (!this.autoplay.enabled) return;
    if ((this.autoplay.stopOnAnyWin && this.lastRound.state.cashPot.amount)
      || (this.autoplay.winLimit.enabled && this.lastRound.state.cashPot.amount
        && this.lastRound.state.cashPot.amount >= this.autoplay.winLimit.amount)) {
      this.stopAutoplay();
    }
  }

  getBalanceAmountMoneyLabel() {
    return this.getMoneyLabel(this.balanceAmount);
  }

  getBetAmountMoneyLabel() {
    return this.getMoneyLabel(this.betAmount);
  }

  getMoneyLabel(amount, showCurrency) {
    const value = numeral(amount).format(this.moneyFormat);

    return showCurrency ? `${value} ${this.player.currency}` : value;
  }

  playTapSound() {
    audio.play(this.options.assets.soundTap);
  }

  setSoundAmbient() {
    if (!this.soundAmbient) {
      this.soundAmbient = audio.play(this.options.assets.soundAmbient, { loop: true });
    }
  }

  setSoundBonusAmbient() {
    this.soundBonusAmbient = audio.play(this.options.assets.soundBonusAmbient, { loop: true });
  }

  stopSoundAmbient() {
    this.soundAmbient = undefined;
    audio.stop(this.options.assets.soundAmbient);
  }

  stopSoundBonusAmbient() {
    this.soundAmbient = undefined;
    audio.stop(this.options.assets.soundBonusAmbient);
  }

  setBetAmountState(isDisabled = false) {
    this.isBetAmountDisabled = isDisabled;
  }

  toggleSound() {
    this.mute = !this.mute;
  }

  updateBalance(balance) {
    if (balance) {
      assign(this.player, { balance });
    }
  }

  updateCashpot(cashpot) {
    if (cashpot) {
      assign(this.cashPot, cashpot);
    }
  }

  updateOptions(options) {
    if (options) {
      Object.assign(this.options, options);
    }
  }

  updateTopPrize(topPrize) {
    if (topPrize) {
      assign(this.topPrize, topPrize);
    }
  }

  setBalanceAmountAfterSpin() {
    if (this.balanceFromSocket) {
      this.updateBalance({
        amount: this.balanceFromSocket,
      });
      this.balanceFromSocket = undefined;
    } else {
      this.updateBalance(this.lastRound.balance);
    }
  }

  setBalanceFromSocket(value) {
    this.balanceFromSocket = value;
  }

  setTopPrizeValue() {
    const { betAmount, topPrize } = this.options.state.state;
    this.topPrizeAmountStart = topPrize.amount / betAmount;
    this.topWinIncreaseTotal = topPrize.amount - this.topPrizeAmountStart * betAmount;
  }

  setBetAmount(value) {
    this.betAmount = value;
  }

  setAutoplaySettings(enabled, numberOfSpins, stopOnAnyWin, lossLimit, winLimit) {
    this.autoplay.enabled = enabled;

    if (!isNil(numberOfSpins)) this.autoplay.numberOfSpins = numberOfSpins;
    if (!isNil(stopOnAnyWin)) this.autoplay.stopOnAnyWin = stopOnAnyWin;
    if (!isNil(lossLimit)) this.autoplay.lossLimit = lossLimit;
    if (!isNil(winLimit)) this.autoplay.winLimit = winLimit;

    if (enabled) {
      this.content.controls.spin();
    }
  }

  startAutoplay(numberOfSpins, stopOnAnyWin, lossLimit, winLimit) {
    this.setAutoplaySettings(true, numberOfSpins, stopOnAnyWin, lossLimit, winLimit);
  }

  stopAutoplay() {
    this.setAutoplaySettings(false, 0, false, { enabled: false }, { enabled: false });
  }

  setAutoplayNumberOfSpins(numberOfSpins) {
    this.autoplay.numberOfSpins = numberOfSpins;

    if (numberOfSpins === 0) {
      this.stopAutoplay();
    }
  }

  decreaseAutoplayNumberOfSpins() {
    if (!this.autoplay.enabled) return;

    this.autoplay.numberOfSpins -= 1;

    if (this.autoplay.numberOfSpins === 0) {
      this.stopAutoplay();
    }
  }

  enableAfterSpin() {
    if (!this.bonusGame.enabled && !this.autoplay.enabled) {
      this.header.enableAfterSpin();
    }
    this.content.controls.enableAfterSpin();
  }

  disableInSpin() {
    this.header.disableInSpin();
    this.content.controls.disableInSpin();
  }

  startBonusGame() {
    this.header.disableInSpin();
    this.bonusGame.enabled = true;
    this.stopSoundAmbient();
    this.setSoundBonusAmbient();
    this.content.controls.spin();
  }

  addBonusGameNumberOfSpins(numOfSpins) {
    this.bonusGame.numberOfSpins = numOfSpins;
  }

  decreaseBonusGameNumberOfSpins() {
    if (this.bonusGame.numberOfSpins === 0) return;

    this.bonusGame.numberOfSpins -= 1;
  }

  endBonusGame() {
    this.bonusGame.enabled = false;
    this.header.enableAfterSpin();
    this.bonusGame.numberOfSpins = 0;
    this.content.controls.enableAfterSpin();

    if (this.soundBonusAmbient) {
      this.stopSoundBonusAmbient();
      this.setSoundAmbient();
    }
  }

  setLastRound(value) {
    this.lastRound = value;
  }

  setDialogOpen(value) {
    this.isDialogOpen = value;
  }

  setErrorDetails(details) {
    if (details) {
      this.errorDetails = getErrorParams(details, this.options.translations);

      // Hide/disable boost
      if (this.header.uiBoost) {
        if (this.errorDetails.isErrorReloadable) {
          this.header.uiBoost.hide();
        } else {
          this.header.uiBoost.disable();
        }
      }

      if (this.errorDetails.isRciError) return;

      this.rootComponent.openNotification(this.errorDetails.notification);
    } else {
      this.errorDetails = undefined;
    }
  }
}

export const state = new State();
