import { Container } from '@/pixi';
import { Controls } from './Controls';
import { state } from './State';
import { ReelsWrapper } from './ReelsWrapper';

export class Content {
  constructor() {
    this.container = new Container();
    this.container.name = 'Content';

    this.controls = new Controls();
    this.reelsWrapper = new ReelsWrapper();

    this.controls.container.x = state.options.calcWidth / 2;

    this.container.addChild(
      this.controls.container,
      this.reelsWrapper.container,
    );
  }

  setPosition() {
    /* Constants */
    const margin = 12;
    const topPadding = 0;
    const controlsMinScale = 0.7;

    /* Calculations */
    const footerHeightWithPadding = state.appHeight - state.components.footer.container.getLocalBounds().top;
    const topPaddingWithControlsAndFooterHeight = topPadding + this.controls.container.height + footerHeightWithPadding;
    const fullHeight = topPaddingWithControlsAndFooterHeight + this.reelsWrapper.height + margin;

    /* Adjust controls scale */
    let controlsScale = 1;

    if (fullHeight > state.appHeight) {
      controlsScale = Math.max(controlsMinScale, (controlsScale * (state.appHeight - margin - this.reelsWrapper.height - footerHeightWithPadding)) / (this.controls.container.height + topPadding));
    }

    this.controls.setScale(controlsScale);

    /* Calculations after setting controls scale */
    const topPaddingWithScaledControlsAndFooterHeight = topPadding + (this.controls.container.height * this.controls.container.scale.y) + footerHeightWithPadding;
    const spaceLeftForReelsWrapper = state.appHeight - topPaddingWithScaledControlsAndFooterHeight - margin;

    /* Adjust reelsWrapper scale */
    let reelsWrapperScale = 1;

    if (this.reelsWrapper.height > spaceLeftForReelsWrapper) {
      reelsWrapperScale = spaceLeftForReelsWrapper / this.reelsWrapper.height;
    }

    this.reelsWrapper.container.scale.set(reelsWrapperScale);

    /* Position reelsWrapper */
    const reelsWidth = this.reelsWrapper.reels.reelsSize.width * reelsWrapperScale;
    const reelsWrapperY = topPadding + spaceLeftForReelsWrapper / 2 - (this.reelsWrapper.height * reelsWrapperScale) / 2;

    this.reelsWrapper.setPositionY(reelsWrapperY);
    this.reelsWrapper.container.x = state.appWidth / 2 - reelsWidth / 2;

    /* Position controls */
    const controlsY = this.reelsWrapper.getBottomPosition() + margin + ((state.appHeight - (this.reelsWrapper.getBottomPosition() + margin + footerHeightWithPadding)) / 2);

    this.controls.container.y = controlsY;
  }
}
