import { Jackpot } from '@/components/shared/Jackpot';
import * as api from '@/api/casino';
import { triggerEvent } from '../utility/Utility';
import { slotState } from './SlotState';
import { SlotButton } from './SlotButton';
import { audio } from './SlotAudio';

export class SlotJackpot extends Jackpot {
  constructor() {
    super({
      buttonComponent: SlotButton,
      fontBold: slotState.options.fontNameBold,
      jackpotWinAmount: slotState.jackpot.winAmount,
      jackpotIcon: slotState.options.assets.iconJackpot,
      formatValue: (value) => slotState.getMoneyLabel(value, slotState.options.currencyDisplayEnabled),
      getAppWidth: () => slotState.options.size().width / slotState.container.scale.x,
      getAppHeight: () => slotState.options.size().height / slotState.container.scale.y,
      onCollect: () => { this.collectJackpot(); },
      onShow() { this.onShowDialog(); },
      playJackpotSound: () => { this.playSound(); },
      /* translations */
      buttonTitle: slotState.options.translations.jackpotCollect,
      congratulationsText: slotState.options.translations.jackpotCongratulation,
      jackpotWonText: slotState.options.translations.jackpotWon,
    });
  }

  async collectJackpot() {
    slotState.jackpot = undefined;

    audio.stop(slotState.options.assets.soundJackpot);

    triggerEvent('ToggleFreeRounds');

    /* enable boost badge */
    if (slotState.options.boostTenantId) {
      triggerEvent('EnableBoostBadge');
    }

    const balance = await api.getBalance(slotState.options.tenantGameId, slotState.options.playerToken);
    if (balance?.amount) {
      slotState.controls.updateBalance(balance);
    }
  }

  playSound() {
    audio.play(slotState.options.assets.soundJackpot);
  }

  onShowDialog() {
    /* disable boost badge */
    if (slotState.options.boostTenantId) {
      triggerEvent('DisableBoostBadge');
    }
  }
}
