import { clone, merge } from 'lodash';
import { Parser as BaseParser } from '@/utility/Parser';

export class Parser extends BaseParser {
  constructor(params, defaults) {
    super(params, defaults);

    this.finalAssets = null;
    this.paramsClone = undefined;

    this.parseState();
  }

  #getResource(name, fromSpritesheet, disableFallback) {
    return this.getResource(this.paramsClone, name, this.finalAssets, fromSpritesheet, disableFallback);
  }

  parseState() {
    this.paramsClone = clone(this.params);

    const { resources } = this.paramsClone;

    // Add symbols
    const symbolsResources = {};
    const symbols = [1, 2, 3, 4, 5, 6, 7, 8, 'Wild', 'Bonus', 'Trash', 'TrashHeader', 'Upgrade', 'Tnt'];
    for (let i = 0; i < symbols.length; i++) {
      const symbol = symbols[i];
      symbolsResources[`symbol${symbol}`] = resources[`symbol${symbol}`];
    }
    // Add cash symbols 1-6
    for (let i = 1; i <= 6; i++) {
      symbolsResources[`symbolCash${i}`] = resources[`symbolCash${i}`];
    }
    // Add cash header symbols and collect level 1-5
    for (let i = 1; i <= 5; i++) {
      symbolsResources[`symbolCashHeader${i}`] = resources[`symbolCashHeader${i}`];
      symbolsResources[`collectLevel${i}`] = resources[`collectLevel${i}`];
    }
    // Add collect symbols 0-5
    for (let i = 0; i <= 5; i++) {
      symbolsResources[`symbolCollect${i}`] = resources[`symbolCollect${i}`];
    }

    this.finalAssets = {
      icons: this.#getResource('generalUI'),
      storeBackground: resources.storeBackground,
      storeBonusBuyOption: resources.storeBonusBuyOption,
      storeCollectBuyOption: resources.storeCollectBuyOption,
      reloadModalBackground: resources.reloadModalBackground,
      reloadModalLogo: resources.reloadModalLogo,
      // Sounds
      soundTap: resources.buttonClick,
      soundAmbient: resources.baseAmbient,
      soundSymbolWin: resources.symbolWin,
      soundSymbolWinMultiplier: resources.symbolWinMultiplier,
      soundSymbolsEnter: resources.symbolsIn,
      soundSymbolsExit: resources.symbolsOut,
      soundCollectShine: resources.collectShine,
      soundCollectLoop: resources.collectLoop,
      soundCollectCash: resources.cashCollect,
      soundCollected: resources.collected,
      soundCashDrop: resources.cashDrop,
      soundSpin: resources.spin,
      soundBonusSymbolWin: resources.bonusWin,
      soundBonusAmbient: resources.bonusAmbient,
      soundBonusIntro: resources.bonusIntro,
      soundBonusOutro: resources.bonusOutro,
      soundBonusScreenTransition: resources.splash,
      soundTntSymbolWin: resources.explosion,
      soundUpgradeSymbolWin: resources.upgradeShine,
      soundUpgradeSymbolHit: resources.upgradeHit,
      soundCollectLevelUnlocking: resources.levelUnlocking,
      soundCollectLevelUnlocked: resources.levelUnlocked,
      soundSpinsAdded: resources.spinsAdded,
      soundGrading: resources.grading,
      soundGradingLevelUp: resources.gradingLevelUp,
      soundGradingIntro: resources.introGrading,
      soundGradingOutro: resources.outroGrading,
      // Spines
      background: resources.background,
      backgroundBottomGradient: resources.backgroundBottomGradient,
      bonusBackground: resources.bonusBackground,
      reels: resources.reels,
      bonusReels: resources.bonusReels,
      logo: resources.logo,
      spinButton: resources.spinButton,
      storeButton: resources.storeButton,
      autoplayButton: resources.autoplayButton,
      hook: resources.hook,
      screenBackground: resources.screenBackground,
      screenTransition: resources.screenTransition,
      winGrading: resources.winGrading,
      coinBurst: resources.coinBurst,
      bonusScreenContent: resources.bonusScreenContent,
      onboardBackground: resources.onboardBackground,
      onboardSound: resources.onboardSound,
      onboardSlide1: resources.onboardSlide1,
      onboardSlide2: resources.onboardSlide2,
      onboardSlide3: resources.onboardSlide3,
      ...symbolsResources,
    };

    Object.assign(this.finalAssets, {
      uiLobby: this.#getResource('uiLobby_0', 'icons', true),
      uiMenu: this.#getResource('uiMenu_0', 'icons', true),
      iconClose: this.#getResource('iconClose', 'icons', true),
      iconOff: this.#getResource('iconOff', 'icons', true),
      iconOn: this.#getResource('iconOn', 'icons', true),
      iconSettings: this.#getResource('iconSettings', 'icons', true),
      iconInfo: this.#getResource('iconInfo', 'icons', true),
      iconHistory: this.#getResource('iconHistory', 'icons', true),
    });

    delete this.paramsClone.state;
    delete this.paramsClone.resources;

    this.options = merge(this.defaults, Object.assign(this.paramsClone, {
      assets: this.finalAssets,
    }));

    return this.options;
  }
}
