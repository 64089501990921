import { Container } from '@/pixi';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Symbol } from '../Symbol';
import { HeaderReelsSymbol } from '../HeaderReelsSymbol';
import { BonusGameCollectLevels } from '../BonusGameCollectLevels';
import { state } from '../State';

export class RoundPreviewStepReels {
  #container;
  #reels;
  #reelsMask;
  #step;
  #isBonusRound;

  constructor(step, isLastStep, round) {
    this.#step = step;
    this.#isBonusRound = round.isFree;

    this.#container = new Container();
    this.#container.name = 'RoundPreviewStepReels';
    this.#container.sortableChildren = true;

    this.#reels = new Graphics().beginFill(0xFCF8EE).drawRect(0, 0, state.appWidth, this.#symbolBoxSize * this.#rowsLength).endFill();
    this.#reelsMask = this.#reels.clone();
    this.#container.addChild(this.#reels, this.#reelsMask);

    // Set bonus game collect levels for bonus game round
    if (this.#isBonusRound) {
      const bonusGameCollectLevels = new BonusGameCollectLevels({
        levelOnStart: round.level,
        numberOfUpgradeSymbolsOnStart: round.upgradeSymbols,
        symbolBoxSize: this.#symbolBoxSize,
        isStatic: true,
      });
      bonusGameCollectLevels.container.y = this.#symbolBoxSize / 2;
      bonusGameCollectLevels.container.mask = this.#reelsMask;
      bonusGameCollectLevels.container.zIndex = 100; // Bonus game collect levels are always above main reels symbols

      this.#container.addChild(bonusGameCollectLevels.container);
    // Set header reels symbols for base game round
    } else {
      this.#step.collectReel.forEach((symbolObject, columnIndex) => {
        const position = {
          x: this.#symbolBoxSize / 2 + this.#symbolBoxSize * columnIndex,
          y: this.#symbolBoxSize / 2,
        };
        const component = new HeaderReelsSymbol({
          symbolNumber: symbolObject.symbol,
          position,
          prize: symbolObject.totalPrize,
          cashLevel: symbolObject.prizeLevel,
          isStatic: true,
        });
        component.container.mask = this.#reelsMask;
        component.container.zIndex = 100; // Header reel symbol is always above main reels symbols

        this.#container.addChild(component.container);
      });
    }

    // Set main reels
    this.#step.baseReels.forEach((columnSymbols, columnIndex) => {
      columnSymbols.forEach((symbolObject, rowIndex) => {
        const position = {
          x: this.#symbolBoxSize / 2 + this.#symbolBoxSize * columnIndex,
          y: this.#symbolBoxSize / 2 + this.#symbolBoxSize * (rowIndex + 1),
        };

        if (symbolObject.isWinningSymbol) {
          const backgroundHighlight = this.#createSymbolHighlight();
          backgroundHighlight.position = position;
          this.#container.addChild(backgroundHighlight);
        }

        const component = new Symbol({
          symbolNumber: symbolObject.symbol,
          position,
          isStatic: true,
          prize: symbolObject.totalPrize,
          cashLevel: symbolObject.prizeLevel,
          multiplier: symbolObject.multiplier,
          numOfBonusRounds: symbolObject.numOfFreeRounds,
          collectAmount: isLastStep && symbolObject.collectedPrize ? symbolObject.collectedPrize : undefined,
          collectLevel: this.#isBonusRound ? symbolObject.gameLevel : undefined,
          symbolBoxSize: this.#symbolBoxSize,
          isBonusGame: this.#isBonusRound,
        });
        component.container.mask = this.#reelsMask;
        // Bottom right symbol has biggest z-index
        component.setZIndex((columnIndex + 1) * (this.#rowsLength + rowIndex));

        this.#container.addChild(component.container);
      });
    });
  }

  get #columnsLength() {
    return this.#step.baseReels.length;
  }

  get #rowsLength() {
    return this.#step.baseReels[0].length + 1;
  }

  get #symbolBoxSize() {
    return state.appWidth / this.#columnsLength;
  }

  get container() {
    return this.#container;
  }

  #createSymbolHighlight() {
    const rect = new Graphics().beginFill(0x000000).drawRect(-this.#symbolBoxSize / 2, -this.#symbolBoxSize / 2, this.#symbolBoxSize, this.#symbolBoxSize).endFill();
    rect.alpha = 0.25;

    return rect;
  }
}
