import animate from 'gsap';
import { assign } from 'lodash';
import { Container, Sprite } from '@/pixi';
import { Stepper } from '@/components/shared/Stepper';
import { Button } from './Button';
import { gameUtils } from '../GameUtils';
import { state } from '../State';

export class DialogBuyContent {
  constructor() {
    this.container = new Container();
    this.container.name = 'BuyContainer';
    this.list = new Container();

    this.timeline = undefined;
    this.stepper = new Stepper({
      fontName: state.options.fontDefaultNormal,
      values: this.getBetAmounts,
      onIncrease: async (betAmount) => {
        this.stepper.setEnabled(false);
        await this.getPrices(betAmount, 'increaseValue');
        this.stepper.setEnabled(true);
      },
      onDecrease: async (betAmount) => {
        this.stepper.setEnabled(false);
        await this.getPrices(betAmount, 'decreaseValue');
        this.stepper.setEnabled(true);
      },

      playTapSound: () => { state.playTapSound(); },
    });

    this.prices = [];

    this.setup();
  }

  get getBetAmounts() {
    return state.options.settings.predefinedBetAmounts.map((value) => ({
      value,
      label: gameUtils.getMoneyLabel(value, state.options.currencyDisplayEnabled),
    }));
  }

  setup() {
    this.timeline = animate.timeline({
      paused: true,
      onStart: () => {
        this.stepper.setEnabled(false);
      },
      onComplete: () => {
        this.getPrices();
      },
    });

    state.options.assets.bonusBuy.forEach((assetItem, index) => {
      const item = new Container();
      const background = new Sprite(assetItem.resource);

      const button = new Button({
        width: 344,
        height: 112,
        radius: 20,
        isEnabled: false,
        isVisible: false,
        onClick: async () => {
          this.buy(index, this.prices[index]);
        },
      });

      button.container.x = background.width - button.container.width - 24;
      button.container.y = background.height - button.container.height - 48;
      button.container.$ref = button;

      item.addChild(background);
      item.addChild(button.container);

      this.list.addChild(item);
    });

    this.container.addChild(this.stepper.container);
    this.container.addChild(this.list);
  }

  async getPrices(betAmount, action) {
    const requestPayload = [
      state.options.tenantGameId,
      state.options.playerToken,
      { betAmount: betAmount || this.stepper.value.value },
      'calculateBonusPrices',
    ];
    const response = await gameUtils.makeApiRequest('calculateBonusPrices', requestPayload);

    if (response.isError) {
      state.setErrorDetails(response);
    }

    if (response.result) {
      if (action) {
        state.setBetAmount(betAmount);
        this.stepper[action]();
      }

      this.list.children.forEach(async (item, index) => {
        this.prices[index] = response.result[index].price;

        const button = item.children[1].$ref;
        const isButtonEnabled = state.balanceAmount >= this.prices[index];

        button.setValue(this.prices[index]);
        button.setText(gameUtils.getMoneyLabel(this.prices[index], state.options.currencyDisplayEnabled));
        button.setEnabled(isButtonEnabled);

        await button.show(index * 0.125);
      });
    }

    this.stepper.setEnabled(true);
  }

  async buy(bonusIndex, price) {
    this.disableButtons();

    const requestPayload = [
      state.options.tenantGameId,
      state.options.playerToken,
      'buyBonus',
      { betAmount: this.stepper.value.value, bonusIndex },
    ];
    const response = await gameUtils.makeApiRequest('buyBonus', requestPayload);

    if (response.isError) {
      state.setErrorDetails(response);
      this.enableButtons();
    } else {
      this.dialog.hide();
      state.components.content.controls.spin({ ...response, bonusBuyPrice: price });
    }
  }

  setPosition({ width, height }) {
    const aspectRatio = height / width;
    const itemMargin = state.options.uiDialogPadding;
    const itemWidth = width - (itemMargin * 2);

    this.timeline.clear();

    this.list.children.forEach((item, index) => {
      const sprite = item.children[0];
      const button = item.children[1];

      sprite.scale.set(itemWidth / item.children[0].texture.width);

      const yEnd = (itemMargin * index) + (sprite.height * index);
      const yStart = yEnd + 200;

      assign(item, {
        alpha: 0,
        x: itemMargin,
        y: yEnd,
      });

      this.timeline.fromTo(item, {
        pixi: {
          alpha: 0,
          y: yStart,
        },
      }, {
        delay: index === 0 ? 0.35 : 0,
        duration: 0.25,
        pixi: {
          alpha: 1,
          y: yEnd,
        },
        onStart() {
          button.$ref.hide();
        },
      }, '=-0.175');
    });

    if (aspectRatio < 1.65) {
      this.list.scale.set(aspectRatio * 0.55);
    } else {
      this.list.scale.set(1);
    }

    this.stepper.container.x = (width / 2) - (this.stepper.container.width / 2);
    this.stepper.container.y = itemMargin * 1.5;

    this.list.x = (width - (itemMargin * 2) - this.list.width) / 2;
    this.list.y = this.stepper.container.y + itemMargin * 1.5 + this.stepper.container.height;
  }

  disableButtons() {
    this.stepper.setEnabled(false);

    this.list.children.forEach((item) => {
      const button = item.children[1].$ref;
      button.setEnabled(false);
    });
  }

  enableButtons() {
    this.stepper.setEnabled(true);

    this.list.children.forEach((item, index) => {
      const button = item.children[1].$ref;
      const isButtonEnabled = state.balanceAmount >= this.prices[index];

      button.setEnabled(isButtonEnabled);
    });
  }

  show() {
    this.stepper.setValue(state.betAmount);
    this.timeline.play(0, true);
  }
}
