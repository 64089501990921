import { DialogBuyContent } from './DialogBuyContent';
import { Dialog } from './Dialog';
import { state } from '../State';

export class DialogBuy extends Dialog {
  constructor() {
    super({
      title: state.options.translations.dialogStoreTitle,
      content: new DialogBuyContent(),
      backgroundImageAsset: state.options.assets.bonusBuyBackground,
    });
  }
}
