import { Container, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { gameUtils } from './GameUtils';
import { state } from './State';

export class BonusIntroStickyWild {
  constructor() {
    this.container = new Container();
    this.container.name = 'BonusIntroStickyWild';
    this.container.eventMode = 'none';
    this.container.alpha = 0;
    this.container.mask = state.components.root.contentMask;

    this.stickyWildIntro = new Spine(state.options.assets.stickyWildIntro.resource.spineData);
    this.backgroundStickyWildIntro = new Spine(state.options.assets.backgroundStickyWildIntro.resource.spineData);

    this.autoCloseTimeout = undefined;
    this.autoCloseWait = 5000;

    this.pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 45,
    });

    this.container.addChild(this.backgroundStickyWildIntro, this.stickyWildIntro, this.pressAnywhereText);

    this.setListeners();
  }

  setListeners() {
    this.container.on('pointertap', () => this.hide());

    this.stickyWildIntro.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'freeSpinsIntroStart') {
          this.stickyWildIntro.state.setAnimation(0, 'freeSpinsIntroIdle', true);
        }
      },
    });

    this.backgroundStickyWildIntro.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'freeSpinsIntroStart') {
          this.backgroundStickyWildIntro.state.setAnimation(0, 'freeSpinsIntroIdle', true);
        }
      },
    });
  }

  setAnimation(name, loop = false) {
    this.stickyWildIntro.state.setAnimation(0, name, loop);
    this.backgroundStickyWildIntro.state.setAnimation(0, name, false);
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundDimensions = this.backgroundStickyWildIntro.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    /* Calculate elements scale */
    let backgroundScale = 1;

    if (aspectRatio > backgroundAspectRatio) {
      backgroundScale = state.appHeight / backgroundDimensions.height;
    }

    let contentScale = backgroundScale;

    if (aspectRatio < backgroundAspectRatio) {
      contentScale = state.appHeight / backgroundDimensions.height;
    }

    /* Set Positions */
    this.backgroundStickyWildIntro.scale.set(backgroundScale);
    this.backgroundStickyWildIntro.x = state.appWidth / 2 - (backgroundDimensions.width / 2) * backgroundScale;
    this.backgroundStickyWildIntro.y = state.appHeight / 2 - (backgroundDimensions.height / 2) * backgroundScale;

    this.stickyWildIntro.scale.set(contentScale);
    this.stickyWildIntro.x = state.appWidth / 2;
    this.stickyWildIntro.y = state.appHeight;

    this.pressAnywhereText.scale.set(contentScale);
    this.pressAnywhereText.x = state.appWidth / 2;
    this.pressAnywhereText.y = state.appHeight - this.pressAnywhereText.height;
    this.pressAnywhereText.anchor.set(0.5);

    /* Reapply mask. On every resize content mask is recalculated */
    this.container.mask = state.components.root.contentMask;
  }

  addLabel() {
    const slot = this.stickyWildIntro.skeleton.findSlot('text_free_spins');
    const text = new BitmapText(state.options.translations.freeSpins.toUpperCase(), {
      fontName: state.options.fontCherryGold,
      fontSize: 75,
    });

    text.scale.set(gameUtils.getTextScaleX(text, slot.attachment.width));
    text.anchor.set(0.5, 0.41);

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text);
  }

  addValue() {
    const slot = this.stickyWildIntro.skeleton.findSlot('free_spins_value');
    const text = new BitmapText(`${state.bonus.freeRounds}`, {
      fontName: state.options.fontCherryGold,
      fontSize: 225,
    });

    text.name = 'FreeSpinsValue';
    text.anchor.set(0.5, 0.41);

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text);
  }

  show() {
    return new Promise((resolve) => {
      this.addValue();
      this.addLabel();

      this.showTimeline = animate.timeline();

      this.showTimeline.to(this.container, {
        duration: 0.175,
        pixi: {
          alpha: 1,
        },
        onStart: () => {
          this.setAnimation('freeSpinsIntroStart');
          state.playSound('bonusIntroOutroStart');
          state.setDialogOpen(true);
          state.components.header.uiBoost?.hide();
        },
        onComplete: () => {
          this.container.eventMode = 'static';
          this.autoCloseTimeout = setTimeout(() => this.hide(), this.autoCloseWait);
          state.reelsWrapper.setReelsLogoPosition();
          resolve(true);
        },
      });

      this.showTimeline.play();
    });
  }

  hide() {
    this.hideTimeline = animate.timeline();

    this.hideTimeline.to(this.container, {
      duration: 0.175,
      pixi: {
        alpha: 0,
      },
      onStart: () => {
        this.container.eventMode = 'none';
        clearTimeout(this.autoCloseTimeout);
        state.setDialogOpen(false);
        state.components.header.uiBoost?.show();
      },
      onComplete: () => {
        this.setAnimation('freeSpinsIntroIdle');
        state.stopSound('bonusIntroOutroStart');
        state.startBonusAutoplay();
      },
    });

    this.hideTimeline.play();
  }
}
