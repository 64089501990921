import { Container, BitmapText, Text, Sprite, Texture } from '@/pixi';
import { forEach } from 'lodash';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { state } from './State';

export class DialogContentMenuGameInfo {
  constructor() {
    this.container = new Container();
    this.container.name = 'DialogContentMenuGameInfo';
    this.items = [];
    this.maxWidth = state.options.calcWidth - state.options.uiDialogPadding * 2;
    this.itemsmargin = 40;
    this.symbolTitleMargin = 35;
    this.symbols = [
      {
        asset: new Sprite(state.options.assets.staticSymbol0.resource),
        title: state.options.translations.goldOfEgyptTrashSymbolTitle,
        description: state.options.translations.goldOfEgyptTrashSymbolDescription,
        hasPaytable: false,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol1.resource),
        title: state.options.translations.winSymbolsTitle,
        description: this.createWinSymbolPaytable(state.options.config.symbols[1].paytable),
        hasPaytable: true,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol2.resource),
        description: this.createWinSymbolPaytable(state.options.config.symbols[2].paytable),
        hasPaytable: true,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol3.resource),
        title: state.options.translations.goldOfEgyptTopPrizeTitle,
        description: state.options.translations.goldOfEgyptTopPrizeDescription,
        hasPaytable: false,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol4.resource),
        title: state.options.translations.wildSymbolsTitle,
        description: state.options.translations.goldOfEgyptWildFirstSymbolDescription,
        hasPaytable: false,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol5.resource),
        description: state.options.translations.goldOfEgyptWildSecondSymbolDescription,
        hasPaytable: false,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol6.resource),
        title: state.options.translations.goldOfEgyptDeadSymbolsTitle,
        description: state.options.translations.goldOfEgyptDeadFirstSymbolDescription,
        hasPaytable: false,
      },
      {
        asset: new Sprite(state.options.assets.staticSymbol7.resource),
        description: state.options.translations.goldOfEgyptDeadSecondSymbolDescription,
        hasPaytable: false,
      },
    ];

    // Game info
    this.items.push(this.createTitle(state.options.translations.howToPlayTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptGameInfoDescription));

    this.items.push(this.createLineSeparator());

    // Main features
    this.items.push(this.createTitle(state.options.translations.mainFeaturesTitle));

    this.items.push(this.createTitle(state.options.translations.towersTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptTowersDescription));

    this.items.push(this.createTitle(state.options.translations.goldOfEgyptLandingBasicSymbolTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptLandingBasicSymbolDescription));

    this.items.push(this.createTitle(state.options.translations.goldOfEgyptPlayerGetsPaidTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptPlayerGetsPaidDescription));

    this.items.push(this.createTitle(state.options.translations.directWinTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptDirectWinDescription));

    this.items.push(this.createTitle(state.options.translations.buyOptionLabel));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptBuyDescription));

    this.items.push(this.createTitle(state.options.translations.goldOfEgyptRtpTitle));
    this.items.push(this.createDescription(state.options.translations.goldOfEgyptRtpDescription.replace('${RTP}', state.options.config.rtp)));

    forEach(this.symbols, (symbol) => {
      this.items.push(this.createSymbolWithDescription(symbol.asset, symbol.title, symbol.description, symbol.hasPaytable));
    });

    this.items.push(this.createLineSeparator());
    this.scrollBox = new ScrollBox({
      items: this.items,
      itemsMargin: this.itemsmargin,
      padding: state.options.uiDialogPadding,
    });
    this.container.addChild(this.scrollBox.container);
  }

  createTitle(text) {
    return new BitmapText(text, {
      fontName: state.options.fontNameBold,
      fontSize: 48,
      tint: state.options.colors.text,
    });
  }

  createDescription(text, maxWidth = this.maxWidth) {
    return new Text(text, {
      fontFamily: state.options.defaultFontFamily,
      fontSize: 40,
      fontWeight: 300,
      lineHeight: 48,
      fill: state.options.colors.text,
      wordWrap: true,
      wordWrapWidth: maxWidth,
    });
  }

  createLineSeparator() {
    const line = new Sprite(Texture.WHITE);

    line.width = this.maxWidth;
    line.height = 6;
    line.alpha = 0.1;

    return line;
  }

  createWinSymbolPaytable(list) {
    const text = list.reverse().map((i, index) => `${list.length - index} - x${i}`).join('\n');

    return new BitmapText(text, {
      fontName: state.options.fontNameLight,
      fontSize: 36,
      tint: state.options.colors.text,
    });
  }

  createSymbolWithDescription(symbol, titleText, descriptionText, hasPaytable) {
    const container = new Container();

    if (titleText) {
      const title = this.createTitle(titleText);
      container.addChild(title);
    }
    container.addChild(symbol);

    if (titleText) {
      // eslint-disable-next-line
      symbol.y += state.options.uiDialogPadding * 3;
    }

    const description = hasPaytable ? descriptionText : this.createDescription(descriptionText, this.maxWidth - symbol.texture.width);
    description.anchor.set(0, 0.5);
    description.x = symbol.texture.width + state.options.uiDialogPadding;
    description.y = symbol.y + symbol.texture.height / 2;
    container.addChild(description);

    return container;
  }

  scrollToTop() {
    this.scrollBox.scrollToTop();
  }

  setPosition() {
    const dialogHeadHeight = state.dialogMenu.getFullHeadHeight();
    const height = state.appHeight - dialogHeadHeight;

    this.scrollBox.setDimensions({ height });
  }
}
