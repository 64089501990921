import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Sprite, Texture, Rectangle, BitmapText } from '@/pixi';
import * as api from '@/api/casino';
import { Loader } from '@/components/shared/Loader';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { RoundsHistoryTable } from './RoundsHistoryTable';
import { RoundPreviewStepReels } from './RoundPreviewStepReels';
import { state } from '../State';

export class RoundPreviewModal {
  #container;
  #background;
  #arrow;
  #line;
  #loader;
  #scrollBox;
  #table;
  #roundIdText;
  #onClose;
  #headerHeight = 64 + state.options.uiDialogPadding * 2;
  #horizontalPadding = state.options.uiDialogPadding * 1.5;
  #tableRowHeight = 140;
  #itemsMargin = 40;

  constructor({ roundId, onClose }) {
    this.#onClose = onClose;

    // Setup

    const scrollBoxItems = [];

    this.#container = new Container();
    this.#container.name = 'RoundPreviewModal';

    this.#background = new Sprite(Texture.WHITE);
    this.#background.tint = 0x000000;
    this.#background.eventMode = 'dynamic';

    const arrowSize = 20;
    this.#arrow = new Graphics()
      .lineStyle(6, state.options.colors.text)
      .moveTo(-arrowSize, -arrowSize / 2)
      .lineTo(0, arrowSize / 2)
      .lineTo(arrowSize, -arrowSize / 2);
    this.#arrow.angle = 90;
    this.#arrow.x = this.#horizontalPadding * 2;
    this.#arrow.y = this.#headerHeight / 2;

    this.#line = new Sprite(Texture.WHITE);
    this.#line.height = 2;
    this.#line.y = this.#headerHeight;
    this.#line.alpha = 0.1;
    this.#line.anchor.set(0, 1);

    this.#loader = new Loader({ color: state.options.colors.theme });

    this.#table = new RoundsHistoryTable({
      columnPadding: this.#horizontalPadding,
      rowHeight: this.#tableRowHeight,
    });
    // Set height to header + 1 row
    this.#table.setPosition(this.#tableRowHeight * 2);
    scrollBoxItems.push(this.#table.container);

    this.#roundIdText = new BitmapText(`${state.options.translations.round}: ${roundId}`, {
      fontName: state.options.fontNameNormal,
      fontSize: 38,
      color: state.options.colors.text,
    });
    this.#roundIdText.x = this.#horizontalPadding;
    scrollBoxItems.push(this.#roundIdText);

    this.#scrollBox = new ScrollBox({
      items: scrollBoxItems,
      itemsMargin: this.#itemsMargin,
      verticalPadding: this.#itemsMargin / 2,
    });
    this.#scrollBox.container.visible = false;

    this.#container.addChild(this.#background, this.#arrow, this.#line, this.#loader.container, this.#scrollBox.container);

    this.setPosition();

    // Set actions

    const hitAreaExtraSpace = 25;
    this.#arrow.eventMode = 'static';
    this.#arrow.cursor = 'pointer';
    this.#arrow.hitArea = new Rectangle(
      -this.#arrow.width / 2 - hitAreaExtraSpace,
      -this.#arrow.height / 2 - hitAreaExtraSpace,
      this.#arrow.width + hitAreaExtraSpace * 2,
      this.#arrow.height + hitAreaExtraSpace * 2,
    );
    this.#arrow.on('pointertap', this.#close.bind(this));

    // Call request

    this.#getRound(roundId);
  }

  get container() {
    return this.#container;
  }

  #close() {
    this.#container.destroy();
    this.#onClose();

    state.playTapSound();
  }

  #addStepsReels(round) {
    const scrollBoxItems = [];
    const isBonusRound = round.isFree;
    const stepsFiltered = isBonusRound ? round.steps.slice(-1) : round.steps;

    stepsFiltered.forEach((step, index) => {
      const titleContainer = new Container();
      const titleStyle = { fontName: state.options.fontNameNormal, fontSize: 42, color: state.options.colors.text };

      // Create title text
      const titleText = index === 0 ? state.options.translations.spin : `${state.options.translations.respin} ${index}`;
      const title = new BitmapText(titleText, titleStyle);
      title.x = state.options.uiDialogPadding;
      titleContainer.addChild(title);

      // Create win amount text
      const stepWinAmount = isBonusRound ? round.totalWinAmount : step.totalStepWinAmount;
      const winAmountText = `${state.options.translations.win}: ${state.getMoneyLabel(stepWinAmount, state.options.currencyDisplayEnabled)}`;
      const winAmount = new BitmapText(winAmountText, titleStyle);
      winAmount.x = state.appWidth - state.options.uiDialogPadding - winAmount.width;
      titleContainer.addChild(winAmount);

      // Create round preview step reels
      const isLastStep = index === stepsFiltered.length - 1;
      const component = new RoundPreviewStepReels(step, isLastStep, round);

      scrollBoxItems.push(titleContainer, component.container);
    });

    this.#scrollBox.addItems(scrollBoxItems);
  }

  async #getRound(id) {
    const response = await api.getRound({
      id,
      tenantGameId: state.options.tenantGameId,
    });

    this.#loader.container.visible = false;

    if (response.isError) {
      state.setErrorDetails(response);
    } else if (!this.#container.destroyed) {
      this.#table.addRoundsRows([response.round]);
      this.#addStepsReels(response.round);
      this.#scrollBox.container.visible = true;
    }
  }

  setPosition() {
    this.#background.width = state.appWidth;
    this.#background.height = state.appHeight;

    this.#line.width = state.appWidth;

    this.#loader.container.x = state.appWidth / 2;
    this.#loader.container.y = (state.appHeight - this.#headerHeight) / 2;

    this.#roundIdText.maxWidth = state.appWidth - this.#horizontalPadding * 2;

    this.#scrollBox.container.y = this.#headerHeight + this.#itemsMargin;
    this.#scrollBox.setDimensions({ height: state.appHeight - this.#scrollBox.container.y });
  }
}
