import { Dialog as BaseDialog } from '@/components/shared/Dialog';
import { state } from '../State';

export class Dialog extends BaseDialog {
  constructor(params) {
    super({
      iconCloseAsset: state.options.assets.iconClose,
      getAppWidth: () => state.options.size().width / state.appScale,
      getAppHeight: () => state.options.size().height / state.appScale,
      playTapSound: () => { state.playTapSound(); },
      ...params,
      onShow: () => {
        if (this.isFullscreen) {
          state.components.header.uiBoost?.hide();
        } else {
          state.components.header.uiBoost?.disable();
        }

        state.setDialogOpen(true);

        params.onShow?.();
      },
      onClose: () => {
        if (this.isFullscreen) {
          state.components.header.uiBoost?.show();
        } else {
          state.components.header.uiBoost?.enable();
        }

        state.setDialogOpen(false);

        params.onClose?.();
      },
    });
  }
}
