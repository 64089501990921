import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container } from '@/pixi';
import { Cash } from './Cash';
import { state } from './State';

export class WinGrading {
  #container;
  #background;
  #grading;
  #winValue;
  #coinBurst;
  #coinBurst2;
  #transition;
  #gradingAssetHeight;
  #gradingAssetWidth;
  #timeline;
  #winValueScale1AnimationDuration = 0.25;
  #winValueScale2AnimationDuration = 0.1;
  #winValueAnimationDuration = this.#winValueScale1AnimationDuration + this.#winValueScale2AnimationDuration;
  #transitionTimeSpot = 1300;
  #coinBurstLoopEnabled = false;

  constructor() {
    const { winGrading, screenBackground, screenTransition, coinBurst } = state.assets;

    this.#container = new Container();
    this.#container.name = 'WinGrading';

    this.#background = new Spine(screenBackground.resource.spineData);

    this.#grading = new Spine(winGrading.resource.spineData);
    this.#gradingAssetHeight = this.#grading.height;
    this.#gradingAssetWidth = this.#grading.width;
    this.#setGradingStartState();

    const winValueMaxWidth = this.#gradingAssetWidth / 2; // 2 is this.#winValue.container.parent.scale.x
    this.#winValue = new Cash(0, winValueMaxWidth, 150);
    // Remove default number text, and add winValue
    const numberContainer = this.#grading.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number');
    numberContainer.removeChildren();
    numberContainer.addChild(this.#winValue.container);

    this.#coinBurst = new Spine(coinBurst.resource.spineData);
    this.#coinBurst2 = new Spine(coinBurst.resource.spineData);

    this.#transition = new Spine(screenTransition.resource.spineData);

    this.#background.visible = false;
    this.#coinBurst.visible = false;
    this.#coinBurst2.visible = false;
    this.#transition.visible = false;
    this.#container.visible = false;

    this.#container.addChild(this.#background, this.#grading, this.#coinBurst, this.#coinBurst2, this.#transition);

    this.#setListeners();
  }

  get #outroAnimationDuration() {
    return this.#background.stateData.skeletonData.findAnimation('outro').duration;
  }

  get #gradingWinValueTransitionAnimationDuration() {
    return this.#grading.stateData.skeletonData.findAnimation('win').duration;
  }

  get #coinBurstAnimationDuration() {
    return this.#coinBurst.stateData.skeletonData.findAnimation('coinBurst').duration;
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#background.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'intro') {
          this.#runIdleAnimation();
        }
      },
    });

    this.#grading.state.addListener({
      complete: (trackEntry) => {
        // After intro run idle animation
        if (trackEntry.animation.name.includes('WinIntro')) {
          const type = trackEntry.animation.name.split('WinIntro')[0];
          this.#runGradingIdleAnimation(type);
        }
      },
    });

    this.#coinBurst.state.addListener({
      complete: () => {
        // Stop loop?
        if (!this.#coinBurstLoopEnabled) {
          this.#coinBurst.state.timeScale = 0;
        }
      },
    });

    this.#coinBurst2.state.addListener({
      complete: () => {
        // Stop loop?
        if (!this.#coinBurstLoopEnabled) {
          this.#coinBurst2.state.timeScale = 0;
        } else {
          // Speed coin burst after every sequence
          this.#coinBurst.state.timeScale += 0.05;
          this.#coinBurst2.state.timeScale += 0.05;
        }
      },
    });

    // On click skip timeline to outro animation start
    this.#background.cursor = 'pointer';
    this.#background.on('pointertap', () => {
      state.playTapSound();
      this.#timeline.seek(`-=${this.#outroAnimationDuration}`, false);
    });
    this.#grading.eventMode = 'none';
    this.#transition.eventMode = 'none';
    this.#coinBurst.eventMode = 'none';
    this.#coinBurst2.eventMode = 'none';
  }

  #getGradingIntroAnimationDuration(type) {
    return this.#grading.stateData.skeletonData.findAnimation(`${type}WinIntro`).duration;
  }

  #getGradingIdleAnimationDuration(type) {
    return this.#grading.stateData.skeletonData.findAnimation(`${type}WinIdle`).duration;
  }

  #runIntroAnimation() {
    this.#background.visible = true;
    this.#background.eventMode = 'static'; // Enable click
    this.#transition.visible = true;
    this.#background.state.setAnimation(0, 'intro');
    this.#transition.state.setAnimation(0, 'bigWinIntro');

    // Start coin burst loop after transition
    setTimeout(() => {
      let isWinGradingStopped = this.#background.eventMode === 'none';

      if (!isWinGradingStopped) {
        this.#coinBurst.state.setAnimation(0, 'coinBurst', true);
        this.#coinBurst.state.timeScale = 1;
        this.#coinBurst.visible = true;
        this.#coinBurstLoopEnabled = true;

        // Start second coin burst loop in the middle of first
        setTimeout(() => {
          isWinGradingStopped = this.#background.eventMode === 'none';

          if (this.#coinBurstLoopEnabled && !isWinGradingStopped) {
            this.#coinBurst2.state.setAnimation(0, 'coinBurst', true);
            this.#coinBurst2.state.timeScale = 1;
            this.#coinBurst2.visible = true;
          }
        }, (this.#coinBurstAnimationDuration / 2) * 1000);
      }
    }, this.#transitionTimeSpot - 700);
  }

  #runIdleAnimation() {
    this.#background.state.setAnimation(0, 'idle', true);
    this.#transition.state.setAnimation(0, 'idle', true);
  }

  #runOutroAnimation() {
    this.#background.state.setAnimation(0, 'outro');
    this.#background.eventMode = 'none'; // Disable click
    this.#transition.state.setAnimation(0, 'outro');

    setTimeout(() => {
      this.#coinBurst.visible = false;
      this.#coinBurst.state.timeScale = 0;
      this.#coinBurst2.visible = false;
      this.#coinBurst2.state.timeScale = 0;
    }, this.#transitionTimeSpot);
  }

  #setGradingStartState() {
    this.#grading.state.setAnimation(0, 'winIdle');
  }

  #runGradingWinValueTransitionAnimation() {
    this.#grading.state.setAnimation(0, 'win');
  }

  #runGradingIntroAnimation(type) {
    this.#grading.state.setAnimation(0, `${type}WinIntro`);
  }

  #runGradingIdleAnimation(type) {
    this.#grading.state.setAnimation(0, `${type}WinIdle`, true);
  }

  #runGradingOutroAnimation(type) {
    this.#grading.state.setAnimation(0, `${type}WinOutro`);
  }

  #reset() {
    this.#container.visible = false;
    this.#background.visible = false;
    this.#transition.visible = false;
    this.#winValue.resetScale();
    this.#winValue.setCash(0);
    this.#setGradingStartState();
  }

  async show(winAmount) {
    const winGrades = state.roundResponse.isFree ? state.roundResponse.bonusWinnings?.winGrades : state.roundResponse.winGrades;
    const pixiScaleStart = { scaleX: 0, scaleY: 0 };
    const pixiScaleMiddle = { scaleX: 1.25, scaleY: -1.25 };
    const pixiScaleEnd = { scaleX: 1, scaleY: -1 };

    this.#timeline = gsap.timeline({ paused: true });

    // Scale 1
    this.#timeline.fromTo(this.#winValue.container, {
      pixi: pixiScaleStart,
    }, {
      pixi: pixiScaleMiddle,
      onStart: () => {
        this.#container.visible = true;

        if (winGrades) {
          state.playSound('soundGradingIntro');
        }
      },
      duration: this.#winValueScale1AnimationDuration,
    }, 'start');

    // Scale 2
    this.#timeline.fromTo(this.#winValue.container, {
      pixi: pixiScaleMiddle,
    }, {
      delay: this.#winValueScale1AnimationDuration,
      pixi: pixiScaleEnd,
      duration: this.#winValueScale2AnimationDuration,
    }, 'start');

    if (winGrades) {
      const firstAmountTo = state.betAmount * 4;
      const hideWinValueDelay = 1;

      this.#timeline.add(this.#winValue.createAmountAnimateTimeline(firstAmountTo, this.#winValueAnimationDuration + this.#gradingWinValueTransitionAnimationDuration), 'start');
      this.#timeline.add(() => {
        this.#runGradingWinValueTransitionAnimation();
        state.components.header.uiBoost?.disable();
      }, `-=${this.#gradingWinValueTransitionAnimationDuration}`);

      winGrades.forEach((grade, index) => {
        const type = grade.type.toLowerCase();
        const amountFrom = index > 0 ? grade.amountFrom : firstAmountTo;
        const duration = grade.timeMs / 1000;

        this.#timeline.to(this.#grading, {
          onStart: () => {
            // On first grade
            if (index === 0) {
              this.#runIntroAnimation();

              state.setSoundVolume('soundAmbient', 0.3);
              state.playSound('soundGrading');
            } else {
              setTimeout(() => {
                state.playSound('soundGradingLevelUp');
              }, 125);
            }
            this.#runGradingIntroAnimation(type);
          },
        }, type);

        this.#timeline.add(this.#winValue.createAmountAnimateTimeline(grade.amountTo, duration, undefined, amountFrom), type);

        // On last grade
        if (index === winGrades.length - 1) {
          const lastGradeDuration = duration + hideWinValueDelay;
          const gradingIntroAnimationDuration = this.#getGradingIntroAnimationDuration(type);
          let delay;

          // In this case wait grading intro animation to finish then call outro
          if (lastGradeDuration <= gradingIntroAnimationDuration) {
            delay = gradingIntroAnimationDuration;
          // In this case wait idle animation to finish then call outro
          } else {
            const remainder = (lastGradeDuration - gradingIntroAnimationDuration) % this.#getGradingIdleAnimationDuration(type);
            const timeLeftForIdleToFinish = remainder > 0 ? 1 - remainder : 0;
            delay = lastGradeDuration + timeLeftForIdleToFinish;
          }

          // Stop coin burst loop after amount animate timeline is done
          this.#timeline.add(() => { this.#coinBurstLoopEnabled = false; }, `${type}+=${duration}`);

          let interval;
          this.#timeline.to(this.#grading, {
            delay,
            onStart: () => {
              this.#runOutroAnimation();
              this.#runGradingOutroAnimation(type);

              state.playSound('soundGradingOutro');
              interval = setInterval(() => {
                state.setSoundVolume('soundGrading', state.assets.soundGrading.resource.volume() - 0.1);
              }, (this.#outroAnimationDuration * 1000) / 10);
            },
            duration: this.#outroAnimationDuration,
            onComplete: () => {
              state.components.header.uiBoost?.enable();
              this.#reset();

              clearInterval(interval);
              state.stopSound('soundGrading');
              state.setSoundVolume('soundGrading', 1); // Reset for next time
              state.setSoundVolume('soundAmbient', 1);
            },
          }, type);
        }
      });
    } else {
      const hideWinValueDelay = 0.55;

      this.#timeline.add(this.#winValue.createAmountAnimateTimeline(winAmount, this.#winValueAnimationDuration), 'start');

      // Set scale to 0
      this.#timeline.fromTo(this.#winValue.container, {
        pixi: pixiScaleEnd,
      }, {
        delay: hideWinValueDelay,
        pixi: pixiScaleMiddle,
        duration: 0.1,
      }).fromTo(this.#winValue.container, {
        pixi: pixiScaleMiddle,
      }, {
        pixi: pixiScaleStart,
        duration: 0.1,
        onComplete: () => {
          this.#reset();
        },
      });
    }

    await this.#timeline.play();
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAttachment = this.#background.spineData.defaultSkin.attachments[0].background;
    const backgroundAttachmentWidth = backgroundAttachment.width * backgroundAttachment.scaleX;
    const backgroundAttachmentHeight = backgroundAttachment.height * backgroundAttachment.scaleY;
    const backgroundAspectRatio = backgroundAttachmentHeight / backgroundAttachmentWidth;

    // If app aspect ratio is larger then background aspect ratio then set larger background/transition scale
    if (aspectRatio > backgroundAspectRatio) {
      this.#background.scale.set(state.appHeight / backgroundAttachmentHeight);
      this.#transition.scale.set(this.#background.scale.y);
    } else {
      this.#background.scale.set(1);
      this.#transition.scale.set(1);
    }

    // Set background/transition to center
    this.#background.x = state.appWidth / 2;
    this.#background.y = state.appHeight / 2;
    this.#transition.x = this.#background.x;
    this.#transition.y = this.#background.y;

    // Set grading scale
    if (this.#gradingAssetHeight > state.appHeight) {
      this.#grading.scale.set(state.appHeight / this.#gradingAssetHeight);
    } else {
      this.#grading.scale.set(1);
    }

    // Set grading to center
    this.#grading.x = state.appWidth / 2 - (this.#gradingAssetWidth * this.#grading.scale.x) / 2;
    this.#grading.y = state.appHeight / 2 - (this.#gradingAssetHeight * this.#grading.scale.y) / 2;

    // Set coin burst position
    this.#coinBurst.x = state.appWidth / 2;
    this.#coinBurst.y = state.appHeight;
    this.#coinBurst2.x = this.#coinBurst.x;
    this.#coinBurst2.y = this.#coinBurst.y;

    // Set coin burst scale
    if (state.appScale < 0.5) {
      this.#coinBurst.scale.set(1 + (1 - state.appScale));
    } else {
      this.#coinBurst.scale.set(1);
    }

    this.#coinBurst2.scale.set(this.#coinBurst.scale.x);
  }
}
