import { Container, Sprite, Texture } from '@/pixi';
import { Loader } from '@/components/shared/Loader';
import { state } from './State';

export class LoaderOverlay {
  #container;
  #background;
  #loader;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'LoaderOverlay';
    this.#container.eventMode = 'dynamic';
    this.#container.visible = false;

    this.#background = new Sprite(Texture.WHITE);
    this.#background.tint = 0x000000;
    this.#background.alpha = 0.4;

    this.#loader = new Loader({});

    this.#container.addChild(this.#background, this.#loader.container);
  }

  get container() {
    return this.#container;
  }

  show() {
    this.#container.visible = true;

    state.components.header.container.alpha = 0.3;
    state.components.content.container.alpha = 0.3;
    state.components.footer.container.alpha = 0.3;
    state.components.header.uiBoost?.disable('0.3');
  }

  hide() {
    if (this.#container.visible) {
      state.components.header.container.alpha = 1;
      state.components.content.container.alpha = 1;
      state.components.footer.container.alpha = 1;
      state.components.header.uiBoost?.enable();

      this.#container.visible = false;
    }
  }

  setPosition() {
    this.#background.width = state.appWidth;
    this.#background.height = state.appHeight;
    this.#loader.container.x = state.appWidth / 2;
    this.#loader.container.y = state.appHeight / 2;
  }
}
