import animate from 'gsap';
import { assign, defaultTo, each, flatten } from 'lodash';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { ScrollBox } from './shared/ScrollBox';
import { Container, Sprite, BitmapText } from '../pixi';
import { Stepper } from './shared/Stepper';
import { SlotButton } from './SlotButton';
import { slotState } from './SlotState';
import { callApiWithRetries, triggerEvent } from '../utility/Utility';
import * as api from '../api/casino';

export class SlotDialogBuy {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.scrollBox = undefined;
    this.buyOptionsList = [];
    this.priceIndex = undefined;
    this.buyActions = [];
    this.featuresWithoutBonusGame = ['buyCollect'];

    this.buyOptions = [
      {
        enabled: 'isCollectBuyEnabled',
        buyAction: 'buyCollect',
        priceAction: 'calculateCollectPrice',
        assetNames: ['buy_option_five'],
      }, {
        enabled: 'isProgressBuyEnabled',
        buyAction: 'buyProgress',
        priceAction: 'calculateProgressPrice',
        assetNames: ['buy_option_four'],
      }, {
        enabled: 'isBonusBuyEnabled',
        buyAction: 'buyBonus',
        priceAction: 'calculateBonusPrices',
        assetNames: ['buy_option_one', 'buy_option_two', 'buy_option_three'],
      },
    ];

    this.stepper = new Stepper({
      fontName: this.options.fontNameNormal,
      highlightColor: this.options.colors.highlightBrighter,
      highlightTextColor: this.options.colors.highlightText,
      values: this.getBetAmounts(),
      isEnabled: false,
      onIncrease: async (betAmount) => {
        if (slotState.isBetIncreased(betAmount)) {
          const isUserApproved = await slotState.dialogWarning.content.getWarningResponse();
          if (!isUserApproved) return;
        }
        this.stepper.setEnabled(false);
        await this.getPrices(betAmount);
        this.updateBetAmount(betAmount);
        this.stepper.increaseValue();
        this.stepper.setEnabled(true);
      },
      onDecrease: async (betAmount) => {
        this.stepper.setEnabled(false);
        await this.getPrices(betAmount);
        this.updateBetAmount(betAmount);
        this.stepper.decreaseValue();
        this.stepper.setEnabled(true);
      },
      playTapSound: () => { slotState.playTapSound(); },
    });

    this.timeline = undefined;
    this.itemAnimationOffset = 200;
    this.pointerY = undefined;

    this.setup();
  }

  addPrice(prices) {
    each(prices, async (priceObject, index) => {
      this.priceIndex = this.priceIndex === undefined ? 0 : this.priceIndex += 1;

      const buttonContainer = this.buyOptionsList[this.priceIndex].children[1];
      const button = buttonContainer.$ref;
      const priceDescription = priceObject;
      const price = priceDescription?.discountPrice || priceDescription?.price;
      const buttonEnabled = slotState.balanceAmount >= price && price > 0;

      button.setValue(price);
      button.setText(slotState.getMoneyLabel(price, this.options.currencyDisplayEnabled));
      button.setEnabled(buttonEnabled);

      // When discount price is used, show real price also
      if (priceDescription?.discountPrice) {
        const addon = this.createPriceButtonAddon(buttonContainer, button, priceDescription?.price);
        this.buyOptionsList[this.priceIndex].addChild(addon);
      }

      await button.show(index * 0.125);
    });
  }

  async buy(button, action, bonusSymbols) {
    const isBonusGame = !this.featuresWithoutBonusGame.includes(action);

    let result = await api.buyBonus(this.options.tenantGameId, this.options.playerToken, action, {
      betAmount: this.stepper.value.value,
      activePaylines: slotState.activePaylines,
      ...(bonusSymbols && { bonusSymbols }),
    });

    result = { ...result, bonusBuyAmount: button.value };

    if (result.isError) {
      button.setEnabled(true);
      slotState.setErrorDetails({ ...result, errorOrigin: 'dialog' });
    } else {
      this.dialog.hide();
      triggerEvent('SpinBonusBuy', { result, isBonusGame });
    }

    slotState.featureToPurchase = undefined;
  }

  async calculatePrice(betAmount, action) {
    const props = [this.options.tenantGameId, this.options.playerToken, {
      betAmount: defaultTo(betAmount, this.stepper.value.value),
      activePaylines: slotState.activePaylines,
    }, action];

    const result = await callApiWithRetries(api.calculateBonusPrices, props, 5);

    if (result.isError) {
      slotState.setErrorDetails({ ...result, errorOrigin: 'dialog' });
      return this.showNoPrice(action);
    }

    return result;
  }

  disableButtons() {
    this.stepper.setEnabled(false);

    each(this.buyOptionsList, (option) => {
      const button = option.children[1].$ref;
      button.setEnabled(false);
    });
  }

  getBetAmounts() {
    return this.options.settings.predefinedBetAmounts.map((value) => ({
      value,
      label: slotState.getMoneyLabel(value, this.options.currencyDisplayEnabled),
    }));
  }

  async getPrices(betAmount) {
    await this.buyOptions.reduce(async (prevPromise, option) => {
      await prevPromise;

      if (slotState[option.enabled]) {
        const result = await this.calculatePrice(betAmount, option.priceAction);

        if (result) {
          this.destroyPriceButtonAddons();

          const pricesArray = [];

          if (result.prices) {
            Object.keys(result.prices).forEach((val) => pricesArray.push(result.prices[val]));
          } else {
            pricesArray.push(result);
          }

          this.addPrice(pricesArray);
        }
      }
    }, Promise.resolve());

    this.priceIndex = undefined;
    this.stepper.setEnabled(true);
  }

  isOptionActive(assetName) {
    return this.options.assets.bonusBuy.some((item) => item.asset.name === assetName);
  }

  setup() {
    this.timeline = animate.timeline({
      paused: true,
      onStart: () => {
        this.stepper.setEnabled(false);
      },
      onComplete: () => {
        this.getPrices();
      },
    });

    const buttonMargin = this.options.uiPadding / 2;

    each(this.sortBuyOptions(), (assetItem, index) => {
      const item = new Container();
      const background = new Sprite(assetItem.asset.resource);
      const action = this.buyActions[index];

      const button = new SlotButton({
        width: 344,
        height: 112,
        radius: 20,
        isEnabled: false,
        isVisible: false,
        onClick: async () => {
          slotState.featureToPurchase = action;
          if (!await slotState.dialogWarning.content.getWarningResponse()) return;
          this.disableButtons();
          await this.buy(button, action, assetItem.symbolsCount);
        },
      });

      button.container.x = background.width - button.container.width - buttonMargin;
      button.container.y = background.height - button.container.height - (buttonMargin * 2);
      button.container.$ref = button;

      item.addChild(background);
      item.addChild(button.container);

      this.buyOptionsList.push(item);
    });
  }

  setPosition({ width, height }) {
    const scale = slotState.container.scale.y;
    const aspectRatio = height / width;
    const scrollBoxScale = aspectRatio < 1.65 ? aspectRatio * 0.55 : 1;
    const headHeight = slotState.dialogBonusBuy.getFullHeadHeight();
    const dialogHeadHeight = headHeight * scale;
    const stepperBounds = this.stepper.container.getBounds();
    const stepperHeight = headHeight + (stepperBounds.height * scale);
    const itemMargin = this.options.uiDialogPadding;
    const itemWidth = width - (itemMargin * 2);

    this.container.removeChildren();
    this.scrollBox = undefined;
    this.timeline.clear();

    this.buyOptionsList.forEach((item, index) => {
      const sprite = item.children[0];
      const button = item.children[1];

      sprite.scale.set(itemWidth / item.children[0].texture.width);

      const yEnd = (itemMargin * index) + (sprite.height * index);
      const yStart = yEnd + this.itemAnimationOffset;

      assign(item, {
        alpha: 0,
        x: 0,
        y: yEnd,
      });

      this.timeline.fromTo(item, {
        pixi: {
          alpha: 0,
          y: yStart,
        },
      }, {
        delay: index === 0 ? 0.35 : 0,
        duration: 0.25,
        pixi: {
          alpha: 1,
          y: yEnd,
        },
        onStart() {
          button.$ref.hide();
        },
      }, '=-0.175');
    });

    this.scrollBox = new ScrollBox({
      items: this.buyOptionsList,
      height: (height - dialogHeadHeight - stepperHeight - itemMargin * 2) / scrollBoxScale,
      horizontalPadding: aspectRatio < 1.65 ? 0 : itemMargin,
      verticalPadding: itemMargin,
      itemsMargin: itemMargin,
    });

    this.container.addChild(this.stepper.container);
    this.container.addChild(this.scrollBox.container);

    if (aspectRatio < 1.65) {
      this.scrollBox.container.scale.set(scrollBoxScale);
      this.scrollBox.container.x = width / 2 - this.scrollBox.container.width / 2;
    } else {
      this.scrollBox.container.scale.set(1);
      this.scrollBox.container.x = 0;
    }

    this.stepper.container.x = (width / 2) - (this.stepper.container.width / 2);
    this.stepper.container.y = itemMargin * 1.5;
    this.scrollBox.container.y = stepperHeight + itemMargin;
  }

  sortBuyOptions() {
    const itemsOrder = [];
    const sortedArray = [];

    each(this.buyOptions, (option) => {
      itemsOrder.push(option.assetNames);

      each(option.assetNames, (assetName) => {
        if (this.isOptionActive(assetName)) {
          this.buyActions.push(option.buyAction);
        }
      });
    });

    each(flatten(itemsOrder), (assetName) => {
      sortedArray.push(this.options.assets.bonusBuy.find((item) => item.asset.name === assetName));
    });

    return sortedArray.filter((item) => item !== undefined);
  }

  show() {
    this.stepper.setValue(slotState.betAmount);
    this.destroyPriceButtonAddons();
    this.timeline.play(0, true);
  }

  showNoPrice(action) {
    const index = this.buyOptions.findIndex((option) => option.priceAction === action);
    const buyOption = this.buyOptions[index];

    let numOfOptions = 0;

    each(buyOption.assetNames, (assetName) => {
      if (this.isOptionActive(assetName)) {
        numOfOptions++;
      }
    });

    if (numOfOptions > 1) {
      const price = { prices: {} };
      for (let i = 0; i < numOfOptions; i++) {
        price.prices[i] = { price: 0 };
      }
      return price;
    }

    return numOfOptions === 1 ? { price: 0 } : undefined;
  }

  updateBetAmount(value) {
    triggerEvent('BetAmountChanged', {
      value,
    });
  }

  createPriceButtonAddon(buttonContainer, button, price) {
    const addon = new Container();
    const addonWidth = button.width;
    const addonHeight = 40;
    const addonRadius = button.radius;
    const addonBackground = new Graphics();
    const addonBackgroundColor = 0x000000;
    const addonText = new BitmapText(slotState.getMoneyLabel(price, this.options.currencyDisplayEnabled), {
      fontName: button.fontName,
      fontSize: button.textSize * 0.7,
      tint: this.options.colors.text,
    });
    const lineThrough = new Graphics();

    addon.name = 'addon';
    addon.x = buttonContainer.x;
    addon.y = buttonContainer.y - addonHeight / 2;
    addon.eventMode = button.container.eventMode;
    addon.cursor = button.container.cursor;
    addon.alpha = button.container.alpha;
    addon.on('pointertap', button.click.bind(button));

    // Draw rect with rounded top left and top right corners
    addonBackground.beginFill(addonBackgroundColor)
      .moveTo(addonRadius, 0)
      .lineTo(addonWidth - addonRadius, 0)
      .arcTo(addonWidth, 0, addonWidth, addonRadius, addonRadius)
      .lineTo(addonWidth, addonHeight)
      .lineTo(0, addonHeight)
      .lineTo(0, addonRadius)
      .arcTo(0, 0, addonRadius, 0, addonRadius)
      .endFill();
    addon.addChild(addonBackground);

    addonText.x = addonWidth / 2;
    addonText.y = addonHeight / 2;
    addonText.anchor.set(0.5);
    addon.addChild(addonText);

    lineThrough.lineStyle(3, this.options.colors.text, 1)
      .moveTo(addonText.x - addonText.width / 2 - 2, addonHeight / 2)
      .lineTo(addonText.x + addonText.width / 2 + 2, addonHeight / 2);
    addon.addChild(lineThrough);

    return addon;
  }

  destroyPriceButtonAddons() {
    this.buyOptionsList.forEach((item) => {
      const childIndex = item.children.findIndex((child) => child.name === 'addon');
      if (childIndex > -1) {
        item.children[childIndex].destroy({
          children: true,
          texture: true,
        });
      }
    });
  }
}
