import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container, Sprite, Texture, Rectangle, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import { chunk, flatten, isArray, isEmpty } from 'lodash';
import { Loader } from '@/components/shared/Loader';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { RoundsHistoryTable } from './RoundsHistoryTable';
import { gameUtils } from '../GameUtils';
import { state } from '../State';
import { Symbol } from '../Symbol';
import { Cash } from '../Cash';

export class RoundPreviewModal {
  constructor({ onClose, roundId }) {
    this.arrowSize = 20;
    this.headerHeight = 64 + state.options.uiDialogPadding * 2;
    this.horizontalPadding = state.options.uiDialogPadding * 1.5;
    this.itemsMargin = 40;
    this.onClose = onClose;
    this.tableRowHeight = 140;
    this.round = undefined;

    this.container = new Container();
    this.container.name = 'RoundPreviewModal';
    this.reelsContainer = new Container();
    this.reelsContainer.name = 'RoundPreviewReels';
    this.reelsContainer.sortableChildren = true;

    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.eventMode = 'dynamic';

    this.arrow = new Graphics()
      .lineStyle(6, state.options.colors.text)
      .moveTo(-this.arrowSize, -this.arrowSize / 2)
      .lineTo(0, this.arrowSize / 2)
      .lineTo(this.arrowSize, -this.arrowSize / 2);
    this.arrow.angle = 90;

    this.line = new Sprite(Texture.WHITE);
    this.line.width = state.options.calcWidth;
    this.line.height = 2;
    this.line.alpha = 0.1;
    this.line.anchor.set(0, 1);

    this.table = new RoundsHistoryTable({
      columnPadding: this.horizontalPadding,
      rowHeight: this.tableRowHeight,
    });

    this.roundIdText = new BitmapText(`${state.options.translations.historyRound}: ${roundId}`, {
      fontName: state.options.fontDefaultNormal,
      fontSize: 38,
      color: state.options.colors.text,
      maxWidth: state.options.calcWidth - this.horizontalPadding * 2,
    });

    this.scrollBox = new ScrollBox({ itemsMargin: this.itemsMargin });
    this.scrollBox.container.visible = false;

    this.loader = new Loader({ color: state.options.colors.theme });

    /* Items that will be added to scrollbox in getRound() after table is populated and reels are created */
    this.scrollBoxItems = [this.table.container, this.roundIdText, this.reelsContainer];

    this.container.addChild(this.background, this.arrow, this.line, this.loader.container, this.scrollBox.container);

    this.setPosition();
    this.setActions();
    this.getRound(roundId);
  }

  async getRound(id) {
    const response = await gameUtils.makeApiRequest('getRound', [{ id, tenantGameId: state.options.tenantGameId }]);

    this.loader.container.visible = false;

    if (response.isError) {
      state.setErrorDetails(response);
      this.close();
    } else if (!this.container.destroyed) {
      this.round = response.round;
      this.createReels();
      this.table.addRoundsRows([this.round]);
      this.scrollBox.addItems(this.scrollBoxItems);
      this.scrollBox.container.visible = true;
    }
  }

  setActions() {
    const hitAreaExtraSpace = 25;

    this.arrow.hitArea = new Rectangle(
      -this.arrow.width / 2 - hitAreaExtraSpace,
      -this.arrow.height / 2 - hitAreaExtraSpace,
      this.arrow.width + hitAreaExtraSpace * 2,
      this.arrow.height + hitAreaExtraSpace * 2,
    );

    this.arrow.eventMode = 'static';
    this.arrow.cursor = 'pointer';
    this.arrow.on('pointertap', () => this.close());
  }

  close() {
    this.onClose();
    this.container.destroy();
    state.playTapSound();
  }

  createReels() {
    const { result, bonus, betAmount } = this.round;

    const background = new Spine(state.options.assets.reelsBackground.resource.spineData);

    const isBonusWon = bonus?.isWon;
    const isBonus = !isEmpty(result.bonus);
    const isCashLink = isBonus && !isBonusWon && result.bonus.type === 'Collect';
    const isSticky = isBonus && !isBonusWon && result.bonus.type === 'FreeRounds';
    const bonusType = isBonus && result.bonus.type;
    const reels = isBonus ? result.reelWindowSticky : result.reelWindow;
    const symbolBoxSize = background.width / state.options.config.reels;
    const hasWinlines = result.winlines.length;
    const reelWindow = []; /* Symbols reference */

    /* Add reels background */
    background.state.setAnimation(0, `reel${isCashLink ? 'CashLink' : 'Base'}Idle`, true);

    this.reelsContainer.addChild(background);

    /* Check if cash link cash values should be multiplied */
    const isCashLinkX2Round = isCashLink && flatten(reels).some((symbol) => symbol === state.options.config.symbolMap.empty) === false;
    const cashLinkFinishMultiplier = isCashLinkX2Round && result.bonus.finishMultiplier;
    const cashLinkMultiplier = cashLinkFinishMultiplier || 1;

    /* Add symbols */
    reels.forEach((reel, reelIndex) => {
      reel.forEach((symbolValue, rowIndex) => {
        const cashValue = result.reelWindowValues.cash?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
        const multiplierValue = result.reelWindowValues.multiplier?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];

        /* Separate sticky wild from wild. Both have same value - 10 */
        const value = symbolValue === state.options.config.symbolMap.wild && isSticky ? state.options.config.symbolMap.sticky : symbolValue;

        const symbol = new Symbol({
          value,
          betAmount,
          mask: undefined,
          maxWidth: symbolBoxSize,
          cashValue: cashValue * cashLinkMultiplier,
          numOfStackedRows: reel.length - state.options.config.rows,
          multiplierValue,
          symbolBoxSize,
          reelIndex,
          rowIndex,
        });

        reelWindow.push(symbol);

        this.reelsContainer.addChild(symbol.container);
      });
    });

    /* Create reelWindow matrix */
    const reelWindowMatrix = chunk(reelWindow, state.options.config.rows);

    /* Set all symbols to no win state before highlighting winning one */
    if (hasWinlines || isBonusWon) {
      reelWindow.forEach((symbol) => {
        symbol.setAnimation('symbolNoWin', false);
        symbol.setZIndex(1);
      });
    }

    /* Highlight symbols win */
    if (hasWinlines) {
      result.winlines.forEach((winline) => {
        winline.payline.forEach((rowIndex, reelIndex) => {
          const reelSymbol = reelWindowMatrix[reelIndex][rowIndex];
          const isSymbolInWinline = reelSymbol.value === winline.symbolReal || reelSymbol.isWild || reelSymbol.isStickyWild;
          const zIndexValue = reelSymbol.isSpecialSymbol ? 3 : 2;

          if (isSymbolInWinline) {
            reelSymbol.isInWinline = true;
            reelSymbol.setAnimation('symbolIdle', false);
            reelSymbol.symbol.state.timeScale = 0; // eslint-disable-line no-param-reassign
            reelSymbol.setZIndex(zIndexValue);
          }
        });
      });
    }

    /* If bonus is won highlight only bonus symbols */
    if (isBonusWon) {
      reelWindowMatrix.forEach((reel) => {
        reel.forEach((reelSymbol) => {
          const bonusSymbols = isBonusWon && state.options.config.bonusList.find((bonusData) => bonusData.type === bonusType).symbol;
          const isBonusSymbol = isArray(bonusSymbols) ? bonusSymbols.includes(reelSymbol.value) : bonusSymbols === reelSymbol.value;

          if (isBonusSymbol) {
            reelSymbol.setAnimation('symbolIdle', false);
            reelSymbol.symbol.state.timeScale = 0; // eslint-disable-line no-param-reassign
            reelSymbol.setZIndex(3);
          } else if (!reelSymbol.isInWinline) {
            reelSymbol.setAnimation('symbolNoWin', true);
            reelSymbol.setZIndex(1);
          }
        });
      });
    }

    /* Set jackpots */
    state.getJackpotsData(betAmount).forEach((jackpot) => {
      const jackpotSlot = background.skeleton.findSlot(`reel_jackpot_${jackpot.name}_value`);

      const jackpotCashLabel = new Cash({
        cash: jackpot.amount,
        currencyPadding: 5,
        fontSize: 45,
        maxWidth: jackpotSlot.attachment.width,
      });

      jackpotSlot.currentSprite.removeChildren();
      jackpotSlot.currentSprite.addChild(jackpotCashLabel.container);
    });

    /* Set reels Positions */
    background.y -= (background.height / 2) - this.tableRowHeight;
  }

  setPosition() {
    this.loader.container.x = state.options.calcWidth / 2;
    this.loader.container.y = (state.appHeight - this.headerHeight) / 2;

    this.background.width = state.appWidth;
    this.background.height = state.appHeight;

    this.arrow.x = this.horizontalPadding + this.arrow.width / 2;
    this.arrow.y = this.headerHeight / 2;

    this.line.y = this.headerHeight;

    this.table.setPosition(this.tableRowHeight * 2);

    this.roundIdText.x = this.horizontalPadding;

    this.scrollBox.container.y = this.headerHeight;
    this.scrollBox.setDimensions({ height: state.appHeight - this.scrollBox.container.y });
  }
}
