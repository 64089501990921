import { Container, BitmapText } from '@/pixi';
import animate, { Sine } from 'gsap';
import { Spine } from 'pixi-spine';
import { state } from './State';

export class TopPrize {
  constructor() {
    this.container = new Container();
    this.options = state.options;
    this.topPrizeOverlay = undefined;
    this.topPrizeAmount = undefined;

    this.container.name = 'TopPrize';

    this.initModels();
    this.setup();
  }

  animateTopPrizeAmount(amountTo) {
    const counter = { value: state.topPrize.amount };
    animate.to(counter, {
      value: amountTo,
      duration: 2,
      onUpdate: () => {
        this.updateTopPrizeAmount(counter.value);
      },
      onComplete() {
        state.updateTopPrize(amountTo);
      },
      ease: Sine.easeIn,
    });
  }

  initModels() {
    this.topPrizeOverlay = new Spine(state.options.resources.topPrizeOverlay.resource.spineData);
    this.topPrizeAmount = new Spine(state.options.resources.topPrizeAmount.resource.spineData);
  }

  updateTopPrizeAmount(amount) {
    this.topPrizeAmount.children[0].children[0].text = amount.toFixed(2);
  }

  setTopPrizeAmount() {
    const amountSprite = this.topPrizeAmount.children[0].children[0];
    const topPrizeAmount = this.options.state.state.topPrize.amount;

    const amount = new BitmapText(topPrizeAmount.toFixed(2), {
      fontName: state.options.customFont3,
      fontSize: 60,
    });
    amount.anchor.set(0.5);
    amount.scale.y = amountSprite.scale.y;
    amount.x += state.options.uiPadding;

    amountSprite.destroy();
    this.topPrizeAmount.children[0].addChild(amount);
  }

  setCurrency() {
    const currencySprite = this.topPrizeAmount.children[1].children[0];

    if (this.options.currencyDisplayEnabled) {
      const currency = new BitmapText(this.options.currency, {
        fontName: state.options.customFont5,
        fontSize: 40,
      });
      currency.anchor.set(0.5);
      currency.scale.y = currencySprite.scale.y;
      currency.y -= state.options.uiPadding / 2;
      currency.x += state.options.uiPadding - 2;

      this.topPrizeAmount.children[1].addChild(currency);
    }
    currencySprite.destroy({ children: true });
  }

  runOverlayAnimation(event) {
    this.topPrizeOverlay.x += state.options.uiPadding / 2;
    this.topPrizeOverlay.state.setAnimation(0, event, false);
    this.topPrizeOverlay.state.addListener({
      complete: (entry) => {
        if (entry.animation.name === 'intro') {
          this.topPrizeOverlay.state.setAnimation(0, 'loop', true);
        }
      },
    });
  }

  runAmountAnimation(event) {
    this.topPrizeAmount.state.setAnimation(0, event, false);
  }

  setup() {
    this.setTopPrizeAmount();
    this.setCurrency();

    this.container.addChild(this.topPrizeOverlay);
    this.container.addChild(this.topPrizeAmount);
  }
}
