import Pusher from 'pusher-js';
import { triggerEvent } from '../utility/Utility';

export default {
  init(playerId, pusherKey, tenantGameId) {
    if (playerId && pusherKey) {
      const pusher = new Pusher(pusherKey, {
        cluster: 'eu',
        forceTLS: true,
      });

      const playerChannel = pusher.subscribe(`NSoft.Player.${playerId}`);
      const casinoPlayerChannel = pusher.subscribe(`NSoft.Casino.Player.${playerId}`);
      const gameId = tenantGameId;

      playerChannel.bind('UpdateBalance', (data) => {
        triggerEvent('SocketUpdateBalance', data);
      });

      playerChannel.bind('SetError', (data) => {
        triggerEvent('ShowNotification', data);
      });

      casinoPlayerChannel.bind('PromotionStart', (data) => {
        /* check is promotion form pusher for current game */
        if (data.tenantGameIds.indexOf(gameId) < 0) return;
        triggerEvent('PromotionOpen', { promotionFromSocket: data, type: 'intro' });
      });

      casinoPlayerChannel.bind('PromotionStop', (data) => {
        triggerEvent('PromotionEnd', { promotionFromSocket: data, type: 'stop' });
      });

      casinoPlayerChannel.bind('PromotionEnd', (data) => {
        /* check is promotion form pusher for current game */
        if (data.tenantGameIds.indexOf(gameId) < 0) return;
        triggerEvent('PromotionEnd', { promotionFromSocket: data, type: 'end' });
      });
    }
  },
};
