import { defaultTo, first, isEmpty } from 'lodash';
import { Container } from '@/pixi';
import { triggerEvent, registerEventListener, sleep } from '@/utility/Utility';
import { SlotPromotion } from '@/components/SlotPromotion';
import { SlotJackpot } from '@/components/SlotJackpot';
import { SlotControlsHeader } from './SlotControlsHeader';
import { SlotControlsMain } from './SlotControlsMain';
import { SlotControlsFooter } from './SlotControlsFooter';
import { SlotBonusGameIntroOutroScreen } from './SlotBonusGameIntroOutroScreen';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';

export class SlotControls {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.controlsHeader = undefined;
    this.controlsMain = undefined;
    this.controlsFooter = undefined;

    this.isBonusGameShownOnInit = false;
    this.scale = undefined;

    this.setup();
    this.setListeners();
    this.disableNoBalance();
  }

  setup() {
    this.controlsHeader = new SlotControlsHeader();
    this.container.addChild(this.controlsHeader.container);

    this.controlsMain = new SlotControlsMain();
    this.container.addChild(this.controlsMain.container);

    this.controlsFooter = new SlotControlsFooter();
    this.container.addChild(this.controlsFooter.container);
  }

  setListeners() {
    const source = 'SlotControls';

    registerEventListener('ActivePaylinesChanged', (event) => {
      slotState.setActivePaylines(event.detail.value);
    }, source);

    registerEventListener('BetAmountChanged', async (event) => {
      if (slotState.isBetIncreased(event.detail.value)) {
        const isUserApproved = await slotState.dialogWarning.content.getWarningResponse();

        if (!isUserApproved) return;
      } else {
        slotState.resetFeaturesOnSpin = false;
      }

      this.updateBetAmount(event.detail.value);
    }, source);

    registerEventListener('DialogChanged', (event) => {
      slotState.setDialogOpen(defaultTo(event.detail.isOpened, false));
    }, source);

    registerEventListener('StartFreeRounds', () => {
      slotState.setDialogOpen(false);
      this.controlsMain.updateSpinCounter(slotState.availableFreeRounds);

      if (slotState.multiplierValue !== slotState.reelsMultipliers.multiplierValue) {
        slotState.reelsMultipliers.showMultiplier(slotState.multiplierValue);
      }

      this.controlsMain.startAutoplay();
    }, source);

    registerEventListener('ToggleFreeRounds', async (event) => {
      if (slotState.isPromotionStopped && (slotState.options.showMultiplier || slotState.lastRound?.bonus?.isWon)) return;
      slotState.setDialogOpen(false);

      const { prizeCountLeft, prizeCountPerPlayer } = event.detail?.promotion || {};
      const spinCounter = event.detail?.isPromotion ? (prizeCountLeft || prizeCountPerPlayer) : slotState.availableFreeRounds;

      this.controlsMain.updateSpinCounter(spinCounter);

      if (event.detail) {
        const {
          isPromotion,
          betAmount,
          activePaylines,
          promotion,
          isDialogClosed,
        } = event.detail;

        slotState.setPromotion(promotion, isPromotion);

        const defaultBetAmount = betAmount || first(this.options.settings.predefinedBetAmounts);
        this.updateBetAmount(defaultBetAmount);

        if (activePaylines) {
          slotState.setActivePaylines(activePaylines);
        }

        if (isDialogClosed && (!isEmpty(slotState.progress) || !isEmpty(slotState.collect))) {
          slotState.resetFeatures();
        }

        if (isDialogClosed && !slotState.isPromotionStopped) {
          await slotState.updateSlotState();
        }

        if (slotState.isPromotionStopped) {
          if (slotState.winGrading.winGrades?.limit) {
            slotState.winGrading.stop();
          }

          if (this.controlsMain.result) {
            triggerEvent('RoundCreated', this.controlsMain.result);
          }

          await slotState.reels.stop();

          this.controlsMain.updateSpinCounter(0);
          slotState.reelsMultipliers.hideMultiplier();
          slotState.availableFreeRounds = 0;
          slotState.setAutoplay(false);

          if (isDialogClosed) {
            slotState.lastRound.isFree = false;

            await slotState.updateSlotState();

            await this.controlsMain.displayBonusIntroOutro();

            this.controlsMain.isSpinning = false;
            return;
          }

          return;
        }
      }

      if (slotState.isInFreeRounds) {
        slotState.reelsMultipliers.showMultiplier(slotState.multiplierValue);

        this.controlsMain.uiAutoplayStop.visible = false;
        this.controlsMain.startAutoplay();
      } else {
        slotState.reelsMultipliers.hideMultiplier();
        if (this.controlsMain.autoplaySpinCount > 0) {
          this.controlsMain.uiAutoplayStop.visible = true;
          this.controlsMain.updateSpinCounter(this.controlsMain.autoplaySpinCount);
          this.controlsMain.startAutoplay();
        } else {
          slotState.reels.enableSymbols();
          slotState.setBetAmountState();
          this.enableAfterSpin();
        }
      }
    }, source);

    registerEventListener('ReelsEnded', (event) => {
      if (event.detail.winAmount <= 0) {
        this.setBalanceAmountAfterWin();
      }
    }, source);

    registerEventListener('WinEnded', () => {
      this.setBalanceAmountAfterWin();
    }, source);

    registerEventListener('WinGradingEnded', () => {
      this.controlsMain.reset();

      triggerEvent('WinEnded', {
        winAmount: this.winAmountValue,
      });
    }, source);

    registerEventListener('UpdateOptions', (event) => {
      slotState.updateOptions(event.detail);
      this.controlsMain.uiDemoLabel.setVisible(slotState.options.isDemo);
    }, source);

    registerEventListener('UpdateBalance', (event) => {
      const oldBalance = slotState.balanceAmount;
      const newBalance = event.detail.amount;

      // This check can be removed after wrapper app cleanup
      if (oldBalance <= newBalance) {
        this.updateBalance(event.detail);
      }
    }, source);

    registerEventListener('SocketUpdateBalance', (event) => {
      slotState.pendingBalance = undefined;
      slotState.updateBalanceOnSocketEvent = true;
      slotState.pendingBalanceOnSocketEvent = event.detail;

      if (slotState.isStateUpdated || slotState.pendingWin > 0) {
        this.updateBalance(event.detail);
      }
    }, source);

    registerEventListener('BonusGameEnd', (event) => {
      audio.play(slotState.soundAmbientAsset, { loop: true }, slotState.soundAmbient);
      this.openPickPrizeOutroDialog(event.detail.winAmount);

      if (slotState.pendingBonusOutroScreen) {
        slotState.pendingBonusOutroScreen.winAmount += event.detail.winAmount;
      }

      slotState.isBonusBuyEnabled = true;
      this.enableAfterSpin();
    }, source);

    registerEventListener('SetError', (event) => {
      slotState.errorOnSocketEvent = event.detail;
    }, source);

    registerEventListener('PromotionOpen', async (event) => {
      const { promotionFromSocket, type } = event.detail;
      const { activePromotion, activeDialog, collect, endPromotion, isAutoplay, isBonusGameActive, isBonusGameWon, isFree, isRoundPreviewActive, lastRound, onboardScreen, progress, promotionType } = slotState;
      const { lobbyDialog, uiBoost } = this.controlsHeader;

      const isPromotionFinished = new Date() > new Date(slotState.activePromotion?.endTime) && !promotionFromSocket && !endPromotion;

      if (isPromotionFinished) {
        slotState.activePromotion = undefined;
        return;
      }

      const isGameFeatureActive = isBonusGameActive || isBonusGameWon || (collect?.multiplier > 1 && lastRound) || (progress?.current?.unitValue > 0 && lastRound);

      const isPromotionAfterPromotion = activePromotion && promotionFromSocket && activePromotion?.id !== promotionFromSocket?.id;

      const isPromotionDialogUnavailable = this.controlsMain.isSpinning
        || onboardScreen
        || (isFree && !activePromotion)
        || isPromotionAfterPromotion
        || isGameFeatureActive;

      /* hold promotion on spinning and open promotion intro on spin reset */
      if (this.controlsMain.isSpinning && !isGameFeatureActive && !isPromotionAfterPromotion) {
        slotState.isPromotionOnHold = true;
      }

      if (promotionFromSocket && !isPromotionAfterPromotion) {
        slotState.activePromotion = promotionFromSocket;
      }

      if (isPromotionAfterPromotion) {
        slotState.promotionAfterPromotion = promotionFromSocket;
        return;
      }

      /* stop autoplay when promotion came from pusher */
      if (isAutoplay && !isFree && !isGameFeatureActive) {
        this.controlsMain.stopAutoplay();
      }

      /* close boost, lobby or active dialog when promotion came from pusher */

      if (uiBoost?.isBoostOpen) {
        uiBoost.hideBoost();
      }

      if (lobbyDialog?.isLobbyOpen) {
        lobbyDialog.hide();
      }

      if (activeDialog) {
        if (isRoundPreviewActive) {
          triggerEvent('CloseRoundModal');
        }
        activeDialog.hide();
      }

      if (isPromotionDialogUnavailable) return;

      await sleep(500);

      /* on dialog button click when promotion is received, if dialog stays open, close active dialog */
      if (slotState.activeDialog) {
        slotState.activeDialog.hide();
      }

      this.openPromotionDialog(promotionType || type);
    });

    registerEventListener('PromotionEnd', (event) => {
      const { promotionFromSocket, type } = event.detail;
      const { activePromotion, isBonusGameActive, isBonusGameWon } = slotState;
      const promotionId = promotionFromSocket?.id || promotionFromSocket?.promotionId;
      const isDiffPromotion = promotionId !== activePromotion?.id;
      const isPromotionDialogUnavailable = isDiffPromotion || isBonusGameActive || isBonusGameWon;

      if (isBonusGameActive && promotionFromSocket) {
        /* when bonus game is active set promotion end info to show when bonus game is finished */
        slotState.endPromotion = promotionFromSocket;
        /* when bonus game is active set promotion stop type to show when bonus game is finished */
        slotState.promotionType = type;
        return;
      }

      if (isPromotionDialogUnavailable) return;

      slotState.isPromotionStopped = true;

      if (!isDiffPromotion) {
        /* remove promotion intro screen when promotion is stopped and intro screen is still active */
        const promotionIntroContainer = slotState.container.getChildByName('PromotionIntro');

        if (promotionIntroContainer) {
          slotState.container.removeChild(promotionIntroContainer);
        }
      }

      triggerEvent('ToggleFreeRounds', {
        promotion: activePromotion,
        isPromotion: true,
        betAmount: slotState.betAmount,
        activePaylines: slotState.activePaylines,
      });

      this.openPromotionDialog(type);
    });
  }

  setPosition() {
    this.scale = this.container.parent.scale;

    this.controlsHeader.setPosition();
    this.controlsMain.setPosition();
    this.controlsFooter.setPosition();

    if (!this.isBonusGameShownOnInit) {
      const isFreeRoundsActive = (slotState.bonusType === 'FreeRounds' || slotState.progressActiveBonusType === 'FreeRounds') && slotState.availableFreeRounds > 0 && !slotState.activePromotion;
      const isPickPrizeActive = (slotState.bonusType?.includes('PickPrize') || slotState.progressActiveBonusType?.includes('PickPrize')) && slotState.isOpenBonus;

      if (isPickPrizeActive) {
        this.openPickPrizeIntroDialog();
      } else if (isFreeRoundsActive) {
        this.notifyFreeRoundsWon();
      }

      this.isBonusGameShownOnInit = true;
    }
  }

  setBalanceAmountAfterBet() {
    this.controlsFooter.setBalanceAmountAfterBet(this.controlsMain.result);

    this.disableNoBalance();
  }

  setBalanceAmountAfterWin() {
    if (slotState.pendingBalance) {
      this.controlsFooter.setBalanceAmountAfterWin();

      this.disableNoBalance();
      this.enableAfterSpin();
    }
  }

  notifyFreeRoundsWon() {
    const textTopStyle = {
      ...this.options.freeSpinsIntroOptions.textStyle,
      ...this.options.freeSpinsIntroOptions.textTopStyle,
    };
    const textBottomStyle = {
      ...this.options.freeSpinsIntroOptions.textStyle,
      ...this.options.freeSpinsIntroOptions.textBottomStyle,
    };
    // Use background asset based on number of bonus symbols selected, fallback to first asset
    const backgroundAssets = [
      this.options.assets.freeSpinsIntroBackground,
      this.options.assets.freeSpinsIntroBackground2,
      this.options.assets.freeSpinsIntroBackground3,
    ];

    const { numOfSymbols } = slotState.dynamicBonusReels || {};
    const { symbolsMin } = this.options.config?.bonus || {};
    const backgroundAsset = backgroundAssets[numOfSymbols - symbolsMin] || backgroundAssets[0];
    const freeSpinsAlreadyRunning = slotState.isFree;
    let isFreeRoundsInPromotion = false;

    if (slotState.isPromotion) {
      slotState.isPromotion = false;
      isFreeRoundsInPromotion = true;
    }

    if ((slotState.availableFreeRounds === slotState.activePromotion?.prizeCountLeft)
      && slotState.availableFreeRounds !== slotState.options.availableFreeRounds
    ) {
      slotState.availableFreeRounds = slotState.options.availableFreeRounds;
    }

    slotState.showDialog(new SlotBonusGameIntroOutroScreen({
      backgroundAsset,
      screenShowSoundAsset: this.options.assets.soundFreeSpinsIntro,
      number: (freeSpinsAlreadyRunning || slotState.isPromotionStopped) && slotState.bonus?.availablePrizeCount ? slotState.bonus.availablePrizeCount : slotState.availableFreeRounds,
      textTop: this.options.translations.bonusGameIntroOutroTopLabel,
      textTopStyle,
      textBottom: this.options.translations.freeSpinsIntroBottomLabel,
      textBottomStyle,
      type: 'FreeRoundsIntro',
      autoClose: freeSpinsAlreadyRunning && !slotState.isPromotionStopped && !slotState.lastRound?.isPromotion,
      onClose() {
        slotState.isPromotionStopped = false;
        slotState.hideDialog();
        triggerEvent(slotState.isFree && !isFreeRoundsInPromotion ? 'StartFreeRounds' : 'ToggleFreeRounds');
      },
    }));

    triggerEvent('ToggleBackground');
  }

  notifyFreeRoundsEnd() {
    slotState.setAutoplay(false);

    slotState.showDialog(new SlotBonusGameIntroOutroScreen({
      backgroundAsset: this.options.assets.freeSpinsOutroBackground,
      screenShowSoundAsset: this.options.assets.soundFreeSpinsOutro,
      number: slotState.pendingBonusOutroScreen?.winAmount || slotState.freeRoundWinAmount,
      numberIsMoney: true,
      textTop: this.options.translations.bonusGameIntroOutroTopLabel,
      textTopStyle: this.options.freeSpinsOutroOptions.textStyle,
      type: 'FreeRoundsOutro',
      onClose() {
        slotState.hideDialog();

        if (slotState.activePromotion || slotState.endPromotion) {
          const type = slotState.isPromotionLastSpin || slotState.endPromotion ? 'outro' : 'intro';
          triggerEvent('PromotionOpen', { type });
        } else {
          triggerEvent('ToggleFreeRounds');
        }
      },
    }));

    triggerEvent('ToggleBackground');
  }

  disableInSpin() {
    this.controlsHeader.disableInSpin();
    this.controlsMain.disableInSpin();
    this.controlsFooter.disableInSpin();
  }

  disableNoBalance() {
    if (!slotState.isFree && !slotState.activePromotion && slotState.balanceAmount < slotState.betAmountDefault) {
      this.controlsMain.disableSpinButton();
      this.controlsMain.disableInSpin();
      this.controlsFooter.disableInSpin();
    }
  }

  enableAfterSpin() {
    /*
      If the free bonus rounds have ended,
      dont enable controls until the outro dialog is closed
      and slotState.updateSlotState is executed.
    */
    if (!this.controlsMain.isBonusFreeRoundsEnd) {
      this.controlsHeader.enableAfterSpin();
      this.controlsMain.enableAfterSpin();
      this.controlsFooter.enableAfterSpin();
    }
  }

  updateBetAmount(value) {
    if (slotState.activePromotion || value <= slotState.balanceAmount) {
      slotState.setBetAmount(value);
      this.enableAfterSpin();
    }
  }

  updateBalance(balance) {
    slotState.updateBalance(balance);
    this.controlsFooter.updateBalance();
    slotState.setBetAmountState();

    this.disableNoBalance();
    this.enableAfterSpin();
  }

  openPickPrizeIntroDialog() {
    slotState.showDialog(new SlotBonusGameIntroOutroScreen({
      backgroundAsset: this.options.assets.pickPrizeIntroBackground,
      screenShowSoundAsset: this.options.assets.soundPickPrizeIntro,
      gameTitleAsset: this.options.assets.pickPrizeTitle,
      disableCloseAnimation: true,
      type: 'PickPrizeIntro',
      onClose: () => {
        slotState.hideDialog();
        audio.pause(slotState.soundAmbientAsset, slotState.soundAmbient);
        const bonus = slotState.progressActiveBonusType?.includes('PickPrize') ? slotState.progress.unload.bonus : this.options.config.bonus;
        triggerEvent('StartBonusGame', bonus);
      },
    }));
  }

  openPickPrizeOutroDialog(winAmount) {
    slotState.showDialog(new SlotBonusGameIntroOutroScreen({
      backgroundAsset: this.options.assets.pickPrizeOutroBackground,
      screenShowSoundAsset: this.options.assets.soundPickPrizeOutro,
      number: winAmount,
      numberIsMoney: true,
      textTop: this.options.translations.bonusGameIntroOutroTopLabel,
      textTopStyle: this.options.pickPrizeOutroOptions.textStyle,
      type: 'PickPrizeOutro',
      disableOpenAnimation: true,
      onClose: async () => {
        // If PickPrize is won in FreeRounds, continue FreeRounds when PickPrize game ends
        if (slotState.bonusType === 'FreeRounds' || slotState.progressBonusType === 'FreeRounds' || slotState.activePromotion) {
          slotState.hideDialog();

          // If PickPrize is won in last round of free rounds, show Free Rounds outro after PickPrize outro
          if (slotState.pendingBonusOutroScreen) {
            this.notifyFreeRoundsEnd();
            slotState.pendingBonusOutroScreen = undefined;
            return;
          }

          await slotState.updateSlotState();

          // Continue FreeRounds sound after PickPrize game is finished
          if (slotState.availableFreeRounds > 0) {
            slotState.toggleSoundAmbient();
          }

          if (slotState.availableFreeRounds > 0 && slotState.totalFreeRoundsCount === 0) {
            this.notifyFreeRoundsWon();
          } else if (slotState.availableFreeRounds > 0 && !slotState.isPromotion) {
            triggerEvent('StartFreeRounds');
          } else if ((slotState.availableFreeRounds <= 0 && slotState.activePromotion) || slotState.endPromotion) {
            const type = slotState.isPromotionLastSpin || slotState.endPromotion ? 'outro' : 'intro';
            triggerEvent('PromotionOpen', { type });
          }
        } else {
          slotState.hideDialog();
        }
      },
    }));
  }

  openPromotionDialog(type) {
    slotState.showDialog(new SlotPromotion(type));
  }

  openJackpotDialog() {
    slotState.showDialog(new SlotJackpot());
  }
}
