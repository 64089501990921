import { BitmapText, Circle, Container } from '@/pixi';
import { template } from 'lodash';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { gameUtils } from './GameUtils';
import { state } from './State';
import { audio } from './Audio';

export class Onboard {
  constructor() {
    this.container = new Container();
    this.container.name = 'Onboard';
    this.container.mask = state.components.root.contentMask;

    this.onboard = new Spine(state.options.assets.onboard.resource.spineData);
    this.onboardBackground = new Spine(state.options.assets.backgroundOnBoard.resource.spineData);
    this.onboardSoundButton = new Spine(state.options.assets.soundButton.resource.spineData);

    /* Save initial content values because values can change if animation is active */
    this.onboardWidth = this.onboard.width;
    this.onboardHeight = this.onboard.height;
    this.onboardBottomY = this.onboard.getLocalBounds().bottom;
    this.onboardLeftX = this.onboard.getLocalBounds().left;

    /* Set elements animations */
    this.onboardBackground.state.setAnimation(0, 'onBoardIntro', false);
    this.onboard.state.setAnimation(0, 'onBoardIntro', false);
    this.onboardSoundButton.state.setAnimation(0, audio.isSoundOff ? 'offIdle' : 'onIdle', true);

    /* Add bottom text */
    this.pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 45,
    });

    /* Add items */
    this.container.addChild(this.onboardBackground, this.onboard, this.onboardSoundButton, this.pressAnywhereText);

    state.setOnBoardOpen(true);

    this.setText();
    this.setListeners();
  }

  setListeners() {
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
    this.onboardSoundButton.eventMode = 'static';
    this.onboardSoundButton.cursor = 'pointer';

    this.container.on('pointertap', () => {
      this.hide();
      this.runGameIntro();
    });

    this.onboardSoundButton.on('pointertap', (e) => {
      e.stopPropagation();
      audio.toggleMute();
      state.playTapSound();
      this.onboardSoundButton.state.setAnimation(0, audio.isSoundOff ? 'onToOff' : 'offToOn', false);
      triggerAnalyticsEvent({ name: audio.isSoundOff ? 'SoundButtonOnClick' : 'SoundButtonOffClick' });
    });

    this.onboard.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'onBoardIntro') {
          this.onboard.state.setAnimation(0, 'onBoardIdle', true);
        }
      },
    });

    this.onboardBackground.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'onBoardIntro') {
          this.onboardBackground.state.setAnimation(0, 'onBoardIdle', true);
        }
      },
    });

    this.onboardSoundButton.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'offToOn') {
          this.onboardSoundButton.state.setAnimation(0, 'onIdle', true);
        }
        if (trackEntry.animation.name === 'onToOff') {
          this.onboardSoundButton.state.setAnimation(0, 'offIdle', true);
        }
      },
    });
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundDimensions = this.onboardBackground.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    /* Calculate elements scale */
    let backgroundScale = 1;
    let contentScale = 1;

    if (aspectRatio > backgroundAspectRatio) {
      backgroundScale = state.appHeight / backgroundDimensions.height;
    }

    if (this.onboardHeight > state.appHeight * 0.8) {
      contentScale = (state.appHeight * 0.8) / this.onboardBottomY;
    }

    /* Set Positions */
    this.onboardBackground.scale.set(backgroundScale);
    this.onboardBackground.x = state.appWidth / 2 - (backgroundDimensions.width / 2) * backgroundScale;
    this.onboardBackground.y = state.appHeight / 2 - (backgroundDimensions.height / 2) * backgroundScale - 1;

    this.onboard.scale.set(contentScale);
    this.onboard.x = state.appWidth / 2 - (this.onboardWidth * contentScale) / 2 - this.onboardLeftX * contentScale;

    this.onboardSoundButton.scale.set(contentScale);
    this.onboardSoundButton.x = state.appWidth / 2;
    this.onboardSoundButton.y = state.appHeight - this.onboardSoundButton.height * 1.15 * backgroundScale;
    this.onboardSoundButton.hitArea = new Circle(0, 0, this.onboardSoundButton.width / 2);

    this.pressAnywhereText.scale.set(contentScale);
    this.pressAnywhereText.x = state.appWidth / 2;
    this.pressAnywhereText.y = state.appHeight - this.pressAnywhereText.height;
    this.pressAnywhereText.anchor.set(0.5);

    /* Reapply mask. On every resize content mask is recalculated */
    this.container.mask = state.components.root.contentMask;
  }

  setText() {
    this.createBonusGameTitleWithDescription({
      slotNameTitle: 'text_free_spins',
      titleText: state.options.translations.freeSpins,
      slotNameDesc: 'text_free_spins_message',
      descText: template(state.options.translations.xOrMoreTriggerFreeSpinsLabel)({
        hits: state.getMinBonusHits().FreeRounds,
      }),
    });

    this.createBonusGameTitleWithDescription({
      slotNameTitle: 'text_cash_link',
      titleText: state.options.translations.cashLink,
      slotNameDesc: 'text_cash_link_message',
      descText: template(state.options.translations.xOrMoreTriggerRespinLabel)({
        hits: state.getMinBonusHits().Collect,
      }),
    });

    this.setTextScale();

    /* Add Sticky golden cherry text */
    const stickyCherrySlot = this.onboard.skeleton.findSlot('text_sticky_golden_cherry');
    const stickyCherryText = new BitmapText(state.options.translations.cherryBlastOnboardStickyCherryDescription.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 70,
    });

    stickyCherryText.scale.set(gameUtils.getTextScaleX(stickyCherryText, stickyCherrySlot.attachment.width));
    stickyCherryText.anchor.set(0.5, 0.41);
    stickyCherryText.x += 15;

    stickyCherrySlot.currentSprite.removeChildren();
    stickyCherrySlot.currentSprite.addChild(stickyCherryText);
  }

  createBonusGameTitleWithDescription({ slotNameTitle, titleText, slotNameDesc, descText }) {
    const textMaxWidth = 360;

    const titleSlot = this.onboard.skeleton.findSlot(slotNameTitle);
    const titleTextElement = new BitmapText(titleText.toUpperCase(), {
      fontName: state.options.fontCherryGold,
      fontSize: 70,
    });

    titleTextElement.anchor.set(0.5);

    titleSlot.currentSprite.scale.set(1, -1);
    titleSlot.currentSprite.removeChildren();
    titleSlot.currentSprite.addChild(titleTextElement);

    const descriptionSlot = this.onboard.skeleton.findSlot(slotNameDesc);
    const descriptionText = new BitmapText(descText.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 40,
      maxWidth: textMaxWidth,
      align: 'center',
    });

    descriptionText.anchor.set(0.5, 0);

    descriptionSlot.currentSprite.removeChildren();
    descriptionSlot.currentSprite.addChild(descriptionText);
  }

  setTextScale() {
    const textMaxWidth = 400;
    const textMaxHeight = 150;

    const titleSlots = ['text_free_spins', 'text_cash_link'];
    const descSlots = ['text_free_spins_message', 'text_cash_link_message'];

    const titleScales = [];
    const descScales = [];

    /* Calculate scale */
    titleSlots.forEach((slotNameTitle) => {
      const titleTextElement = this.onboard.skeleton.findSlot(slotNameTitle).currentSprite.children[0];
      titleScales.push(gameUtils.getTextScaleX(titleTextElement, textMaxWidth));
    });

    descSlots.forEach((slotNameDesc) => {
      const descTextElement = this.onboard.skeleton.findSlot(slotNameDesc).currentSprite.children[0];
      descScales.push(gameUtils.getTextScaleY(descTextElement, textMaxHeight));
    });

    /* Set scale */
    titleSlots.forEach((slotNameTitle) => {
      const titleTextElement = this.onboard.skeleton.findSlot(slotNameTitle).currentSprite.children[0];
      titleTextElement.scale.set(Math.min(...titleScales));
    });

    descSlots.forEach((slotNameDesc) => {
      const descTextElement = this.onboard.skeleton.findSlot(slotNameDesc).currentSprite.children[0];
      descTextElement.scale.set(Math.min(...descScales));
      descTextElement.y -= this.onboard.skeleton.findSlot(slotNameDesc).currentSprite.height / 2 - descTextElement.fontSize;
    });
  }

  runGameIntro() {
    state.setBonusGameStateOnInit();

    if (!state.bonus.isActive) {
      state.enableAfterSpin();
      state.components.header.uiBoost?.show();
      state.reelsWrapper.logo.logoWinAnimation();
      state.reelsWrapper.reels.setReelWindowSymbolsAnimation('symbolSpinStop');
    } else {
      state.reelsWrapper.reels.setReelWindowSymbolsAnimation('symbolIdle');
    }

    if (!state.bonus.isActive) {
      state.playSound('introGame');
    }

    state.setOnBoardOpen(false);
  }

  hide() {
    this.hideTimeline = animate.timeline();

    this.hideTimeline.to(this.container, {
      duration: 0.175,
      pixi: {
        alpha: 0,
      },
      onComplete: () => {
        /* Destroy container */
        this.container.visible = false;
        this.container.destroy();
        state.components.onboard = undefined; /* Remove reference as container will be destroyed */
      },
    });

    this.hideTimeline.play();
  }
}
