import { makeAutoObservable, reaction } from 'mobx';
import { assign, get, isNil } from 'lodash';
import animate from 'gsap';
import { SlotSpinSpeedType } from '@/models';
import { getErrorParams } from '@/utility/Utility';
import { audio } from './Audio';

class State {
  constructor() {
    this.options = undefined;

    this.balanceFromSocket = undefined;
    this.betAmount = undefined;
    this.bonus = {};
    this.components = {};
    this.dialogOpen = undefined;
    this.errorDetails = undefined;
    this.numOfPaylines = undefined;
    this.onBoardOpen = undefined;
    this.spinResult = {};
    this.winAmount = undefined;

    this.autoplay = {
      active: false,
      numberOfSpins: 0,
      stopOnAnyWin: false,
      currentBalance: 0,
      lossLimit: {
        enabled: false,
        amount: 0,
      },
      winLimit: {
        enabled: false,
        amount: 0,
      },
    };

    this.bonusAutoplay = {
      active: false,
      numberOfSpins: 0,
    };

    /* Spin Animation Values */
    this.reelSpinBaseDuration = 0.4;
    this.reelSpinBounceAmount = 60;
    this.reelSpinBounceDuration = 0.3;
    this.reelSpinStopDelay = 0.3;

    this.reelLightningSpinBaseDuration = 0.2;
    this.reelLightningSpinBounceAmount = 30;
    this.reelLightningSpinBounceDuration = 0.1;
    this.reelLightningSpinStopDelay = 0;

    makeAutoObservable(this);
  }

  watch(path, callback) {
    reaction(() => get(this, path), callback);
  }

  /* Game state getters */

  get balanceAmount() {
    return this.options.player.balance.amount;
  }

  get betPerLine() {
    return this.betAmount / this.numOfPaylines;
  }

  get sufficientFunds() {
    return this.balanceAmount >= this.betAmount;
  }

  get spinSpeedType() {
    return SlotSpinSpeedType[this.options.spinSpeedType];
  }

  get isNormalSpin() {
    return this.options.spinSpeedType === SlotSpinSpeedType.Normal;
  }

  get isLightningSpin() {
    return this.options.spinSpeedType === SlotSpinSpeedType.Lightning;
  }

  /* Bonus Game Getters */

  get isStickyBonus() {
    return this.bonus.freeRounds > 0 && this.bonus.type === 'FreeRounds';
  }

  get isStickyBonusEnd() {
    return this.bonus.freeRounds === 0 && this.bonus.type === 'FreeRounds';
  }

  get isCashLinkBonus() {
    return this.bonus.freeRounds > 0 && this.bonus.type === 'Collect';
  }

  get isCashLinkBonusEnd() {
    return this.bonus.freeRounds === 0 && this.bonus.type === 'Collect';
  }

  get isBonus() {
    return this.isCashLinkBonus || this.isStickyBonus;
  }

  get isBonusEnd() {
    return this.isCashLinkBonusEnd || this.isStickyBonusEnd;
  }

  /* Autoplay Getters */

  get isAutoplay() {
    return this.autoplay.active;
  }

  get isBonusAutoplay() {
    return this.bonusAutoplay.active;
  }

  get isAnyAutoplay() {
    return this.autoplay.active || this.bonusAutoplay.active;
  }

  /* Game dimensions/positions getters */

  get appScale() {
    return this.components.container.scale.y;
  }

  get appHeight() {
    return this.options.size().height / this.appScale;
  }

  get appWidth() {
    return this.options.size().width / this.appScale;
  }

  get reelsScale() {
    return this.reelsWrapper.container.scale.x;
  }

  get reelsCenter() {
    const { reelsBackground } = this.components.content.reelsWrapper.reels;

    return reelsBackground.getBounds().bottom / this.appScale
      - (reelsBackground.getLocalBounds().height * this.reelsScale) / 2
      + (this.options.reelPadding[0] * this.reelsScale) * 2
      - 5;
  }

  /* Components getters */

  get reelsWrapper() {
    return this.components.content && this.components.content.reelsWrapper;
  }

  get reels() {
    return this.components.content && this.components.content.reelsWrapper.reels;
  }

  /* Initial game state */

  create(options) {
    this.options = options;

    this.betAmount = options.state.state.betAmount || options.settings.predefinedBetAmounts[0];
    this.numOfPaylines = options.config.paylines.length;

    if (options.isDemo) {
      this.options.player = {
        balance: {
          amount: 1000,
          currency: options.currency,
        },
      };
    }
  }

  /* Controls */

  disableInSpin() {
    this.components.content.controls.disableInSpin();
    this.components.header.disableInSpin();
    this.components.footer.disableInSpin();
  }

  enableAfterSpin() {
    this.components.content.controls.enableAfterSpin();
    this.components.header.enableAfterSpin();
    this.components.footer.enableAfterSpin();
  }

  setSpinSpeedType(type) {
    this.options.spinSpeedType = type;
  }

  /* Round related */

  setBetAmount(value) {
    this.betAmount = value;
  }

  setWinAmount(value) {
    this.winAmount = value;
  }

  setBalanceAmount(value) {
    this.options.player.balance.amount = value;
    this.balanceFromSocket = undefined;
  }

  setBalanceFromSocket(value) {
    this.balanceFromSocket = value;
  }

  setBalanceAmountOnSpinStart() {
    let stake = this.betAmount;

    if (this.isBonus) stake = 0;
    if (this.spinResult.bonusBuyPrice) stake = this.spinResult.bonusBuyPrice;

    this.setBalanceAmount(this.balanceAmount - stake);

    if (this.isAutoplay && (this.autoplay.lossLimit.enabled || this.autoplay.winLimit.enabled)) {
      this.autoplay.currentBalance = parseFloat((this.autoplay.currentBalance - stake).toFixed(2));
    }
  }

  setBalanceAmountOnSpinEnd() {
    const newBalance = !isNil(this.balanceFromSocket) ? this.balanceFromSocket : this.spinResult.balance;

    this.setBalanceAmount(newBalance);

    if (this.spinResult.winAmount) {
      if (this.isAutoplay && (this.autoplay.lossLimit.enabled || this.autoplay.winLimit.enabled)) {
        this.autoplay.currentBalance = parseFloat((this.autoplay.currentBalance + this.spinResult.winAmount).toFixed(2));
      }
    }
  }

  updateOptions(options) {
    if (options) {
      assign(this.options, options);
      this.options.isDemo = isNil(this.options.playerToken);
    }
  }

  /* Autoplay */

  startAutoplay(numberOfSpins, stopOnAnyWin, lossLimit, winLimit) {
    this.setAutoplay(true, numberOfSpins, stopOnAnyWin, lossLimit, winLimit, 0);
  }

  stopAutoplay() {
    this.setAutoplay(false, 0, false, { enabled: false }, { enabled: false }, 0);
  }

  setAutoplay(enabled, numberOfSpins, stopOnAnyWin, lossLimit, winLimit, currentBalance) {
    this.autoplay.active = enabled;

    if (!isNil(numberOfSpins)) this.autoplay.numberOfSpins = numberOfSpins;
    if (!isNil(stopOnAnyWin)) this.autoplay.stopOnAnyWin = stopOnAnyWin;
    if (!isNil(lossLimit)) this.autoplay.lossLimit = lossLimit;
    if (!isNil(winLimit)) this.autoplay.winLimit = winLimit;
    if (!isNil(currentBalance)) this.autoplay.currentBalance = currentBalance;

    if (enabled) {
      this.components.content.controls.spin();
    }
  }

  updateAutoplayNumberOfSpins() {
    if (this.autoplay.active) {
      this.autoplay.numberOfSpins -= 1;
    }
  }

  checkAutoplay() {
    if (this.autoplay.numberOfSpins === 0 || !this.sufficientFunds) {
      this.stopAutoplay();
    }

    if ((this.autoplay.stopOnAnyWin && this.spinResult.winAmount)
      || (this.autoplay.lossLimit.enabled && this.autoplay.currentBalance <= this.autoplay.lossLimit.amount * -1)
      || (this.autoplay.winLimit.enabled && this.autoplay.currentBalance >= this.autoplay.winLimit.amount)) {
      this.stopAutoplay();
    }
  }

  /* Bonus game */

  setBonusGameStateOnInit() {
    if (this.options.bonusDataOnInit) {
      this.bonus = {
        ...this.options.bonusDataOnInit,
        isWon: true,
        isEnd: false,
      };

      this.reels.bonusGameStart();
    } else {
      this.options.bonusDataOnInit = undefined;
    }
  }

  setBonusGameState() {
    this.bonus = {
      ...this.spinResult.bonus,
      isWon: this.spinResult.isBonusWon,
      isEnd: this.spinResult.isBonusEnd,
      winGradesBonus: this.spinResult.winGradesBonus,
    };

    if (this.bonus.isWon && this.autoplay.active) {
      this.stopAutoplay();
    }

    if (this.bonus.isWon) {
      this.reels.bonusGameStart();
    }

    if (this.bonus.isEnd) {
      this.reels.bonusGameEnd();
    }
  }

  setBonusAutoplay() {
    this.bonusAutoplay.active = this.bonus.isActive;
    this.bonusAutoplay.numberOfSpins = this.bonus.freeRounds;
  }

  startBonusAutoplay() {
    this.components.content.controls.spin();
  }

  stopBonusAutoplay() {
    this.bonusAutoplay.active = false;
    this.bonusAutoplay.numberOfSpins = 0;
  }

  updateBonusNumberOfSpins() {
    if (this.bonusAutoplay.active) {
      this.bonusAutoplay.numberOfSpins = this.bonus.freeRounds - 1;
    }
  }

  setCashLinkNumberOfSpins() {
    if (this.isCashLinkBonus) {
      this.bonusAutoplay.numberOfSpins = this.bonus.freeRounds;
    }
  }

  async showBonusIntroScreen() {
    if (this.isCashLinkBonus) {
      await this.components.bonusIntroCashLink.show();
    }

    if (this.isStickyBonus) {
      await this.components.bonusIntroStickyWild.show();
    }
  }

  async showBonusOutroScreen() {
    await this.components.bonusOutro.show();
  }

  /* Sound */

  playSound(soundName, loop = false, volume = 1, fadeIn = false) {
    const soundAsset = this.options.assets.sounds[soundName];

    if (fadeIn) {
      this.fadeSound(soundAsset, volume, loop);
    } else {
      audio.play(soundAsset, { loop, volume });
    }
  }

  stopSound(soundName, fadeOut = false) {
    const soundAsset = this.options.assets.sounds[soundName];

    if (fadeOut) {
      this.fadeSound(soundAsset, 0);
    } else {
      audio.stop(soundAsset);
    }
  }

  setSoundVolume(soundName, volume = 1) {
    this.options.assets.sounds[soundName].resource.volume(volume);
  }

  setSoundAmbientVolume(volume = 1) {
    this.options.assets.sounds.ambientBase.resource.volume(volume);
    this.options.assets.sounds.ambientBonus.resource.volume(volume);
  }

  playTapSound() {
    audio.play(this.options.assets.sounds.slotClick);
  }

  playSoundAmbientBase() {
    audio.play(this.options.assets.sounds.ambientBase, { loop: true });
  }

  stopSoundAmbientBase() {
    audio.stop(this.options.assets.sounds.ambientBase);
  }

  playSoundAmbientBonus() {
    audio.play(this.options.assets.sounds.ambientBonus, { loop: true });
  }

  stopSoundAmbientBonus() {
    audio.stop(this.options.assets.sounds.ambientBonus);
  }

  playSoundAmbient() {
    const soundAmbientBase = this.options.assets.sounds.ambientBase;
    const soundAmbientBonus = this.options.assets.sounds.ambientBonus;

    if (this.isBonus) {
      this.toggleSounds(soundAmbientBase, soundAmbientBonus, 1, true);
    } else {
      this.toggleSounds(soundAmbientBonus, soundAmbientBase, 1, true);
    }
  }

  toggleSounds(soundToStop, soundToPlay, volume = 1, loop = false) {
    const timeline = animate.timeline();

    timeline.to(soundToStop.resource, {
      duration: 0.5,
      volume: 0,
      onComplete: () => {
        audio.stop(soundToStop);
      },
    });

    timeline.to({}, {
      onStart: () => {
        audio.play(soundToPlay, { loop, volume: 0 });
      },
    });

    timeline.to(soundToPlay.resource, {
      duration: 0.5,
      volume,
    });

    timeline.play();
  }

  fadeSound(soundToFade, volume, loop = false, fadeDuration = 0.5) {
    const timeline = animate.timeline();

    if (volume === 0) {
      timeline.to(soundToFade.resource, {
        duration: fadeDuration,
        volume,
        onComplete: () => {
          audio.stop(soundToFade);
        },
      });
    }

    if (volume === 1) {
      timeline.to({}, {
        onStart: () => {
          audio.play(soundToFade, { loop, volume: 0 });
        },
      });

      timeline.to(soundToFade.resource, {
        duration: fadeDuration,
        volume,
      });
    }

    timeline.play();
  }

  /* Spin Result */

  parseSpinResult(response) {
    this.spinResult = {
      balance: response.player.balance.amount,
      bonus: response.state.bonus || {},
      bonusBuyPrice: response.bonusBuyPrice,
      isBonusEnd: response.state.bonus?.freeRounds === 0,
      isBonusWon: response.state.bonus?.freeRounds > 0 && !this.bonus.freeRounds,
      reelWindow: response.result.reelWindow,
      reelWindowValues: response.result.reelWindowValues,
      stickySymbols: response.state.bonus?.stickySymbols || [],
      winAmount: response.result.winAmountTotal,
      winAmountBonus: response.result.winAmountBonus,
      winGrades: response.result.winGrades,
      winGradesBonus: response.result.winGradesBonus,
      winlines: response.result.winlines,
    };
  }

  /* Dialogs */

  setDialogOpen(isOpen) {
    this.dialogOpen = isOpen;
  }

  setOnBoardOpen(isOpen) {
    this.onBoardOpen = isOpen;
  }

  /* Errors */

  setErrorDetails(details) {
    if (details) {
      this.errorDetails = getErrorParams(details, this.options.translations);

      // Hide/disable boost
      if (this.components.header.uiBoost) {
        if (this.errorDetails.isErrorReloadable) {
          this.components.header.uiBoost.hide();
        } else {
          this.components.header.uiBoost.disable();
        }
      }

      if (this.errorDetails.isRciError) return;

      this.components.root.openNotification(this.errorDetails.notification);
    } else {
      this.errorDetails = undefined;
    }
  }

  /* Formatters */

  getJackpotsData(customBetAmount) {
    const betAmount = customBetAmount || this.betAmount;
    const jackpots = this.bonus?.pots || this.options.config.bonusList[2].pots;
    const jackpotNames = {
      potOne: 'mini',
      potTwo: 'mega',
      potThree: 'grand',
    };

    return Object.entries(jackpots).map(([key, { multiplier }]) => ({
      name: jackpotNames[key],
      amount: multiplier * betAmount,
      symbolValue: this.options.config.symbolMap.jackpot[jackpotNames[key]],
    }));
  }

  getMinBonusHits() {
    const minHits = {};

    this.options.config.bonusList.forEach((bonus) => {
      const { type, hits } = bonus;

      if (!minHits[type] || hits < minHits[type]) {
        minHits[type] = hits;
      }
    });

    /* Response example: { FreeRounds: 3, Collect: 4 } */
    return minHits;
  }
}

export const state = new State();
