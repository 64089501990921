import animate from 'gsap';
import { Container, BitmapText } from '@/pixi';
import { gameUtils } from './GameUtils';
import { state } from './State';

export class Cash {
  constructor({
    cash, // Cash amount
    currencyPadding = 10, // Padding between cash and currency
    direction = 'x', // x - currency right to cash. y - currency below cash
    fontSize = 60, // Cash font size, currency is fontSize / 2
    maxWidth, // Point where text will be scaled down
    showCurrency = false, // If true currency will be displayed even if currencyDisplayEnabled is false
  }) {
    this.currencyPadding = currencyPadding;
    this.direction = direction;
    this.fontSize = fontSize;
    this.maxWidth = maxWidth;
    this.showCurrency = showCurrency || state.options.currencyDisplayEnabled;

    this.container = new Container();
    this.container.name = 'Cash';
    this.container.interactiveChildren = false;

    this.cash = new BitmapText(gameUtils.getMoneyLabel(cash), {
      fontName: state.options.fontCherryNumbers,
      fontSize: this.fontSize,
    });
    this.cash.anchor.set(0.5, 0.41);

    this.container.addChild(this.cash);

    if (this.showCurrency) {
      this.currency = new BitmapText(state.options.currency, {
        fontName: state.options.fontCherryGold,
        fontSize: this.cash.fontSize / 2,
      });

      this.container.addChild(this.currency);

      if (this.direction === 'x') {
        this.container.pivot.x += (this.currency.width + this.currencyPadding) / 2;
      }

      this.setCurrencyPosition();
    }

    this.setCashScale();
  }

  getCurrentAmount() {
    return typeof this.cash.text === 'number' ? this.cash.text : parseFloat(this.cash.text.replaceAll(',', ''));
  }

  setCashScale() {
    if (this.showCurrency && (this.container.width > this.maxWidth - this.currencyPadding)) {
      this.container.scale.set((this.maxWidth - this.currencyPadding) / (this.container.width / this.container.scale.y));
    }

    if (this.showCurrency === false && (this.cash.width > this.maxWidth)) {
      this.cash.scale.set(this.maxWidth / (this.cash.width / this.cash.scale.y));
    }
  }

  setCash(amount, updateCurrencyPosition = true) {
    this.cash.text = gameUtils.getMoneyLabel(amount);
    this.setCashScale();

    if (updateCurrencyPosition && this.currency) {
      this.setCurrencyPosition();
    }
  }

  resetScale() {
    this.cash.scale.set(1);
  }

  setCurrencyPosition() {
    if (this.direction === 'x') {
      this.currency.anchor.set(0, 0.5);

      this.currency.x = this.cash.width / 2 + this.currencyPadding;
      this.currency.y = this.currency.height / 2;
    } else {
      this.currency.anchor.set(0.5, 0);

      this.currency.y = this.cash.height / 2 + this.currencyPadding;
    }
  }

  createAmountAnimateTimeline(amount, duration, delay = 0, amountFrom = this.getCurrentAmount()) {
    const timeline = animate.timeline();
    const amountAnimate = { amount: amountFrom };

    timeline.to(amountAnimate, {
      amount,
      delay,
      duration,
      ease: 'sine.inOut',
      onUpdate: () => {
        this.setCash(amountAnimate.amount, true);
      },
    });

    return timeline;
  }
}
