import { isFunction, upperFirst } from 'lodash';
import { Container, Sprite, BitmapText } from '@/pixi';

export class Promotion {
  constructor({
    backgroundAsset,
    buttonComponent,
    currency,
    customFontName,
    fontBold,
    fontNormal,
    getAppHeight,
    getAppWidth,
    onEnd,
    onShow,
    onStart,
    promotion,
    promotionWinAmount,
    type,
    claimText,
    closeText,
    continueText,
    youReceivedText,
    freeSpinsText,
    daysText,
    hoursText,
    minutesText,
    secondsText,
    endsInText,
    youWonText,
    endedText,
    operatorEndedText,
  }) {
    this.backgroundAsset = backgroundAsset;
    this.buttonComponent = buttonComponent;
    this.button = undefined;
    this.buttonTitle = undefined;
    this.container = new Container();
    this.containerBackground = undefined;
    this.currency = currency;
    this.customFont = customFontName;
    this.dialogPadding = 100;
    this.fontBold = fontBold;
    this.fontNormal = fontNormal;
    this.isEnded = undefined;
    this.isIntro = undefined;
    this.isOutro = undefined;
    this.isStoped = undefined;
    this.promotion = promotion;
    this.promotionWinAmount = promotionWinAmount;
    this.type = type;

    this.getAppWidth = getAppWidth;
    this.getAppHeight = getAppHeight;
    this.onEnd = onEnd;
    this.onShow = onShow;
    this.onStart = onStart;

    this.counterFooterLabel = undefined;
    this.counterHeaderLabel = undefined;
    this.counterLabel = undefined;
    this.promotionEndLabel = undefined;
    this.promotionEndTimeLabel = undefined;
    this.promotionOperatorEndedLabel = undefined;

    this.claimText = claimText;
    this.closeText = closeText;
    this.continueText = continueText;
    this.youReceivedText = youReceivedText;
    this.freeSpinsText = freeSpinsText;
    this.daysText = daysText;
    this.hoursText = hoursText;
    this.minutesText = minutesText;
    this.secondsText = secondsText;
    this.endsInText = endsInText;
    this.youWonText = youWonText;
    this.endedText = endedText;
    this.operatorEndedText = operatorEndedText;

    this.setup();
  }

  setup() {
    this.container.eventMode = 'static';
    this.container.name = `Promotion${upperFirst(this.type)}`;
    /* intro - intro screen start promotion */
    this.isIntro = this.type === 'intro';
    /* outro - outro screen of promotion */
    this.isOutro = this.type === 'outro';
    /* outro - outro screen when promotion is ended */
    this.isEnded = this.type === 'end';
    /* isStoped - when operator stops promotion */
    this.isStoped = this.type === 'stop';

    this.containerBackground = new Sprite(this.backgroundAsset.resource);
    this.container.addChild(this.containerBackground);

    if (this.isIntro) {
      this.buttonTitle = this.claimText;
      this.setPromotionIntroLabels();
    }

    if (this.isOutro || this.isEnded) {
      this.buttonTitle = this.isOutro ? this.claimText : this.continueText;
      this.setPromotionOutroLabels();
    }

    if (this.isStoped) {
      this.buttonTitle = this.closeText;
      this.setPromotionStopLabels();
    }

    this.button = this.createButton();
    this.container.addChild(this.button.container);
  }

  createButton() {
    const ButtonComponent = this.buttonComponent;
    const methodType = this.isIntro ? 'Start' : 'End';

    const button = new ButtonComponent({
      width: this.getAppWidth() - this.dialogPadding,
      height: 175,
      radius: 20,
      textSize: 52,
      text: this.buttonTitle,
      backgroundColor: '#fa7424',
      borderColor: '#A03232',
      textColor: '#060301',
      onClick: () => {
        this[`onPromotion${methodType}`]();
      },
    });

    return button;
  }

  setPromotionIntroLabels() {
    const numberOfSpins = this.promotion.prizeCountLeft || this.promotion.prizeCountPerPlayer;

    this.counterHeaderLabel = new BitmapText(this.youReceivedText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 50,
    });

    this.counterLabel = new BitmapText(numberOfSpins, {
      fontName: this.customFont,
      fontSize: 250,
    });

    this.counterFooterLabel = new BitmapText(this.freeSpinsText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 75,
    });

    this.container.addChild(this.counterHeaderLabel);
    this.container.addChild(this.counterLabel);
    this.container.addChild(this.counterFooterLabel);

    this.setPromotionEndTimeLabel();
  }

  setPromotionEndTimeLabel() {
    const promotionEndDate = new Date(this.promotion.endTime);

    let seconds = Math.floor((promotionEndDate - new Date()) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours -= (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    const daysLabel = days ? `${days} ${this.daysText}, ` : '';
    const hoursLabel = hours ? `${hours} ${this.hoursText}, ` : '';
    const minutesLabel = minutes ? `${minutes} ${this.minutesText}` : '';
    const secondsLabel = minutes <= 0 && seconds ? `${seconds} ${this.secondsText}` : '';

    this.promotionEndLabel = new BitmapText(this.endsInText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 60,
    });

    this.promotionEndTimeLabel = new BitmapText(`${daysLabel}${hoursLabel}${minutesLabel}${secondsLabel}`, {
      fontName: this.fontNormal,
      fontSize: 50,
    });

    this.container.addChild(this.promotionEndLabel);
    this.container.addChild(this.promotionEndTimeLabel);
  }

  setPromotionOutroLabels() {
    this.counterHeaderLabel = new BitmapText(this.youWonText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 50,
    });

    this.counterLabel = new BitmapText(this.promotionWinAmount, {
      fontName: this.customFont,
      fontSize: 175,
    });

    this.counterFooterLabel = new BitmapText(this.currency, {
      fontName: this.fontBold,
      fontSize: 75,
    });

    if (this.isEnded) {
      this.promotionEndLabel = new BitmapText(this.endedText.toUpperCase(), {
        fontName: this.fontBold,
        fontSize: 50,
      });

      this.container.addChild(this.promotionEndLabel);
    }

    this.container.addChild(this.counterHeaderLabel);
    this.container.addChild(this.counterLabel);
    this.container.addChild(this.counterFooterLabel);
  }

  setPromotionStopLabels() {
    this.promotionOperatorEndedLabel = new BitmapText(this.operatorEndedText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 50,
    });

    this.container.addChild(this.promotionOperatorEndedLabel);
  }

  onPromotionStart() {
    this.container.destroy({ children: true });
    this.onStart();
  }

  onPromotionEnd() {
    this.container.destroy({ children: true });
    this.onEnd();
  }

  show() {
    if (isFunction(this.onShow)) {
      this.onShow();
    }
  }

  setPosition() {
    const appWidth = this.getAppWidth();
    const appHeight = this.getAppHeight();

    this.containerBackground.width = appWidth;
    this.containerBackground.height = appHeight;

    this.button.container.x = appWidth - this.button.width - this.dialogPadding / 2;
    this.button.container.y = appHeight - this.dialogPadding * 2;

    if (this.isIntro || this.isOutro || this.isEnded) {
      this.counterHeaderLabel.anchor.set(0.5);
      this.counterHeaderLabel.x = appWidth / 2;
      this.counterHeaderLabel.y = (appHeight / 2) - (this.dialogPadding * 2);

      this.counterLabel.anchor.set(0.5);
      this.counterLabel.x = appWidth / 2;
      this.counterLabel.y = appHeight / 2;

      this.counterFooterLabel.anchor.set(0.5);
      this.counterFooterLabel.x = appWidth / 2;
      this.counterFooterLabel.y = (appHeight / 2) + (this.dialogPadding * 2);
    }

    if (this.isIntro || this.isEnded) {
      this.promotionEndLabel.anchor.set(0.5);
      this.promotionEndLabel.x = appWidth / 2;
      this.promotionEndLabel.y = appHeight - (this.button.container.height + (this.dialogPadding * 2.75));
    }

    if (this.isIntro) {
      this.promotionEndTimeLabel.anchor.set(0.5);
      this.promotionEndTimeLabel.x = appWidth / 2;
      this.promotionEndTimeLabel.y = appHeight - (this.button.container.height + (this.dialogPadding * 2));
    }

    if (this.isStoped) {
      this.promotionOperatorEndedLabel.anchor.set(0.5);
      this.promotionOperatorEndedLabel.x = appWidth / 2;
      this.promotionOperatorEndedLabel.y = (appHeight / 2) + this.dialogPadding;
    }
  }
}
