/* eslint-disable no-await-in-loop */
import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { AdjustmentFilter } from '@pixi/filter-adjustment';
import { Container, BitmapText } from '@/pixi';
import { state } from './State';

export class BonusGameCollectLevels {
  #container;
  #numOfSymbolsText;
  #collectLevels = {};
  #activeLevel;
  #currentNumberOfUpgradeSymbols;
  #isStatic;

  constructor({ levelOnStart = 0, numberOfUpgradeSymbolsOnStart = 0, symbolBoxSize, isStatic = false }) {
    this.#container = new Container();
    this.#container.name = 'BonusGameCollectLevels';

    this.#activeLevel = levelOnStart;
    this.#currentNumberOfUpgradeSymbols = numberOfUpgradeSymbolsOnStart;
    this.#isStatic = isStatic;

    // Create collect levels
    for (let i = 1; i <= state.options.bonusGameMaxCollectLevel; i++) {
      const spine = new Spine(state.assets[`collectLevel${i}`].resource.spineData);

      spine.x = symbolBoxSize / 2 + symbolBoxSize * (i - 1); // i-1 is column index
      this.#collectLevels[i] = spine;

      if (this.#isStatic) {
        spine.state.timeScale = 1000;
      }

      // Bonus game is active on application start
      if (this.#currentNumberOfUpgradeSymbols) {
        // Set active level
        if (i === this.#activeLevel) {
          this.#setActiveState();
        // Set previous level
        } else if (i < this.#activeLevel) {
          this.#setInactiveState(i);
        // Set next level
        } else if (i === this.nextLevel) {
          // None of upgrade symbols collected for next level
          if ((this.#nextLevelData.levelBoundary - this.#currentNumberOfUpgradeSymbols) === this.#nextLevelData.upgradeSymbolsRequired) {
            this.#runNextAnimation();
          // Some upgrade symbols collected for next levels
          } else {
            this.#runWinAnimation();

            if (!this.#isStatic) {
              state.components.content.reelsWithLogo.reels.runBonusGameReelsNextAnimation(this.nextLevel);
            }
          }
        // Set rest
        } else {
          this.#setIdleState(i);
        }
      // On bonus game start
      } else {
        const startTimeline = gsap.timeline({ paused: true });
        let nextFunction;

        startTimeline.add(() => { this.#runIntroAnimation(i); });

        if (i === 1) {
          nextFunction = () => { this.#runNextAnimation(); };
        } else {
          nextFunction = () => { this.#setIdleState(i); };
        }

        startTimeline.add(nextFunction, !this.#isStatic ? this.#getIntroAnimationDuration(i) : 0);

        startTimeline.play();
      }

      this.#container.addChild(spine);
    }
  }

  get #nextLevelData() {
    return state.options.collectLevelsData[this.nextLevel];
  }

  get #nextWinNumber() {
    return this.#nextLevelData.upgradeSymbolsRequired - (this.#nextLevelData.levelBoundary - this.#currentNumberOfUpgradeSymbols);
  }

  get container() {
    return this.#container;
  }

  get activeLevel() {
    return this.#activeLevel;
  }

  get nextLevel() {
    return this.#activeLevel + 1;
  }

  get numOfUpgradeSymbolsLeftToCollectLevel() {
    return this.#nextLevelData.levelBoundary - this.#currentNumberOfUpgradeSymbols;
  }

  #getIntroAnimationDuration(level) {
    return this.#collectLevels[level].stateData.skeletonData.findAnimation('intro').duration;
  }

  #getNextAnimationDuration() {
    return this.#collectLevels[this.nextLevel].stateData.skeletonData.findAnimation('next').duration;
  }

  #getWinAnimationDuration() {
    return this.#collectLevels[this.nextLevel].stateData.skeletonData.findAnimation(`win${this.#nextWinNumber}`).duration;
  }

  #getOutroAnimationDuration() {
    return this.#collectLevels[this.#activeLevel].stateData.skeletonData.findAnimation('outro').duration;
  }

  #runIntroAnimation(level) {
    this.#collectLevels[level].state.setAnimation(0, 'intro');
  }

  #setIdleState(level) {
    this.#setSaturationState(level, true);
    this.#collectLevels[level].state.setAnimation(0, 'idle');
  }

  #runNextAnimation() {
    this.#setSaturationState(this.nextLevel, false);
    this.#setNumberOfSymbolsText();
    this.#collectLevels[this.nextLevel].state.setAnimation(0, 'next');

    if (!this.#isStatic) {
      state.components.content.reelsWithLogo.reels.runBonusGameReelsNextAnimation(this.nextLevel);
    }
  }

  #runWinAnimation() {
    this.#setNumberOfSymbolsText();
    this.#collectLevels[this.nextLevel].state.setAnimation(0, `win${this.#nextWinNumber}`);
  }

  #setActiveState() {
    this.#collectLevels[this.#activeLevel].state.setAnimation(0, 'active');
  }

  #runOutroAnimation() {
    this.#collectLevels[this.#activeLevel].state.setAnimation(0, 'outro');
    this.#setSaturationState(this.#activeLevel, true);
  }

  #setInactiveState(level) {
    this.#setSaturationState(level, true);
    this.#collectLevels[level].state.setAnimation(0, 'inactive');
  }

  #setSaturationState(level, enabled) {
    if (enabled) {
      const saturationFilter = new AdjustmentFilter({ saturation: 0.1 });
      saturationFilter.resolution = window.devicePixelRatio || 1;
      this.#collectLevels[level].filters = [saturationFilter];
    // Disable saturation filter
    } else if (this.#collectLevels[level].filters) {
      this.#collectLevels[level].filters = null;
    }
  }

  #setNumberOfSymbolsText() {
    const textContainer = this.#collectLevels[this.nextLevel].slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number1');
    const upgradeSymbolsCollected = this.#currentNumberOfUpgradeSymbols - (this.#nextLevelData.levelBoundary - this.#nextLevelData.upgradeSymbolsRequired);
    const text = `${upgradeSymbolsCollected}/${this.#nextLevelData.upgradeSymbolsRequired}`;

    // Create BitmapText if not created
    if (textContainer) {
      this.#numOfSymbolsText = new BitmapText(text, {
        fontName: state.options.customFont,
        fontSize: 80,
      });
      this.#numOfSymbolsText.anchor.set(0.5);
      this.#numOfSymbolsText.angle = 6.5;
      this.#numOfSymbolsText.scale.y = -1; // Adjust to textContainer

      // Remove default text, and add custom text
      textContainer.removeChildren();
      textContainer.addChild(this.#numOfSymbolsText);
    } else {
      this.#numOfSymbolsText.text = text;
    }
  }

  #createNextTimeline() {
    const timeline = gsap.timeline();

    timeline.to(this.#collectLevels[this.nextLevel], {
      onStart: () => {
        this.#runNextAnimation();
      },
      duration: this.#getNextAnimationDuration(),
    });

    return timeline;
  }

  #createWinTimeline() {
    const timeline = gsap.timeline();

    timeline.to(this.#collectLevels[this.nextLevel], {
      onStart: () => {
        this.#runWinAnimation();
      },
      duration: this.#getWinAnimationDuration(),
    });

    return timeline;
  }

  #createOutroTimeline() {
    const timeline = gsap.timeline();

    timeline.to(this.#collectLevels[this.#activeLevel], {
      onStart: () => {
        this.#runOutroAnimation();
      },
      duration: this.#getOutroAnimationDuration(),
    });

    return timeline;
  }

  destroy() {
    this.#container.destroy({ children: true });
  }

  setTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: 1,
      },
    }, {
      pixi: {
        alpha: 0.5,
      },
      duration: 0.75,
    });
  }

  resetTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: this.#container.alpha,
      },
    }, {
      pixi: {
        alpha: 1,
      },
      duration: 0.75,
    });
  }

  async addUpgradeSymbols(numberOfUpgradeSymbolsToAdd) {
    for (let i = 0; i < numberOfUpgradeSymbolsToAdd; i++) {
      this.#currentNumberOfUpgradeSymbols += 1;

      const winTimeline = gsap.timeline({ paused: true });
      const isLastWin = this.#nextLevelData.levelBoundary === this.#currentNumberOfUpgradeSymbols;

      winTimeline.add(this.#createWinTimeline(), 'win');

      // On first upgrade, play sound
      if (i === 0) {
        winTimeline.add(() => { state.playSound('soundCollectLevelUnlocking', true); }, 'win');
      }

      if (isLastWin) {
        winTimeline.add(() => {
          state.stopSound('soundCollectLevelUnlocking');
          state.playSound('soundCollectLevelUnlocked');
        }, 'win+=1.35'); // Add delay so that sound is played at the right time on last win

        // Run outro for active symbol?
        if (this.#activeLevel > 0) {
          winTimeline.add(this.#createOutroTimeline(), 'win');
        }
      // On last upgrade, stop sound
      } else if (i === numberOfUpgradeSymbolsToAdd - 1) {
        winTimeline.add(() => { state.stopSound('soundCollectLevelUnlocking'); });
      }

      await winTimeline.play();

      // After last win for next symbol, set new active symbol and set extra spins
      if (isLastWin) {
        const afterLastWinTimeline = gsap.timeline({ paused: true });

        this.#activeLevel += 1;

        afterLastWinTimeline.add(() => {
          const { extraSpins } = state.options.collectLevelsData[this.#activeLevel];

          state.addBonusGameNumberOfSpins(extraSpins);
          state.components.content.reelsWithLogo.reels.showBonusGameExtraSpinsText(extraSpins);
        }, 'lastWin+=0.01');

        // Run animation for next symbol?
        if (this.#activeLevel < state.options.bonusGameMaxCollectLevel) {
          afterLastWinTimeline.add(this.#createNextTimeline(), 'lastWin');
        }

        await afterLastWinTimeline.play();
      }
    }
  }
}
