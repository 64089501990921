import * as api from '@/api/casino';
import { triggerEvent, getDomain } from '@/utility/Utility';

export class Lobby {
  constructor({
    locale = 'en',
    tenantGameId, // Required, String
    isDemo = true,
    boostTenantId, // Optional, String
    currency, // Required, String
    playerToken, // Required, String
    platform, // Required, String
    token, // Required, String
  }) {
    this.tenantGameId = tenantGameId;
    this.isDemo = isDemo;
    this.boostTenantId = boostTenantId;
    this.iframeWidth = 360;

    const domain = getDomain();

    // Create iframe
    this.iframe = document.createElement('iframe');
    this.iframe.src = `https://casino-lobby.${domain}/?locale=${locale}`;
    this.iframe.id = 'Lobby';
    this.iframe.frameBorder = 0;
    this.iframe.scrolling = 'off';
    this.iframe.style.position = 'fixed';
    this.iframe.style.width = `${this.iframeWidth}px`;
    this.iframe.style.transformOrigin = 'top left';
    this.iframe.style.zIndex = 1000;

    this.isLobbyOpen = false;

    this.hide();

    document.body.append(this.iframe);

    this.iframe.onload = () => {
      const getLobbyParams = {
        locale,
        currency,
        playerToken,
        platform,
        token,
      };

      this.onLoad(getLobbyParams);
    };

    this.addEventListeners();
  }

  async onLoad(getLobbyParams) {
    const result = await api.getLobby(getLobbyParams);

    if (result.isError) {
      triggerEvent('ShowNotification', result);
    } else if (result.games) {
      this.iframe.contentWindow.postMessage({
        action: 'InitLobby',
        value: result.games,
      }, '*');
    }
  }

  addEventListeners() {
    window.addEventListener('message', (event) => {
      if (event.data?.action) {
        const { action, value } = event.data;

        if (action === 'HideLobby') {
          this.hide();
        } else if (action === 'OpenGame') {
          if (value?.gameUrl && (value.tenantGameId !== this.tenantGameId || value.isDemo !== this.isDemo)) {
            let { gameUrl } = value;

            if (this.boostTenantId) {
              gameUrl += `&boostTenantId=${this.boostTenantId}`;
            }

            window.open(gameUrl, '_self');
          } else {
            this.hide();
          }
        }
      }
    });
  }

  // Called in ControlsHeader.setPosition
  setPosition() {
    const canvas = document.getElementsByTagName('canvas')[0];
    const canvasRect = canvas.getBoundingClientRect();
    const scale = canvasRect.width / this.iframeWidth;

    this.iframe.style.left = `${canvasRect.left + window.scrollX}px`;
    this.iframe.style.top = `${canvasRect.top + window.scrollY}px`;
    this.iframe.style.transform = `scale(${scale})`;
    this.iframe.style.height = `${canvasRect.height / scale}px`;
  }

  show() {
    this.isLobbyOpen = true;
    this.iframe.style.display = 'block';
    this.iframe.contentWindow.focus();
  }

  hide() {
    this.isLobbyOpen = false;
    this.iframe.style.display = 'none';
  }
}
