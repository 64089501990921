import animate from 'gsap';
import { state } from './State';

export class Winlines {
  constructor({ winlines }) {
    this.winlines = winlines;
    this.winSymbolReference = undefined;
    this.winlinesLoopTimeline = undefined;
    this.symbolWinAnimationDuration = state.reels.reelWindowSymbols.find((symbol) => symbol.hasAnimation('symbolWin')).getAnimationDuration('symbolWin');
  }

  get showIndividualWinlines() {
    return !state.spinResult.isBonusWon && (!state.isAnyAutoplay || state.autoplay.stopOnAnyWin);
  }

  showWinlines() {
    const { reels, reelWindowSymbols, reelWindowStickyReels } = state.reels;

    state.playSound('slotWinline');

    /* Run logo win animation once */
    state.reelsWrapper.logo.logoWinAnimation();

    /* Reset all symbols to the non-winning state */
    reelWindowSymbols.forEach((symbol) => {
      symbol.setAnimation('symbolNoWin');
      symbol.setZIndex(1);
    });

    /* Highlight all win symbols */
    this.winlines.forEach((winline) => {
      winline.payline.forEach((rowIndex, reelIndex) => {
        const reelSymbol = reels[reelIndex][rowIndex];
        const stickySymbol = reelWindowStickyReels[reelIndex][rowIndex];
        const isSymbolInWinline = reelSymbol.value === winline.symbolReal || reelSymbol.isWild || stickySymbol;

        if (isSymbolInWinline) {
          const zIndexValue = reelSymbol.isSpecialSymbol ? 3 : 2;

          if (stickySymbol) {
            stickySymbol.setAnimation('symbolWin', true);
            stickySymbol.setZIndex(zIndexValue);
          } else {
            reelSymbol.setAnimation('symbolWin', true);
            reelSymbol.setZIndex(zIndexValue);

            /* Reference to win symbol so we can track animation state */
            this.winSymbolReference = reelSymbol;
          }
        }
      });
    });
  }

  playWinlinesLoop() {
    /* Set all sticky symbols to idle state */
    this.setStickysAnimationToIdle();

    /* Smooth transition from all winlines into individual winlines loop */
    if (this.showIndividualWinlines) {
      this.setupWinlinesTransition();
    }
  }

  setStickysAnimationToIdle() {
    const stickySymbols = state.reels.reelWindowStickyReels.flat().filter((symbol) => symbol !== undefined);

    stickySymbols.forEach((symbol) => {
      const symbolState = symbol.symbol.state;
      const isWinAnimation = symbolState.getCurrent(0).animation.name === 'symbolWin';

      if (isWinAnimation) {
        symbolState.addListener({
          complete: (trackEntry) => {
            if (trackEntry.animation.name === 'symbolWin') {
              symbol.setAnimation('symbolIdle', true);
              /* Remove all listeners after animation is set to idle */
              symbolState.listeners = [];
            }
          },
        });
      } else {
        symbol.setAnimation('symbolIdle', true);
      }
    });
  }

  setupWinlinesTransition() {
    const symbolState = this.winSymbolReference && this.winSymbolReference.symbol.state;

    if (symbolState) {
      symbolState.addListener({
        complete: (trackEntry) => {
          if (trackEntry.animation.name === 'symbolWin') {
            /* Play individual winlines loop */
            this.playIndividualWinlinesLoop();
            /* Remove all listeners after transition to winlines loop */
            symbolState.listeners = [];
          }
        },
      });
    } else {
      this.playIndividualWinlinesLoop();
    }
  }

  playIndividualWinlinesLoop() {
    const { reels, reelWindowSymbols } = state.reels;

    this.winlinesLoopTimeline = animate.timeline({ repeat: -1 });

    this.winlines.forEach((winline) => {
      this.winlinesLoopTimeline.to({}, {
        duration: this.symbolWinAnimationDuration,
        onStart: () => {
          /* Set all symbols to the non-winning state */
          reelWindowSymbols.forEach((symbol) => {
            symbol.setAnimation('symbolNoWin');
            symbol.setZIndex(1);
          });

          /* Highlight the winning symbols in the current winline */
          winline.payline.forEach((rowIndex, reelIndex) => {
            const reelSymbol = reels[reelIndex][rowIndex];

            if (reelSymbol.value === winline.symbolReal || reelSymbol.isWild) {
              const zIndexValue = reelSymbol.isSpecialSymbol ? 3 : 2;

              reelSymbol.setAnimation('symbolWin', true);
              reelSymbol.setZIndex(zIndexValue);
            }
          });
        },
      });
    });

    this.winlinesLoopTimeline.play();
  }

  clear() {
    if (this.winlinesLoopTimeline) {
      this.winlinesLoopTimeline.kill();
      this.winlinesLoopTimeline = undefined;
    }
  }
}
