import { RoundsHistoryTable as BaseRoundsHistoryTable } from '@/components/shared/RoundsHistoryTable';
import { state } from '../State';

export class RoundsHistoryTable extends BaseRoundsHistoryTable {
  constructor(tableParams) {
    super({
      tableParams,
      width: state.options.calcWidth,
      textColor: state.options.colors.text,
      fontNameLight: state.options.fontNameLight,
      fontNameBold: state.options.fontNameBold,
      translations: state.options.translations,
      currency: state.options.currency,
      currencyDisplayEnabled: state.options.currencyDisplayEnabled,
      playTapSound: () => { state.playTapSound(); },
      getMoneyLabel: (...data) => state.getMoneyLabel(...data),
    });
  }
}
