import { capitalize } from 'lodash';
import { Container } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { gameUtils } from './GameUtils';
import { state } from './State';
import { Cash } from './Cash';

export class WinGrading {
  constructor() {
    this.container = new Container();
    this.container.name = 'WinGrading';
    this.container.visible = false;
    this.container.alpha = 0;
    this.container.mask = state.components.root.contentMask;

    this.winGrading = new Spine(state.options.assets.winGrading.resource.spineData);
    this.coinBurst = new Spine(state.options.assets.coinBurst.resource.spineData);
    this.coinBurst2 = new Spine(state.options.assets.coinBurst.resource.spineData);
    this.background = new Spine(state.options.assets.backgroundWinGrading.resource.spineData);
    this.background.alpha = 0;

    /* Set idle state, and then get asset dimensions, that way dimensions are correct */
    this.setAnimation('bigWinIdle');
    this.winGradingWidth = this.winGrading.getLocalBounds().width;
    this.winGradingHeight = this.winGrading.getLocalBounds().height;
    this.winGradingBottomY = this.winGrading.getLocalBounds().bottom;
    this.winGradingLeftX = this.winGrading.getLocalBounds().left;

    this.winAmountAnimationDuration = 1;
    this.winAmountHideDelay = 1;
    this.winAmountText = undefined;
    this.winAmountTotal = undefined;
    this.winGrades = undefined;
    this.mainTimeline = undefined;
    this.isStoppable = true;
    this.isStopped = false;

    this.container.addChild(this.background, this.coinBurst, this.coinBurst2, this.winGrading);

    this.setText();
    this.setListeners();
    this.setPosition();
  }

  get isActive() {
    return this.container.visible;
  }

  getCoinBurstDuration() {
    return this.coinBurst.stateData.skeletonData.findAnimation('winGradingIdle').duration;
  }

  getWinDuration() {
    return this.winGrading.stateData.skeletonData.findAnimation('win').duration;
  }

  getGradeIntroDuration(gradeName) {
    return this.winGrading.stateData.skeletonData.findAnimation(`${gradeName}WinIntro`).duration;
  }

  getGradeIdleDuration(gradeName) {
    return this.winGrading.stateData.skeletonData.findAnimation(`${gradeName}WinIdle`).duration;
  }

  getGradeOutroDuration(gradeName) {
    return this.winGrading.stateData.skeletonData.findAnimation(`${gradeName}WinOutro`).duration;
  }

  getGradeIdleLoopDuration(duration, gradeName) {
    return duration - this.getGradeIntroDuration(gradeName) / 2;
  }

  getLastGradeName() {
    return this.winGrades[this.winGrades.length - 1].type.toLowerCase();
  }

  setAnimation(name, loop = false) {
    this.background.state.setAnimation(0, name, loop);
    this.winGrading.state.setAnimation(0, name, loop);
  }

  setOutroAnimation(gradeName) {
    this.winGrading.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === `${gradeName}WinIdle`) {
          this.setAnimation(`${gradeName}WinOutro`);
          this.winGrading.state.listeners = [];
        }
      },
    });
  }

  setListeners() {
    this.container.on('pointertap', () => {
      this.stop();
    });
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundDimensions = this.background.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    /* Calculate elements scale */
    let backgroundScale = 1;
    let winGradingScale = 1;
    let coinBurstScale = 1;

    if (aspectRatio > backgroundAspectRatio) {
      backgroundScale = state.appHeight / backgroundDimensions.height;
    }

    if (this.winGradingHeight > state.appHeight * 0.8) {
      winGradingScale = (state.appHeight * 0.8) / this.winGradingBottomY;
    }

    if (state.appScale < 0.5) {
      coinBurstScale = 1 + (1 - state.appScale);
    }

    /* Set Positions */
    this.background.scale.set(backgroundScale);
    this.background.x = state.appWidth / 2 - backgroundDimensions.width / 2;

    const winGradingYOffset = 325 * winGradingScale; /* Used to set win amount text to reels center */

    this.winGrading.scale.set(winGradingScale);
    this.winGrading.x = state.appWidth / 2 - (this.winGradingWidth * winGradingScale) / 2 - this.winGradingLeftX * winGradingScale;
    this.winGrading.y = state.reelsCenter - (this.winGradingHeight * winGradingScale) / 2 - winGradingYOffset;

    this.coinBurst.scale.set(coinBurstScale);
    this.coinBurst.x = state.appWidth / 2;
    this.coinBurst.y = state.appHeight;

    this.coinBurst2.scale.set(coinBurstScale);
    this.coinBurst2.x = this.coinBurst.x;
    this.coinBurst2.y = this.coinBurst.y;

    /* Reapply mask on every resize */
    this.container.mask = state.components.root.contentMask;
  }

  setText() {
    const slot = this.winGrading.skeleton.findSlot('grading_value_label');

    this.winAmountText = new Cash({
      cash: 0,
      fontSize: 125,
      maxWidth: slot.attachment.width * 0.9,
    });

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(this.winAmountText.container);
  }

  formatWinGrades(winGrades) {
    const winGradesRef = winGrades;

    if (winGradesRef) {
      const lastGradeName = winGradesRef[winGradesRef.length - 1].type.toLowerCase();

      /* Adjust first grade start amount */
      winGradesRef[0].amountFrom = state.betAmount * 5;

      /* Adjust last grade name */
      if (lastGradeName === 'sensation') {
        winGradesRef[winGradesRef.length - 1].type = 'Blast';
      }
    }

    return winGradesRef;
  }

  createShowTimeline() {
    const timeline = animate.timeline();

    timeline.to(this.container, {
      duration: 0.2,
      ease: 'power1.out',
      pixi: {
        alpha: 1,
      },
      onStart: () => {
        this.container.visible = true;
        this.container.eventMode = 'dynamic';
        this.container.cursor = 'pointer';
      },
    });

    return timeline;
  }

  createHideTimeline(isInterrupted) {
    const timeline = animate.timeline();

    timeline.to(this.container, {
      duration: 0.2,
      ease: 'power1.out',
      pixi: {
        alpha: 0,
      },
      onStart: () => {
        /* Don't update balance in bonus rounds instead show total bonus win only */
        if (!state.isBonus || state.isBonusEnd) {
          state.setBalanceAmountOnSpinEnd();
          state.setWinAmount(this.winAmountTotal);
        } else {
          const amountTo = state.spinResult.bonus.winAmount;
          const amountFrom = state.spinResult.bonus.winAmount - state.spinResult.winAmountBonus;
          state.components.footer.showWinAmountWithIncrement(amountTo, gameUtils.getMoneyLabel, amountFrom);
        }

        /* Transition winlines from all winlines shown to individual winlines loop.
           Don't play loop if wingrading is interrupted by new spin */
        if (state.reels.winlines && !isInterrupted) {
          state.reels.winlines.playWinlinesLoop();
        }

        /* If win grading is after bonus outro, enable controls */
        if (state.bonus.isEnd) {
          state.enableAfterSpin();
        }
      },
      onComplete: () => {
        /* Reset */
        this.container.alpha = 0;
        this.container.visible = false;
        this.container.eventMode = 'static';
        this.container.cursor = 'pointer';
        this.background.alpha = 0;
        this.isStoppable = true;
        this.isStopped = false;
        this.setText();

        if (this.mainTimeline) {
          this.mainTimeline.kill();
          this.mainTimeline = undefined;
        }

        state.components.header.uiBoost?.enable();
      },
    });

    return timeline;
  }

  createWinAmountTimeline() {
    const winAmount = this.winGrades ? this.winGrades[0].amountFrom : this.winAmountTotal;

    return this.winAmountText.createAmountAnimateTimeline(winAmount, this.winAmountAnimationDuration, 0, 0);
  }

  createGradesTimeline(winGrades) {
    if (!winGrades) return undefined;

    const timeline = animate.timeline({
      onStart: () => {
        state.playSound('winGradingLoop', false);
        state.setSoundAmbientVolume(0.3);
      },
      onComplete: () => {
        state.setSoundAmbientVolume(1);
      },
    });

    const amountTimeline = animate.timeline();
    const gradesTimeline = animate.timeline();
    const backgroundTimeline = animate.timeline();

    /* Create winGrades animations and transitions */
    winGrades.forEach((grade, index) => {
      const gradeName = grade.type.toLowerCase();
      const gradeDuration = grade.timeMs / 1000;
      const isFirstGrade = index === 0;
      const isLastGrade = winGrades.length === index + 1;

      /* Add amount update timeline for current grade */
      let amountUpdateDuration = gradeDuration + this.getGradeIntroDuration(gradeName) / 2;

      if (isFirstGrade) {
        amountUpdateDuration = gradeDuration + this.getGradeIntroDuration(gradeName);
      }

      if (isLastGrade) {
        amountUpdateDuration -= this.winAmountHideDelay;
      }

      amountTimeline.add(this.winAmountText.createAmountAnimateTimeline(grade.amountTo, amountUpdateDuration, 0, grade.amountFrom));

      /* Grade spine animations timeline */
      const gradeTimeline = animate.timeline();

      gradeTimeline.to({}, {
        duration: this.getGradeIntroDuration(gradeName),
        onStart: () => {
          this.setAnimation(`${gradeName}WinIntro`);

          if (!this.isStopped) {
            state.reels.reelsBackground.state.setAnimation(0, `reel${capitalize(gradeName)}WinIntro`, false);

            if (!isFirstGrade) {
              state.playSound('winGradingTitleHit');
            }
          }

          if (isFirstGrade) {
            this.isStoppable = true;
            this.createCoinBurstTimeline().play();
          }
        },
      });

      gradeTimeline.to({}, {
        duration: this.getGradeIdleLoopDuration(gradeDuration, gradeName),
        onStart: () => {
          this.setAnimation(`${gradeName}WinIdle`, true);
        },
      });

      if (isLastGrade) {
        gradeTimeline.to({}, {
          duration: this.getGradeOutroDuration(gradeName),
          onStart: () => {
            this.isStoppable = false;
            this.setOutroAnimation(gradeName);
            this.coinBurstLoopTimeline.clear();
            state.stopSound('winGradingLoop', true);
          },
        });
      }

      gradesTimeline.add(gradeTimeline);
    });

    /* Show wingrading background timeline */
    backgroundTimeline.to(this.background, {
      duration: 0.2,
      ease: 'power1.out',
      pixi: {
        alpha: 1,
      },
      onStart: () => this.setPosition(),
    });

    timeline.add(gradesTimeline);
    timeline.add(amountTimeline, '<');
    timeline.add(backgroundTimeline, '<');

    return timeline;
  }

  createCoinBurstTimeline() {
    this.coinBurstLoopTimeline = animate.timeline({ repeat: -1 });

    this.coinBurstLoopTimeline.to({}, {
      duration: this.getCoinBurstDuration() / 2,
      onStart: () => {
        this.coinBurst.state.setAnimation(0, 'winGradingIdle', false);
      },
    });

    this.coinBurstLoopTimeline.to({}, {
      duration: this.getCoinBurstDuration() / 2,
      onStart: () => {
        this.coinBurst2.state.setAnimation(0, 'winGradingIdle', false);
      },
    });

    return this.coinBurstLoopTimeline;
  }

  createWinGradingTimeline(winAmountTotal, winGrades) {
    this.isStoppable = !winGrades;
    this.winAmountTotal = winAmountTotal;
    this.winGrades = this.formatWinGrades(winGrades);

    if (this.winGrades) {
      state.components.header.uiBoost?.disable();
      state.components.content.controls.setSpinButtonEnabled(false);
    }

    this.mainTimeline = animate.timeline({
      onStart: () => this.setAnimation('win'),
    });

    /* Show win amount */
    this.mainTimeline.add(this.createShowTimeline());

    /* Animate win amount before bigWin/winGrades */
    this.mainTimeline.add(this.createWinAmountTimeline(), '<');

    /* Play win grades */
    this.mainTimeline.add(this.createGradesTimeline(this.winGrades));

    /* Stop point for win amount animation if there is no win grades */
    this.mainTimeline.addLabel('winGradingStop', '>');

    /* Hide win grading */
    this.mainTimeline.add(this.createHideTimeline(), `+=${this.winAmountHideDelay}`);

    /* Nedded for smooth stop() */
    this.mainTimeline.smoothChildTiming = true;

    return this.mainTimeline;
  }

  stop() {
    if (this.mainTimeline.isActive() && this.mainTimeline.progress() < 0.8 && this.isStoppable) {
      this.container.eventMode = 'none';
      this.container.cursor = 'default';
      this.isStoppable = false;
      this.isStopped = true;

      if (this.winGrades) {
        this.mainTimeline.seek(`-=${this.getGradeOutroDuration(this.getLastGradeName()) + this.winAmountHideDelay}`, false);
      } else {
        this.mainTimeline.seek('winGradingStop', false);
      }

      state.reels.reelsBackground.state.setAnimation(0, 'reelBaseIdle', false);
      state.stopSound('winGradingTitleHit');
    }
  }

  async interruptWithNewSpin() {
    this.mainTimeline.kill();
    this.mainTimeline = undefined;
    await this.createHideTimeline(true).play();
  }
}
