import { SlotSpinSpeedType } from '../models';
import { BitmapText, Container } from '../pixi';
import { SlotCheckbox } from './SlotCheckbox';
import { audio } from './SlotAudio';
import { slotState } from './SlotState';

export class SlotDialogMenuSettings {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.labelFontName = this.options.fontNameNormal;
    this.labelFontSize = 36;
    this.labelFontColor = this.options.colors.text;
    this.labelStyle = { fontName: this.labelFontName, fontSize: this.labelFontSize, tint: this.labelFontColor };

    this.setup();
  }

  setup() {
    this.soundLabel = new BitmapText(this.options.translations.dialogSettingsSoundLabel, this.labelStyle);
    this.turboLabel = new BitmapText(this.options.translations.dialogSettingsTurboLabel, this.labelStyle);
    this.lightningLabel = new BitmapText(this.options.translations.dialogSettingsLightningLabel, this.labelStyle);
    this.soundCheckbox = new SlotCheckbox({ onChange: () => { audio.toggleMute(); } });
    this.turboCheckbox = new SlotCheckbox({ onChange: () => { this.setSpinSpeedType.bind(this)(SlotSpinSpeedType.Turbo); } });
    this.lightningCheckbox = new SlotCheckbox({ onChange: () => { this.setSpinSpeedType.bind(this)(SlotSpinSpeedType.Lightning); } });

    this.container.addChild(this.soundLabel);
    this.container.addChild(this.turboLabel);
    this.container.addChild(this.lightningLabel);
    this.container.addChild(this.soundCheckbox.container);
    this.container.addChild(this.turboCheckbox.container);
    this.container.addChild(this.lightningCheckbox.container);
  }

  setPosition({ width }) {
    this.soundCheckbox.container.x = width - this.soundCheckbox.container.width - this.options.uiDialogPadding;
    this.soundCheckbox.container.y = this.options.uiDialogPadding;
    this.soundLabel.x = this.options.uiDialogPadding;
    this.soundLabel.y = this.soundCheckbox.container.y + ((this.soundCheckbox.container.height / 2) - (this.soundLabel.height / 2));
    this.turboCheckbox.container.x = this.soundCheckbox.container.x;
    this.turboCheckbox.container.y = this.soundCheckbox.container.y + this.soundCheckbox.container.height + this.options.uiDialogPadding;
    this.turboLabel.x = this.soundLabel.x;
    this.turboLabel.y = this.turboCheckbox.container.y + ((this.turboCheckbox.container.height / 2) - (this.turboLabel.height / 2));
    this.lightningCheckbox.container.x = this.turboCheckbox.container.x;
    this.lightningCheckbox.container.y = this.turboCheckbox.container.y + this.turboCheckbox.container.height + this.options.uiDialogPadding;
    this.lightningLabel.x = this.soundLabel.x;
    this.lightningLabel.y = this.lightningCheckbox.container.y + ((this.lightningCheckbox.container.height / 2) - (this.lightningLabel.height / 2));
  }

  setSpinSpeedType(type) {
    if (type === SlotSpinSpeedType.Lightning && this.lightningCheckbox.isActive) {
      this.turboCheckbox.set(false);
      slotState.setSpinSpeedType(SlotSpinSpeedType.Lightning);
    } else if (type === SlotSpinSpeedType.Turbo && this.turboCheckbox.isActive) {
      this.lightningCheckbox.set(false);
      slotState.setSpinSpeedType(SlotSpinSpeedType.Turbo);
    } else {
      slotState.setSpinSpeedType(SlotSpinSpeedType.Normal);
    }
  }

  setData() {
    this.soundCheckbox.set(!audio.isSoundOff);
    this.turboCheckbox.set(slotState.options.spinSpeedType === SlotSpinSpeedType.Turbo);
    this.lightningCheckbox.set(slotState.options.spinSpeedType === SlotSpinSpeedType.Lightning);
  }
}
