import { Container, Rectangle, Sprite, Texture, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import { assign } from 'lodash';
import animate from 'gsap';
import { state } from './State';
import { gameUtils } from './GameUtils';
import { callApiWithRetries, triggerEvent, triggerAnalyticsEvent } from '../../utility/Utility';
import * as api from '../../api/casino';

export class BonusGameIntroScreen {
  constructor() {
    this.container = new Container();
    this.container.name = 'BonusGameIntroScreen';
    this.container.eventMode = 'static';
    this.options = state.options;
    this.isBonusStarted = undefined;
    this.buyButton = undefined;
    this.setup();
    this.bonusGameDetails = {
      amount: 0,
      numberOfSpins: 20,
    };
  }

  runBackgroundAnimation() {
    animate.to(this.background, {
      pixi: {
        alpha: 1,
      },
      duration: 0.5,
      onComplete: () => {
        state.background.hideLogo();
        state.addBonusGameNumberOfSpins(this.bonusGameDetails.numberOfSpins);
        this.close();
        state.background.runBackgroundAnimation('bonusGame');
        state.startBonusGame();
        triggerEvent('ShowBoostBadge');
      },
    });
  }

  initDialogModel() {
    this.bonusDialog = new Spine(state.options.resources.bonusBuy.resource.spineData);
  }

  setBackground() {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.alpha = 0.75;
    this.background.eventMode = 'static';
  }

  setup() {
    this.initDialogModel();
    this.setBackground();
    this.container.addChild(this.background);
    this.container.addChild(this.bonusDialog);
    this.setIntroAnimations();
    this.setContainerVisibilityState(false);
  }

  setContainerVisibilityState(visible) {
    this.container.visible = visible;
    this.container.children.forEach((c) => { c.visible = visible; }); // eslint-disable-line no-param-reassign
  }

  setIntroAnimations() {
    this.bonusDialog.state.setAnimation(0, 'idle', true);
  }

  setPosition() {
    const { width, height } = this.options.size();
    const scale = this.container.parent.scale.y;
    const backgroundWidth = width / scale;
    const backgroundHeight = height / scale;
    const aspectRatio = height / width;

    this.background.width = state.appWidth;
    this.background.height = state.appHeight;
    this.background.anchor.set(0.5);
    this.background.x = state.appWidth - (state.appWidth / 2);
    this.background.y = state.appHeight - (state.appHeight / 2);

    this.bonusDialog.scale.set(aspectRatio * 0.55);
    this.bonusDialog.x = backgroundWidth - (backgroundWidth / 2);
    this.bonusDialog.y = backgroundHeight - (backgroundHeight / 2) - this.options.uiPadding * 5;
  }

  setNumberOfSpins() {
    this.numberOfSpinsLabel = new BitmapText(this.bonusGameDetails.numberOfSpins, {
      fontName: state.options.customFont4,
      fontSize: 200,
    });

    this.numberOfSpinsLabel.anchor.set(0.5);
    this.numberOfSpinsLabel.x += state.options.uiPadding * 2;
    this.numberOfSpinsLabel.y -= state.options.uiPadding * 8;

    this.bonusDialog.addChild(this.numberOfSpinsLabel);
  }

  setSpinsLabel() {
    const spinsLabel = new BitmapText(this.options.translations.spins.toUpperCase(), {
      fontName: state.options.customFont5,
      fontSize: 100,
    });

    spinsLabel.anchor.set(0.5);
    spinsLabel.x = this.numberOfSpinsLabel.x - state.options.uiPadding;
    spinsLabel.y -= state.options.uiPadding * 2;

    this.bonusDialog.addChild(spinsLabel);
  }

  setBonusDescription() {
    const bonusDescription = new BitmapText(this.options.translations.goldOfEgyptSpinsWithoutTrapsLabel.toUpperCase(), {
      fontName: state.options.customFont5,
      fontSize: 55,
    });

    bonusDescription.anchor.set(0.5);
    bonusDescription.x = this.numberOfSpinsLabel.x - state.options.uiPadding;
    bonusDescription.y = this.numberOfSpinsLabel.y - this.numberOfSpinsLabel.height + state.options.uiPadding * 2;

    this.bonusDialog.addChild(bonusDescription);
  }

  setBuyPrice() {
    this.buyPrice = new BitmapText(this.bonusGameDetails.amount.toFixed(2), {
      fontName: state.options.customFont2,
      fontSize: 50,
    });

    this.buyPrice.anchor.set(0.5);
    this.buyPrice.x += state.options.uiPadding / 4;
    this.buyPrice.y -= state.options.uiPadding / 4;

    this.bonusDialog.children[2].children[0].addChild(this.buyPrice);
  }

  setBuyCurrency() {
    const currencyLabel = new BitmapText(this.options.currency, {
      fontName: state.options.customFont2,
      fontSize: 18,
    });

    currencyLabel.anchor.set(0.5);
    currencyLabel.x += state.options.uiPadding / 4;
    currencyLabel.y += this.bonusDialog.children[2].children[0].height / 2 - currencyLabel.height;

    this.bonusDialog.children[2].children[0].addChild(currencyLabel);
  }

  setCollectLabel() {
    const buttonOffset = 15;
    const { buyLabel, start } = this.options.translations;
    const buttonLabel = !this.isBonusStarted ? buyLabel : start;
    this.buyButton = this.bonusDialog.children[3].children[0]; // eslint-disable-line
    this.buyButton.hitArea = new Rectangle(
      -this.buyButton.width / 2 + buttonOffset,
      -this.buyButton.height / 2 + buttonOffset,
      this.buyButton.width - buttonOffset * 2,
      this.buyButton.height - buttonOffset * 2,
    );
    const label = new BitmapText(buttonLabel.toUpperCase(), {
      fontName: state.options.customFont1,
      fontSize: gameUtils.getFontSize({
        textLength: buttonLabel.length,
        baseSize: 24,
        minSize: 10,
        maxLength: 25,
      }),
    });

    label.anchor.set(0.5);
    label.x += state.options.uiPadding / 4;
    label.y -= state.options.uiPadding / 8;

    this.enabeBuyButton();
    this.buyButton.on('pointertap', () => {
      this.disableBuyButton();
      if (!this.isBonusStarted) {
        this.cancelLabel.interactive = false;
      }
      this.start();
    });

    this.buyButton.addChild(label);
  }

  enabeBuyButton() {
    this.buyButton.eventMode = 'static';
    this.buyButton.cursor = 'pointer';
    this.buyButton.alpha = 1;
  }

  disableBuyButton() {
    this.buyButton.eventMode = 'none';
    this.buyButton.alpha = 0.5;
  }

  setCancelLabel() {
    this.cancelLabel = new BitmapText(this.options.translations.cancel.toUpperCase(), {
      fontName: state.options.customFont2,
      fontSize: 50,
    });

    this.cancelLabel.anchor.set(0.5);
    this.cancelLabel.x += state.appWidth / 2;
    this.cancelLabel.y = state.appHeight - state.options.uiPadding * 8;
    this.cancelLabel.interactive = true;
    this.cancelLabel.buttonMode = true;
    this.cancelLabel.cursor = 'pointer';
    this.cancelLabel.on('pointertap', () => {
      state.content.controls.enableBuy();
      state.content.controls.enableSpin();
      state.content.controls.enableAutoplayButton();
      state.content.controls.runAutoplayButtonAnimation('active');
      this.close();
      triggerEvent('ShowBoostBadge');
    });

    this.container.addChild(this.cancelLabel);
  }

  async calculatePrice() {
    const props = [
      this.options.tenantGameId,
      this.options.playerToken,
      {
        betAmount: state.betAmount,
      },
      'calculateBonusPrices',
    ];

    const result = await callApiWithRetries(api.calculateBonusPrices, props, 5);

    if (result.isError) {
      state.setErrorDetails({ ...result, errorOrigin: 'dialog' });
    }

    return result;
  }

  async buy() {
    const requestPayload = [
      this.options.tenantGameId,
      this.options.playerToken,
      'buyBonus',
      {
        betAmount: state.betAmount,
        numberOfSpins: 20,
      },
    ];

    const response = await gameUtils.makeApiRequest('buyBonus', requestPayload, false);

    return response;
  }

  setupDialogInfo() {
    if (!this.buyButton) {
      this.setCollectLabel();
    }
    this.setNumberOfSpins();
    this.setSpinsLabel();
    this.setBonusDescription();

    if (!this.isBonusStarted) {
      this.setBuyPrice();
      this.setCancelLabel();
      if (this.options.currencyDisplayEnabled) {
        this.setBuyCurrency();
      }
    }
  }

  async show(isBonusStarted = false) {
    let priceDetails;
    state.content.controls.disableSpin();
    state.content.controls.disableAutoplayButton();
    triggerEvent('HideBoostBadge');
    this.isBonusStarted = isBonusStarted;

    if (!this.isBonusStarted) {
      priceDetails = await this.calculatePrice();
      assign(this.bonusGameDetails, priceDetails.prices[0]);
    } else {
      assign(this.bonusGameDetails, {
        numberOfSpins: state.options.state.availableFreeRounds,
      });
    }

    this.setContainerVisibilityState(true);
    state.setDialogOpen(true);
    this.setupDialogInfo();

    if (priceDetails?.prices[0].amount > state.balanceAmount && !this.isBonusStarted) {
      this.disableBuyButton();
    } else {
      this.enabeBuyButton();
    }
  }

  close() {
    state.playTapSound();
    this.setContainerVisibilityState(false);
    state.setDialogOpen(false);
    this.bonusDialog.removeChild(this.numberOfSpinsLabel); // issue with duplicate shadow on font
    this.bonusDialog.children[2].children[0].removeChild(this.buyPrice);
  }

  async start() {
    if (!this.isBonusStarted) {
      const response = await this.buy();
      if (response.isError) {
        state.setErrorDetails(response);
      } else {
        assign(this.bonusGameDetails, response.price);
        state.updateBalance(response.balance);
      }
    }
    this.runBackgroundAnimation();
    triggerAnalyticsEvent({ name: 'StartBonusGameClick' });
  }
}
