import { Container, BitmapText } from '@/pixi';
import { Checkbox } from './Checkbox';
import { audio } from '../Audio';
import { state } from '../State';

export class DialogContentMenuSettings {
  #container;
  #soundLabel;
  #soundCheckbox;
  #lightningSpinLabel;
  #lightningSpinCheckbox;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'DialogContentMenuSettings';

    const labelStyle = {
      fontName: state.options.fontNameNormal,
      fontSize: 36,
      tint: state.options.colors.text,
    };

    this.#soundLabel = new BitmapText(state.options.translations.dialogSettingsSoundLabel, labelStyle);
    this.#soundCheckbox = new Checkbox({ onChange: () => { this.#toggleMute(); } });
    this.container.addChild(this.#soundLabel, this.#soundCheckbox.container);

    this.#lightningSpinLabel = new BitmapText(state.options.translations.dialogSettingsLightningLabel, labelStyle);
    this.#lightningSpinCheckbox = new Checkbox({ onChange: () => { this.#toggleLightningSpin(); } });
    this.container.addChild(this.#lightningSpinLabel, this.#lightningSpinCheckbox.container);
  }

  get container() {
    return this.#container;
  }

  #toggleMute() {
    audio.toggleMute();
  }

  #toggleLightningSpin() {
    state.setLightningSpinState(!state.isLightningSpinEnabled);
  }

  setData() {
    this.#soundCheckbox.set(!audio.isSoundOff);
    this.#lightningSpinCheckbox.set(state.isLightningSpinEnabled);
  }

  setPosition() {
    this.#soundCheckbox.container.x = state.appWidth - state.options.uiDialogPadding - this.#soundCheckbox.container.width;
    this.#soundCheckbox.container.y = state.options.uiDialogPadding;
    this.#soundLabel.x = state.options.uiDialogPadding;
    this.#soundLabel.y = this.#soundCheckbox.container.y + ((this.#soundCheckbox.container.height / 2) - (this.#soundLabel.height / 2));

    this.#lightningSpinCheckbox.container.x = this.#soundCheckbox.container.x;
    this.#lightningSpinCheckbox.container.y = this.#soundCheckbox.container.y + this.#soundCheckbox.container.height + state.options.uiDialogPadding;
    this.#lightningSpinLabel.x = this.#soundLabel.x;
    this.#lightningSpinLabel.y = this.#lightningSpinCheckbox.container.y + ((this.#lightningSpinCheckbox.container.height / 2) - (this.#lightningSpinLabel.height / 2));
  }
}
