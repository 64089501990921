import { Container } from '@/pixi';
import { Spine } from 'pixi-spine';
import { findLastIndex, forEach, forEachRight, upperFirst } from 'lodash';
import { state } from './State';

export class Posts {
  constructor() {
    this.container = new Container();
    this.options = state.options;
    this.numberOfLevels = state.options.state.game.config.levels;
    this.rightPost = undefined;
    this.leftPost = undefined;
    this.rightPlates = [
      {
        multiplier: 'x2',
        isActive: false,
      },
      {
        multiplier: 'x8',
        isActive: false,
      },
      {
        multiplier: 'x15',
        isActive: false,
      },
      {
        multiplier: 'x30',
        isActive: false,
      },
      {
        multiplier: 'x50',
        isActive: false,
      },
      {
        multiplier: 'x80',
        isActive: false,
      },
      {
        multiplier: 'x120',
        isActive: false,
      },
    ];
    this.leftPlates = [
      {
        multiplier: 'x3',
        isActive: false,
      },
      {
        multiplier: 'x10',
        isActive: false,
      },
      {
        multiplier: 'x20',
        isActive: false,
      },
      {
        multiplier: 'x50',
        isActive: false,
      },
      {
        multiplier: 'x80',
        isActive: false,
      },
      {
        multiplier: 'x120',
        isActive: false,
      },
      {
        multiplier: 'x160',
        isActive: false,
      },
    ];
    this.container.name = 'Posts';
    this.setup();
  }

  clearPlates(post) {
    forEachRight(this[`${post}Plates`], (plate, index) => {
      this[`run${upperFirst(post)}PostAnimation`](`${this[`${post}Plates`][index].multiplier}loss`);
      plate.isActive = false; // eslint-disable-line no-param-reassign
    });
  }

  initModels() {
    this.rightPost = new Spine(state.options.resources.rightMultiplier.resource.spineData);
    this.leftPost = new Spine(state.options.resources.leftMultiplier.resource.spineData);
  }

  isWinSymbol(type) {
    return type.includes('win') || type.includes('wild') || type.includes('topPrize');
  }

  isRightDirectWin(details) {
    const { winRight, wins } = details.state;

    return wins?.direct && winRight.level === this.numberOfLevels && this.isWinSymbol(state.lastRound.result.type);
  }

  isLeftDirectWin(details) {
    const { winLeft, wins } = details.state;

    return wins?.direct && winLeft.level === this.numberOfLevels && this.isWinSymbol(state.lastRound.result.type);
  }

  runDirectWinAnimation(post) {
    state.content.runCoinsAnimation(post);
  }

  runRightPostAnimation(event) {
    this.rightPost.state.setAnimation(0, event, false);
    this.rightPost.state.addListener({
      complete: (entry) => { // call only one check since intro for both posts is done in same time
        if (entry.animation.name === 'introRight') {
          state.content.controls.enableSpin();
          this.checkPlates(this.options.state);
          state.content.controls.setInitialControlsState();
        }
      },
    });
  }

  runLeftPostAnimation(event) {
    this.leftPost.state.setAnimation(0, event, false);
  }

  runPlateAnimation(post, levelDetails) {
    const { level } = levelDetails;

    if (level && !this[`${post}Plates`][level - 1].isActive) {
      this[`run${upperFirst(post)}PostAnimation`](`${this[`${post}Plates`][level - 1].multiplier}win`);
    }

    forEach(this[`${post}Plates`], (plate, index) => {
      if ((index + 1) <= level && !plate.isActive) {
        plate.isActive = true; // eslint-disable-line no-param-reassign
      }
    });

    forEachRight(this[`${post}Plates`], (plate, index) => {
      if ((index + 1) > level && plate.isActive) {
        this[`run${upperFirst(post)}PostAnimation`](`${this[`${post}Plates`][index].multiplier}loss`);
        plate.isActive = false; // eslint-disable-line no-param-reassign
      }
    });
  }

  checkPlate(details, isCollect) {
    const { winRight, winLeft, wins } = details.state;
    const activeRightPostLevel = findLastIndex(this.rightPlates, (plate) => plate.isActive) + 1;
    const activeLeftPostLevel = findLastIndex(this.leftPlates, (plate) => plate.isActive) + 1;

    if (wins?.topPrize) {
      this.runDirectWinAnimation('right');
      this.runDirectWinAnimation('left');
      this.clearPlates('right');
      this.clearPlates('left');
      state.content.controls.runCoinAnimation('collect');
      state.content.controls.animateCashPotAmount(state.lastRound.state.cashPot.amount);

      return;
    }

    if (this.isRightDirectWin(details)) {
      this.runDirectWinAnimation('right');
    }

    if (this.isLeftDirectWin(details)) {
      this.runDirectWinAnimation('left');
    }

    if (activeRightPostLevel !== winRight.level && !wins.direct) {
      this.runPlateAnimation('right', winRight, isCollect);
    }

    if (activeLeftPostLevel !== winLeft.level && !wins.direct) {
      this.runPlateAnimation('left', winLeft, isCollect);
    }
  }

  checkPlates(details, isCollect = false) {
    this.checkPlate(details, isCollect);

    if (state.lastRound) {
      state.content.controls.updateCashpot(state.lastRound.state.cashPot);
      state.updateTopPrize(state.lastRound.state.topPrize);

      if (!isCollect && this.isWinSymbol(state.lastRound.result.type)) {
        state.background.runWinAnimations();
      }
    }
  }

  hide() {
    this.container.alpha = 0;
  }

  show() {
    this.container.alpha = 1;
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAspectRatio = state.originalSize.height / state.originalSize.width;

    if (aspectRatio < backgroundAspectRatio) {
      const scaleFactor = 0.75;

      this.container.scale.set(1, scaleFactor);
    }
  }

  setup() {
    this.initModels();
    this.hide();
    this.container.addChild(this.rightPost);
    this.container.addChild(this.leftPost);
  }
}
