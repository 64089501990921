import { assign, clone, isNil, merge } from 'lodash';
import { Parser as BaseParser } from '@/utility/Parser';

export class Parser extends BaseParser {
  constructor(params, defaults) {
    super(params, defaults);
    this.finalAssets = null;

    this.parseState();
  }

  parseState() {
    if (isNil(this.params.state)) {
      this.options = this.params;
      return this.options;
    }

    const options = clone(this.params);
    this.options = merge(this.defaults, options);

    this.finalAssets = {
      customFont1: options.resources.customFont1,
      customFont2: options.resources.customFont2,
      customFont3: options.resources.customFont3,
      customFont4: options.resources.customFont4,
      customFont5: options.resources.customFont5,
      icons: this.getResource(options, 'generalUI'),
      reloadModalBackground: options.resources.reloadModalBackground,
      reloadModalLogo: options.resources.reloadModalLogo,
      soundAmbient: options.resources.ambient,
      soundBonusAmbient: options.resources.bonusAmbient,
      soundCollect: options.resources.coin,
      soundOnboard: options.resources.onboard,
      soundSpin: options.resources.spin,
      soundSpinClick: options.resources.spinClick,
      soundSymbol0: options.resources.soundSymbol0,
      soundSymbol1: options.resources.soundSymbol1,
      soundSymbol2: options.resources.soundSymbol2,
      soundSymbol3: options.resources.soundSymbol2,
      soundSymbol4: options.resources.soundSymbol4,
      soundSymbol5: options.resources.soundSymbol5,
      soundSymbol6: options.resources.soundSymbol6,
      soundSymbol7: options.resources.soundSymbol7,
      soundTap: options.resources.click,
    };

    assign(this.finalAssets, {
      iconClose: this.getResource(options, 'iconClose', this.finalAssets, 'icons', true),
      iconHistory: this.getResource(options, 'iconHistory', this.finalAssets, 'icons', true),
      iconInfo: this.getResource(options, 'iconInfo', this.finalAssets, 'icons', true),
      iconOff: this.getResource(options, 'iconOff', this.finalAssets, 'icons', true),
      iconOn: this.getResource(options, 'iconOn', this.finalAssets, 'icons', true),
      iconPaytable: this.getResource(options, 'iconPaytable', this.finalAssets, 'icons', true),
      iconSettings: this.getResource(options, 'iconSettings', this.finalAssets, 'icons', true),
      staticSymbol0: this.getResource(options, 'staticSymbol0', this.finalAssets),
      staticSymbol1: this.getResource(options, 'staticSymbol1', this.finalAssets),
      staticSymbol2: this.getResource(options, 'staticSymbol2', this.finalAssets),
      staticSymbol3: this.getResource(options, 'staticSymbol3', this.finalAssets),
      staticSymbol4: this.getResource(options, 'staticSymbol4', this.finalAssets),
      staticSymbol5: this.getResource(options, 'staticSymbol5', this.finalAssets),
      staticSymbol6: this.getResource(options, 'staticSymbol6', this.finalAssets),
      staticSymbol7: this.getResource(options, 'staticSymbol7', this.finalAssets),
      uiLobby: this.getResource(options, 'uiLobby_0', this.finalAssets, 'icons', true),
      uiMenu: this.getResource(options, 'uiMenu_0', this.finalAssets, 'icons', true),
    });

    if (options.assets) {
      this.finalAssets = merge(this.finalAssets, options.assets);
    }

    this.options = merge(this.defaults, assign(options, {
      assets: this.finalAssets,
    }));

    return this.options;
  }
}
