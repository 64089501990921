import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container } from '@/pixi';
import { state } from './State';

export class Hook {
  #container;
  #hook;

  constructor(position) {
    this.#container = new Container();
    this.#container.name = 'Hook';

    this.#hook = new Spine(state.assets.hook.resource.spineData);
    this.#container.addChild(this.#hook);

    this.#container.position = position;
    this.#container.visible = false;
  }

  get #catchAnimationDuration() {
    return this.#hook.stateData.skeletonData.findAnimation('down').duration;
  }

  get #pullAnimationDuration() {
    return this.#hook.stateData.skeletonData.findAnimation('up').duration;
  }

  get #bone() {
    return this.#hook.skeleton.findBone('grabBone');
  }

  get container() {
    return this.#container;
  }

  get bonePosition() {
    return {
      x: this.#container.x + this.#bone.worldX,
      y: this.#container.y + this.#bone.worldY,
    };
  }

  #runCatchAnimation() {
    this.#hook.state.setAnimation(0, 'down');
  }

  #runPullAnimation() {
    this.#hook.state.setAnimation(0, 'up');
  }

  createCollectAnimationTimeline() {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        state.components.hooks.addHook(this.#container);
        this.#container.visible = true;
        this.#runCatchAnimation();
      },
      duration: this.#catchAnimationDuration,
    });

    timeline.to(this.#container, {
      onStart: () => {
        this.#runPullAnimation();
      },
      duration: this.#pullAnimationDuration,
      onComplete: () => {
        this.#container.destroy({ children: true });
      },
    });

    return timeline;
  }
}
