import { Container } from '@/pixi';
import { Reels } from './Reels';
import { Logo } from './Logo';
import { state } from './State';

export class ReelsWrapper {
  constructor() {
    this.container = new Container();

    this.container.name = 'ReelsWrapper';
    this.container.sortableChildren = true;

    this.reels = new Reels();
    this.logo = new Logo();

    this.container.addChild(
      this.reels.container,
      this.logo.container,
    );

    // Preserve initial values
    this.height = this.container.height;
    this.width = this.container.width;
    this.bottomY = this.container.getLocalBounds().bottom;
    this.topY = this.container.getLocalBounds().top;
  }

  setReelsLogoPosition() {
    if (state.isCashLinkBonus) {
      this.logo.logoCashLinkIdleAnimation();
      this.logo.container.zIndex = 1;
      this.reels.container.zIndex = 2;
    }

    if (state.isCashLinkBonusEnd) {
      this.logo.logoIdleAnimation();
      this.logo.container.zIndex = 2;
      this.reels.container.zIndex = 1;
    }
  }

  getBottomPosition() {
    return this.container.y + this.bottomY * this.container.scale.y;
  }

  setPositionY(value) {
    this.container.y = -this.topY * this.container.scale.y + value;
  }
}
