import numeral from 'numeral';
import { get } from 'lodash';
import { makeAutoObservable, reaction } from 'mobx';
import { getErrorParams } from '@/utility/Utility';
import { audio } from './Audio';

class State {
  constructor() {
    this.options = undefined;
    this.components = {};
    this.roundResponse = undefined;
    this.soundAmbient = undefined;
    this.errorDetails = undefined;

    this.betAmount = undefined;
    this.balanceFromSocket = undefined;
    this.isLightningSpinEnabled = false;
    this.isDialogOpen = false;
    this.autoplay = {
      enabled: false,
      numberOfSpins: 0,
      stopOnAnyWin: false,
      lossLimit: {
        enabled: false,
        amount: 0,
      },
      winLimit: {
        enabled: false,
        amount: 0,
      },
      currentBalance: 0,
    };
    this.bonusGame = {
      numberOfSpins: 0,
      totalNumberOfSpins: 0,
    };

    this.moneyFormat = '0,0.00';

    makeAutoObservable(this);
  }

  watch(path, callback) {
    reaction(() => get(this, path), callback);
  }

  get appScale() {
    return this.components.container.scale.y;
  }

  get appWidth() {
    return this.options.size().width / this.appScale;
  }

  get appHeight() {
    return this.options.size().height / this.appScale;
  }

  get assets() {
    return this.options.assets;
  }

  get player() {
    return this.options.player;
  }

  get balanceAmount() {
    return this.player.balance.amount;
  }

  get sufficientFunds() {
    return this.balanceAmount >= this.betAmount;
  }

  get isBonusGameIntroScreenOpen() {
    return this.components.bonusGameIntroScreen.container.visible;
  }

  create(options) {
    this.options = options;

    if (this.options.isDemo) {
      // Dummy player
      this.options.player = {
        currency: this.options.currency,
        balance: {
          amount: 1000,
        },
      };
    }

    if (options.bonusGameDataOnAppStart?.betAmount) {
      this.betAmount = options.bonusGameDataOnAppStart.betAmount;
    } else {
      [this.betAmount] = this.options.predefinedBetAmounts;
    }
  }

  updateOptions(options) {
    if (options) {
      Object.assign(this.options, options);
    }
  }

  getMoneyLabel(amount, showCurrency = false) {
    const value = numeral(amount).format(this.moneyFormat);

    return showCurrency ? `${value} ${this.player.currency}` : value;
  }

  getBalanceAmountMoneyLabel() {
    return this.getMoneyLabel(this.balanceAmount);
  }

  getBetAmountMoneyLabel() {
    return this.getMoneyLabel(this.betAmount);
  }

  setRoundResponse(value) {
    this.roundResponse = value;

    // Autoplay condition
    if (value?.winAmount && this.autoplay.enabled && (this.autoplay.lossLimit.enabled || this.autoplay.winLimit.enabled)) {
      const newBalance = this.autoplay.currentBalance + value.winAmount;
      this.autoplay.currentBalance = parseFloat(newBalance.toFixed(2));
    }
  }

  setBetAmount(value) {
    this.betAmount = value;

    // Enable controls after bet amount change, it's possible they were disabled
    this.components.content.controls.enableAfterSpin();
  }

  setBalanceAmount(value) {
    this.player.balance.amount = value;
  }

  setBalanceAmountAfterSpin() {
    if (this.balanceFromSocket) {
      this.setBalanceAmount(this.balanceFromSocket);
      this.balanceFromSocket = undefined;
    } else {
      this.setBalanceAmount(this.roundResponse.balance.amount);
    }
  }

  decreaseBetAmountFromBalance(storeBuyResponse) {
    if (storeBuyResponse) {
      this.setBalanceAmount(this.balanceAmount - storeBuyResponse.buyAmount);
    } else {
      this.setBalanceAmount(this.balanceAmount - this.betAmount);

      // Autoplay condition
      if (this.autoplay.enabled && (this.autoplay.lossLimit.enabled || this.autoplay.winLimit.enabled)) {
        const newBalance = this.autoplay.currentBalance - this.betAmount;
        this.autoplay.currentBalance = parseFloat(newBalance.toFixed(2));
      }
    }
  }

  setBalanceFromSocket(value) {
    this.balanceFromSocket = value;
  }

  setSoundAmbient() {
    this.soundAmbient = audio.play(this.assets.soundAmbient, { loop: true });
  }

  pauseSoundAmbient() {
    audio.pause(this.assets.soundAmbient, this.soundAmbient);
  }

  playSoundAmbient() {
    audio.play(this.assets.soundAmbient, { loop: true }, this.soundAmbient);
  }

  setSoundVolume(soundName, volume = 1) {
    this.assets[soundName].resource.volume(volume);
  }

  playTapSound() {
    audio.play(this.assets.soundTap);
  }

  playSound(soundName, loop = false) {
    audio.play(this.assets[soundName], { loop });
  }

  stopSound(soundName) {
    audio.stop(this.assets[soundName]);
  }

  disableInSpin() {
    this.components.header.disableInSpin();
    this.components.content.controls.disableInSpin();
    this.components.footer.disableInSpin();
  }

  enableAfterSpin() {
    this.components.header.enableAfterSpin();
    this.components.content.controls.enableAfterSpin();
    this.components.footer.enableAfterSpin();
  }

  setLightningSpinState(value) {
    this.isLightningSpinEnabled = value;
  }

  setDialogOpen(value) {
    this.isDialogOpen = value;
  }

  setAutoplaySettings(enabled, numberOfSpins, stopOnAnyWin, lossLimit, winLimit) {
    this.autoplay.enabled = enabled;

    if (numberOfSpins != null) this.autoplay.numberOfSpins = numberOfSpins;
    if (stopOnAnyWin != null) this.autoplay.stopOnAnyWin = stopOnAnyWin;
    if (lossLimit != null) this.autoplay.lossLimit = lossLimit;
    if (winLimit != null) this.autoplay.winLimit = winLimit;
  }

  startAutoplay(numberOfSpins, stopOnAnyWin, lossLimit, winLimit) {
    this.setAutoplaySettings(true, numberOfSpins, stopOnAnyWin, lossLimit, winLimit);
  }

  stopAutoplay() {
    this.setAutoplaySettings(false, 0, false, { enabled: false }, { enabled: false });
    this.autoplay.currentBalance = 0;
  }

  decreaseAutoplayNumberOfSpins() {
    if (!this.autoplay.enabled) return;

    this.autoplay.numberOfSpins -= 1;

    if (this.autoplay.numberOfSpins === 0) {
      this.stopAutoplay();
    }
  }

  checkAutoplayConditions() {
    if (!this.autoplay.enabled) return;

    // If any condition is met then stop autoplay
    if ((this.autoplay.stopOnAnyWin && this.roundResponse.winAmount)
      || (this.autoplay.lossLimit.enabled && this.autoplay.currentBalance <= this.autoplay.lossLimit.amount * -1)
      || (this.autoplay.winLimit.enabled && this.autoplay.currentBalance >= this.autoplay.winLimit.amount)) {
      this.stopAutoplay();
    }
  }

  startBonusGame() {
    // Bonus is won in current spin : Bonus game is active on application start
    const bonusGameNumberOfSpins = this.roundResponse ? this.roundResponse.bonus.availableRounds : this.options.bonusGameDataOnAppStart.availableRounds;
    const bonusWinAmount = this.options.bonusGameDataOnAppStart?.bonusWinAmount || 0;

    this.components.content.reelsWithLogo.logo.hide();
    this.components.background.changeBackground(true);
    this.components.content.reelsWithLogo.reels.onBonusGameStart();
    this.components.rootComponent.resize();

    this.components.footer.showWinAmount(this.getMoneyLabel(bonusWinAmount));
    this.addBonusGameNumberOfSpins(bonusGameNumberOfSpins);
    this.components.content.controls.spin();
  }

  addBonusGameNumberOfSpins(numOfSpins) {
    this.bonusGame.numberOfSpins += numOfSpins;
    this.bonusGame.totalNumberOfSpins += numOfSpins;
  }

  decreaseBonusGameNumberOfSpins() {
    if (this.bonusGame.numberOfSpins === 0) return;

    this.bonusGame.numberOfSpins -= 1;
  }

  async endBonusGame() {
    this.options.bonusGameDataOnAppStart = undefined;
    this.bonusGame.totalNumberOfSpins = 0;

    this.components.content.reelsWithLogo.logo.show();
    this.components.background.changeBackground(false);
    this.components.content.reelsWithLogo.reels.onBonusGameEnd();
    this.components.rootComponent.resize();
    this.components.footer.hideWinAmount();

    const { totalWinAmount } = this.roundResponse.bonusWinnings;
    if (totalWinAmount > 0) {
      await this.components.winGrading.show(totalWinAmount);
      this.components.footer.showWinAmount(this.getMoneyLabel(totalWinAmount));
    }

    this.setBalanceAmountAfterSpin();

    this.enableAfterSpin();
  }

  setErrorDetails(details) {
    if (details) {
      this.errorDetails = getErrorParams(details, this.options.translations);

      // Hide/disable boost
      if (this.components.header.uiBoost) {
        if (this.errorDetails.isErrorReloadable) {
          this.components.header.uiBoost.hide();
        } else {
          this.components.header.uiBoost.disable();
        }
      }

      if (this.errorDetails.isRciError) return;

      this.components.rootComponent.openNotification(this.errorDetails.notification);
    } else {
      this.errorDetails = undefined;
    }
  }
}

export const state = new State();
