import { clone, merge } from 'lodash';
import { Parser as BaseParser } from '@/utility/Parser';

export class Parser extends BaseParser {
  constructor(params, defaults) {
    super(params, defaults);

    this.finalAssets = null;
    this.paramsClone = undefined;

    this.parseState();
  }

  getResourceRef(name, fromSpritesheet, disableFallback) {
    return this.getResource(this.paramsClone, name, this.finalAssets, fromSpritesheet, disableFallback);
  }

  getResourceAnimationRef(name) {
    return this.getResourceAnimation(this.finalAssets, name);
  }

  parseState() {
    this.paramsClone = clone(this.params);

    const { resources } = this.paramsClone;

    const symbols = [1, 2, 3, 4, 5, 6, 7, 10, 12, 13, 16, 23, 24, 25, 26, 'Sticky'];
    const symbolsResources = {};

    for (let i = 0; i < symbols.length; i++) {
      symbolsResources[`symbol${symbols[i]}`] = resources[`symbol${symbols[i]}`];
    }

    this.finalAssets = {
      icons: this.getResourceRef('generalUI'),
      bonusBuy: [resources.bonusBuyOne, resources.bonusBuyTwo, resources.bonusBuyThree],
      bonusBuyBackground: resources.bonusBuyBackground,
      reloadModalBackground: resources.reloadModalBackground,
      reloadModalLogo: resources.reloadModalLogo,
      // Spines
      autoplayButton: resources.autoplayButton,
      background: resources.background,
      backgroundBonusOutro: resources.backgroundBonusOutro,
      backgroundFreeSpins: resources.backgroundFreeSpins,
      backgroundOnBoard: resources.backgroundOnBoard,
      backgroundStickyWildIntro: resources.backgroundStickyWildIntro,
      backgroundWinGrading: resources.backgroundWinGrading,
      bonusOutro: resources.bonusOutro,
      bonusWait: resources.bonusWait,
      buyButton: resources.buyButton,
      cashLinkIntro: resources.cashLinkIntro,
      cashLinkx2: resources.cashLinkOverlay,
      coinBurst: resources.coinBurst,
      footer: resources.footer,
      logo: resources.logo,
      onboard: resources.onBoard,
      reelsBackground: resources.reels,
      soundButton: resources.soundButton,
      spinButton: resources.spinButton,
      stickyWildIntro: resources.stickyWildIntro,
      symbolWait: resources.symbolWait,
      winGrading: resources.winGrading,
      sounds: {
        ambientBase: resources.ambientBase,
        ambientBonus: resources.ambientBonus,
        bonusIntroOutroStart: resources.bonusIntroOutroStart,
        bonusSymbolAppear: resources.bonusSymbolAppear,
        bonusWaitSound: resources.bonusWaitSound,
        cashCollect: resources.cashCollect,
        cashlinkCherryx2: resources.cashlinkCherryx2,
        cashlinkCovered: resources.cashlinkCovered,
        cashlinkEnd: resources.cashlinkEnd,
        cashLinkIntroStart: resources.cashLinkIntroStart,
        cashLinkLand: resources.cashLinkLand,
        introGame: resources.introGame,
        slotBonusGameWin: resources.slotBonusGameWin,
        slotClick: resources.slotClick,
        slotSpinStart: resources.slotSpinStart,
        slotWinline: resources.slotWinline,
        stickyWild: resources.stickyWild,
        winGradingLoop: resources.winGradingLoop,
        winGradingTitleHit: resources.winGradingTitleHit,
        x2Hit: resources.x2Hit,
      },
      ...symbolsResources,
      // Fonts
      customFontNumbers: resources.customFontNumbers,
      cherryGold: resources.cherryGold,
      cherryWhite: resources.cherryWhite,
    };

    Object.assign(this.finalAssets, {
      uiLobby: this.getResourceRef('uiLobby_0', 'icons', true),
      uiMenu: this.getResourceRef('uiMenu_0', 'icons', true),
      iconClose: this.getResourceRef('iconClose', 'icons', true),
      iconOff: this.getResourceRef('iconOff', 'icons', true),
      iconOn: this.getResourceRef('iconOn', 'icons', true),
      iconSettings: this.getResourceRef('iconSettings', 'icons', true),
      iconInfo: this.getResourceRef('iconInfo', 'icons', true),
      iconHistory: this.getResourceRef('iconHistory', 'icons', true),
      uiOnboardSound: this.getResourceAnimationRef('uiOnboardSound'),
    });

    delete this.paramsClone.resources;

    this.options = merge(this.defaults, Object.assign(this.paramsClone, {
      assets: this.finalAssets,
    }));

    return this.options;
  }
}
