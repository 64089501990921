import { Slot } from './Slot';
import { Wheel } from './Wheel';
import { FishyGuy } from './FishyGuy';
import { GoldOfEgypt } from './GoldOfEgypt';
// import { TolucaFortune } from './TolucaFortune';
import { CherryBlast } from './CherryBlast';

export function Game(config, gameName) {
  switch (gameName) {
    case 'TestSlot5':
    case 'FishyGuy': {
      return new FishyGuy(config);
    }
    case 'GoldOfEgypt': {
      return new GoldOfEgypt(config);
    }
    // case 'TolucaFortune': {
    //   return new TolucaFortune(config);
    // }
    case 'CherryBlast': {
      return new CherryBlast(config);
    }
    default: {
      return new Slot(config);
    }
  }
}

export { Slot, Wheel, FishyGuy, GoldOfEgypt, CherryBlast };
