import { Container, Rectangle, Circle, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate, { Sine } from 'gsap';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { Label } from './Label';
import { state } from './State';
import { gameUtils } from './GameUtils';
import { audio } from './Audio';

export class Controls {
  constructor() {
    this.container = new Container();
    this.options = state.options;
    this.buttonOffset = 10;

    this.initModels();
    this.updateCollectComponents();
    this.createControls();
    this.setListeners();
    this.setWatchers();

    this.container.name = 'Controls';
    this.container.visible = false;
  }

  get isSpinDisabled() {
    return this.spinButton.eventMode === 'none';
  }

  get isSpaceBarSpinEnabled() {
    return !this.coins.visible
      && !this.isSpinDisabled
      && !state.autoplay.enabled
      && !state.bonusGame.enabled
      && !state.isDialogOpen
      && !state.errorDetails
      && state.background.isIntroAnimationFinished;
  }

  animateCashPotAmount(amountTo) {
    const counter = { value: state.cashPot.amount };
    animate.to(counter, {
      value: amountTo,
      duration: 1.5,
      onUpdate: () => {
        this.setCollectAmount(counter.value);
      },
      onStart: () => {
        this.disableCollect();
        this.disableInSpin();
      },
      onComplete: () => {
        state.updateCashpot({ amount: amountTo });
        this.enableAutoplayButton();
      },
      ease: Sine.easeIn,
    });
  }

  initModels() {
    this.spinButton = new Spine(state.options.resources.spin.resource.spineData);
    this.spinButtonOverlay = new Spine(state.options.resources.spin.resource.spineData);
    this.buyButton = new Spine(state.options.resources.buy.resource.spineData);
    this.autoplayButton = new Spine(state.options.resources.autoplay.resource.spineData);
    this.uiBackground = new Spine(state.options.resources.controlsBackground.resource.spineData);
    this.uiCollect = new Spine(state.options.resources.collect.resource.spineData);
    this.coins = new Spine(state.options.resources.coins.resource.spineData);
  }

  createControls() {
    const margin = state.options.uiPadding * 2;
    const collectButton = this.uiCollect.children[3];

    this.container.addChild(this.uiBackground);

    this.coins.y = this.uiCollect.y - this.uiCollect.height - this.options.uiPadding * 6;
    this.coins.x -= this.options.uiPadding / 3;

    this.container.addChild(this.spinButton);
    this.container.addChild(this.spinButtonOverlay);

    this.spinCounter = new BitmapText(0, {
      fontName: state.options.customFont3,
      fontSize: 150,
    });
    this.spinCounter.anchor.set(0.5);
    this.spinCounter.x = state.options.uiPadding;
    this.spinCounter.y = state.options.uiPadding / 2;
    this.spinCounter.visible = false;
    this.container.addChild(this.spinCounter);

    this.uiCollect.y = -(this.spinButton.height / 2 + this.options.uiPadding);
    this.uiCollect.eventMode = 'static';
    this.uiCollect.cursor = 'pointer';
    this.uiCollect.hitArea = new Rectangle(
      collectButton.x - collectButton.width / 2,
      collectButton.y + this.buttonOffset,
      collectButton.width,
      collectButton.height - this.buttonOffset,
    );
    this.container.addChild(this.uiCollect);

    this.buyButton.x = this.spinButton.x - this.spinButton.width / 2 - margin - this.buyButton.width / 2 - this.buttonOffset - this.buttonOffset / 2;
    this.buyButton.y = -(this.spinButton.y + margin) + this.buttonOffset;
    this.buyButton.hitArea = new Circle(0, 0, this.buyButton.width / 2 - this.buttonOffset);
    this.buyButton.eventMode = 'static';
    this.buyButton.cursor = 'pointer';
    this.container.addChild(this.buyButton);

    this.autoplayButton.x = this.spinButton.x + this.spinButton.width / 2 + margin + this.autoplayButton.width / 2 + this.buttonOffset + this.buttonOffset / 2;
    this.autoplayButton.y = -(this.spinButton.y + margin) + this.buttonOffset;
    this.autoplayButton.hitArea = new Circle(0, 0, this.autoplayButton.width / 2 - this.buttonOffset);
    this.autoplayButton.eventMode = 'static';
    this.autoplayButton.cursor = 'pointer';
    this.container.addChild(this.autoplayButton);

    this.demoLabel = new Label({ text: state.options.translations.demoLabel });
    this.demoLabel.container.x = this.spinButton.x - this.demoLabel.container.width / 2;
    this.demoLabel.container.y = this.spinButton.y + this.spinButton.height / 2 - this.demoLabel.container.height * 2;
    this.demoLabel.setVisible(state.options.isDemo);
    this.container.addChild(this.demoLabel.container);

    this.spinButtonOverlay.eventMode = 'static';
    this.spinButtonOverlay.cursor = 'pointer';
    this.spinButtonOverlay.alpha = 0.5;
    this.spinButtonOverlay.hitArea = new Circle(0, 0, this.spinButton.width / 2 - this.buttonOffset * 2);

    this.container.addChild(this.coins);
    this.coins.visible = false;
  }

  async collect() {
    const requestPayload = [
      state.options.tenantGameId,
      state.options.playerToken,
      {},
      {},
    ];

    const response = await gameUtils.makeApiRequest('cashout', requestPayload, false);

    if (response.isError) {
      state.setErrorDetails(response);
    } else {
      const balance = { amount: state.player.balance.amount + response.winAmount };
      state.posts.checkPlates(response, true);
      this.animateCashPotAmount(response.state.cashPot.amount);
      state.footer.animateBalanceAmount(balance.amount);
      this.runCoinAnimation('collect');
    }
  }

  checkBalanceAfterSpinAction(response) {
    const { direct, topPrize } = response.state.wins;
    // when direct win or topPrize is active wait for coin animation to update balance
    if (direct || topPrize) {
      this.updateBalance({
        amount: state.balanceAmount - state.betAmount,
      });
    } else {
      state.setBalanceAmountAfterSpin();
    }
  }

  checkAutoplayAfterSpinAction() {
    if (state.sufficientFunds) {
      state.decreaseAutoplayNumberOfSpins();
    } else {
      state.stopAutoplay();
    }
  }

  checkBonusAfterSpinAction() {
    if (state.bonusGame.numberOfSpins > 0) {
      state.decreaseBonusGameNumberOfSpins();
    }
  }

  async spin() {
    if (state.reel.isSpinning) return;
    state.disableInSpin();
    this.runSpinOverlayAnimation('motion');

    const requestPayload = [
      state.options.tenantGameId,
      state.options.playerToken,
      { betAmount: state.betAmount },
      {},
    ];

    const response = await gameUtils.makeApiRequest('createRound', requestPayload, true);
    const isBonusGame = response.isFree;

    if (response.isError) {
      state.setErrorDetails(response);
      state.stopAutoplay();
    } else {
      state.setLastRound(response);
      state.reel.spin(response);

      if (!isBonusGame) {
        this.checkBalanceAfterSpinAction(response);
      }

      if (state.autoplay.enabled) {
        this.checkAutoplayAfterSpinAction();
      } else if (isBonusGame) {
        this.checkBonusAfterSpinAction();
      }
    }
  }

  updateCashpot(cashpot) {
    state.updateCashpot(cashpot);
    this.setCollectAmount(cashpot.amount);
  }

  updateBalance(balance) {
    state.updateBalance(balance);
  }

  enableBuy() {
    this.runBuyButtonAnimation('active');
    this.buyButton.eventMode = 'static';
  }

  disableBuy() {
    this.runBuyButtonAnimation('inactive');
    this.buyButton.eventMode = 'none';
  }

  enableAutoplayButton() {
    this.autoplayButton.eventMode = 'static';
    this.autoplayButton.alpha = 1;
  }

  disableAutoplayButton() {
    this.autoplayButton.eventMode = 'none';
    this.runAutoplayButtonAnimation('inactive');
  }

  enableCollect() {
    this.uiCollect.eventMode = 'static';
    this.runCollectAnimation('active');
  }

  disableCollect(isAnimated = false) {
    this.uiCollect.eventMode = 'none';
    if (state.cashPot.amount > 0 || isAnimated) {
      if (!state.bonusGame.enabled) {
        this.runCollectAnimation('inactive');
      }
    }
  }

  enableSpin() {
    this.spinButton.eventMode = 'static';
    this.spinButtonOverlay.eventMode = 'static';

    this.spinButton.alpha = 1;
    this.spinButtonOverlay.alpha = 1;
  }

  disableSpin() {
    this.spinButton.eventMode = 'none';
    this.spinButtonOverlay.eventMode = 'none';
  }

  disableInSpin() {
    this.disableSpin();
    this.disableBuy();
    this.disableCollect();
    state.footer.disableBetAmountClick();

    if (!state.autoplay.enabled) {
      this.disableAutoplayButton();
    }
  }

  enableAfterSpin() {
    if (state.sufficientFunds && !state.bonusGame.enabled) {
      this.enableAutoplayButton();
    }

    if (state.sufficientFunds) {
      this.enableSpin();
    } else {
      this.disableSpin();
      this.disableAutoplayButton();
    }

    if (!state.autoplay.enabled && !state.bonusGame.enabled) {
      if (state.sufficientFunds) {
        this.runAutoplayButtonAnimation('active');
      }

      if (!state.cashPot.amount) {
        this.enableBuy();
      }
    }

    if (!state.bonusGame.enabled && !state.autoplay.enabled) {
      if (state.cashPot.amount > 0) {
        this.enableCollect();
      } else {
        state.footer.enableBetAmountClick();
      }
    }
  }

  runSpinButtonAnimation(event) {
    this.spinButton.state.setAnimation(0, event, false);
    this.spinButton.state.addListener({
      complete: (entry) => {
        if (entry.animation.name === 'spinIntro') {
          this.spinButton.state.setAnimation(0, 'idle', true);
        }
      },
    });
  }

  runSpinOverlayAnimation(event) {
    this.spinButtonOverlay.state.setAnimation(0, event, false);
  }

  runControlBackgrounAnimation(event) {
    this.uiBackground.state.setAnimation(0, event, false);
  }

  runAutoplayButtonAnimation(event) {
    this.autoplayButton.state.setAnimation(0, event, false);
  }

  runBuyButtonAnimation(event) {
    this.buyButton.state.setAnimation(0, event, false);
  }

  runCollectAnimation(event) {
    this.uiCollect.state.setAnimation(0, event, false);
  }

  runCoinAnimation(event) {
    this.coins.visible = true;
    this.coins.state.setAnimation(0, event, false);
    audio.play(this.options.assets.soundCollect);

    this.coins.state.addListener({
      start: () => {
        audio.play(this.options.assets.soundCollect);
      },
      complete: (entry) => {
        if (entry.animation.name === 'collect') {
          this.coins.visible = false;
        }
      },
    });
  }

  updateCollectComponents() {
    const { amount } = this.options.state.state.cashPot;

    this.setCollectAmount(amount);
    this.setCollectLabel();
    this.setCollectCurrency();
  }

  setCollectAmount(amount) {
    const cashpotAmount = this.uiCollect.children[1].children[0];

    const cashpot = new BitmapText(amount.toFixed(2), {
      fontName: state.options.customFont2,
      fontSize: 60,
    });
    cashpot.anchor.set(0.5);
    cashpot.scale.y = cashpotAmount.scale.y;
    cashpot.y += state.options.uiPadding / 2;
    cashpot.x += state.options.uiPadding / 2;

    cashpotAmount.destroy({ children: true });
    this.uiCollect.children[1].addChild(cashpot);
  }

  setCollectCurrency() {
    const currencyLabel = this.uiCollect.children[2].children[0];

    if (this.options.currencyDisplayEnabled) {
      const currency = new BitmapText(this.options.currency, {
        fontName: state.options.customFont2,
        fontSize: 20,
      });
      currency.anchor.set(0.5);
      currency.scale.y = currencyLabel.scale.y;
      currency.y += state.options.uiPadding / 3;

      this.uiCollect.children[2].addChild(currency);
    }
    currencyLabel.destroy({ children: true });
  }

  setCollectLabel() {
    const collectLabel = this.uiCollect.children[6].children[0];
    const collectTranslation = this.options.translations.collect;

    const label = new BitmapText(collectTranslation.toUpperCase(), {
      fontName: state.options.customFont1,
      fontSize: gameUtils.getFontSize({
        textLength: collectTranslation.length,
        baseSize: 32,
        minSize: 12,
        maxLength: 25,
      }),
    });
    label.anchor.set(0.5);
    label.scale.y = collectLabel.scale.y;
    label.y += this.options.uiPadding / 4;
    label.x -= this.options.uiPadding / 2;

    collectLabel.destroy({ children: true });
    this.uiCollect.children[6].addChild(label);
  }

  setCounterState(numberOfSpins) {
    if (numberOfSpins) {
      this.spinCounter.text = numberOfSpins;
      this.spinCounter.visible = true;
    } else {
      this.spinCounter.visible = false;
    }
  }

  setListeners() {
    this.spinButtonOverlay.on('pointertap', () => {
      audio.play(this.options.assets.soundSpinClick);
      triggerAnalyticsEvent({ name: 'SpinButtonClick' });
      if (!state.autoplay.enabled && !state.bonusGame.enabled) {
        this.spin();
      }
    });

    document.body.onkeyup = (event) => {
      if (event.code === 'Space' && this.isSpaceBarSpinEnabled) {
        triggerAnalyticsEvent({ name: 'SpinButtonClick' });
        this.spin();
      }
    };

    this.buyButton.on('pointertap', () => {
      this.disableBuy();
      state.playTapSound();
      state.bonusGameIntroScreen.show();
      triggerAnalyticsEvent({ name: 'BuyButtonClick' });
    });

    this.autoplayButton.on('pointertap', () => {
      state.playTapSound();
      if (state.autoplay.enabled) {
        state.stopAutoplay();
        if (state.lastRound.state.cashPot.amount) {
          this.enableCollect();
          this.disableBuy();
        }
        triggerAnalyticsEvent({ name: 'AutoplayStopButtonClick' });
      } else {
        state.dialogAutoplay.show();
        triggerAnalyticsEvent({ name: 'AutoplayButtonClick' });
      }
    });

    this.uiCollect.on('pointertap', () => {
      this.disableCollect();
      this.disableSpin();
      this.disableAutoplayButton();
      this.collect();
      triggerAnalyticsEvent({ name: 'CollectButtonClick' });
    });
  }

  setWatchers() {
    state.watch('topPrize.amount', (amount) => {
      if (amount) {
        state.background.topPrize.updateTopPrizeAmount(amount);
      }
    });

    state.watch('cashPot.amount', (amount) => {
      if (amount > 0 && !state.bonusGame.enabled && !state.autoplay.enabled) {
        this.enableCollect();
        this.disableBuy();
        state.footer.disableBetAmountClick();
        state.footer.showBetUiOverlay();
      } else if (amount === 0) {
        this.enableBuy();
        this.disableCollect();
        state.footer.enableBetAmountClick();
        state.footer.hideBetUiOverlay();

        if (!state.autoplay.enabled) {
          this.runCollectAnimation('inactive');
        }
      }
    });

    state.watch('autoplay.enabled', (enabled) => {
      if (enabled) {
        this.runAutoplayButtonAnimation('stop');
        this.disableCollect();
        state.footer.disableBetAmountClick();
        state.header.disableInSpin();
      } else {
        this.runAutoplayButtonAnimation('active');
        state.header.enableAfterSpin();

        if (state.cashPot.amount === 0) {
          state.footer.enableBetAmountClick();
        }
      }
    });

    state.watch('autoplay.numberOfSpins', (numberOfSpins) => {
      if (numberOfSpins > 0) {
        this.setCounterState(numberOfSpins);
      } else {
        this.setCounterState(0);
        this.enableBuy();
      }
    });

    state.watch('lastRound.availableFreeRounds', (numberOfSpins) => {
      if (numberOfSpins > 0) {
        this.setCounterState(numberOfSpins);
      } else {
        this.setCounterState(0);
      }
    });
  }

  setInitialControlsState() {
    this.container.visible = true;
    this.runControlBackgrounAnimation('intro');
    this.runSpinButtonAnimation('intro', false);
    this.runSpinOverlayAnimation('intro');
    this.runAutoplayButtonAnimation('intro');
    this.runBuyButtonAnimation('intro');

    if (state.cashPot.amount) {
      this.enableCollect();
      this.disableBuy();
      state.footer.disableBetAmountClick();
      state.footer.showBetUiOverlay();
    } else {
      this.disableCollect(true);
    }

    if (!state.sufficientFunds) {
      this.disableSpin();
      this.disableAutoplayButton();
    }

    if (state.options.state.availableFreeRounds) {
      this.disableCollect();
      this.disableBuy();
      this.disableAutoplayButton();
      this.disableSpin();

      // add delay for dialog display
      setTimeout(() => {
        state.bonusGameIntroScreen.show(true);
      }, 500);
    }

    if (!state.options.state.state.buy.isResolved) {
      this.disableCollect();
      this.disableBuy();
      this.disableAutoplayButton();
      this.disableSpin();

      // add delay for dialog display
      setTimeout(() => {
        state.bonusGameOutroScreen.show();
      }, 500);
    }
  }
}
