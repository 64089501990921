import { BitmapText, Container } from '@/pixi';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { Checkbox } from './Checkbox';
import { state } from './State';
import { audio } from './Audio';

export class DialogMenuSettings {
  constructor() {
    this.options = state.options;
    this.container = new Container();
    this.container.name = 'DialogMenuSettings';
    this.labelStyle = { fontName: this.options.fontNameNormal, fontSize: 36, tint: this.options.colors.text };

    this.setup();
  }

  setup() {
    this.soundLabel = new BitmapText(this.options.translations.sound, this.labelStyle);
    this.soundCheckbox = new Checkbox(
      {
        onChange: () => {
          audio.toggleMute();
          if (!state.soundAmbient) {
            state.setSoundAmbient();
          }
          triggerAnalyticsEvent({ name: state.soundAmbient ? 'SoundButtonOnClick' : 'SoundButtonOffClick' });
        },
      },
    );

    this.container.addChild(this.soundLabel, this.soundCheckbox.container);
  }

  setPosition({ width }) {
    this.soundCheckbox.container.x = width - this.soundCheckbox.container.width - this.options.uiDialogPadding;
    this.soundCheckbox.container.y = this.options.uiDialogPadding;
    this.soundLabel.x = this.options.uiDialogPadding;
    this.soundLabel.y = this.soundCheckbox.container.y + ((this.soundCheckbox.container.height / 2) - (this.soundLabel.height / 2));
  }

  setData() {
    this.soundCheckbox.set(!audio.isSoundOff);
  }
}
