import { Table } from '@/components/shared/Table';
import { state } from './State';

export class RoundsHistoryTable extends Table {
  constructor(
    tableParams,
  ) {
    const { historyTimeLabel, historyCurrencyLabel, historyBetLabel, historyWinLabel, potAmountLabel } = state.options.translations;

    super({
      headerItems: [historyTimeLabel, historyCurrencyLabel, historyBetLabel, historyWinLabel, potAmountLabel],
      width: state.options.calcWidth,
      textColor: state.options.colors.text,
      tableFontName: state.options.fontNameLight,
      summaryTableFontName: state.options.fontNameBold,
      playTapSound: () => { state.playTapSound(); },
      ...tableParams,
    });

    this.historySummaryLabel = state.options.translations.historySummaryLabel;
    this.currency = state.options.currency;
    this.currencyDisplayEnabled = state.options.currencyDisplayEnabled;
    this.getMoneyLabel = (...data) => state.getMoneyLabel(...data);
  }

  formatDate(isoDate) {
    const dateObject = new Date(isoDate);
    const time = dateObject.toTimeString().slice(0, 8);
    const date = dateObject.toLocaleDateString('en-GB', { month: '2-digit', day: '2-digit' });

    return `${time} ${date}`;
  }

  addRoundsRows(rounds) {
    const rows = rounds.map((round) => ({
      columns: [
        this.formatDate(round.createdAt),
        this.currency,
        this.getMoneyLabel(round.betAmount),
        this.getMoneyLabel(round.totalWinAmount),
        this.getMoneyLabel(round.possibleCashout),
      ],
      round,
    }));

    this.addRows(rows);
  }

  addRoundsSummary({ betAmount, totalWinAmount }) {
    const summaryItems = [
      this.historySummaryLabel,
      '',
      this.getMoneyLabel(betAmount, this.currencyDisplayEnabled),
      this.getMoneyLabel(totalWinAmount, this.currencyDisplayEnabled),
      '',
    ];

    this.addSummary(summaryItems);
  }
}
