import { DialogContentBetAmount } from '@/components/shared/DialogContentBetAmount';
import { Dialog } from './Dialog';
import { gameUtils } from '../GameUtils';
import { state } from '../State';

export class DialogBetAmount extends Dialog {
  constructor() {
    const content = new DialogContentBetAmount({
      betAmounts: state.options.settings.predefinedBetAmounts,
      getActiveBetAmount: () => state.betAmount,
      getBalanceAmount: () => state.balanceAmount,
      onChange: (value) => {
        state.setBetAmount(value);
      },
      formatBetAmount: (value) => gameUtils.getMoneyLabel(value),
      playTapSound: () => {
        state.playTapSound();
      },
      betAmountColorActive: state.options.colors.theme,
    });

    super({
      title: state.options.translations.dialogBetAmountTitle,
      content,
      isFluid: true,
    });
  }
}
