import { DialogContentMenuHistory as BaseDialogContentMenuHistory } from '@/components/shared/DialogContentMenuHistory';
import { RoundsHistoryTable } from './RoundsHistoryTable';
import { RoundPreviewModal } from './RoundPreviewModal';
import { state } from '../State';

export class DialogContentMenuHistory extends BaseDialogContentMenuHistory {
  constructor() {
    super({
      RoundsTableComponent: RoundsHistoryTable,
      RoundModalComponent: RoundPreviewModal,
      rootContainer: state.components.container,
      isDemo: state.options.isDemo,
      tenantGameId: state.options.tenantGameId,
      playerToken: state.options.playerToken,
      translations: state.options.translations,
      width: state.options.calcWidth,
      uiDialogPadding: state.options.uiDialogPadding,
      textColor: state.options.colors.text,
      fontNameNormal: state.options.fontNameNormal,
      fontNameBold: state.options.fontNameBold,
      highlightColor: state.options.colors.theme,
      loaderColor: state.options.colors.theme,
      getAppWidth: () => state.appWidth,
      getAppHeight: () => state.appHeight,
      getDialogMenuFullHeadHeight: () => state.components.dialogMenu.getFullHeadHeight(),
      playTapSound: () => { state.playTapSound(); },
    });
  }
}
