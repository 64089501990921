import { Container, Rectangle, Sprite, Texture, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { state } from './State';
import { gameUtils } from './GameUtils';
import { triggerEvent, triggerAnalyticsEvent } from '../../utility/Utility';

export class BonusGameOutroScreen {
  constructor() {
    this.container = new Container();
    this.options = state.options;
    this.container.name = 'BonusGameOutroScreen';
    this.container.eventMode = 'static';
    this.mainWonAmount = undefined;
    this.collectButton = undefined;
    this.setup();
  }

  runBackgroundAnimation() {
    animate.to(this.background, {
      pixi: {
        alpha: 1,
      },
      duration: 0.5,
      onComplete: () => {
        state.background.runBackgroundAnimation('baseGame');
        state.background.showLogo();
        state.endBonusGame();
        this.clearItems();
        this.setContainerVisibilityState(false);
        triggerEvent('ShowBoostBadge');

        if (state.cashPot.amount) {
          state.content.controls.disableSpin();
          state.content.controls.disableCollect();
          state.content.controls.collect();
        }
      },
    });
  }

  initDialogModel() {
    this.bonusDialog = new Spine(state.options.resources.bonusBuy.resource.spineData);
  }

  setBackground() {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.alpha = 0.75;
    this.background.eventMode = 'static';
  }

  setup() {
    this.initDialogModel();
    this.setBackground();
    this.container.addChild(this.background);
    this.container.addChild(this.bonusDialog);
    this.setContainerVisibilityState(false);
    this.setIntroAnimations();
  }

  setContainerVisibilityState(visible) {
    this.container.visible = visible;
    this.container.children.forEach((c) => { c.visible = visible; }); // eslint-disable-line no-param-reassign
  }

  setIntroAnimations() {
    this.bonusDialog.state.setAnimation(0, 'idle', true);
  }

  setPosition() {
    const { width, height } = this.options.size();
    const scale = this.container.parent.scale.y;
    const backgroundWidth = width / scale;
    const backgroundHeight = height / scale;
    const aspectRatio = height / width;

    this.background.width = state.appWidth;
    this.background.height = state.appHeight;
    this.background.anchor.set(0.5);
    this.background.x = state.appWidth - (state.appWidth / 2);
    this.background.y = state.appHeight - (state.appHeight / 2);

    this.bonusDialog.scale.set(aspectRatio * 0.55);
    this.bonusDialog.x = backgroundWidth - (backgroundWidth / 2);
    this.bonusDialog.y = backgroundHeight - (backgroundHeight / 2) - this.options.uiPadding * 5;
  }

  setMainWonAmount() {
    const { totalBonusWinAmount } = state.lastRound ? state.lastRound.state : state.options.state.state;
    this.mainWonAmount = new BitmapText(totalBonusWinAmount.toFixed(2), {
      fontName: state.options.customFont4,
      fontSize: 220,
    });

    this.mainWonAmount.anchor.set(0.5);
    this.mainWonAmount.x += state.options.uiPadding * 2;
    this.mainWonAmount.y -= state.options.uiPadding * 8;

    this.bonusDialog.addChild(this.mainWonAmount);
  }

  setMainCurrencyLabel() {
    const mainCurrency = new BitmapText(this.options.currency, {
      fontName: state.options.customFont5,
      fontSize: 80,
    });
    mainCurrency.anchor.set(0.5);
    mainCurrency.x = this.mainWonAmount.x - state.options.uiPadding;
    mainCurrency.y -= state.options.uiPadding * 2;

    this.bonusDialog.addChild(mainCurrency);
  }

  setWonDescription() {
    const wonLabel = new BitmapText(this.options.translations.youWon.toUpperCase(), {
      fontName: state.options.customFont5,
      fontSize: 80,
    });
    wonLabel.anchor.set(0.5);
    wonLabel.x = this.mainWonAmount.x - state.options.uiPadding;
    wonLabel.y = this.mainWonAmount.y - this.mainWonAmount.height + state.options.uiPadding * 2;

    this.bonusDialog.addChild(wonLabel);
  }

  setFooterWonAmount() {
    const { totalBonusWinAmount } = state.lastRound ? state.lastRound.state : state.options.state.state;
    this.footerWonAmount = new BitmapText(totalBonusWinAmount.toFixed(2), {
      fontName: state.options.customFont2,
      fontSize: 50,
    });
    this.footerWonAmount.anchor.set(0.5);
    this.footerWonAmount.x += state.options.uiPadding / 4;
    this.footerWonAmount.y -= state.options.uiPadding / 4;

    this.bonusDialog.children[2].children[0].addChild(this.footerWonAmount);
  }

  setFooterCurrency() {
    const currencyLabel = new BitmapText(this.options.currency, {
      fontName: state.options.customFont2,
      fontSize: 18,
    });
    currencyLabel.anchor.set(0.5);
    currencyLabel.x += state.options.uiPadding / 4;
    currencyLabel.y += this.bonusDialog.children[2].children[0].height / 2 - currencyLabel.height;

    this.bonusDialog.children[2].children[0].addChild(currencyLabel);
  }

  setCollectLabel() {
    const buttonOffset = 15;
    this.collectButton = this.bonusDialog.children[3].children[0]; // eslint-disable-line
    const collectTranslation = this.options.translations.collect;
    this.collectButton.hitArea = new Rectangle(
      -this.collectButton.width / 2 + buttonOffset,
      -this.collectButton.height / 2 + buttonOffset,
      this.collectButton.width - buttonOffset * 2,
      this.collectButton.height - buttonOffset * 2,
    );
    const collectLabel = new BitmapText(collectTranslation.toUpperCase(), {
      fontName: state.options.customFont1,
      fontSize: gameUtils.getFontSize({
        textLength: collectTranslation.length,
        baseSize: 24,
        minSize: 10,
        maxLength: 25,
      }),
    });

    collectLabel.anchor.set(0.5);
    collectLabel.x += state.options.uiPadding / 4;
    collectLabel.y -= state.options.uiPadding / 8;

    this.collectButton.on('pointertap', () => {
      this.close();
    });

    this.collectButton.addChild(collectLabel);
  }

  setupDialogInfo() {
    if (!this.collectButton) {
      this.setCollectLabel();
    }
    this.setFooterWonAmount();
    if (!this.mainWonAmount) {
      this.setMainWonAmount();
    }
    if (this.options.currencyDisplayEnabled) {
      this.setFooterCurrency();
      this.setMainCurrencyLabel();
    }
    this.setWonDescription();

    this.collectButton.eventMode = 'static';
    this.collectButton.cursor = 'pointer';
  }

  async show() {
    this.setContainerVisibilityState(true);
    state.setDialogOpen(true);
    this.setupDialogInfo();
    triggerEvent('HideBoostBadge');
  }

  clearItems() {
    const numberOfDialogItems = this.bonusDialog.children[2].children[0].children.length;
    this.mainWonAmount.destroy();
    this.mainWonAmount = undefined;

    for (let i = numberOfDialogItems - 1; i >= 0; i--) {
      this.bonusDialog.children[2].children[0].children[i].destroy({ children: true });
    }
  }

  async close() {
    this.collectButton.eventMode = 'none';
    state.playTapSound();
    this.runBackgroundAnimation();
    state.setDialogOpen(false);
    triggerAnalyticsEvent({ name: 'CloseBonusGameClick' });
  }
}
