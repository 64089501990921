import { RoundsHistoryTable as BaseRoundsHistoryTable } from '@/components/shared/RoundsHistoryTable';
import { gameUtils } from '../GameUtils';
import { state } from '../State';

export class RoundsHistoryTable extends BaseRoundsHistoryTable {
  constructor(tableParams) {
    super({
      tableParams,
      width: state.options.calcWidth,
      textColor: state.options.colors.text,
      fontNameLight: state.options.fontDefaultLight,
      fontNameBold: state.options.fontDefaultBold,
      translations: state.options.translations,
      currency: state.options.currency,
      currencyDisplayEnabled: state.options.currencyDisplayEnabled,
      roundBetPropertyName: 'paymentAmount',
      roundWinPropertyName: 'winAmountTotal',
      playTapSound: () => { state.playTapSound(); },
      getMoneyLabel: (...data) => gameUtils.getMoneyLabel(...data),
    });
  }
}
