import { Dialog as BaseDialog } from '@/components/shared/Dialog';
import { state } from './State';

export class Dialog extends BaseDialog {
  constructor(params) {
    super({
      labelFontColor: state.options.colors.text,
      fontName: state.options.fontNameNormal,
      iconCloseAsset: state.options.assets.iconClose,
      padding: state.options.uiDialogPadding,
      getAppWidth: () => state.appWidth,
      getAppHeight: () => state.appHeight,
      playTapSound: () => { state.playTapSound(); },
      ...params,
      onShow: () => {
        if (this.isFullscreen) {
          state.header.uiBoost?.hide();
        } else {
          state.header.uiBoost?.disable();
        }

        state.setDialogOpen(true);

        params.onShow?.();
      },
      onClose: () => {
        if (this.isFullscreen) {
          state.header.uiBoost?.show();
        } else {
          state.header.uiBoost?.enable();
        }

        state.setDialogOpen(false);

        params.onClose?.();
      },
    });
  }
}
