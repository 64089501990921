import { DialogContentBetAmount } from '@/components/shared/DialogContentBetAmount';
import { Dialog } from './Dialog';
import { state } from '../State';

export class DialogBetAmount extends Dialog {
  constructor() {
    const content = new DialogContentBetAmount({
      betAmounts: state.options.predefinedBetAmounts,
      getActiveBetAmount: () => state.betAmount,
      getBalanceAmount: () => state.balanceAmount,
      onChange: (value) => {
        state.setBetAmount(value);
      },
      formatBetAmount: (value) => state.getMoneyLabel(value),
      fontName: state.options.fontNameNormal,
      betAmountColor: 0x7d7d7d,
      betAmountColorActive: state.options.colors.theme,
      padding: state.options.uiDialogPadding,
      playTapSound: () => { state.playTapSound(); },
    });

    super({
      title: state.options.translations.dialogBetAmountTitle,
      content,
      isFluid: true,
    });

    this.container.name = 'DialogBetAmount';
  }
}
