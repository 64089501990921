import { Container, Graphics } from '@/pixi';
import { chunk, defaultTo, isArray } from 'lodash';
import animate from 'gsap';
import { Spine } from 'pixi-spine';
import { sleep } from '@/utility/Utility';
import { state } from './State';
import { Symbol } from './Symbol';
import { ReelStack } from './ReelStack';
import { Winlines } from './Winlines';
import { ReelsCashLink } from './ReelsCashLink';
import { Cash } from './Cash';

export class Reels {
  constructor() {
    this.container = new Container();
    this.container.name = 'Reels';
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
    this.reelsContainer = new Container();
    this.reelsContainer.name = 'ReelsContainer';
    this.stickyReelsContainer = new Container();
    this.stickyReelsContainer.name = 'StickyReelsContainer';
    this.reelsCashLink = undefined;
    this.reelsCashLinkContainer = undefined;

    /* Spines */
    this.reelsBackground = new Spine(state.options.assets.reelsBackground.resource.spineData);
    this.bonusWait = new Spine(state.options.assets.bonusWait.resource.spineData);

    /* Masks */
    this.reelMask = undefined;

    /* Symbols arrays */
    this.reelWindowStickyReels = [];
    this.reelWindowSymbolContainers = [];
    this.reelWindowSymbols = [];
    this.spinReels = [];

    /* Used on bonus game end to reset reels to bonus win round */
    this.reelWindowBonusWon = undefined;
    this.reelWindowBonusWonValues = undefined;

    /* Only used on game init to set reels to state where it was when game was closed while bonus was active */
    this.reelWindowBonus = undefined;
    this.reelWindowBonusValues = undefined;

    /* Other values */
    this.bonusSymbolHits = state.options.config.bonusList.map((bonus) => [bonus.symbol, bonus.hits]);
    this.bonusWaitAnimationDuration = 0;
    this.bonusWaitReelIndexStart = undefined;
    this.elementsToRemove = [];
    this.jackpotValues = {}; // Jackpot values (Cash component reference)
    this.numberOfPositions = state.options.config.reels * state.options.config.rows;
    this.stackSize = undefined;

    /* Components */
    this.winlines = undefined;

    this.setup();
    this.setListeners();
    this.setWatchers();
  }

  get reelsSize() {
    return {
      width: this.reelsBackground.width,
      height: this.reelsBackground.height,
    };
  }

  get reels() {
    return chunk(this.reelWindowSymbols, state.options.config.rows);
  }

  get reelsSymbolContainers() {
    return chunk(this.reelWindowSymbolContainers, state.options.config.rows);
  }

  get bonusWaitAvailable() {
    return !state.isBonus && state.spinSpeedType !== 'Lightning';
  }

  get bonusWaitPending() {
    return this.bonusWaitReelIndexStart && this.bonusWaitReelIndexStart < state.options.config.reels;
  }

  get symbolBoxSize() {
    return this.reelsBackground.width / state.options.config.reels;
  }

  get maxWidth() {
    return this.symbolBoxSize - state.options.reelPadding[1] * 2;
  }

  setup() {
    this.container.addChild(this.reelsBackground, this.reelsContainer, this.stickyReelsContainer, this.bonusWait);
    this.container.sortableChildren = true;

    this.reelsContainer.y = this.reelsBackground.getLocalBounds().top;
    this.reelsContainer.sortableChildren = true;
    this.reelsContainer.interactiveChildren = false;

    this.stickyReelsContainer.y = this.reelsBackground.getLocalBounds().top;
    this.stickyReelsContainer.sortableChildren = true;
    this.stickyReelsContainer.visible = false;

    this.bonusWait.visible = false;
    this.bonusWaitAnimationDuration = this.bonusWait.stateData.skeletonData.findAnimation('animation').duration;

    this.reelWindowStickyReels = chunk(Array.from({ length: this.numberOfPositions }, undefined), state.options.config.rows);

    this.setJackpots();
    this.createReelMask();
    this.createRevealReels();
  }

  enableActions() {
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
  }

  disableActions() {
    this.container.eventMode = 'none';
    this.container.cursor = 'default';
  }

  setListeners() {
    this.container.on('pointertap', async () => {
      await state.components.content.controls.spinStartStop();
    });
  }

  setWatchers() {
    state.watch('betAmount', () => {
      this.setJackpots();
    });
  }

  setJackpots() {
    state.getJackpotsData().forEach((jackpot) => {
      const jackpotSlot = this.reelsBackground.skeleton.findSlot(`reel_jackpot_${jackpot.name}_value`);

      this.jackpotValues[jackpot.name] = new Cash({
        cash: jackpot.amount,
        currencyPadding: 5,
        fontSize: 47.5,
        maxWidth: jackpotSlot.attachment.width,
      });

      jackpotSlot.currentSprite.removeChildren();
      jackpotSlot.currentSprite.addChild(this.jackpotValues[jackpot.name].container);
    });
  }

  createReelMask() {
    const x = this.reelsBackground.getLocalBounds().left + state.options.reelPadding[1];
    const y = this.reelsBackground.getLocalBounds().top + state.options.reelJackpotHeight + state.options.reelPadding[0];
    const width = this.reelsBackground.width - state.options.reelPadding[1] * 2;
    const height = this.reelsBackground.height - state.options.reelJackpotHeight - state.options.reelPadding[0] * 2.5;

    this.reelMask = new Graphics().beginFill(0x000000).drawRect(x, y, width, height).endFill();
    this.reelMask.alpha = 0;
    this.reelMask.cacheAsBitmap = true;

    this.container.addChild(this.reelMask);
  }

  setReelWindow(reelWindow, reelWindowValues) {
    this.reelsContainer.removeChildren();
    this.createRevealReels(reelWindow, reelWindowValues);
    this.setReelWindowSymbolsAnimation('symbolIdle');
  }

  createRevealReels(reelWindow, reelWindowValues) {
    const revealWindowSymbols = reelWindow || state.options.ui.reveal.reelWindow;
    const revealWindowValues = reelWindowValues || state.options.ui.reveal.reelWindowValues;

    this.reelWindowSymbolContainers = [];
    this.reelWindowSymbols = [];

    revealWindowSymbols.forEach((reel, reelIndex) => {
      reel.forEach((symbolValue, rowIndex) => {
        /* Get cash/multiplier values */
        const cashValue = revealWindowValues.cash?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
        const multiplierValue = revealWindowValues.multiplier?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];

        /* Create symbol and set symbol position */
        const symbol = new Symbol({
          value: symbolValue,
          mask: this.reelMask,
          maxWidth: this.maxWidth,
          symbolBoxSize: this.symbolBoxSize,
          numOfStackedRows: 0,
          multiplierValue,
          cashValue,
          reelIndex,
          rowIndex,
        });

        /* Save symbols to array */
        this.reelWindowSymbolContainers.push(symbol.container);
        this.reelWindowSymbols.push(symbol);

        /* Add symbols to reel container */
        this.reelsContainer.addChild(symbol.container);

        /* If sticky bonus is active on init, create sticky symbols */
        if (symbol.isStickyWild) {
          this.createStickySymbol(symbol, false);
        }
      });
    });
  }

  createSpinReels(reelWindow, reelWindowValues) {
    if (state.isLightningSpin) {
      this.stackSize = [0, 0, 0, 0];
    } else {
      this.stackSize = [10, 15, 20, 25];
    }

    if (this.bonusWaitAvailable) {
      this.checkBonusWait(reelWindow);
    }

    /* References to elements that needs to be removed after spin */
    this.elementsToRemove.push(...this.reelWindowSymbolContainers);

    /* ReelWindow from previous spin */
    this.spinReels = this.reelsSymbolContainers;

    /* Reset states from previous spin */
    this.reelWindowSymbolContainers = [];
    this.reelWindowSymbols = [];

    /* Create spin reels */
    reelWindow.forEach((reel, reelIndex) => {
      /* Create reel spin stack */
      const reelStack = new ReelStack({
        availableSymbols: state.options.config.baseGameSymbols,
        symbolBoxSize: this.symbolBoxSize,
        stackSize: this.stackSize[reelIndex],
        mask: this.reelMask,
        numOfStackedRows: state.options.config.rows,
        rowIndex: state.options.config.rows - 1,
        reelIndex,
      });

      this.spinReels[reelIndex].push(reelStack.container);
      this.elementsToRemove.push(reelStack.container);

      this.reelsContainer.addChild(reelStack.container);

      /* Add symbols from response */
      reel.forEach((symbolValue, rowIndex) => {
        /* Get symbol cash/multiplier value */
        const cashValue = reelWindowValues.cash.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];
        const multiplierValue = reelWindowValues.multiplier?.find(([x, y]) => x === reelIndex && y === rowIndex)?.[2];

        /* Create symbol */
        const symbol = new Symbol({
          value: symbolValue,
          mask: this.reelMask,
          maxWidth: this.maxWidth,
          symbolBoxSize: this.symbolBoxSize,
          numOfStackedRows: state.options.config.rows + this.stackSize[reelIndex],
          multiplierValue,
          cashValue,
          reelIndex,
          rowIndex,
        });

        this.spinReels[reelIndex].push(symbol.container);

        /* Save symbol to arrays */
        this.reelWindowSymbolContainers.push(symbol.container);
        this.reelWindowSymbols.push(symbol);

        /* Add symbol to reelsContainer */
        this.reelsContainer.addChild(symbol.container);
      });
    });
  }

  createStickySymbol(symbol, runAnimation, yOffset = 0) {
    const stickySymbol = new Symbol({ value: symbol.value, mask: this.reelMask });

    stickySymbol.enableMask();
    stickySymbol.container.x = symbol.container.x;
    stickySymbol.container.y = symbol.container.y - yOffset;

    this.reelWindowStickyReels[symbol.reelIndex][symbol.rowIndex] = stickySymbol;

    this.stickyReelsContainer.addChild(stickySymbol.container);

    if (runAnimation) {
      state.playSound('stickyWild');
      stickySymbol.setAnimationToIdle('symbolSpinStop');
    } else {
      stickySymbol.setAnimation('symbolIdle');
    }
  }

  setReelWindowSymbolsAnimation(animationName) {
    this.setReelWindowSymbolsMask(false);

    this.reelWindowSymbols.forEach((symbol) => {
      const name = animationName || 'symbolIdle';
      symbol.setAnimationToIdle(name);
    });
  }

  setReelWindowSymbolsMask(isEnabled) {
    this.reelWindowSymbols.forEach((symbol) => {
      if (isEnabled) {
        symbol.enableMask();
      } else {
        symbol.disableMask();
      }
    });
  }

  createWinlines(winlines) {
    if (this.winlines) {
      this.winlines.clear();
      this.winlines = undefined;
    }

    if (winlines.length >= 0) {
      this.winlines = new Winlines({ winlines });
    }
  }

  checkBonusWait(reelWindow) {
    this.bonusWaitReelIndexStart = undefined;

    this.bonusSymbolHits.forEach(([bonusSymbolValue, symbolHitsNeeded]) => {
      let bonusHitsFound = 0;

      reelWindow.forEach((reel, reelIndex) => {
        reel.forEach((symbolValue) => {
          bonusHitsFound += isArray(bonusSymbolValue) ? bonusSymbolValue.includes(symbolValue) : symbolValue === bonusSymbolValue;

          const bonusWaitConditionMet = symbolHitsNeeded - 1 === bonusHitsFound;
          const isLowestReelIndex = !this.bonusWaitReelIndexStart || reelIndex < this.bonusWaitReelIndexStart;

          if (bonusWaitConditionMet && isLowestReelIndex) {
            this.bonusWaitReelIndexStart = reelIndex + 1;
          }
        });
      });
    });

    if (this.bonusWaitReelIndexStart !== undefined) {
      this.stackSize.forEach((_, stackIndex) => {
        if (stackIndex >= this.bonusWaitReelIndexStart) {
          this.stackSize[stackIndex] += stackIndex * this.stackSize[stackIndex];
        }
      });
    }
  }

  showBonusWait(reelIndex) {
    if (!this.bonusWaitAvailable || reelIndex >= state.options.config.reels) return;

    if (this.bonusWaitReelIndexStart <= reelIndex) {
      const reelsX = this.reelsBackground.getLocalBounds().left + state.options.reelPadding[1];
      const reelsY = this.reelsBackground.getLocalBounds().top + state.options.reelJackpotHeight;
      const reelsHeight = this.reelsBackground.getLocalBounds().height - state.options.reelJackpotHeight - state.options.reelPadding[0];

      this.bonusWait.x = reelsX + (this.symbolBoxSize * reelIndex) + this.symbolBoxSize / 2;
      this.bonusWait.y = reelsY + reelsHeight / 2;
      this.bonusWait.state.setAnimation(0, 'animation');
      this.bonusWait.mask = this.reelMask;
      this.bonusWait.visible = true;

      state.playSound('bonusWaitSound');

      /* Disable spin stop if bonus wait is active */
      state.components.content.controls.setSpinButtonEnabled(false);
    }
  }

  async bonusGameStart() {
    await state.showBonusIntroScreen();

    state.setBonusAutoplay();
    state.playSoundAmbient();

    if (state.options.bonusDataOnInit) {
      const { baseRound, reelWindowSticky, reelWindowValues } = state.options.bonusDataOnInit;

      /* ReelWindow when game is closed in bonus */
      this.reelWindowBonus = reelWindowSticky.length ? reelWindowSticky : baseRound.reelWindow;
      this.reelWindowBonusValues = reelWindowSticky.length ? reelWindowValues : baseRound.reelWindowValues;

      /* ReelWindow of spin that won bonus game */
      this.reelWindowBonusWon = baseRound.reelWindow;
      this.reelWindowBonusWonValues = baseRound.reelWindowValues;

      /* Remove bonus data after it is applied */
      state.options.bonusDataOnInit = undefined;
    } else {
      /* ReelWindow of spin that won bonus game */
      this.reelWindowBonusWon = state.spinResult.reelWindow;
      this.reelWindowBonusWonValues = state.spinResult.reelWindowValues;
    }

    if (state.isStickyBonus) {
      const reelWindow = this.reelWindowBonus || this.reelWindowBonusWon;
      const reelWindowValues = this.reelWindowBonusValues || this.reelWindowBonusWonValues;
      this.setReelWindow(reelWindow, reelWindowValues);
      this.stickyReelsContainer.visible = true;
      state.components.background.toggleBonusBackground();
    }

    if (state.isCashLinkBonus) {
      this.showCashLinkReels(true);
    }
  }

  async bonusGameEnd() {
    state.stopBonusAutoplay();
    state.playSoundAmbient();

    await state.showBonusOutroScreen();

    this.setReelWindow(this.reelWindowBonusWon, this.reelWindowBonusWonValues);

    if (state.isStickyBonusEnd) {
      this.stickyReelsContainer.visible = false;
      this.stickyReelsContainer.removeChildren();
      this.reelWindowStickyReels = chunk(Array.from({ length: this.numberOfPositions }, undefined), state.options.config.rows);

      state.components.background.toggleBonusBackground();
    }

    if (state.isCashLinkBonusEnd) {
      this.showCashLinkReels(false);
    }

    this.reelWindowBonus = undefined;
    this.reelWindowBonusValues = undefined;
    this.reelWindowBonusWon = undefined;
    this.reelWindowBonusWonValues = undefined;
  }

  showCashLinkReels(isCashLink) {
    if (isCashLink) {
      const reelWindow = this.reelWindowBonus || this.reelWindowBonusWon;
      const reelWindowValues = this.reelWindowBonusValues || this.reelWindowBonusWonValues;

      this.reelsCashLink = new ReelsCashLink();
      this.reelsCashLinkContainer = this.reelsCashLink.container;
      this.reelsCashLinkContainer.visible = true;
      this.reelsCashLink.createRevealReels(reelWindow, reelWindowValues);

      this.container.addChild(this.reelsCashLinkContainer);
    } else {
      this.elementsToRemove.push(this.reelsCashLinkContainer);
      this.removeElements();

      this.reelsCashLink = undefined;
      this.reelsCashLinkContainer = undefined;
    }

    this.reelsContainer.visible = !isCashLink;
    this.reelsBackground.state.setAnimation(0, `reel${isCashLink ? 'CashLink' : 'Base'}Idle`, true);

    this.setJackpots();

    state.components.content.setPosition();
  }

  removeElements() {
    this.elementsToRemove.forEach((element) => {
      element.parent.removeChild(element);
      element.destroy({ children: true, texture: false, baseTexture: false });
    });

    this.elementsToRemove = [];
  }

  resetReelsTimeline() {
    this.reelsTimeline.kill();
    this.reelsTimeline = undefined;
  }

  async spinNormal() {
    return new Promise((resolve) => {
      const reelSpinBaseDuration = defaultTo(state[`reel${state.spinSpeedType}SpinBaseDuration`], state.reelSpinBaseDuration);
      const reelSpinStopDelay = defaultTo(state[`reel${state.spinSpeedType}SpinStopDelay`], state.reelSpinStopDelay);
      const reelSpinBounceDuration = defaultTo(state[`reel${state.spinSpeedType}SpinBounceDuration`], state.reelSpinBounceDuration);
      const reelSpinBounceAmount = defaultTo(state[`reel${state.spinSpeedType}SpinBounceAmount`], state.reelSpinBounceAmount);

      const {
        isBonusEnd,
        isBonusWon,
        reelWindow,
        reelWindowValues,
        winAmount,
        winGrades,
        winlines,
      } = state.spinResult;

      this.bonusWaitReelIndexStart = undefined;
      this.setReelWindowSymbolsMask(true);
      this.createSpinReels(reelWindow, reelWindowValues);
      this.createWinlines(winlines);

      this.reelsTimeline = animate.timeline({
        onStart: () => state.playSound('slotSpinStart'),
        onComplete: async () => {
          this.setReelWindowSymbolsMask(false);

          /* When spin ends remove elements from previous spin */
          this.removeElements();

          /* Hide bonus wait */
          if (this.bonusWait && this.bonusWait.visible) {
            this.bonusWait.visible = false;
          }

          /* Show winlines */
          if (winlines.length > 0) {
            this.winlines.showWinlines();
          }

          /* Show winGrading */
          if (winAmount > 0) {
            const isSyncWinAmount = state.isAnyAutoplay || isBonusWon || winGrades;

            if (isSyncWinAmount) {
              await state.components.winGrading.createWinGradingTimeline(winAmount, winGrades).play();
            } else {
              state.components.winGrading.createWinGradingTimeline(winAmount, winGrades).play();
            }
          } else {
            state.setWinAmount(undefined);
          }

          /* Notify bonus win */
          if (isBonusWon) {
            await this.createShowOnlyBonusSymbolsTimeline().play();
          }

          /* Add delay before showing bonus outro screen */
          if (isBonusEnd) {
            await sleep(500);
          }

          this.resetReelsTimeline();

          resolve(true);
        },
      });

      this.spinReels.forEach((reel, reelIndex) => {
        const reelHeight = (this.symbolBoxSize * (state.options.config.rows + this.stackSize[reelIndex])) + reelSpinBounceAmount;
        const reelSpinDuration = reelSpinBaseDuration + reelIndex * reelSpinStopDelay;
        const reelBonusWaitSpinDuration = reelSpinDuration + (((reelIndex + 1) - this.bonusWaitReelIndexStart) * this.bonusWaitAnimationDuration) - reelSpinStopDelay;

        const reelTimeline = animate.timeline();

        reel.forEach((symbol) => {
          const isFromResult = this.reelWindowSymbolContainers.includes(symbol);
          const symbolsBounceDirection = isFromResult ? `-=${reelSpinBounceAmount}` : `+=${this.symbolBoxSize * 2}`;
          const symbolResultRef = isFromResult && symbol.$ref;

          const symbolTimeline = animate.timeline();

          symbolTimeline.to(symbol, {
            duration: this.bonusWaitReelIndexStart <= reelIndex ? reelBonusWaitSpinDuration : reelSpinDuration,
            ease: 'none',
            pixi: {
              y: `+=${reelHeight}`,
            },
            onStart: () => symbol.$ref?.setAnimation('symbolSpin'),
          });

          symbolTimeline.to(symbol, {
            duration: reelSpinBounceDuration,
            ease: 'Sine.easeInOut',
            pixi: {
              y: symbolsBounceDirection,
            },
          });

          /* Spin stop label */
          symbolTimeline.addLabel('spinStop', `<-${0.05 * reelIndex}`);

          /* Add symbolSpinStop animation */
          symbolTimeline.add(this.createSpinStopTimeline(symbolResultRef), '<');

          /* Apply sticky wild symbols */
          symbolTimeline.add(this.createApplyStickyWildTimeline(symbolResultRef, reelSpinBounceAmount), '<');

          reelTimeline.add(symbolTimeline, 0);
        });

        /* Check for bonus wait */
        reelTimeline.add(this.createShowBonusWaitTimeline(reelIndex), '-=75%');

        this.reelsTimeline.add(reelTimeline, 0);
      });

      /* Needed for smooth stop() */
      this.reelsTimeline.smoothChildTiming = true;

      this.reelsTimeline.play();
    });
  }

  stop() {
    const isSpinStoppable = !this.bonusWaitPending && this.reelsTimeline?.isActive();

    if (isSpinStoppable) {
      this.reelsTimeline.getChildren(false, false).forEach((reelTimeline) => {
        reelTimeline.getChildren(false, false).forEach((symbolTimeline) => {
          const time = symbolTimeline.labels.spinStop;

          if (reelTimeline.isActive() && reelTimeline.totalTime() < time) {
            reelTimeline.seek(time, false);
          }
        });
      });
    }
  }

  async spin() {
    if (state.isCashLinkBonus) {
      await this.reelsCashLink.spin();
    } else {
      await this.spinNormal();
    }
  }

  /* Animations timelines */
  createShowBonusWaitTimeline(reelIndex) {
    const timeline = animate.timeline();

    timeline.to({}, {
      onStart: () => {
        this.showBonusWait(reelIndex + 1);
      },
    });

    return timeline;
  }

  createSpinStopTimeline(symbol) {
    if (!symbol) return undefined;

    const isBehindSticky = this.reelWindowStickyReels[symbol.reelIndex][symbol.rowIndex];
    const duration = (state.isLightningSpin && 0.01) || (isBehindSticky && 0.01) || (symbol.getAnimationDuration('symbolSpinStop') || 0.01);

    const timeline = animate.timeline();

    timeline.to({}, {
      duration,
      onStart: () => {
        if (symbol.canTriggerBonus) {
          state.playSound('bonusSymbolAppear');
        }

        if (state.isLightningSpin) {
          symbol.setAnimation('symbolIdle');
          return;
        }

        if (symbol.isStickyWild || isBehindSticky) {
          symbol.setAnimation('symbolIdle');
          return;
        }

        symbol.setAnimationToIdle('symbolSpinStop');
      },
    }, 0);

    return timeline;
  }

  createApplyStickyWildTimeline(symbol, yOffset) {
    const isNewSymbol = symbol && this.reelWindowStickyReels[symbol.reelIndex][symbol.rowIndex] === undefined;

    if (!symbol?.isStickyWild || !state.isStickyBonus || !isNewSymbol) return undefined;

    const timeline = animate.timeline();

    timeline.to({}, {
      duration: symbol.getAnimationDuration('symbolSpinStop'),
      onStart: () => {
        this.createStickySymbol(symbol, true, yOffset);
      },
    }, 0);

    return timeline;
  }

  createShowOnlyBonusSymbolsTimeline() {
    const timeline = animate.timeline({
      onStart: () => state.playSound('slotBonusGameWin'),
    });

    this.reelWindowSymbols.forEach((symbol) => {
      const bonusSymbols = state.options.config.bonusList.find((bonus) => bonus.type === state.spinResult.bonus.type).symbol;
      const isBonusSymbol = isArray(bonusSymbols) ? bonusSymbols.includes(symbol.value) : bonusSymbols === symbol.value;

      timeline.to({}, {
        duration: symbol.getAnimationDuration('symbolWin') || 0.01,
        onStart: () => {
          if (isBonusSymbol) {
            symbol.setAnimation('symbolWin');
            symbol.setZIndex(2);
          } else {
            symbol.setAnimation('symbolNoWin');
            symbol.setZIndex(1);
          }
        },
      }, 0);
    });

    return timeline;
  }
}
