import { Spine } from 'pixi-spine';
import { Container } from '@/pixi';
import { state } from './State';

export class Background {
  #container;
  #background;
  #bonusGameBackground;
  #bottomGradient;

  constructor() {
    const { background, bonusBackground, backgroundBottomGradient } = state.assets;

    this.#container = new Container();
    this.#container.name = 'Background';

    this.#background = new Spine(background.resource.spineData);
    this.#bonusGameBackground = new Spine(bonusBackground.resource.spineData);
    this.#bonusGameBackground.visible = false;
    this.#bonusGameBackground.state.setAnimation(0, 'bonusBackgroundLoop', true);
    this.#bonusGameBackground.state.timeScale = 0;
    this.#bottomGradient = new Spine(backgroundBottomGradient.resource.spineData);
    this.#bottomGradient.scale.set(0.9); // Set scale before setPosition to not overflow screen
    this.#container.addChild(this.#background, this.#bonusGameBackground, this.#bottomGradient);

    this.#setListeners();
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#background.state.addListener({
      complete: (trackEntry) => {
        // After intro animation is complete run idle animation
        if (trackEntry.animation.name === 'backgroundIntro') {
          this.#background.state.setAnimation(0, 'backgroundIdle2', true);
        }
      },
    });
  }

  changeBackground(setBonusGameBackground) {
    this.#background.visible = !setBonusGameBackground;
    this.#background.state.timeScale = !setBonusGameBackground ? 1 : 0;
    this.#bonusGameBackground.visible = setBonusGameBackground;
    this.#bonusGameBackground.state.timeScale = setBonusGameBackground ? 1 : 0;
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const background = this.#background.visible ? this.#background : this.#bonusGameBackground;
    const spineSprite = this.#background.visible ? this.#background.children[0].children[0].children[0].children[0] : this.#bonusGameBackground.children[0].children[0];
    const backgroundAspectRatio = spineSprite.height / spineSprite.width;

    // If app aspect ratio is larger then background aspect ratio then set larger background scale
    if (aspectRatio > backgroundAspectRatio) {
      background.scale.set(state.appHeight / spineSprite.attachment.height);
      this.#bottomGradient.scale.set(background.scale.y);
    } else {
      background.scale.set(1);
      this.#bottomGradient.scale.set(1);
    }

    background.x = state.appWidth / 2 - background.width / 2;
    background.y = state.appHeight / 2 - background.height / 2;
    this.#bottomGradient.x = state.appWidth / 2 - this.#bottomGradient.width / 2;
    this.#bottomGradient.y = state.appHeight - this.#bottomGradient.height;
  }

  runIntroAnimation() {
    this.#background.state.setAnimation(0, 'backgroundIntro');
  }
}
