import { Graphics, Container, Ticker } from '@/pixi';
import { Spine } from 'pixi-spine';
import { TopPrize } from './TopPrize';
import { state } from './State';
import { audio } from './Audio';

export class Background {
  constructor() {
    this.background = undefined;
    this.door = undefined;
    this.floor = undefined;
    this.logo = undefined;
    this.options = state.options;
    this.container = new Container();
    this.container.name = 'Background';
    this.ticker = new Ticker();
    this.topPrize = new TopPrize();
    this.isTabActive = true;
    this.isIntroAnimationFinished = false;
    this.setup();
    this.setListeners();
  }

  addComponents() {
    this.container.addChild(this.createBackgroundColor());
    this.container.addChild(this.background);
    this.container.addChild(this.logo);
    this.container.addChild(this.door);
    this.container.addChild(this.floor);
    this.container.addChild(this.topPrize.container);
  }

  createBackgroundColor() {
    const backgroundColor = new Graphics();
    backgroundColor.beginFill(0x070512);
    backgroundColor.drawRect(0, 0, state.originalSize.width, state.originalSize.height * 3);
    backgroundColor.endFill();

    return backgroundColor;
  }

  initModels() {
    this.background = new Spine(state.options.resources.background.resource.spineData);
    this.door = new Spine(state.options.resources.door.resource.spineData);
    this.floor = new Spine(state.options.resources.floor.resource.spineData);
    this.logo = new Spine(state.options.resources.logo.resource.spineData);
  }

  runBackgroundAnimation(eventName) {
    this.background.state.setAnimation(0, eventName, false);
    this.background.state.addListener({
      complete: (entry) => {
        if (entry.animation.name === 'intro') {
          this.stopTicker();
        }
      },
      event: (trackIndex, event) => {
        this.runAnimationByEventType(event);
      },
    });
  }

  runLogoAnimation(eventName) {
    this.logo.state.setAnimation(0, eventName, false);
    this.logo.state.addListener({
      complete: (entry) => {
        if (entry.animation.name === 'logoIntro' && !this.isTabActive) {
          this.setLogoPosition();
        }
        this.logo.state.setAnimation(0, 'logoIdle', false);
      },
    });
  }

  runDoorAnimation(eventName, loop = false) {
    this.door.state.setAnimation(0, eventName, loop);
  }

  runFloorAnimation(eventName) {
    this.floor.state.setAnimation(0, eventName, false);
  }

  runAnimationByEventType(event) {
    if (event.data.name.includes('postIntro')) {
      state.posts.show();
      state.posts.runRightPostAnimation('introRight');
      state.posts.runLeftPostAnimation('introLeft');
      state.reel.reveal();

      setTimeout(() => {
        this.isIntroAnimationFinished = true;
      }, 1000);
    }
  }

  runWinAnimations() {
    this.runLogoAnimation('logoWin');
    this.runFloorAnimation('win');
    this.runDoorAnimation('win');
  }

  setup() {
    this.initModels();
    this.setLogoPosition();
    this.hideLogo();
    this.addComponents();
    this.trackLogoBoneMovement();
    this.runBackgroundAnimation('intro');
    this.background.state.timeScale = 0;
  }

  setAudio() {
    audio.play(this.options.assets.soundOnboard);
    if (!state.soundAmbient) {
      state.setSoundAmbient();
    }
  }

  startAnimation() {
    state.content.controls.disableSpin();
    this.showLogo();
    this.startTicker();
    this.background.state.timeScale = 1;
    this.runLogoAnimation('logoIntro');
    this.topPrize.runOverlayAnimation('intro');
    this.topPrize.runAmountAnimation('amount');
    this.setAudio();
  }

  hideLogo() {
    this.logo.visible = false;
  }

  showLogo() {
    this.logo.visible = true;
  }

  setLogoPosition() {
    const bone = this.background.skeleton.findBone('logo');

    this.logo.position.set(
      bone.worldX,
      bone.worldY,
    );
  }

  startTicker() {
    this.ticker.start();
  }

  stopTicker() {
    this.ticker.stop();
  }

  setListeners() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.isTabActive = false;
      }
    }, false);
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAspectRatio = state.originalSize.height / state.originalSize.width;

    if (aspectRatio < backgroundAspectRatio) {
      const scaleFactor = 0.75;

      this.background.scale.set(1, scaleFactor);
      this.logo.scale.set(1, scaleFactor);
      this.door.scale.set(1, scaleFactor);
      this.floor.scale.set(1, scaleFactor);
      this.topPrize.container.scale.set(1, scaleFactor);
    }
  }

  trackLogoBoneMovement() {
    this.ticker.add(() => {
      this.setLogoPosition();
    });
  }
}
