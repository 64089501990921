import { Checkbox as BaseCheckbox } from '@/components/shared/Checkbox';
import { state } from '../State';

export class Checkbox extends BaseCheckbox {
  constructor(params) {
    super({
      ...Checkbox.getDefaultParams(),
      ...params,
    });
  }

  static getDefaultParams = () => ({
    iconOffAsset: state.options.assets.iconOff,
    iconOnAsset: state.options.assets.iconOn,
    colorActive: state.options.colors.theme,
    playTapSound: () => { state.playTapSound(); },
  });
}
