import { Container, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import { isArray, isNil } from 'lodash';
import { state } from './State';
import { Cash } from './Cash';

export class Symbol {
  constructor({
    betAmount = state.betAmount,
    cashValue = 0,
    mask,
    maxWidth,
    multiplierValue = 1,
    numOfStackedRows,
    reelIndex,
    rowIndex,
    symbolBoxSize,
    value,
  }) {
    this.container = new Container();
    this.container.name = 'Symbol';
    this.container.interactiveChildren = false;
    this.container.$ref = this;

    this.value = value;
    this.cashLabel = undefined;
    this.cashValue = cashValue * betAmount;
    this.mask = mask;
    this.maxWidth = maxWidth;
    this.multiplierValue = multiplierValue;
    this.numOfStackedRows = numOfStackedRows;
    this.symbolBoxSize = symbolBoxSize;
    this.reelIndex = reelIndex;
    this.rowIndex = rowIndex;
    this.zIndexAboveEverything = 100;
    this.isSticky = false;

    /* Adjust symbol values before creating symbol */
    this.formatSymbolValue();

    /* Create symbol */
    this.setup();
    this.setPosition();
  }

  get isWild() {
    return this.value === state.options.config.symbolMap.wild;
  }

  get isBonus() {
    return this.value === state.options.config.symbolMap.bonus;
  }

  get isCash() {
    return this.value === state.options.config.symbolMap.cash;
  }

  get isMultiplier() {
    return this.value === state.options.config.symbolMap.multiplier;
  }

  get isStickyWild() {
    return this.value === state.options.config.symbolMap.sticky;
  }

  get isPreservableInCashLink() {
    return this.isSticky && state.isCashLinkBonus;
  }

  get isSpecialSymbol() {
    return this.isBonus || this.isCash || this.isWild || this.isStickyWild;
  }

  get isJackpot() {
    return state.getJackpotsData().some((jackpot) => Object.values(jackpot).includes(this.value));
  }

  get jackpotName() {
    return state.getJackpotsData().find((jackpot) => Object.values(jackpot).includes(this.value)).name;
  }

  get stickySymbols() {
    return state.spinResult.stickySymbols || state.options.state.state.bonus?.stickySymbols || [];
  }

  get canTriggerBonus() {
    return state.options.config.bonusList.some((bonus) => (isArray(bonus.symbol) ? bonus.symbol.includes(this.value) : bonus.symbol === this.value));
  }

  setup() {
    this.symbol = new Spine(state.options.assets[`symbol${this.value}`].resource.spineData);

    if (this.isSpecialSymbol) {
      this.setZIndex(2);
    }

    if (this.hasAnimation('symbolSpinStop')) {
      this.setZIndex(3);
    }

    if (this.isCash) {
      this.addCashValue();
    }

    if (this.isMultiplier) {
      this.addMultiplierValue();
    }

    this.container.addChild(this.symbol);
  }

  formatSymbolValue() {
    /* Does symbol stay preserved on reels in bonus games */
    this.isSticky = this.stickySymbols.some(([x, y, z]) => x === this.reelIndex && y === this.rowIndex && z === this.value);

    /* Sticky Wild and Wild have the same value, so we saperate them */
    if (this.isSticky && state.isStickyBonus) {
      this.value = state.options.config.symbolMap.sticky;
    }
  }

  setPosition() {
    if (isNil(this.reelIndex) || isNil(this.rowIndex) || isNil(this.numOfStackedRows)) return;

    const { reelJackpotHeight, reelPadding } = state.options;

    const offsetX = this.symbolBoxSize / 2 + this.reelIndex * this.symbolBoxSize + reelPadding[1];
    const offsetY = this.symbolBoxSize / 2 + (this.rowIndex - this.numOfStackedRows) * this.symbolBoxSize + reelPadding[0] + reelJackpotHeight;

    this.container.x = offsetX;
    this.container.y = offsetY;

    this.enableMask();
  }

  addCashValue() {
    const slot = this.symbol.skeleton.findSlot('symbol_cash_label');

    this.cashLabel = new Cash({
      cash: this.cashValue,
      direction: 'y',
      fontSize: 100,
      maxWidth: this.maxWidth,
    });

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(this.cashLabel.container);
  }

  addMultiplierValue() {
    const slot = this.symbol.skeleton.findSlot('symbol_golden_label');
    const text = new BitmapText(`x${this.multiplierValue}`, {
      fontName: state.options.fontCherryNumbers,
      fontSize: 125,
    });

    text.anchor.set(0.5, 0.41);

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text);
  }

  setZIndex(value) {
    this.container.zIndex = value;
  }

  setAnimation(name, loop = false) {
    if (name && this.symbol.state.hasAnimation(name)) {
      this.symbol.state.setAnimation(0, name, loop);
    }
  }

  /* Set animation and when it completes run symbolIdle animation in loop */
  setAnimationToIdle(name) {
    if (!this.symbol.state.hasAnimation(name)) {
      this.setAnimation('symbolIdle', true);
    } else {
      this.disableMask();

      this.setAnimation(name, false);

      this.symbol.state.addListener({
        complete: (trackEntry) => {
          if (trackEntry.animation.name === name) {
            this.setAnimation('symbolIdle', true);
          }
        },
      });
    }
  }

  hasAnimation(name) {
    return this.symbol.state.hasAnimation(name);
  }

  getAnimationDuration(name) {
    return this.symbol.stateData.skeletonData.findAnimation(name)?.duration;
  }

  enableMask() {
    if (this.mask) {
      this.container.mask = this.mask;
    }
  }

  disableMask() {
    if (this.mask) {
      this.container.mask = undefined;
    }
  }
}
