import { DialogContentMenuHistory as BaseDialogContentMenuHistory } from '@/components/shared/DialogContentMenuHistory';
import { RoundsHistoryTable } from './RoundsHistoryTable';
import { state } from './State';

export class DialogContentMenuHistory extends BaseDialogContentMenuHistory {
  constructor() {
    super({
      RoundsTableComponent: RoundsHistoryTable,
      fontNameBold: state.options.fontNameBold,
      fontNameNormal: state.options.fontNameNormal,
      getAppHeight: () => state.appHeight,
      getAppWidth: () => state.appWidth,
      getDialogMenuFullHeadHeight: () => state.dialogMenu.getFullHeadHeight(),
      highlightColor: state.options.colors.theme,
      isDemo: state.options.isDemo,
      loaderColor: state.options.colors.theme,
      playerToken: state.options.playerToken,
      playTapSound: () => { state.playTapSound(); },
      rootContainer: state.container,
      tenantGameId: state.options.tenantGameId,
      textColor: state.options.colors.text,
      translations: state.options.translations,
      uiDialogPadding: state.options.uiDialogPadding,
      width: state.options.calcWidth,
    });
  }
}
