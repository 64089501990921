import animate from 'gsap';
import { Container, BitmapText, Circle } from '@/pixi';
import { Spine } from 'pixi-spine';
import { sleep, triggerAnalyticsEvent } from '@/utility/Utility';
import { SlotSpinSpeedType } from '@/models';
import { gameUtils } from './GameUtils';
import { Label } from './Label';
import { state } from './State';

export class Controls {
  constructor() {
    this.container = new Container();
    this.container.name = 'Controls';

    this.allowPointerTap = true;
    this.isSpinning = false;
    this.isSpinStoppable = false;
    this.previousSpinSpeedType = undefined;
    this.spinNumber = 0;
    this.uiScale = 1;
    this.spinButtonHoldTimeline = undefined;

    this.setup();
    this.setListeners();
    this.setWatchers();
  }

  get autoplaySpinWait() {
    return state.isLightningSpin ? 150 : 250;
  }

  get isSpacebarSpinEnabled() {
    return this.spinButton.eventMode === 'static' && !state.dialogOpen && !state.onBoardOpen && !state.errorDetails;
  }

  setup() {
    this.spinButton = new Spine(state.options.assets.spinButton.resource.spineData);
    this.spinButton.hitArea = new Circle(0, 0, this.spinButton.width / 2);
    this.container.addChild(this.spinButton);

    this.buyButton = new Spine(state.options.assets.buyButton.resource.spineData);
    this.buyButton.x = this.spinButton.x - this.spinButton.width / 2 - 48 - this.buyButton.width / 2;
    this.buyButton.hitArea = new Circle(0, 0, this.buyButton.width / 2);
    this.container.addChild(this.buyButton);

    this.autoplayButton = new Spine(state.options.assets.autoplayButton.resource.spineData);
    this.autoplayButton.x = this.spinButton.x + this.spinButton.width / 2 + 48 + this.autoplayButton.width / 2;
    this.autoplayButton.hitArea = new Circle(0, 0, this.autoplayButton.width / 2);
    this.container.addChild(this.autoplayButton);

    this.autoplayCounter = new BitmapText(0, {
      fontName: state.options.fontCherryNumbers,
      fontSize: 175,
    });
    this.autoplayCounter.visible = false;
    this.autoplayCounter.anchor.set(0.5, 0.41);
    this.container.addChild(this.autoplayCounter);

    this.demoLabel = new Label({ text: state.options.translations.demoLabel });
    this.demoLabel.container.x = this.spinButton.x - this.demoLabel.container.width / 2;
    this.demoLabel.container.y = this.spinButton.y + this.spinButton.height / 2 - this.demoLabel.container.height;
    this.demoLabel.setVisible(state.options.isDemo);
    this.container.addChild(this.demoLabel.container);

    this.lightningSpinLabel = new Label({ text: state.options.translations.holdForLightningMode });
    this.lightningSpinLabel.container.x = this.spinButton.x - (this.lightningSpinLabel.container.width / 2);
    this.lightningSpinLabel.container.y = this.spinButton.y + this.spinButton.height / 2 - this.lightningSpinLabel.container.height;
    this.lightningSpinLabel.setVisible(false);
    this.container.addChild(this.lightningSpinLabel.container);
  }

  setScale(scale) {
    this.uiScale = scale;

    this.spinButton.scale.set(scale);
    this.autoplayCounter.scale.set(scale);

    this.buyButton.scale.set(scale);
    this.buyButton.x = this.spinButton.x - this.spinButton.width / 2 - 48 - this.buyButton.width / 2;

    this.autoplayButton.scale.set(scale);
    this.autoplayButton.x = this.spinButton.x + this.spinButton.width / 2 + 48 + this.autoplayButton.width / 2;

    if (this.demoLabel) {
      this.demoLabel.container.scale.set(scale);
      this.demoLabel.container.x = this.spinButton.x - this.demoLabel.container.width / 2;
      this.demoLabel.container.y = this.spinButton.y + this.spinButton.height / 2 - this.demoLabel.container.height;
    }

    if (this.lightningSpinLabel) {
      this.lightningSpinLabel.container.scale.set(scale);
      this.lightningSpinLabel.container.x = this.spinButton.x - (this.lightningSpinLabel.container.width / 2);
      this.lightningSpinLabel.container.y = this.spinButton.y + this.spinButton.height / 2 - this.lightningSpinLabel.container.height;
    }
  }

  setListeners() {
    this.spinButton.eventMode = 'static';
    this.spinButton.cursor = 'pointer';

    this.spinButtonHoldTimeline = animate.timeline();

    this.spinButton.on('pointerdown', () => {
      this.allowPointerTap = true;

      if (!this.isSpinning) {
        this.spinButtonHoldTimeline.to(this.spinButton, {
          delay: 0.1,
          duration: 0.667,
          pixi: {
            scale: this.uiScale * 1.17,
          },
        }).to(this.spinButton, {
          duration: 0.167,
          pixi: {
            scale: this.uiScale * 0.91,
          },
        }).to(this.spinButton, {
          duration: 0.167,
          pixi: {
            scale: this.uiScale,
            alpha: 0.5,
          },
          onComplete: () => {
            this.allowPointerTap = false;
            this.previousSpinSpeedType = state.spinSpeedType;
            state.setSpinSpeedType(SlotSpinSpeedType.Lightning);
            state.startAutoplay(10000);
          },
        });
      }
    });

    this.spinButton.on('pointerupoutside', () => {
      this.resetSpinButton();
    });

    this.spinButton.on('pointertap', async () => {
      if (!this.allowPointerTap) return;

      if (this.isSpinning) {
        triggerAnalyticsEvent({ name: 'SpinButtonStopClick' });
      } else {
        triggerAnalyticsEvent({ name: 'SpinButtonClick' });
      }

      state.playTapSound();

      await this.spinStartStop();
    });

    document.body.onkeyup = async (event) => {
      if (event.code === 'Space' && this.isSpacebarSpinEnabled) {
        await this.spinStartStop();
      }
    };

    this.buyButton.eventMode = 'static';
    this.buyButton.cursor = 'pointer';
    this.buyButton.on('pointertap', () => {
      state.playTapSound();
      state.components.dialogBuy.show();
      triggerAnalyticsEvent({ name: 'BuyButtonClick' });
    });

    this.autoplayButton.eventMode = 'static';
    this.autoplayButton.cursor = 'pointer';
    this.autoplayButton.on('pointertap', () => {
      state.playTapSound();

      if (state.isAutoplay) {
        state.stopAutoplay();
        triggerAnalyticsEvent({ name: 'AutoplayStopButtonClick' });
      } else {
        state.components.dialogAutoplay.show();
        triggerAnalyticsEvent({ name: 'AutoplayButtonClick' });
      }
    });
  }

  setWatchers() {
    state.watch('autoplay.active', (isActive) => {
      if (isActive) {
        this.setAutoplayButtonAnimation('autoplayToAutoplayStop');
      } else {
        this.setAutoplayButtonAnimation('autoplayStopToAutoplay');
        this.setAutoplayButtonEnabled(false);
        this.resetSpinTypeAfterLightningMode();
      }
    });

    state.watch('autoplay.numberOfSpins', (numberOfSpins) => {
      if (numberOfSpins > 0 && numberOfSpins < 100) {
        this.autoplayCounter.text = numberOfSpins;
        this.autoplayCounter.visible = true;
      } else {
        this.autoplayCounter.visible = false;
      }
    });

    state.watch('bonusAutoplay.numberOfSpins', (numberOfSpins) => {
      if (numberOfSpins > 0) {
        this.autoplayCounter.text = numberOfSpins;
        this.autoplayCounter.visible = true;
      } else {
        this.autoplayCounter.visible = false;
      }
    });

    state.watch('betAmount', () => {
      this.enableAfterSpin();
    });
  }

  checkLightningLabel() {
    this.spinNumber += 1;

    /* Show lightning label on 7th spin */
    if (this.spinNumber === 7 && !state.options.isDemo && !state.isLightningSpin && !state.isAnyAutoplay) {
      this.lightningSpinLabel.setVisible(true);
    }

    /* Hide lightning label on 8th spin */
    if (this.spinNumber === 8) {
      this.lightningSpinLabel.setVisible(false, true);
      this.lightningSpinLabel = undefined;
    }
  }

  resetSpinButton() {
    this.spinButton.alpha = 1;
    this.spinButton.scale.set(this.uiScale);
    this.spinButtonHoldTimeline?.clear();
  }

  resetSpinTypeAfterLightningMode() {
    if (this.previousSpinSpeedType) {
      state.setSpinSpeedType(this.previousSpinSpeedType);
      this.previousSpinSpeedType = undefined;
    }
  }

  setAutoplayButtonAnimation(animation) {
    this.autoplayButton.state.setAnimation(0, animation, false);

    this.autoplayButton.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'autoplayToAutoplayStop') {
          this.autoplayButton.state.setAnimation(0, 'autoplayStopIdle', true);
        }

        if (trackEntry.animation.name === 'autoplayStopToAutoplay') {
          this.autoplayButton.state.setAnimation(0, 'autoplayIdle', true);
        }
      },
    });
  }

  runSpinAnimation() {
    this.spinButton.state.setAnimation(0, 'spin', false);
  }

  async spinStartStop() {
    this.resetSpinButton();
    this.setSpinButtonEnabled(!this.isSpinning);

    if (this.isSpinning) {
      state.reels.stop();
    } else {
      await this.spin();
    }
  }

  async spin(bonusBuyResponse) {
    this.isSpinStoppable = !bonusBuyResponse && !state.isAnyAutoplay && !state.isLightningSpin;
    this.isSpinning = true;

    state.disableInSpin();

    const requestPayload = [
      state.options.tenantGameId,
      state.options.playerToken,
      { betAmount: state.betAmount }, /* Details */
      { spinType: state.spinSpeedType }, /* Meta */
    ];
    const response = bonusBuyResponse || await gameUtils.makeApiRequest('createRound', requestPayload, true);

    if (response.isError) {
      state.setErrorDetails(response);
      this.isSpinStoppable = false;
      this.isSpinning = false;
    } else {
      /* Before spin */
      if (state.isAutoplay) {
        await sleep(this.autoplaySpinWait);
        state.updateAutoplayNumberOfSpins();
      }

      if (state.isBonusAutoplay) {
        state.setCashLinkNumberOfSpins();
        await sleep(this.autoplaySpinWait);
        state.updateBonusNumberOfSpins();
      }

      this.runSpinAnimation();
      this.checkLightningLabel();
      state.parseSpinResult(response);
      state.setBalanceAmountOnSpinStart();

      if (state.components.winGrading.isActive) {
        await state.components.winGrading.interruptWithNewSpin();
      } else {
        state.setWinAmount(undefined);
      }

      /* Spin */
      await state.reels.spin();

      /* After spin */
      this.isSpinStoppable = false;
      this.isSpinning = false;

      state.updateOptions({ ui: response.ui });
      state.setBonusGameState();

      if (state.isAutoplay) {
        state.checkAutoplay();
      }

      /* In Bonus controls are enabled on outro screen close */
      if (!state.isAnyAutoplay && !state.bonus.isWon && !state.bonus.isEnd) {
        state.enableAfterSpin();
      }

      if (state.isAnyAutoplay) {
        await this.spin();
      }
    }
  }

  setSpinButtonEnabled(isEnabled) {
    const isButtonEnabled = isEnabled && state.sufficientFunds;

    if (isButtonEnabled) {
      state.reels.enableActions();
    } else {
      state.reels.disableActions();
    }

    this.spinButton.eventMode = isButtonEnabled ? 'static' : 'none';
    this.spinButton.alpha = isButtonEnabled ? 1 : 0.5;
    this.spinButton.cursor = isButtonEnabled ? 'pointer' : 'default';
  }

  setBuyButtonEnabled(isEnabled) {
    const isButtonEnabled = isEnabled && state.options.ui.bonusBuyEnabled;

    this.buyButton.eventMode = isButtonEnabled ? 'static' : 'none';
    this.buyButton.alpha = isButtonEnabled ? 1 : 0.5;
    this.buyButton.cursor = isButtonEnabled ? 'pointer' : 'default';
  }

  setAutoplayButtonEnabled(isEnabled) {
    const isButtonEnabled = isEnabled && state.options.ui.autoPlayEnabled && state.sufficientFunds;

    this.autoplayButton.eventMode = isButtonEnabled ? 'static' : 'none';
    this.autoplayButton.alpha = isButtonEnabled ? 1 : 0.5;
    this.autoplayButton.cursor = isButtonEnabled ? 'pointer' : 'default';
  }

  disableInSpin() {
    this.setBuyButtonEnabled(false);
    this.setAutoplayButtonEnabled(state.isAutoplay);
    this.setSpinButtonEnabled(this.isSpinStoppable);
  }

  enableAfterSpin() {
    this.setBuyButtonEnabled(true);
    this.setAutoplayButtonEnabled(true);
    this.setSpinButtonEnabled(true);
  }
}
