import { template } from 'lodash';
import { Container, BitmapText, Text, Sprite, Texture } from '@/pixi';
import { ScrollBox } from '@/components/shared/ScrollBox';
import { gameUtils } from '../GameUtils';
import { state } from '../State';
import { Symbol } from '../Symbol';

export class DialogMenuRules {
  constructor() {
    this.container = new Container();
    this.container.name = 'DialogMenuRules';
    this.mainSymbolsPaytableContainer = new Container();
    this.mainSymbolsPaytableContainer.name = 'MainSymbolsPaytable';

    this.items = [];
    this.itemsMargin = 50;
    this.maxWidth = state.options.calcWidth - state.options.uiDialogPadding * 2.5;
    this.symbolBoxSize = state.options.calcWidth / state.options.config.reels;
    this.symbolTitleMargin = 35;

    // Game info
    const gameInfoDescription = template(state.options.translations.cherryBlastGameInfo)({ rtp: '96.07' });
    this.items.push(this.createTitle(state.options.translations.aboutTheGameTitle));
    this.items.push(this.createDescription(gameInfoDescription));
    this.items.push(this.createLineSeparator());

    // Features
    this.items.push(this.createTitle(state.options.translations.wildFeature));
    this.items.push(this.createDescription(state.options.translations.wildFeatureDescription));
    this.items.push(this.createLineSeparator());

    // Free spins
    this.items.push(this.createTitle(state.options.translations.freeSpins));
    this.items.push(this.createDescription(state.options.translations.cherryBlastFreeSpinsDescription));

    this.items.push(this.createSymbolWithDescription(state.options.config.symbolMap.sticky, state.options.translations.wildStickySymbol, state.options.translations.stickyWildNoMultiplierSymbolDescription));
    this.items.push(this.createLineSeparator());

    // Paytable
    this.items.push(this.createTitle(state.options.translations.dialogMenuPaytableTitle));
    this.items.push(this.createMainSymbolsPaytable());
    this.items.push(this.createLineSeparator());

    // Cash Link
    this.items.push(this.createTitle(state.options.translations.cashLinkBonusGame));
    this.items.push(this.createDescription(state.options.translations.cherryBlastCashLinkDesctiption));

    const cashSymbolDescription = template(state.options.translations.cashSymbolDescription)({ minDistributionValue: '2', maxDistributionValue: '100' });
    this.items.push(this.createSymbolWithDescription(state.options.config.symbolMap.cash, state.options.translations.cashSymbol, cashSymbolDescription));
    this.items.push(this.createSymbolWithDescription(state.options.config.symbolMap.multiplier, state.options.translations.multiplierSymbol, state.options.translations.cherryBlastMultiplierSymbolDescription));
    this.items.push(this.createJackpotSymbolsWithDescription());
    this.items.push(this.createLineSeparator());

    // Paylines
    this.items.push(this.createTitle(state.options.translations.paylines));
    this.items.push(this.createPaylines());
    this.items.push(this.createLineSeparator());

    // Store
    const storeDescription = template(state.options.translations.cherryBlastStoreDescription)({ rtp1: '96.27', rtp2: '96.31', rtp3: '96.27' });
    this.items.push(this.createTitle(state.options.translations.dialogStoreTitle));
    this.items.push(this.createDescription(storeDescription));
    this.items.push(this.createLineSeparator());

    // How to play
    this.items.push(this.createTitle(state.options.translations.howToPlayTitle));
    this.items.push(this.createDescription(state.options.translations.howToPlayDescription));

    this.scrollBox = new ScrollBox({
      items: this.items,
      itemsMargin: this.itemsMargin,
      padding: state.options.uiDialogPadding,
    });

    this.container.addChild(this.scrollBox.container);

    this.setWatchers();
  }

  setWatchers() {
    state.watch('betAmount', () => {
      this.createMainSymbolsPaytable();
      this.items[this.items.indexOf(this.mainSymbolsPaytableContainer)] = this.mainSymbolsPaytableContainer;
      this.scrollBox.removeAllItems();
      this.scrollBox.addItems(this.items);
    });
  }

  createTitle(text) {
    return new BitmapText(text, {
      fontName: state.options.fontDefaultBold,
      fontSize: 48,
      tint: state.options.colors.text,
    });
  }

  createDescription(text, maxWidth = this.maxWidth) {
    return new Text(text, {
      fontFamily: state.options.fontDefault,
      fontSize: 40,
      fontWeight: 300,
      lineHeight: 48,
      fill: state.options.colors.text,
      wordWrap: true,
      wordWrapWidth: maxWidth,
    });
  }

  createLineSeparator() {
    const line = new Sprite(Texture.WHITE);

    line.width = this.maxWidth;
    line.height = 6;
    line.alpha = 0.1;

    return line;
  }

  createMainSymbolsPaytable() {
    const columnsMargin = 20;
    const rowsMargin = 50;
    const symbolMaxWidth = 225;

    this.mainSymbolsPaytableContainer.removeChildren();

    state.options.config.paytable.forEach((symbol, index) => {
      const symbolContainer = new Container();
      const symbolValue = symbol.symbol;
      const symbolHits = symbol.hits;

      const symbolComponent = new Symbol({ value: symbolValue });
      symbolComponent.container.scale.set(0.75);
      symbolComponent.container.x = symbolMaxWidth / 2;
      symbolContainer.addChild(symbolComponent.container);

      const symbolText = this.createSymbolHitsText(symbolHits);
      symbolText.x = symbolComponent.container.x + symbolMaxWidth / 2;
      symbolText.anchor.set(0, 0.5);
      symbolContainer.addChild(symbolText);

      const symbolTextMaxWidth = this.maxWidth / 2 - columnsMargin - symbolMaxWidth;
      if (symbolText.width > symbolTextMaxWidth) {
        symbolText.scale.set(symbolTextMaxWidth / symbolText.width);
      }

      symbolContainer.x = index % 2 === 0 ? 0 : this.maxWidth / 2;

      if (index < 2) {
        symbolContainer.y = symbolContainer.height / 2;
      } else {
        const containerAbove = this.mainSymbolsPaytableContainer.getChildAt(index - 2);
        symbolContainer.y = containerAbove.y + containerAbove.height / 2 + rowsMargin + symbolContainer.height / 2;
      }

      /* Make sure symbols in row are always aligned */
      if (index % 2 !== 0) {
        const currSymbol = symbolContainer;
        const prevSymbol = this.mainSymbolsPaytableContainer.getChildAt(index - 1);
        const isFirstBigger = currSymbol.height < prevSymbol.height;
        const isSecondBigger = currSymbol.height > prevSymbol.height;
        const difference = Math.abs(currSymbol.height - prevSymbol.height);

        if (isFirstBigger) {
          symbolContainer.y += difference;
        }

        if (isSecondBigger) {
          prevSymbol.y += difference;
        }
      }

      this.mainSymbolsPaytableContainer.addChild(symbolContainer);
    });

    return this.mainSymbolsPaytableContainer;
  }

  createSymbolHitsText(hits) {
    const hitsFormatted = hits.map((hitWin, hitIndex) => ({ hitWin, numOfHits: hitIndex + 1 })).reverse();

    const text = hitsFormatted
      .map((hit) => {
        if (hit.hitWin > 0) {
          return `${hit.numOfHits} - ${gameUtils.getMoneyLabel(hit.hitWin * state.betPerLine, state.options.currencyDisplayEnabled)}`;
        }
        return undefined;
      })
      .filter((hit) => hit !== undefined)
      .join('\n');

    return new BitmapText(text, {
      fontName: state.options.fontDefaultBold,
      fontSize: 36,
      tint: state.options.colors.text,
      align: 'left',
    });
  }

  createSymbolWithDescription(symbol, titleText, descriptionText) {
    const container = new Container();
    const symbolRightMargin = 20;

    const title = this.createTitle(titleText);
    container.addChild(title);

    const symbolComponent = new Symbol({
      value: symbol,
      cashValue: 100 / state.betAmount, // Always show 100
      maxWidth: this.symbolBoxSize,
      multiplierValue: 2,
    });
    symbolComponent.container.scale.set(0.75);
    symbolComponent.container.x = -symbolComponent.container.getLocalBounds().left;
    symbolComponent.container.y = title.height + this.symbolTitleMargin + symbolComponent.container.height / 2;
    container.addChild(symbolComponent.container);

    const descriptionMaxWidth = this.maxWidth - symbolComponent.container.width - symbolRightMargin;
    const description = this.createDescription(descriptionText, descriptionMaxWidth);
    description.anchor.set(0, 0.5);
    description.x = symbolComponent.container.x + symbolComponent.container.getLocalBounds().right + symbolRightMargin;
    container.addChild(description);

    if (symbolComponent.container.height > description.height) {
      description.y = symbolComponent.container.y;
    } else {
      description.y = title.height + this.symbolTitleMargin + description.height / 2;
    }

    return container;
  }

  createJackpotSymbolsWithDescription() {
    const container = new Container();
    const jackpotSymbolsContainer = new Container();

    /* Text */
    const title = this.createTitle(state.options.translations.jackpotSymbols);
    const description = this.createDescription(state.options.translations.cherryBlastJackpotSymbolsDescription);

    /* Symbols */
    state.getJackpotsData().forEach(({ symbolValue }) => {
      const jackpotSymbol = new Symbol({ value: symbolValue });
      jackpotSymbolsContainer.addChild(jackpotSymbol.container);
    });

    /* Set positions */
    jackpotSymbolsContainer.children.forEach((symbol, index) => {
      symbol.scale.set(0.75); // eslint-disable-line no-param-reassign
      symbol.x = (this.maxWidth / 4) * ((index % 3) + 1); // eslint-disable-line no-param-reassign
      symbol.y = symbol.height / 2; // eslint-disable-line no-param-reassign
    });
    jackpotSymbolsContainer.y = title.height + this.symbolTitleMargin;
    description.y = jackpotSymbolsContainer.y + jackpotSymbolsContainer.height;

    container.addChild(title, jackpotSymbolsContainer, description);

    return container;
  }

  createPaylines() {
    const container = new Container();
    const { rows, paylines } = state.options.config;
    const paylineContainersPerRow = 4;
    const paylineContainersInLastRow = paylines.length % paylineContainersPerRow;

    paylines.forEach((payline, paylineIndex) => {
      const paylineContainer = new Container();
      const boxes = new Container();

      let columnsPerRow = paylineContainersPerRow;

      // Change columnsPerRow for last row if row length is less then paylineContainersPerRow
      if ((paylines.length - paylineContainersInLastRow) < (paylineIndex + 1)) {
        columnsPerRow = paylineContainersInLastRow;
      }

      const label = new BitmapText(paylineIndex + 1, {
        fontName: state.options.fontDefaultBold,
        fontSize: 38,
        tint: state.options.colors.text,
      });

      for (let row = 0; row < rows; row++) {
        payline.forEach((highlightIndex, column) => {
          const square = new Sprite(Texture.WHITE);
          const squareSize = 24;
          const margin = 8;

          square.width = squareSize;
          square.height = squareSize;
          square.x = column * squareSize + margin * column;
          square.y = row * squareSize + margin * row;

          if (highlightIndex === row) {
            square.tint = state.options.colors.theme;
          } else {
            square.alpha = 0.1;
          }

          boxes.addChild(square);
        });
      }

      label.x = boxes.width / 2 - label.width / 2;
      boxes.y = label.height;

      paylineContainer.addChild(label);
      paylineContainer.addChild(boxes);

      const spaceLeft = this.maxWidth - paylineContainer.width * columnsPerRow;
      const marginBeetWeenColumns = spaceLeft / (columnsPerRow + 1);
      paylineContainer.x = marginBeetWeenColumns + ((paylineContainer.width + marginBeetWeenColumns) * (paylineIndex % columnsPerRow));

      if (paylineIndex >= columnsPerRow) {
        const containerAbove = container.getChildAt(paylineIndex - columnsPerRow);
        paylineContainer.y = containerAbove.y + containerAbove.height + this.itemsMargin / 2;
      }

      container.addChild(paylineContainer);
    });

    return container;
  }

  scrollToTop() {
    this.scrollBox.scrollToTop();
  }

  setPosition() {
    const dialogHeadHeight = state.components.dialogMenu.getFullHeadHeight();
    const height = state.appHeight - dialogHeadHeight;

    this.scrollBox.setDimensions({ height });
  }
}
