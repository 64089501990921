import { defaultTo, each } from 'lodash';
import { Application, utils, Ticker } from './pixi';
import { Component } from './components/shared/Component';
import { finishLoading, setFullscreen, triggerEvent } from './utility/Utility';

export class App {
  constructor({ canvasId, resizeTo, backgroundColor, backgroundAlpha } = {}) {
    this.canvasId = defaultTo(canvasId, 'app');
    this.canvas = document.getElementById(this.canvasId);
    this.resizeTo = defaultTo(resizeTo, document.documentElement);
    this.backgroundColor = defaultTo(backgroundColor, 0x000000);
    this.backgroundAlpha = defaultTo(backgroundAlpha, 1);
    this.components = {};

    this.app = new Application({
      view: this.canvas,
      resizeTo: this.resizeTo,
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight,
      antialias: false,
      backgroundColor: this.backgroundColor,
      backgroundAlpha: this.backgroundAlpha,
      autoDensity: true,
      resolution: window.devicePixelRatio || 1,
    });

    Ticker.shared.maxFPS = 60;

    if (process.env.NODE_ENV === 'development') {
      // Enables PixiJS Chrome extension
      globalThis.__PIXI_APP__ = this.app; // eslint-disable-line
    }

    this.app.stage.eventMode = 'static';
    this.app.stage.on('tap', setFullscreen);
    this.app.stage.on('pointertap', this.notifyTap);
  }

  get size() {
    return {
      width: this.app.screen.width,
      height: this.app.screen.height,
    };
  }

  get stage() {
    return this.app.stage;
  }

  get view() {
    return this.app.view;
  }

  add(component) {
    if (component instanceof Component) {
      this.app.stage.addChild(component.container);
      this.components[component.name] = component;
    }
  }

  addOnly(component) {
    this.clear();
    this.add(component);

    finishLoading();
  }

  clear() {
    this.app.stage.removeChildren();
  }

  clearCache() {
    utils.clearTextureCache();
  }

  mute(isSoundOff) {
    each(this.components, (component) => {
      if (component.audio) {
        component.audio.mute(isSoundOff);
      }
    });
  }

  notifyTap() {
    triggerEvent('AppPointerTap');
  }
}
