import { Container } from '@/pixi';
import { state } from './State';

export class Hooks {
  #container;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'Hooks';
  }

  get container() {
    return this.#container;
  }

  addHook(hook) {
    this.#container.addChild(hook);
  }

  addCashSymbol(symbol) {
    this.#container.addChild(symbol);
  }

  removeCashSymbol(symbol) {
    this.#container.removeChild(symbol);
  }

  // Position based on reels
  setPosition() {
    const { reelsWithLogo } = state.components.content;
    const scale = reelsWithLogo.container.scale.y;

    this.#container.scale.set(scale);
    this.#container.position = reelsWithLogo.container.position;
  }
}
