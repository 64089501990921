import { Container, BitmapText } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { gameUtils } from './GameUtils';
import { state } from './State';
import { Cash } from './Cash';

export class BonusOutro {
  constructor() {
    this.container = new Container();
    this.container.name = 'BonusOutro';
    this.container.eventMode = 'none';
    this.container.alpha = 0;
    this.container.mask = state.components.root.contentMask;

    this.bonusOutro = new Spine(state.options.assets.bonusOutro.resource.spineData);
    this.backgroundBonusOutro = new Spine(state.options.assets.backgroundBonusOutro.resource.spineData);

    this.autoCloseTimeout = undefined;
    this.autoCloseWait = 5000;

    this.pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 45,
    });

    this.container.addChild(this.backgroundBonusOutro, this.bonusOutro, this.pressAnywhereText);

    this.setListeners();
  }

  setListeners() {
    this.container.on('pointertap', () => this.hide());

    this.bonusOutro.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'bonusOutroStart') {
          this.bonusOutro.state.setAnimation(0, 'bonusOutroIdle', true);
        }
      },
    });

    this.backgroundBonusOutro.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'bonusOutroStart') {
          this.backgroundBonusOutro.state.setAnimation(0, 'bonusOutroIdle', true);
        }
      },
    });
  }

  setAnimation(name, loop = false) {
    this.bonusOutro.state.setAnimation(0, name, loop);
    this.backgroundBonusOutro.state.setAnimation(0, name, false);
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundDimensions = this.backgroundBonusOutro.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    /* Calculate elements scale */
    let backgroundScale = 1;

    if (aspectRatio > backgroundAspectRatio) {
      backgroundScale = state.appHeight / backgroundDimensions.height;
    }

    let contentScale = backgroundScale;

    if (aspectRatio < backgroundAspectRatio) {
      contentScale = state.appHeight / backgroundDimensions.height;
    }

    /* Set Positions */
    this.backgroundBonusOutro.scale.set(backgroundScale);
    this.backgroundBonusOutro.x = state.appWidth / 2 - (backgroundDimensions.width / 2) * backgroundScale;
    this.backgroundBonusOutro.y = state.appHeight / 2 - (backgroundDimensions.height / 2) * backgroundScale;

    this.bonusOutro.scale.set(contentScale);
    this.bonusOutro.x = state.appWidth / 2;
    this.bonusOutro.y = state.appHeight;

    this.pressAnywhereText.scale.set(contentScale);
    this.pressAnywhereText.x = state.appWidth / 2;
    this.pressAnywhereText.y = state.appHeight - this.pressAnywhereText.height;
    this.pressAnywhereText.anchor.set(0.5);

    /* Reapply mask. On every resize content mask is recalculated */
    this.container.mask = state.components.root.contentMask;
  }

  addLabel() {
    const slot = this.bonusOutro.skeleton.findSlot('you_won_label');
    const text = new BitmapText(state.options.translations.youWon.toUpperCase(), {
      fontName: state.options.fontCherryGold,
      fontSize: 75,
    });

    text.scale.set(gameUtils.getTextScaleX(text, slot.attachment.width));
    text.anchor.set(0.5, 0.41);

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text);
  }

  addValue() {
    const slot = this.bonusOutro.skeleton.findSlot('bonus_outro_value_label');
    const text = new Cash({
      cash: state.bonus.winAmount,
      fontSize: 110,
      maxWidth: slot.attachment.width * 0.80,
    });

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text.container);
  }

  show() {
    return new Promise((resolve) => {
      this.addLabel();
      this.addValue();

      this.showTimeline = animate.timeline();

      this.showTimeline.to(this.container, {
        duration: 0.175,
        pixi: {
          alpha: 1,
        },
        onStart: () => {
          this.setAnimation('bonusOutroStart');
          state.playSound('bonusIntroOutroStart');
          state.setDialogOpen(true);
          state.components.header.uiBoost?.hide();
          state.components.footer.hideWinAmount();
        },
        onComplete: () => {
          this.container.eventMode = 'static';
          this.autoCloseTimeout = setTimeout(() => this.hide(), this.autoCloseWait);
          state.reelsWrapper.setReelsLogoPosition();
          resolve(true);
        },
      });

      this.showTimeline.play();
    });
  }

  hide() {
    this.hideTimeline = animate.timeline();

    this.hideTimeline.to(this.container, {
      duration: 0.175,
      pixi: {
        alpha: 0,
      },
      onStart: () => {
        this.container.eventMode = 'none';
        clearTimeout(this.autoCloseTimeout);
        state.setDialogOpen(false);
        state.components.header.uiBoost?.show();

        /* If winGrading is pending controls are enabled after winGrading ends */
        if (!state.bonus.winGradesBonus) {
          state.enableAfterSpin();
        }
      },
      onComplete: () => {
        this.setAnimation('bonusOutroIdle');
        state.stopSound('bonusIntroOutroStart');

        if (state.bonus.winAmount) {
          state.components.winGrading.createWinGradingTimeline(state.bonus.winAmount, state.bonus.winGradesBonus).play();
        }
      },
    });

    this.hideTimeline.play();
  }
}
