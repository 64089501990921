import gsap, { Power2 } from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, BitmapText, Ticker } from '@/pixi';
import { Cash } from './Cash';
import { state } from './State';

export class Symbol {
  #container;
  #symbol;
  #prize;
  #symbolCopy;
  #symbolNumber;
  #cashLevel;
  #collectLevel;
  #symbolBoxSize;
  #isBonusGame;
  #previousZIndex;
  #previousMask;
  #zIndexOverEverything = 60;

  constructor({
    symbolNumber,
    position,
    prize, // Used for cash symbol
    cashLevel, // Used for cash symbol
    multiplier, // Used for wild multiplier symbol
    numOfBonusRounds, // Used for bonus symbol
    collectLevel, // Used for collect symbol in bonus game
    symbolBoxSize,
    isStatic = false,
    isBonusGame = false,
    collectAmount, // Used for collect symbol in round preview
  }) {
    this.#symbolNumber = symbolNumber;
    this.#cashLevel = cashLevel;
    this.#collectLevel = collectLevel;
    this.#symbolBoxSize = symbolBoxSize;
    this.#isBonusGame = isBonusGame;

    this.#container = new Container();
    this.#container.name = 'Symbol';

    this.#symbol = new Spine(this.#symbolAsset.resource.spineData);
    this.#container.addChild(this.#symbol);

    if (prize) {
      this.#addPrizeToSymbol(prize);
    } else if (multiplier) {
      this.#addMultiplierToSymbol(multiplier);
    } else if (numOfBonusRounds) {
      this.#addNumOfBonusRoundsToSymbol(numOfBonusRounds);
    } else if (state.options.symbols.trash === this.#symbolNumber && this.#isBonusGame) {
      this.#removeTextFromTrashSymbol();
    }

    if (position) {
      this.#container.position = position;
    }

    this.#container.visible = isStatic;

    if (isStatic) {
      this.#symbol.state.timeScale = 1000;

      if (state.options.symbols.collect.includes(this.#symbolNumber) && collectAmount > 0) {
        this.#addPrizeToSymbol(collectAmount);
        this.#runCollectOutroAnimation();
      } else {
        this.#setIdleState();
      }
    } else {
      this.#setListeners();
    }
  }

  get #symbolAsset() {
    let name;

    if (state.options.symbols.wild.includes(this.#symbolNumber)) {
      name = 'Wild';
    } else if (this.#isBonusSymbol) {
      name = 'Bonus';
    } else if (state.options.symbols.cash === this.#symbolNumber) {
      name = `Cash${this.#cashLevel}`;
    } else if (state.options.symbols.collect.includes(this.#symbolNumber)) {
      let num = this.#collectLevel;

      // Collect in base game
      if (this.#collectLevel === undefined) {
        // symbolCollect0 asset for base collect, symbolCollect4 asset for extra collect
        num = state.options.symbols.collectBase === this.#symbolNumber ? 0 : 4;
      }

      name = `Collect${num}`;
    } else if (state.options.symbols.trash === this.#symbolNumber) {
      name = 'Trash';
    } else if (state.options.symbols.upgrade === this.#symbolNumber) {
      name = 'Upgrade';
    } else if (state.options.symbols.tnt === this.#symbolNumber) {
      name = 'Tnt';
    } else {
      name = this.#symbolNumber;
    }

    return state.assets[`symbol${name}`];
  }

  get #spinStartAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolSpinStart').duration;
  }

  get #spinStopAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolSpinStop').duration;
  }

  get #winAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolWin').duration;
  }

  // Used for cash symbol
  get #collectAnimationDuration() {
    return this.#symbolCopy.stateData.skeletonData.findAnimation('symbolCollect').duration;
  }

  // Used for collect symbols
  get #collectIntroAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolCollectIntro').duration;
  }

  // Used for collect symbols
  get #collectOutroAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolCollectOutro').duration;
  }

  // Used for collect symbols
  get #collectShineAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('symbolScale').duration;
  }

  get #currentAnimation() {
    return this.#symbol.state.queue.animState.tracks[0]?.animation?.name;
  }

  get #isBonusSymbol() {
    return state.options.symbols.bonus === this.#symbolNumber;
  }

  get container() {
    return this.#container;
  }

  get isDestroyed() {
    return this.#container.destroyed;
  }

  #setListeners() {
    this.#symbol.state.addListener({
      complete: (trackEntry) => {
        const animationName = trackEntry.animation.name;

        if (animationName === 'symbolSpinStart' || (animationName === 'symbolSpinStart2' && this.#isBonusSymbol)) {
          this.#setSpinState();
        } else if (animationName === 'symbolSpinStop') {
          if (this.#isBonusSymbol) {
            this.#runSpinStopToIdle();
          } else {
            this.#setIdleState();
          }
        // Used for collect symbols
        } else if (animationName === 'symbolCollectIntro') {
          this.#setCollectIdleState();
        // Used for bonus symbol
        } else if (['symbolSpinStopToIdle', 'symbolSpinStop2'].includes(animationName)) {
          this.#setIdleState(true);
        }
      },
    });
  }

  // Used for cash and collect symbols
  #addPrizeToSymbol(prize) {
    const numberContainer = this.#symbol.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number');

    const maxWidth = this.#symbolBoxSize - 15; // 15 is padding
    this.#prize = new Cash(prize, maxWidth);

    // Remove default number text, and add custom text
    numberContainer.removeChildren();
    numberContainer.addChild(this.#prize.container);
  }

  // Used for wild multiplier symbol
  #addMultiplierToSymbol(multiplier) {
    const textContainer = this.#symbol.slotContainers.find((container) => container.children[0]?.attachment?.name === 'wild');

    const text = new BitmapText(`x${multiplier}`, {
      fontName: state.options.customFont,
      fontSize: 90,
    });
    text.anchor.set(0.5);
    text.angle = 6.5;
    text.scale.y = -1; // Adjust to textContainer

    // Remove default wild text, and add custom multiplier text
    textContainer.removeChildren();
    textContainer.addChild(text);

    // Hide lightning effect used for default wild text
    this.#symbol.children[10].children[1].visible = false;
  }

  // Used for bonus symbol
  #addNumOfBonusRoundsToSymbol(numOfBonusRounds) {
    const textContainer = this.#symbol.slotContainers.find((container) => container.children[0]?.attachment?.name === 'number');

    const text = new BitmapText(numOfBonusRounds, {
      fontName: state.options.customFont,
      fontSize: 150,
    });
    text.anchor.set(0.5);
    text.angle = 6.5;
    text.scale.y = -1; // Adjust to textContainer

    // Remove default number text, and add custom text
    textContainer.removeChildren();
    textContainer.addChild(text);
  }

  #removeTextFromTrashSymbol() {
    const textContainer = this.#symbol.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Rip');
    textContainer.removeChildren();
  }

  // Used for cash and upgrade symbol
  #createSymbolCopy() {
    this.#symbolCopy = new Spine(this.#symbolAsset.resource.spineData);
    this.#symbolCopy.visible = false;

    // Remove prize on cash symbol
    if (state.options.symbols.cash === this.#symbolNumber) {
      this.#symbolCopy.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number').children[0].destroy();
    }
  }

  #runSpinStartAnimation() {
    let animationName = 'symbolSpinStart';

    // For collect symbol that has prize run symbolSpinStart2
    // For bonus symbol (win case) run symbolSpinStart2
    if ((state.options.symbols.collect.includes(this.#symbolNumber) && this.#prize) || (this.#isBonusSymbol && this.#currentAnimation.includes('symbolWin'))) {
      animationName += '2';
    }

    this.#symbol.state.setAnimation(0, animationName);
  }

  #setSpinState() {
    // Only show spin state for bonus symbol on enter to reels, and leave from reels (if win case)
    if (this.#isBonusSymbol && this.#currentAnimation && this.#currentAnimation !== 'symbolSpinStart2') return;

    // Don't show spin state for cash or multiplier symbol. Because custom text is added on it, and blurred spin state can't have custom text
    if ([state.options.symbols.cash, state.options.symbols.wildMultiplier].includes(this.#symbolNumber)) {
      this.#setIdleState();
    } else {
      let animationName = 'symbolSpin';

      // For trash symbol in bonus game run symbolSpin2
      if (state.options.symbols.trash === this.#symbolNumber && this.#isBonusGame) {
        animationName += '2';
      }

      this.#symbol.state.setAnimation(0, animationName);
    }
  }

  #runSpinStopAnimation() {
    let animationName = 'symbolSpinStop';

    // For bonus symbol in idle state run symbolSpinStop2
    if (this.#isBonusSymbol && this.#currentAnimation === 'symbolIdle') {
      animationName += '2';
    }

    this.#symbol.state.setAnimation(0, animationName);
  }

  #setIdleState(isLoop = false) {
    this.#symbol.state.setAnimation(0, 'symbolIdle', isLoop);
  }

  #runWinAnimation() {
    this.#symbol.state.setAnimation(0, 'symbolWin');

    if (this.#isBonusSymbol) {
      if (!state.isBonusGameIntroScreenOpen) {
        state.playSound('soundBonusSymbolWin');
      }

      // After win animation run win loop animation
      setTimeout(() => {
        this.#runWinLoopAnimation();
      }, this.#winAnimationDuration * 1000);
    }
  }

  // Used for bonus symbol
  #runWinLoopAnimation() {
    this.#symbol.state.setAnimation(0, 'symbolWinLoop', true);

    // After 10sec set win state that will be shown after bonus game
    setTimeout(() => {
      this.#symbol.state.setAnimation(0, 'symbolWinLoop');
    }, 10000);
  }

  // Used for cash symbol
  #runCollectAnimation() {
    this.#symbolCopy.state.setAnimation(0, 'symbolCollect');
    state.playSound('soundCollectCash');
  }

  // Used for collect symbols
  #runCollectIntroAnimation() {
    this.#symbol.state.setAnimation(0, 'symbolCollectIntro');
  }

  // Used for collect symbols
  #setCollectIdleState() {
    this.#symbol.state.setAnimation(0, 'symbolCollectIdle');
  }

  // Used for collect symbols
  #runCollectOutroAnimation() {
    this.#symbol.state.setAnimation(0, 'symbolCollectOutro');
  }

  // Used for collect symbols
  #runCollectShineAnimation() {
    this.#symbol.state.setAnimation(0, 'symbolScale');
    if (!state.isBonusGameIntroScreenOpen) {
      state.playSound('soundCollectShine');
    }
  }

  // Used for bonus symbol
  #runSpinStopToIdle() {
    this.#symbol.state.setAnimation(0, 'symbolSpinStopToIdle');
  }

  // Used for cash symbol
  #followHookMovement(hook) {
    this.#symbolCopy.position = hook.bonePosition;
  }

  destroy() {
    this.#container.destroy({ children: true });
  }

  setZIndex(zIndex = 0) {
    this.#container.zIndex = zIndex;
  }

  setTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: 1,
      },
    }, {
      onStart: () => {
        // If symbolIdle animation is active for bonus symbol then pause it
        if (this.#isBonusSymbol && this.#currentAnimation === 'symbolIdle') {
          this.#symbol.state.timeScale = 0;
        }
      },
      pixi: {
        alpha: 0.5,
      },
      duration: 0.75,
    });
  }

  resetTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: this.#container.alpha,
      },
    }, {
      onStart: () => {
        // If symbolIdle animation is active for bonus symbol then resume it
        if (this.#isBonusSymbol && this.#currentAnimation === 'symbolIdle') {
          this.#symbol.state.timeScale = 1;
        }
      },
      pixi: {
        alpha: 1,
      },
      duration: 0.75,
    });
  }

  createEnterTimeline(positionY) {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        this.#setSpinState();
        this.#container.visible = true;
      },
      duration: 0.2,
      pixi: {
        y: positionY,
      },
      ease: Power2.in,
    });

    timeline.to(this.#container, {
      onStart: () => {
        this.#runSpinStopAnimation();
      },
      duration: this.#spinStopAnimationDuration,
    });

    // For collect symbol in base game run shine animation
    if (state.options.symbols.collect.includes(this.#symbolNumber) && !this.#isBonusGame) {
      timeline.to(this.#container, {
        onStart: () => {
          this.#runCollectShineAnimation();
        },
        duration: this.#collectShineAnimationDuration,
      });
    }

    return timeline;
  }

  createWinTimeline(destroy = true) {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        // Set highest z-index for symbol
        if (this.#isBonusSymbol || state.options.symbols.tnt === this.#symbolNumber) {
          this.setZIndex(this.#container.zIndex * this.#zIndexOverEverything);
        }

        // Remove mask from symbol
        if (state.options.symbols.tnt === this.#symbolNumber) {
          this.#container.mask = null;
        }

        this.#runWinAnimation();
      },
      duration: this.#winAnimationDuration,
      onComplete: () => {
        if (destroy) {
          this.destroy();
        } else if (!this.#isBonusSymbol) {
          this.#setIdleState();
        }
      },
    });

    return timeline;
  }

  createMoveTimeline(positionY) {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        this.#setSpinState();
        this.#container.visible = true;
      },
      duration: 0.25,
      pixi: {
        y: positionY,
      },
      ease: Power2.in,
    });

    timeline.to(this.#container, {
      onStart: () => {
        this.#runSpinStopAnimation();
      },
      duration: this.#spinStopAnimationDuration,
    });

    // For collect symbol in base game run shine animation on first fall to reel
    if (state.options.symbols.collect.includes(this.#symbolNumber) && !this.#isBonusGame && !this.#container.visible) {
      timeline.to(this.#container, {
        onStart: () => {
          this.#runCollectShineAnimation();
        },
        duration: this.#collectShineAnimationDuration,
      });
    }

    return timeline;
  }

  createExitTimeline(positionY) {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        this.#runSpinStartAnimation();
      },
      duration: this.#spinStartAnimationDuration,
    });

    timeline.to(this.#container, {
      duration: 0.2,
      pixi: {
        y: positionY,
      },
      ease: Power2.out,
      onComplete: () => {
        this.destroy();
      },
    });

    return timeline;
  }

  // Used for cash symbol
  createCollectTimeline(hook, isLast) {
    const timeline = gsap.timeline();
    const ticker = new Ticker().add(() => { this.#followHookMovement(hook); });
    const hookStartPosition = { x: hook.container.x, y: hook.container.y };

    if (!this.#symbolCopy) {
      this.#createSymbolCopy();
      state.components.hooks.addCashSymbol(this.#symbolCopy);
    }

    timeline.to(this.#container, {
      onStart: () => {
        this.#runWinAnimation();
      },
      duration: this.#winAnimationDuration,
    });

    timeline.to(this.#container, {
      onStart: () => {
        // Set symbolCopy to start position
        this.#symbolCopy.position = hookStartPosition;
        this.#symbolCopy.visible = true;
        // Start ticker, so cash symbol follows hook
        ticker.start();
        // Run collect for cash symbol
        this.#runCollectAnimation();
        // Run spinStop for new cash symbol
        this.#runSpinStopAnimation();

        // Hide prize if last cash symbol
        if (isLast) {
          this.#prize.container.visible = false;
        }
      },
      duration: this.#collectAnimationDuration,
      onComplete: () => {
        ticker.stop();

        if (isLast) {
          state.components.hooks.removeCashSymbol(this.#symbolCopy);
        // Prepare symbolCopy for new collect
        } else {
          this.#symbolCopy.visible = false;
        }
      },
    });

    return timeline;
  }

  // Used for collect symbols
  createCollectIntroTimeline(amount, amountAnimationDuration, isLast, multiplier) {
    const timeline = gsap.timeline();
    const amountAnimationDelay = 1.25;

    this.#addPrizeToSymbol(0);

    timeline.to(this.#container, {
      onStart: () => {
        // Set zIndex, save old so it can be returned after collectOutro
        this.#previousZIndex = this.#container.zIndex;
        this.setZIndex(this.#zIndexOverEverything);
        // Remove mask, save it so it can be returned after collectOutro
        this.#previousMask = this.#container.mask;
        this.#container.mask = null;
        this.#runCollectIntroAnimation();
      },
      duration: this.#collectIntroAnimationDuration,
    });

    timeline.add(this.#prize.createAmountAnimateTimeline(amount, amountAnimationDuration, amountAnimationDelay, undefined, multiplier));

    // Sounds
    timeline.add(() => {
      state.playSound('soundCollected');

      if (isLast) {
        state.stopSound('soundCollectLoop');
        state.setSoundVolume(this.#isBonusGame ? 'soundBonusAmbient' : 'soundAmbient', 1);
      }
    });

    return timeline;
  }

  // Used for collect symbols
  createCollectOutroTimeline() {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        this.#runCollectOutroAnimation();
      },
      duration: this.#collectOutroAnimationDuration,
      onComplete: () => {
        this.setZIndex(this.#previousZIndex);
        this.#container.mask = this.#previousMask;
      },
    });

    return timeline;
  }

  // Used for upgrade symbol
  createUpgradeTimeline(position) {
    const timeline = gsap.timeline();

    // Create symbol copy that will animate to collect levels in reels header
    this.#createSymbolCopy();
    this.#symbolCopy.position = this.#container.position; // Start position
    this.#symbolCopy.zIndex = this.#zIndexOverEverything;
    this.#container.parent.addChild(this.#symbolCopy);

    timeline.to(this.#symbolCopy, {
      onStart: () => {
        this.#symbolCopy.visible = true;
        // Symbol below
        this.#runSpinStopAnimation();
        this.setTransparency();

        state.playSound('soundUpgradeSymbolHit');
      },
      duration: 0.5,
      pixi: {
        scale: 0.25,
      },
      motionPath: gsap.plugins.motionPath.pointsToSegment([
        this.#container.x, this.#container.y, // Start
        position.x + (this.#container.x - position.x) / 4, position.y + (this.#container.y - position.y) / 2, // Middle
        position.x, position.y, // End
      ], 3),
      ease: Power2.out,
      onComplete: () => {
        this.#container.parent.removeChild(this.#symbolCopy);
      },
    });

    return timeline;
  }
}
