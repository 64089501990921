import { Container } from '@/pixi';
import { Spine } from 'pixi-spine';
import { Controls } from './Controls';
import { state } from './State';

export class Content {
  constructor() {
    this.container = new Container();
    this.controls = new Controls();
    this.controls.container.x = state.options.calcWidth / 2;

    this.initModels();
    this.addComponents();
    this.positionCoins();
    this.container.name = 'Content';
  }

  initModels() {
    this.leftCoins = new Spine(state.options.resources.leftCoin.resource.spineData);
    this.rightCoins = new Spine(state.options.resources.rightCoin.resource.spineData);
  }

  addComponents() {
    this.leftCoins.visible = false;
    this.rightCoins.visible = false;

    this.container.addChild(
      this.controls.container,
      this.leftCoins,
      this.rightCoins,
    );
  }

  runCoinsAnimation(position) {
    this[`${position}Coins`].visible = true;
    this[`${position}Coins`].state.setAnimation(0, 'collect', false);
    this[`${position}Coins`].state.addListener({
      complete: () => {
        this[`${position}Coins`].visible = false;
        state.setBalanceAmountAfterSpin();
      },
    });
  }

  positionCoins() {
    this.leftCoins.x += this.leftCoins.width / 2;
    this.leftCoins.y += this.leftCoins.height / 2;
    this.rightCoins.x += this.rightCoins.width / 2;
    this.rightCoins.y += this.rightCoins.height / 2;
  }

  setPosition() {
    const footerHeightWithPadding = state.appHeight - state.footer.container.getLocalBounds().top;
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAspectRatio = state.originalSize.height / state.originalSize.width;

    if (aspectRatio < backgroundAspectRatio) {
      const scaleFactor = 0.8;

      this.controls.container.scale.set(scaleFactor, scaleFactor);
      this.controls.container.y = state.appHeight - footerHeightWithPadding * 1.5;
    } else {
      this.controls.container.y = state.appHeight - footerHeightWithPadding - (state.options.uiPadding * 6);
    }
  }
}
