/* eslint-disable max-len */
export default {
  aboutTheGameTitle: 'About the Game',
  balance: 'Balance',
  bet: 'Bet',
  bonusBuy: 'Bonus buy',
  bonusBuyDescription: 'A Bonus Buy lets you buy your way into the bonus round by pressing the Store button. Once you\'ve chosen an option, the dialog closes and the next spin triggers the bonus game.',
  cashLink: 'Cash link',
  cashLinkBonusGame: 'Cash link bonus game',
  cashSymbol: 'Cash Symbol',
  cashSymbolDescription: 'Cash symbol provides a bet multiplier worth ${minDistributionValue}x - ${maxDistributionValue}x your stake.',
  cherryBlastCashLinkDesctiption: 'Land at least four cash or jackpot symbols on the reels to trigger the Cash Link Bonus game. Three respins are granted and when you land either cash, jackpot or multiplier symbol, number of respins resets back to three. Within the Cash Link Bonus game, there are three distinct jackpots with each jackpot offering its own unique prize. This bonus game includes a global multiplier, x2, which activates when all spots on the reels are populated. Global multiplier applies to all jackpots which landed on the reel as well. The collect phase continues until you get three non-winning spins in a row.',
  cherryBlastFreeSpinsDescription: 'Score big with free spins! Land at least three bonus symbols to activate the bonus game with Sticky wild feature for every spin! Land three bonus symbols to activate Sticky Spin delight with 10 free spins with Sticky wild feature offering chances for enormous wins. Land four bonus symbols to activate the Mega Sticky Spins with 15 free spins with Sticky wild feature offering enhanced opportunities for substantial wins. Besides more free spins, extra wilds have been added to the reels.',
  cherryBlastGameInfo: 'Cherry Blast is a 4x3 classic slot game which offers an engaging experience with an RTP of ${rtp}%. Featuring a Wild and Sticky wild features, it adds an extra layer of excitement to the gameplay. Landing at least three bonus symbols triggers the free spins bonus game. During the free spins wild symbols get sticky. Additionally, four cash or jackpots symbols on the reels trigger the Cash Link Bonus game which features jackpots, enhancing the excitement and potential rewards for players.',
  cherryBlastJackpotSymbolsDescription: 'Appearance of the following jackpot symbols during the Cash Link Bonus Game triggers the corresponding jackpot.',
  cherryBlastMultiplierSymbolDescription: 'It multiplies all cash symbols with the multiplier 2 during the Cash Link Bonus game when it lands.',
  cherryBlastOnboardStickyCherryDescription: 'Sticky golden cherry',
  cherryBlastStoreDescription: 'To buy the bonus rounds, you have to wager at least 100x your regular bet. Buy the Sticky Spin delight to activate 10 free rounds with sticky wilds. The theoretical RTP of the feature is equal to ${rtp1}%. Buy the Mega Sticky Spins to activate 15 free rounds with sticky wilds. The theoretical RTP of the feature is equal to ${rtp2}%.Buy the Cherry Harvesting to activate Cash Link Bonus game which gives you an exciting opportunity to win the jackpots. The theoretical RTP of the feature is equal to ${rtp3}%.',
  demoLabel: 'Demo',
  dialogAutoplayTitle: 'Autoplay',
  dialogBetAmountTitle: 'Bet Amount',
  dialogMenuGameInfoTitle: 'Game Info',
  dialogMenuHistoryTitle: 'History',
  dialogMenuPaytableTitle: 'Paytable',
  dialogSettingsLightningLabel: 'Lightning spin',
  dialogSettingsSoundLabel: 'Sound',
  dialogSettingsTitle: 'Settings',
  dialogStoreTitle: 'Store',
  fillPositions: 'Fill positions',
  freeSpins: 'Free Spins',
  historyBetLabel: 'Bet',
  historyCurrencyLabel: 'Currency',
  historyDemoModeMessage: 'Tickets history not available in Demo mode.',
  historyNoTicketsMessage: 'There is no tickets for selected filter. Try different filters.',
  historyRound: 'Round',
  historySelectModalAccept: 'Accept',
  historySelectModalCancel: 'Cancel',
  historySelectModalDateRange: 'Date range',
  historySelectToday: 'Today',
  historySelectYesterday: 'Yesterday',
  historySummaryLabel: 'Summary',
  historyTimeLabel: 'Time',
  historyWinLabel: 'Win',
  holdForLightningMode: 'Hold for lightning',
  howToPlayDescription: 'Select a bet size, then press the Spin button to begin. To use autoplay, press the Autoplay button and select the desired number of rounds. You can stop autoplay mode anytime by clicking the stop button. You can control the spin speed by selecting one of the predefined spin options:\n- Normal spin: This is the default mode.\n- Lighting spin: In the first spin symbols just fall off on the reel, without any lazy animations. You can activate this option in the settings.\n- Lighting mode: To activate this mode press the spin button for 1 second. The lighting spin is activated, and an autoplay with an infinite number of spins is started. You can always stop this mode by clicking the stop button.',
  howToPlayTitle: 'How to play',
  jackpotSymbols: 'Jackpot symbols',
  multiplierSymbol: 'Multiplier Symbol',
  paylines: 'Paylines',
  pressAnywhereToContinue: 'Press anywhere to continue',
  reload: 'Reload',
  reloadGameToPlay: 'Reload game to play',
  stickyWildNoMultiplierSymbolDescription: 'Wild symbol replaces any symbol except Bonus symbol. It becomes sticky and doesn\'t change the position until free spins are finished.',
  wildFeature: 'Wild feature',
  wildFeatureDescription: 'Wild substitutes every symbol except Bonus symbols. Wilds can show up on all reels, linking them would make big wins.',
  wildStickySymbol: 'Wild Sticky Symbol',
  win: 'Win',
  xOrMoreTriggerFreeSpinsLabel: '${hits} or more trigger free spins bonus game',
  xOrMoreTriggerRespinLabel: '${hits} or more trigger respin bonus game',
  youWon: 'You Won',
  /* Error translations */
  actionError: 'Action error',
  actionSuccess: 'Action success',
  activePromotionError: 'Promotion is currently active',
  closeGame: 'Game can not be continued. Please close game.',
  dbReadError: 'Database error',
  dbSaveError: 'Database error',
  expiredPlayerTokenError: 'Expired player token',
  featureNotAvailableError: 'Feature not available',
  fieldNotFoundError: 'Round validation error',
  gameConfigNotFoundError: 'Invalid game configuration',
  gameMultipeInstancesError: 'Invalid parameters',
  gameNotFoundError: 'Game not found',
  insufficientFundsError: 'Insufficient funds',
  integrationRequestError: 'Integration error',
  integrationRequestServiceError: 'Integration error',
  integrationRequestTimeoutError: 'Integration error',
  integrationRequestUrlError: 'Integration error',
  integrationResponseError: 'Integration error',
  invalidDateRANGE: 'Invalid date range',
  invalidGambleGAME: 'Invalid gamble parameters',
  invalidGameError: 'Invalid game parameters',
  invalidInputError: 'Invalid input',
  invalidJackpotQueue: 'Jackpot error',
  invalidParamError: 'Invalid parameters',
  invalidPlayerError: 'Invalid player',
  invalidPlayerTokenError: 'Invalid player token',
  invalidRoundError: 'Invalid round',
  invalidSignatureError: 'Invalid signature',
  invalidStateError: 'An error happened',
  invalidTransactionCurrencyError: 'Transaction error',
  invalidTransactionError: 'Transaction error',
  invalidTransactionTypeError: 'Transaction error',
  jackpotGamesNotFoundError: 'Jackpot error',
  jackpotNotFoundError: 'Jackpot error',
  notImplementedError: 'Feature not implemented',
  openBonusError: 'Bonus game is currently open',
  openGambleError: 'Gamble game is currently open',
  partnerDeactivatedError: 'Game is deactivated',
  partnerSetupError: 'Invalid setup',
  platformUnavailableError: 'Platform unavailable',
  roundNotFoundError: 'Round not found',
  roundStartGambleError: 'Gamble error',
  tenantCurrencyUnsupportedError: 'Unsupported currency',
  tenantDeactivatedError: 'Game or client is deactivated',
  tenantGamesNotFoundError: 'Invalid parameters',
  tenantMismatchError: 'Invalid parameters',
  tenantNotFoundError: 'Invalid parameters',
  transactionErrorError: 'Transaction error',
  transactionInProgressError: 'Existing transaction is in progress',
  transactionNotFoundError: 'Transaction not found',
};
