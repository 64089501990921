import { DialogContentAutoplay } from '@/components/shared/DialogContentAutoplay';
import { Dialog } from './Dialog';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { state } from '../State';

export class DialogAutoplay extends Dialog {
  constructor() {
    const content = new DialogContentAutoplay({
      padding: state.options.uiDialogPadding,
      fontName: state.options.fontNameNormal,
      color: state.options.colors.text,
      highlightColor: state.options.colors.theme,
      stopLabelText: state.options.translations.stopOnAnyWin,
      lossLimitLabelText: state.options.translations.lossLimit,
      winLimitLabelText: state.options.translations.winLimit,
      startButtonText: state.options.translations.start,
      formatValue: (value) => state.getMoneyLabel(value, state.options.currencyDisplayEnabled),
      getBetAmount: () => state.betAmount,
      onStart: (selectedSpinValue, stopOnAnyWin, lossLimit, winLimit) => {
        state.startAutoplay(selectedSpinValue, stopOnAnyWin, lossLimit, winLimit);
      },
      buttonParams: Button.getDefaultParams(),
      checkboxParams: Checkbox.getDefaultParams(),
    });

    super({
      title: state.options.translations.autoplay,
      content,
      isFluid: true,
    });

    this.container.name = 'DialogAutoplay';
  }
}
