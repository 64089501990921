import gsap from 'gsap';
import { Container, BitmapText } from '@/pixi';
import { state } from './State';

export class Cash {
  #container;
  #cashContainer;
  #cash;
  #currency;
  #multiplier;
  #maxWidth;

  constructor(cash, maxWidth, fontSize = 60, showCurrency = false) {
    this.#maxWidth = maxWidth;

    this.#container = new Container();
    this.#container.name = 'Cash';

    this.#cashContainer = new Container();
    this.#cash = new BitmapText(state.getMoneyLabel(cash), {
      fontName: state.options.customFont,
      fontSize,
    });
    this.#cash.anchor.set(0.5);
    this.#cashContainer.addChild(this.#cash);
    this.#container.addChild(this.#cashContainer);

    this.#setCashScale();

    if (showCurrency || state.options.currencyDisplayEnabled) {
      this.#currency = new BitmapText(state.options.currency, {
        fontName: state.options.customFont,
        fontSize: this.#cash.fontSize / 2,
      });
      this.#currency.anchor.set(0.5, 0.15);
      this.#currency.y = this.#cash.height / 2;
      this.#container.addChild(this.#currency);
    }

    this.#container.pivot.y = this.#container.height / 2 - this.#cash.height / 2;
    this.#container.angle = 6.5;
    this.#container.scale.y = -1; // Adjust to spine default amount element
  }

  get container() {
    return this.#container;
  }

  #setCashScale() {
    if (this.#cash.width > this.#maxWidth) {
      this.#cash.scale.set(this.#maxWidth / (this.#cash.width / this.#cash.scale.y));
    }
  }

  setCash(amount, updateCurrencyPosition = true) {
    this.#cash.text = state.getMoneyLabel(amount);
    this.#setCashScale();

    if (updateCurrencyPosition && this.#currency) {
      this.#currency.y = this.#cash.height / 2;
    }
  }

  resetScale() {
    this.#cash.scale.set(1);
  }

  // eslint-disable-next-line default-param-last
  createAmountAnimateTimeline(amount, duration, delay = 0, amountFrom = this.#cash.text, multiplier) {
    const timeline = gsap.timeline();
    const amountAnimate = { amount: amountFrom };
    const isMultiplier = multiplier > 1;

    timeline.to(amountAnimate, {
      amount: isMultiplier ? amount / multiplier : amount,
      delay,
      duration,
      onUpdate: () => {
        this.setCash(amountAnimate.amount, false);
      },
    });

    if (isMultiplier) {
      const multiplierDelay = 0.2;

      this.#multiplier = new BitmapText(`x${multiplier}`, {
        fontName: state.options.customFont2,
        fontSize: this.#cash.fontSize * 1.5,
      });
      this.#multiplier.anchor.set(0.5);
      this.#multiplier.position = this.#cash.position;

      // Scale 1
      // Rotation 1
      timeline.fromTo(this.#multiplier, {
        pixi: {
          scale: 0,
          rotation: -25,
        },
      }, {
        pixi: {
          scale: 1.2,
          rotation: 0,
        },
        onStart: () => {
          this.#container.addChild(this.#multiplier);
        },
        duration: 0.233,
      }, `start+=${multiplierDelay}`);

      // Scale 2
      timeline.fromTo(this.#multiplier, {
        pixi: {
          scale: 1.2,
        },
      }, {
        pixi: {
          scale: 1,
        },
        delay: 0.233,
        duration: 0.067,
      }, `start+=${multiplierDelay}`);

      // Rotation 2
      timeline.fromTo(this.#multiplier, {
        pixi: {
          rotation: 0,
        },
      }, {
        pixi: {
          rotation: 1,
        },
        delay: 0.233,
        duration: 0.176,
      }, `start+=${multiplierDelay}`);

      // Rotation 3
      timeline.fromTo(this.#multiplier, {
        pixi: {
          rotation: 10,
        },
      }, {
        pixi: {
          rotation: -8,
        },
        duration: 0.124,
      });

      // Rotation 4
      timeline.fromTo(this.#multiplier, {
        pixi: {
          rotation: -8,
        },
      }, {
        pixi: {
          rotation: 6,
        },
        duration: 0.096,
      });

      // Rotation 5
      timeline.fromTo(this.#multiplier, {
        pixi: {
          rotation: 6,
        },
      }, {
        pixi: {
          rotation: -9,
        },
        duration: 0.087,
      });

      // Rotation 6
      timeline.fromTo(this.#multiplier, {
        pixi: {
          rotation: -9,
        },
      }, {
        pixi: {
          rotation: 4,
        },
        duration: 0.067,
      });

      // Scale 3
      timeline.fromTo(this.#multiplier, {
        pixi: {
          scale: 1,
        },
      }, {
        pixi: {
          scale: 0,
        },
        duration: 0.117,
      });

      // Cash scale 1
      timeline.fromTo(this.#cashContainer, {
        pixi: {
          scale: 1,
        },
      }, {
        pixi: {
          scale: 1.4,
        },
        onStart: () => {
          this.setCash(amount, false);
        },
        duration: 0.1,
      }, '>-0.05');

      // Cash scale 2
      timeline.to(this.#cashContainer, {
        pixi: {
          scale: 1,
        },
        duration: 0.067,
      });
    }

    return timeline;
  }
}
