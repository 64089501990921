import animate, { Power2 } from 'gsap';
import { defaultTo, isFunction } from 'lodash';
import { Container, Sprite, BitmapText, Texture, Rectangle } from '@/pixi';

export class Dialog {
  constructor({
    title,
    content,
    isFluid = false,
    isCenter = false,
    isClosable = true,
    isWarning = false,
    isTransparent = false,
    onShow,
    onClose,
    onBeforeClose,
    backgroundImageAsset,
    iconCloseAsset,
    getAppWidth,
    getAppHeight,
    playTapSound,
    padding = 48,
    labelFontColor = 0xdfe0e5,
    fontName = 'RobotoNormal',
  }) {
    this.title = title;
    this.content = content;
    this.isFluid = isFluid;
    this.isCenter = isCenter;
    this.isWarning = isWarning;
    this.isTransparent = isTransparent;
    this.onBeforeClose = onBeforeClose;
    this.isClosable = isClosable;
    this.onShow = onShow;
    this.onClose = onClose;
    this.onBeforeClose = onBeforeClose;
    this.backgroundImageAsset = backgroundImageAsset;
    this.iconCloseAsset = iconCloseAsset;
    this.getAppWidth = getAppWidth;
    this.getAppHeight = getAppHeight;
    this.playTapSound = playTapSound;
    this.padding = padding;
    this.labelFontColor = labelFontColor;
    this.fontName = fontName;

    this.container = new Container();
    this.header = new Container();
    this.body = new Container();
    this.overlay = new Sprite(Texture.WHITE);
    this.background = undefined;
    this.headerLine = this.createLine();
    this.close = undefined;
    this.titleLabel = undefined;
    this.iconClose = undefined;
    this.titleFontSize = 54;
    this.width = 0;
    this.height = 0;
    this.y = 0;
    this.x = 0;
    this.timeline = animate.timeline();
    this.scaleFrom = 0.5;
    this.alphaTo = 1;
    this.showDuration = 0.25;
    this.hideDuration = 0.25;

    this.setup();
    this.setActions();
  }

  createLine(height, alpha, color) {
    const line = new Sprite(Texture.WHITE);

    line.height = defaultTo(height, 2);
    line.alpha = defaultTo(alpha, 0.1);

    if (color) {
      line.tint = color;
    }

    return line;
  }

  getFullHeadHeight() {
    let height = this.content.container.y;

    if (this.content.tabs) {
      height += this.content.tabs.tabNavigation.height;
    }

    return height;
  }

  get isFullscreen() {
    return !this.isFluid && !this.isCenter;
  }

  setup() {
    this.overlay.tint = 0x000000;
    this.overlay.eventMode = 'static';
    this.container.addChild(this.overlay);

    if (this.backgroundImageAsset) {
      this.background = new Sprite(this.backgroundImageAsset.resource);
    } else {
      this.background = new Sprite(Texture.WHITE);
      this.background.tint = 0x000000;
      this.background.alpha = this.isTransparent ? 0 : 1;
    }

    this.background.eventMode = 'static';
    this.body.addChild(this.background);

    this.container.visible = false;
    this.container.x = 0;
    this.container.y = 0;

    this.header.y = this.padding;

    if (this.title) {
      this.titleLabel = new BitmapText(this.title, {
        fontName: this.fontName,
        fontSize: this.titleFontSize,
        tint: this.labelFontColor,
      });

      this.titleLabel.anchor.set(0.5, 0);
      this.header.addChild(this.titleLabel);
      this.header.addChild(this.headerLine);
    }

    if (this.content) {
      this.content.container.y = this.titleLabel ? this.titleLabel.height + (this.padding * 2) : 0;
      this.body.addChild(this.content.container);
    }

    if (this.isClosable && this.iconCloseAsset) {
      this.iconClose = new Sprite(this.iconCloseAsset.resource);
      this.iconClose.anchor.set(1, 0);
      this.iconClose.y = this.padding + this.iconClose.height * 0.15;
      this.body.addChild(this.iconClose);
    }

    this.body.addChild(this.header);
    this.container.addChild(this.body);
  }

  setActions() {
    if (this.iconClose) {
      const { width, height } = this.iconClose;
      const extraSpace = 35;

      this.iconClose.eventMode = 'static';
      this.iconClose.cursor = 'pointer';
      this.iconClose.hitArea = new Rectangle(-width - extraSpace, -extraSpace, width + extraSpace * 2, height + extraSpace * 2);
      this.iconClose.on('pointertap', () => {
        this.playTapSound?.();
        this.hide();
      });
    }

    this.overlay.on('pointertap', () => {
      this.playTapSound?.();
      this.hide();
    });
  }

  setPosition() {
    const height = this.getAppHeight();
    const width = this.getAppWidth();

    this.background.width = this.isCenter ? width * 0.8 : width;
    this.background.height = this.isCenter ? height * 0.6 : height;

    this.overlay.width = width;
    this.overlay.height = height;

    if (this.titleLabel) {
      this.titleLabel.x = this.background.width / 2;
      this.headerLine.y = this.titleLabel.height + this.padding;
      this.headerLine.width = width;
    }

    if (this.iconClose) {
      this.iconClose.x = this.background.width - this.padding;
    }

    if (this.content) {
      this.content.setPosition({
        width: this.background.width,
        height: this.background.height,
      });
    }

    this.width = width;
    this.height = height;
    this.setHeight();
  }

  setHeight() {
    if (this.isFluid && this.content) {
      this.background.height = this.content.container.y + this.content.container.getLocalBounds().y + this.content.container.height + this.padding;
    }

    this.y = this.isCenter ? (this.height - this.background.height) / 2 : this.height - this.background.height;
    this.x = this.isCenter ? (this.width - this.background.width) / 2 : 0;

    this.body.y = this.y;
    this.body.x = this.x;
  }

  show(data) {
    this.setHeight();
    this.timeline.clear();

    const that = this;
    let originX = this.background.width / 4;
    let originY = this.y > 0 ? (this.height) : (this.background.height / 4);

    if (this.isCenter) {
      originX = (this.width / 2) - (this.background.width / 4);
      originY = (this.height / 2) - (this.background.height / 4);
    }

    this.timeline.fromTo(this.body, {
      pixi: {
        alpha: 0,
        scale: this.scaleFrom,
        x: originX,
        y: originY,
      },
    }, {
      duration: this.showDuration,
      ease: Power2.easeOut,
      pixi: {
        alpha: this.alphaTo,
        scale: 1,
        x: this.x,
        y: this.y,
      },
      onStart() {
        that.onShow?.();

        if (that.content && that.content.show) {
          that.content.dialog = that;
          that.content.show(data);
        }

        that.container.visible = true;
      },
    }, '<');

    this.timeline.fromTo(this.overlay, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.showDuration,
      ease: Power2.easeOut,
      pixi: {
        alpha: 0.25,
      },
    }, '<');
  }

  hide() {
    this.timeline.clear();

    const that = this;
    let targetX = this.background.width / 4;
    let targetY = this.y > 0 ? (this.height) : (this.background.height / 4);

    if (this.isCenter) {
      targetX = (this.width / 2) - (this.background.width / 4);
      targetY = (this.height / 2) - (this.background.height / 4);
    }

    this.timeline.to(this.body, {
      duration: this.hideDuration,
      ease: Power2.easeIn,
      pixi: {
        alpha: 0,
        scale: this.scaleFrom,
        x: targetX,
        y: targetY,
      },
      onStart: () => {
        if (isFunction(that.onBeforeClose)) {
          that.onBeforeClose();
        }
      },
      onComplete: () => {
        that.container.visible = false;

        if (isFunction(that.onClose)) {
          that.onClose();
        }
      },
    }, '<');

    this.timeline.to(this.overlay, {
      duration: this.hideDuration,
      ease: Power2.easeIn,
      pixi: {
        alpha: 0,
      },
    }, '<');
  }

  updateTitle(title) {
    this.titleLabel.text = title;
  }
}
