import * as api from '@/api/casino';
import { state } from './State';

class GameUtils {
  getFontSize(configuration) {
    const { textLength, maxLength, baseSize, minSize } = configuration;
    let newSize;

    if (textLength > maxLength) {
      newSize = minSize;
    } else {
      const sizeReduction = (textLength / maxLength) * (baseSize - minSize);
      newSize = baseSize - sizeReduction;
    }

    return newSize;
  }

  async makeApiRequest(action, payload, withLoaderOverlay = false, loaderTimeoutDuration = 2000) {
    const loaderTimeout = withLoaderOverlay
      ? setTimeout(() => state.loaderOverlay.show(), loaderTimeoutDuration)
      : undefined;

    const response = await api[action](...payload);

    if (loaderTimeout) {
      clearTimeout(loaderTimeout);
      state.loaderOverlay.hide();
    }

    return response;
  }
}

export const gameUtils = new GameUtils();
