import animate from 'gsap';
import { isArray, isNil } from 'lodash';
import { Container, AnimatedSprite, Sprite, Circle } from '@/pixi';
import * as api from '@/api/casino';
import { SlotSpinSpeedType } from '@/models';
import { registerEventListener, triggerEvent, triggerAnalyticsEvent, getAspectRatio, sleep } from '@/utility/Utility';
import { toJS } from 'mobx';
import { SlotNumber } from './SlotNumber';
import { SlotLabel } from './SlotLabel';
import { SlotReelsLoader } from './SlotReelsLoader';
import { Tooltip } from './shared/Tooltip';
import { slotState } from './SlotState';
import { audio } from './SlotAudio';

export class SlotControlsMain {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.uiSpin = undefined;
    this.uiSpinCounter = undefined;
    this.uiDemoLabel = undefined;
    this.lightningSpinLabel = undefined;
    this.uiBuy = undefined;
    this.uiAutoplay = undefined;
    this.uiAutoplayStop = undefined;
    this.reelsLoader = undefined;
    this.uiScale = 1;
    this.spinNumber = 1;
    this.previousSpinSpeedType = undefined;

    this.isLightning = false;
    this.isSpinning = false;
    this.isStoppable = false;
    this.autoplaySpinCount = 0;
    this.autoplaySpinStartWait = 100;
    this.buySpinWait = 1000;
    this.result = undefined;
    this.bonusGameWait = 1000;
    this.reelsLoaderTimeout = 2500;

    this.setup();
    this.setActions();
    this.setListeners();
  }

  get autoplaySpinWait() {
    return this.isLightning ? 150 : 350;
  }

  get isBuyEnabled() {
    return slotState.isBonusBuyEnabled || slotState.isProgressBuyEnabled || slotState.isCollectBuyEnabled;
  }

  get isUiBuyVisible() {
    return slotState.options.settings.bonusBuyEnabled !== false
    || slotState.options.settings.progressBuyEnabled !== false
    || slotState.options.settings.collectBuyEnabled !== false;
  }

  get isBonusFreeRoundsEnd() {
    return slotState.lastRound?.isFree && !slotState.availableFreeRounds && !slotState.bonus?.isWon && !slotState.lastRound?.isProgressWon && !slotState.lastRound?.isPromotion;
  }

  setup() {
    const { uiSpin, uiBuy, uiAutoplay, uiAutoplayStop } = this.options.assets;

    this.uiSpin = isArray(uiSpin.resource) ? new AnimatedSprite(uiSpin.resource) : new Sprite(uiSpin.resource);
    this.uiSpin.anchor.set(0.5);
    this.uiSpin.x = this.options.calcWidth / 2;
    this.container.addChild(this.uiSpin);

    this.uiSpinCounter = new SlotNumber(this.autoplaySpinCount, undefined, 0);
    this.uiSpinCounter.container.visible = false;
    this.container.addChild(this.uiSpinCounter.container);

    this.uiDemoLabel = new SlotLabel({ text: this.options.translations.demoLabel });
    this.uiDemoLabel.container.x = this.uiSpin.x - (this.uiDemoLabel.container.width / 2);
    this.container.addChild(this.uiDemoLabel.container);
    this.uiDemoLabel.setVisible(slotState.options.isDemo);

    this.lightningSpinLabel = new SlotLabel({ text: this.options.translations.holdForLightningLabel });
    this.lightningSpinLabel.container.x = this.uiSpin.x - (this.lightningSpinLabel.container.width / 2);
    this.container.addChild(this.lightningSpinLabel.container);
    this.lightningSpinLabel.setVisible(false);

    this.uiBuy = isArray(uiBuy.resource) ? new AnimatedSprite(uiBuy.resource) : new Sprite(uiBuy.resource);
    this.uiBuy.visible = this.isUiBuyVisible;
    this.container.addChild(this.uiBuy);

    this.uiAutoplay = isArray(uiAutoplay.resource) ? new AnimatedSprite(uiAutoplay.resource) : new Sprite(uiAutoplay.resource);
    this.uiAutoplay.visible = this.options.autoPlayEnabled;
    this.container.addChild(this.uiAutoplay);

    this.uiAutoplayStop = isArray(uiAutoplayStop.resource) ? new AnimatedSprite(uiAutoplayStop.resource) : new Sprite(uiAutoplayStop.resource);
    this.container.addChild(this.uiAutoplayStop);

    this.reelsLoader = new SlotReelsLoader();
  }

  setActions() {
    this.uiSpin.alpha = slotState.isInFreeRounds ? 0.5 : 1;
    this.uiSpin.eventMode = slotState.isInFreeRounds ? 'none' : 'static';
    this.uiSpin.cursor = 'pointer';

    const timeline = animate.timeline();
    let allowPointerTap = true;

    this.uiSpin.on('pointerdown', () => {
      allowPointerTap = true;

      if (!this.isSpinning) {
        timeline.to(this.uiSpin, {
          delay: 0.1,
          duration: 0.667,
          pixi: {
            scale: this.uiScale * 1.17,
          },
        }).to(this.uiSpin, {
          duration: 0.167,
          pixi: {
            scale: this.uiScale * 0.91,
          },
        }).to(this.uiSpin, {
          duration: 0.167,
          pixi: {
            scale: this.uiScale,
            alpha: 0.5,
          },
          onComplete: () => {
            allowPointerTap = false;
            this.previousSpinSpeedType = this.options.spinSpeedType;
            slotState.setSpinSpeedType(SlotSpinSpeedType.Lightning);
            triggerEvent('ToggleAutoplay', {
              count: 10000, // Set infinite autoplay
            });
          },
        });
      }
    });

    this.uiSpin.on('pointertap', async () => {
      if (allowPointerTap) {
        // Reset spin button
        timeline.clear();
        this.uiSpin.scale.set(this.uiScale);
        this.uiSpin.alpha = 1;

        await this.spin(true);
      }
    });

    // Cancel hold
    this.uiSpin.on('pointerupoutside', () => {
      // Reset spin button
      timeline.clear();
      this.uiSpin.scale.set(this.uiScale);
      this.uiSpin.alpha = 1;
    });

    document.body.onkeyup = async (event) => {
      if (event.code === 'Space' && slotState.isSpacebarEnabled && this.uiSpin.eventMode === 'static' && !slotState.onboardScreen) {
        await this.spin(true);
      }
    };

    this.uiAutoplay.alpha = this.uiSpin.alpha;
    this.uiAutoplay.eventMode = this.uiSpin.eventMode;
    this.uiAutoplay.cursor = this.uiSpin.cursor;
    this.uiAutoplay.on('pointertap', this.showAutoplayDialog.bind(this));

    this.uiAutoplayStop.eventMode = 'static';
    this.uiAutoplayStop.cursor = 'pointer';
    this.uiAutoplayStop.visible = false;
    this.uiAutoplayStop.on('pointertap', this.stopAutoplayManually.bind(this));

    this.uiBuy.eventMode = 'static';
    this.uiBuy.cursor = this.isBuyEnabled ? 'pointer' : null;
    this.uiBuy.alpha = this.isBuyEnabled ? 1 : 0.5;
    this.uiBuy.on('pointertap', () => {
      if (this.uiBuy.cursor) {
        this.showBuyDialog();
      }
    });
  }

  setPosition() {
    const appSize = this.options.size();
    const scale = slotState.controls.scale.y;
    const height = appSize.height / scale;
    const spinAreaCenter = (height - slotState.content.getBottomPosition()) / 2;

    if (this.options.isTablet) {
      this.uiScale = 0.8;
    } else if (getAspectRatio().height < this.options.smallDeviceHeightLimit) {
      this.uiScale = 0.8;
    } else {
      this.uiScale = 1;
    }

    this.uiSpin.y = Math.floor(height - spinAreaCenter);
    this.uiSpin.scale.set(this.uiScale);
    this.uiSpinCounter.scale(this.uiScale / 2);
    this.uiSpinCounter.container.x = this.uiSpin.x - (this.uiSpinCounter.container.width / 2);
    this.uiSpinCounter.container.y = this.uiSpin.y - (this.uiSpinCounter.container.height / 2);

    this.uiSpin.y = Math.floor(this.uiSpin.y - slotState.controls.controlsFooter.uiBetIcon.height);
    this.uiSpinCounter.container.y = this.uiSpin.y - (this.uiSpinCounter.container.height / 2);
    this.uiDemoLabel.container.y = this.uiSpin.y + (this.uiSpin.height / 2) - (this.uiDemoLabel.container.height);
    this.uiSpin.hitArea = new Circle(0, 0, this.uiSpin.width / 2);

    if (this.lightningSpinLabel) {
      this.lightningSpinLabel.container.y = this.uiSpin.y + (this.uiSpin.height / 2) - (this.lightningSpinLabel.container.height);
    }

    this.uiBuy.scale.set(this.uiScale);
    this.uiBuy.x = this.uiSpin.x - (this.uiSpin.width / 2) - (this.uiBuy.width / 2) - (this.options.uiPadding * 2);
    this.uiBuy.y = this.uiSpin.y;
    this.uiBuy.anchor.set(0.5);
    this.uiBuy.hitArea = new Circle(0, 0, this.uiBuy.width / 2);

    if (this.options.isDemo) {
      // eslint-disable-next-line no-new
      new Tooltip({
        element: this.uiBuy,
        parentContainer: this.container,
        text: this.options.translations.demoStoreTooltip,
        position: 'top-start',
        offset: 20,
        name: 'uiBuyDemo',
      });
    }

    this.uiAutoplay.scale.set(this.uiScale);
    this.uiAutoplay.x = this.uiSpin.x + (this.uiSpin.width / 2) + (this.uiAutoplay.width / 2) + (this.options.uiPadding * 2);
    this.uiAutoplay.y = this.uiSpin.y;
    this.uiAutoplay.anchor.set(0.5);
    this.uiAutoplay.hitArea = new Circle(0, 0, this.uiAutoplay.width / 2);

    this.uiAutoplayStop.scale.set(this.uiScale);
    this.uiAutoplayStop.x = this.uiAutoplay.x;
    this.uiAutoplayStop.y = this.uiAutoplay.y;
    this.uiAutoplayStop.anchor.set(0.5);
    this.uiAutoplayStop.hitArea = new Circle(0, 0, this.uiAutoplayStop.width / 2);

    this.reelsLoader.setPosition();
  }

  setListeners() {
    const source = 'SlotControls';

    registerEventListener('SpinBonusBuy', (event) => {
      this.result = event.detail.result;
      this.spinBonusBuy(event.detail.isBonusGame);
    }, source);

    registerEventListener('ToggleAutoplay', (event) => {
      const { count } = event.detail;

      slotState.setDialogOpen(false);

      if (slotState.isAutoplay) {
        this.stopAutoplay();
      } else if (count > 0) {
        this.autoplaySpinCount = count;
        this.updateSpinCounter(this.autoplaySpinCount);
        this.startAutoplay();
        this.uiAutoplayStop.visible = true;
      }
    }, source);
  }

  async spin(isManual) {
    this.isLightning = this.options.spinSpeedType === SlotSpinSpeedType.Lightning;

    const isStop = this.isSpinning && !slotState.isAutoplay;
    const isNotStoppable = this.options.isCascade || this.isLightning;

    if (isManual) {
      if (isStop) {
        triggerEvent('SpinButtonStopClick');
        triggerAnalyticsEvent({
          name: 'SpinButtonStopClick',
        });
      } else {
        triggerEvent('SpinButtonClick');
        triggerAnalyticsEvent({
          name: 'SpinButtonClick',
        });
      }
    }

    if (isNotStoppable) {
      this.disableSpinButton();
      this.isStoppable = false;
    } else if (isStop) {
      this.disableSpinButton();
      this.isStoppable = false;
      await slotState.reels.stop();
      return;
    }

    this.result = undefined;
    this.isSpinning = true;
    slotState.isStateUpdated = false;
    slotState.controls.disableInSpin();
    slotState.setLastRound(undefined);

    audio.play(this.options.assets.soundTap);

    animate.to(this.uiSpin, {
      duration: 0.5,
      pixi: {
        angle: 360,
      },
      onComplete: () => {
        this.uiSpin.angle = 0;

        // Show lightning label on 7th spin
        if (this.spinNumber === 7 && !slotState.options.isDemo && !this.isLightning && !slotState.isInFreeRounds) {
          this.lightningSpinLabel.setVisible(true);
        }

        // Hide lightning label on 8th spin
        if (this.spinNumber === 8) {
          this.lightningSpinLabel.setVisible(false, true);
          this.lightningSpinLabel = undefined;
        }

        this.spinNumber += 1;
      },
    });

    const timeout = setTimeout(() => {
      this.reelsLoader.showLoader();
    }, this.reelsLoaderTimeout);

    this.result = await api.createRound(
      this.options.tenantGameId,
      this.options.playerToken,
      {
        betAmount: slotState.betAmount,
        activePaylines: slotState.activePaylines,
        isPromotion: slotState.lastRound?.isFreeRoundsEnd ? false : slotState.isPromotion,
      },
      {
        spinType: this.options.spinSpeedType,
      },
    );

    clearTimeout(timeout);
    this.reelsLoader.hideLoader();

    if (this.result.isError || slotState.errorOnSocketEvent) {
      const error = this.result.isError ? this.result : toJS(slotState.errorOnSocketEvent);

      slotState.setErrorDetails(error);

      if (!slotState.isInFreeRounds) {
        this.stopAutoplay();
      }

      if (isNil(this.result.ui) || (this.result.ui && this.result.ui.action !== 'CRASH')) {
        this.isSpinning = false;
        slotState.controls.enableAfterSpin();
      }

      slotState.errorOnSocketEvent = undefined;
    } else {
      this.isStoppable = true;

      let autoplayCount = slotState.availableFreeRounds > 0 ? slotState.availableFreeRounds : this.autoplaySpinCount;

      if (slotState.isAutoplay && this.uiSpinCounter && autoplayCount > 0) {
        if (slotState.availableFreeRounds > 0) {
          autoplayCount = slotState.availableFreeRounds - 1;
        } else {
          autoplayCount = this.autoplaySpinCount - 1;
          this.autoplaySpinCount = autoplayCount;
        }

        this.updateSpinCounter(autoplayCount);
      }

      await slotState.reels.spin({
        spinSpeedType: this.options.spinSpeedType,
      }, this.result);

      this.isSpinning = false;

      await this.reset(autoplayCount);
    }
  }

  async reset(autoplayCount) {
    if (slotState.isPaused) {
      return;
    }

    const balance = slotState.pendingBalance ? slotState.pendingBalance.amount : slotState.balanceAmount;
    const autoplayStopOnWin = slotState.autoplaySettings.stopOnAnyWin && slotState.lastRound.winAmount;
    const autoplayWinLimitReached = slotState.autoplaySettings.winLimit.enabled
      && slotState.autoplaySettings.currentBalance >= slotState.autoplaySettings.winLimit.amount;
    const autoplayLossLimitReached = slotState.autoplaySettings.lossLimit.enabled
      && slotState.autoplaySettings.currentBalance <= slotState.autoplaySettings.lossLimit.amount * -1;

    if (autoplayCount <= 0
      || (!slotState.isInFreeRounds && (balance < slotState.betAmount || balance < this.options.settings.minBetAmount))
      || (!slotState.isInFreeRounds && (autoplayStopOnWin || autoplayWinLimitReached || autoplayLossLimitReached))) {
      this.stopAutoplay();
    }

    if (this.result?.jackpot?.isWon) {
      triggerEvent('ToggleAutoplay', { count: 0 });
      slotState.controls.openJackpotDialog();
    }

    triggerEvent('RoundCreated', this.result);

    if (slotState.isPromotionStopped) return;

    await this.displayBonusIntroOutro();

    await this.checkPromotion();

    slotState.progressBar?.isHidden();

    if (slotState.isAutoplay) {
      await sleep(this.autoplaySpinWait);
      /*
      Double check because of stop button that
      can be clicked while sleep is active.
      */
      if (slotState.isAutoplay && !slotState.isFeatureWon) {
        if (slotState?.availableFreeRounds !== 0 || this.autoplaySpinCount !== 0) {
          triggerEvent('AutoplaySpinStarted');
          return await this.spin(); // eslint-disable-line no-return-await, consistent-return
        }

        this.stopAutoplay();
      }
    }

    if (!slotState.isAutoplay) {
      this.isSpinning = false;
      this.isStoppable = false;
      slotState.controls.enableAfterSpin();
    }
  }

  async displayBonusIntroOutro() {
    const { isFreeRoundsWon, isPickPrizeWon, isProgressFreeRoundsWon, isProgressPickPrizeWon } = slotState.lastRound || {};

    if (this.isBonusFreeRoundsEnd) {
      if (!slotState.activePromotion) await sleep(this.bonusGameWait);
      slotState.controls.notifyFreeRoundsEnd();
    } else if (isPickPrizeWon || isProgressPickPrizeWon) {
      await sleep(this.bonusGameWait);
      slotState.controls.openPickPrizeIntroDialog();
      this.stopAutoplay();
    } else if (isFreeRoundsWon || isProgressFreeRoundsWon) {
      await sleep(this.bonusGameWait);
      this.autoplaySpinCount = 0;
      slotState.controls.notifyFreeRoundsWon();
    }

    return false;
  }

  async checkPromotion() {
    const { availableFreeRounds, activePromotion, collect, isBonusGameWon, isBonusGameActive, isPromotion, isPromotionOnHold, isPromotionLastSpin, progress } = slotState;
    const isBonusGame = isBonusGameWon || isBonusGameActive;

    if (isBonusGameWon && (isPromotion || availableFreeRounds)) {
      /* reset free round counter and wait bonus intro screen */
      triggerEvent('ToggleAutoplay', { count: 0 });
    }

    if (!activePromotion) return false;

    if (activePromotion?.prizeCountLeft === 1 && isPromotion) {
      slotState.isPromotionLastSpin = true;
    }

    const isPromotionIntroDialogAvailable = !isBonusGame
      && !isPromotion && (activePromotion || isPromotionOnHold)
      && !(collect?.multiplier > 1)
      && !progress?.current?.unitValue;

    const isPromotionOutroDialogAvailable = (isPromotion
      && (!this.result?.activePromotion || this.result?.activePromotion?.id !== activePromotion?.id)
      && !isBonusGame
      && !(collect?.multiplier > 1)
      && !progress?.current?.unitValue)
      || (isPromotionLastSpin && collect?.multiplier > 1);

    if (isPromotionIntroDialogAvailable) {
      this.isSpinning = false;
      slotState.isPromotionOnHold = false;
      triggerEvent('PromotionOpen', { type: 'intro' });
    }

    if (isPromotionOutroDialogAvailable) {
      slotState.controls.openPromotionDialog('outro');
    }

    return false;
  }

  async spinBonusBuy(isBonusGame) {
    await slotState.reels.stop();

    this.isSpinning = true;
    this.isStoppable = false;
    this.disableSpinButton();
    slotState.controls.disableInSpin();

    slotState.toggleBonusBuy();

    await slotState.reels.spin({
      spinSpeedType: this.options.spinSpeedType,
    }, this.result);

    slotState.toggleBonusBuy();

    triggerEvent('RoundCreated', this.result);

    await sleep(this.buySpinWait);

    if (!isBonusGame) return;

    const { isFreeRoundsWon, isPickPrizeWon, isProgressFreeRoundsWon, isProgressPickPrizeWon } = slotState.lastRound || {};

    if (isPickPrizeWon || isProgressPickPrizeWon) {
      slotState.controls.openPickPrizeIntroDialog();
      this.isSpinning = false;
    } else if (isFreeRoundsWon || isProgressFreeRoundsWon) {
      slotState.controls.notifyFreeRoundsWon();
    }

    slotState.progressBar?.isHidden();
  }

  async startAutoplay() {
    this.disableSpinButton();
    await sleep(this.autoplaySpinStartWait);

    slotState.setAutoplay(true);
    triggerEvent('AutoplaySpinStarted');
    await this.spin();
  }

  stopAutoplayManually() {
    triggerAnalyticsEvent({
      name: 'AutoplayStopButtonClick',
    });

    slotState.playTapSound();

    this.stopAutoplay();
  }

  stopAutoplay() {
    if (slotState.isAutoplay) {
      slotState.setAutoplay(false);

      this.autoplaySpinCount = 0;
      this.updateSpinCounter(this.autoplaySpinCount);
      this.uiAutoplayStop.visible = false;

      triggerEvent('StopAutoplay');

      slotState.reels.enableSymbols();
      slotState.setBetAmountState(this.result);

      if (this.previousSpinSpeedType) {
        slotState.setSpinSpeedType(this.previousSpinSpeedType);
        this.previousSpinSpeedType = undefined;
      }
    }
  }

  updateSpinCounter(value) {
    const isVisible = value > 0 && value <= 100;

    this.uiSpinCounter.container.visible = isVisible;

    if (isVisible) {
      this.uiSpinCounter.create(value);
      this.uiSpinCounter.container.x = this.uiSpin.x - (this.uiSpinCounter.container.width / 2);
      this.uiSpinCounter.container.y = this.uiSpin.y - (this.uiSpinCounter.container.height / 2);
    }
  }

  disableSpinButton() {
    this.uiSpin.eventMode = 'none';
    this.uiSpin.alpha = 0.5;
  }

  disableInSpin() {
    this.uiBuy.cursor = null;
    this.uiBuy.alpha = 0.5;
    this.uiAutoplay.eventMode = 'none';
    this.uiAutoplay.alpha = 0.5;
  }

  enableAfterSpin() {
    const uiSpinInteractive = !slotState.isInFreeRounds && !slotState.isAutoplay && slotState.balanceAmount >= this.options.settings.minBetAmount && slotState.betAmount <= slotState.balanceAmount;
    const uiBuyInteractive = this.isBuyEnabled && !slotState.isInFreeRounds && !slotState.isAutoplay;

    this.uiSpin.eventMode = uiSpinInteractive ? 'static' : 'none';
    this.uiSpin.alpha = uiSpinInteractive ? 1 : 0.5;
    this.uiAutoplay.eventMode = this.uiSpin.eventMode;
    this.uiAutoplay.alpha = this.uiSpin.alpha;
    this.uiBuy.cursor = uiBuyInteractive ? 'pointer' : null;
    this.uiBuy.alpha = uiBuyInteractive ? 1 : 0.5;
  }

  showBuyDialog() {
    triggerAnalyticsEvent({
      name: 'BuyButtonClick',
    });

    slotState.playTapSound();

    slotState.dialogBonusBuy.show();
  }

  showAutoplayDialog() {
    triggerAnalyticsEvent({
      name: 'AutoplayButtonClick',
    });

    slotState.playTapSound();

    slotState.dialogAutoplay.show();
  }
}
