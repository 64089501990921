import Animate from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { CustomEase } from 'gsap/CustomEase';
import * as Pixi from '../pixi';

// Register GSAP Plugins
Animate.registerPlugin(MotionPathPlugin);
Animate.registerPlugin(PixiPlugin);
Animate.registerPlugin(CustomEase);
PixiPlugin.registerPIXI(Pixi);

// Register GSAP Effects
Animate.registerEffect({
  name: 'sleep',
  effect: (targets) => Animate.to(Animate.effects.sleep, { duration: targets[0] / 1000 }),
  extendTimeline: true,
});
