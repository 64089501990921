import animate from 'gsap';
import { isNil, max } from 'lodash';
import { Container, Sprite } from '../pixi';
import { LogType } from '../models';
import { slotState } from './SlotState';
import { registerEventListener, isSpriteVideo } from '../utility/Utility';
import logger from '../utility/Logger';

export class SlotBackground {
  constructor() {
    this.options = slotState.options;
    this.container = new Container();
    this.frame = new Container();
    this.asset = this.options.assets.videoBackground.resource;
    this.assetFree = this.options.assets.videoBackgroundFree.resource;
    this.sprite = undefined;
    this.spriteFree = undefined;
    this.spriteUi = undefined;
    this.video = undefined;
    this.videoFree = undefined;
    this.timeline = undefined;
    this.timelineFree = undefined;
    this.toggleDuration = 0.5;

    this.setup();
    this.setupFree();
    this.setupTimelines();
    this.setListeners();
    this.start();
  }

  setup() {
    if (isNil(this.asset)) {
      return;
    }

    const { uiBackground } = this.options.assets;

    this.sprite = new Sprite(this.asset);
    this.spriteUi = uiBackground ? new Sprite(uiBackground.resource[0]) : undefined;

    if (isSpriteVideo(this.sprite)) {
      this.video = this.sprite.texture.baseTexture.resource.source;
      this.video.loop = true;
    }

    this.frame.addChild(this.sprite);
    this.container.addChild(this.frame);
  }

  setupFree() {
    if (isNil(this.assetFree)) {
      return;
    }

    this.spriteFree = new Sprite(this.assetFree);

    if (isSpriteVideo(this.spriteFree)) {
      this.videoFree = this.spriteFree.texture.baseTexture.resource.source;
      this.videoFree.loop = true;
    }

    this.spriteFree.visible = false;
    this.frame.addChild(this.spriteFree);

    if (this.spriteUi) {
      this.frame.addChild(this.spriteUi);
    }
  }

  setPosition() {
    if (isNil(this.sprite)) {
      return;
    }

    this.sprite.scale.set(1);
    if (this.spriteFree) this.spriteFree.scale.set(1);
    if (this.spriteUi) this.spriteUi.scale.set(1);

    const scaleX = this.options.size().width / this.sprite.getBounds().width;
    const scaleY = this.options.size().height / this.sprite.getBounds().height;
    const scale = max([scaleX, scaleY]);

    this.sprite.scale.set(scale);
    if (this.spriteFree) this.spriteFree.scale.set(scale);

    this.frame.x = ((this.options.size().width / this.container.parent.scale.x) - this.sprite.width) / 2;

    if (this.spriteUi) {
      this.spriteUi.scale.set(scale);
      this.spriteUi.x = 0;
      this.spriteUi.y = Math.ceil((this.options.size().height / this.container.parent.scale.y) - this.spriteUi.height);
    }
  }

  setupTimelines() {
    if (isNil(this.sprite) || isNil(this.spriteFree)) {
      return;
    }

    this.setupTimeline();
    this.setupTimelineFree();
  }

  setupTimeline() {
    const that = this;

    this.timeline = animate.timeline({
      paused: true,
      onStart() {
        if (that.videoFree) {
          if (that.video) {
            const setCurrentTime = () => {
              that.videoFree.currentTime = that.video.currentTime;
              that.videoFree.removeEventListener('play', setCurrentTime);
            };

            that.videoFree.addEventListener('play', setCurrentTime);
          }

          that.videoFree.play().catch(() => {
            logger.log(LogType.Warn, 'SlotBackgroundFreePlayError');
          });
        }

        if (that.video) {
          that.video.pause();
        }

        that.sprite.visible = true;
        that.spriteFree.visible = true;
      },
      onComplete() {
        that.sprite.visible = false;
      },
    });

    this.timeline.fromTo(this.sprite, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 0,
      },
    });

    this.timeline.fromTo(this.spriteFree, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 1,
      },
    }, '<');
  }

  setupTimelineFree() {
    const that = this;

    this.timelineFree = animate.timeline({
      paused: true,
      onStart() {
        if (that.video) {
          if (that.videoFree) {
            const setCurrentTime = () => {
              that.video.currentTime = that.videoFree.currentTime;
              that.video.removeEventListener('play', setCurrentTime);
            };

            that.video.addEventListener('play', setCurrentTime);
          }

          that.video.play().catch(() => {
            logger.log(LogType.Warn, 'SlotBackgroundPlayError');
          });
        }

        if (that.videoFree) {
          that.videoFree.pause();
        }

        that.sprite.visible = true;
        that.spriteFree.visible = true;
      },
      onComplete() {
        that.spriteFree.visible = false;
      },
    });

    this.timelineFree.fromTo(this.sprite, {
      pixi: {
        alpha: 0,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 1,
      },
    });

    this.timelineFree.fromTo(this.spriteFree, {
      pixi: {
        alpha: 1,
      },
    }, {
      duration: this.toggleDuration,
      pixi: {
        alpha: 0,
      },
    }, '<');
  }

  setListeners() {
    const source = 'SlotBackground';

    registerEventListener('FullscreenSet', () => {
      this.setPosition();
    }, source);

    registerEventListener('ToggleBackground', () => {
      this.toggle();
    }, source);
  }

  start() {
    if (this.videoFree) {
      this.videoFree.pause();
    }

    if (this.video) {
      this.video.play().catch(() => {
        logger.log(LogType.Warn, 'SlotBackgroundPlayError');
      });
    }

    this.sprite.alpha = 1;
    this.sprite.visible = true;
    this.spriteFree.visible = false;
  }

  startFree() {
    if (this.video) {
      this.video.pause();
    }

    if (this.videoFree) {
      this.videoFree.play().catch(() => {
        logger.log(LogType.Warn, 'SlotBackgroundPlayError');
      });
    }

    this.spriteFree.alpha = 1;
    this.spriteFree.visible = true;
    this.sprite.visible = false;
  }

  toggle() {
    if (isNil(this.spriteFree)) {
      if (this.video) {
        this.video.play().catch(() => {
          logger.log(LogType.Warn, 'SlotBackgroundPlayError');
        });
      }

      this.sprite.alpha = 1;
      this.sprite.visible = true;

      return;
    }

    const isInFreeRounds = slotState.availableFreeRounds > 0 && !slotState.activePromotion;

    if (this.spriteFree.visible && !isInFreeRounds) {
      this.timelineFree.play(0);
    } else if (this.sprite.visible && isInFreeRounds) {
      this.timeline.play(0);
    } else if (isInFreeRounds) {
      this.startFree();
    } else {
      this.start();
    }
  }
}
