import NoSleep from 'nosleep.js';
import { registerEventListener } from '../utility/Utility';

export default {
  init() {
    const source = 'NoSleep';

    registerEventListener('AppPointerTap', () => {
      const noSleep = new NoSleep();
      noSleep.enable();
    }, source, true);
  },
};
