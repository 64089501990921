import { Container } from '@/pixi';
import { Tabs } from '@/components/shared/Tabs';
import { Dialog } from './Dialog';
import { DialogContentMenuSettings } from './DialogContentMenuSettings';
import { DialogContentMenuGameInfo } from './DialogContentMenuGameInfo';
import { DialogContentMenuHistory } from './DialogContentMenuHistory';
import { state } from '../State';

export class DialogMenu extends Dialog {
  constructor() {
    const contentHistory = new DialogContentMenuHistory();
    const contentGameInfo = new DialogContentMenuGameInfo();

    const tabs = new Tabs({
      items: [
        {
          title: state.options.translations.dialogSettingsTitle,
          iconAsset: state.options.assets.iconSettings,
          content: new DialogContentMenuSettings(),
        },
        {
          title: state.options.translations.dialogMenuGameInfoTitle,
          iconAsset: state.options.assets.iconInfo,
          content: contentGameInfo,
          onHide: contentGameInfo.scrollToTop.bind(contentGameInfo),
        },
        {
          title: state.options.translations.history,
          iconAsset: state.options.assets.iconHistory,
          content: contentHistory,
          onShow: contentHistory.onShow.bind(contentHistory),
        },
      ],
      playTapSound: () => { state.playTapSound(); },
      onChange: (item) => {
        this.updateTitle(item.title);
      },
      colorActive: state.options.colors.theme,
      padding: state.options.uiDialogPadding,
    });

    const content = {
      container: new Container(),
      tabs,
      show: (tabIndex = 0) => {
        tabs.showTab(tabIndex);
      },
      hide: () => {
        tabs.hideActiveTab();
      },
      setPosition: (data) => {
        tabs.setPosition(data);
      },
    };

    content.container.addChild(tabs.container);

    super({
      title: state.options.translations.dialogSettingsTitle,
      content,
    });

    this.container.name = 'DialogMenu';
  }
}
