import { Button as BaseButton } from '@/components/shared/Button';
import { state } from '../State';

export class Button extends BaseButton {
  constructor(params) {
    super({
      ...Button.getDefaultParams(),
      ...params,
    });
  }

  static getDefaultParams = () => ({
    backgroundColor: state.options.colors.theme,
    textColor: state.options.colors.themeText,
    fontName: state.options.fontNameNormal,
    playTapSound: () => { state.playTapSound(); },
  });
}
