import gsap from 'gsap';
import { Container, BitmapText, Rectangle } from '@/pixi';
import { triggerAnalyticsEvent } from '@/utility/Utility';

export class ControlsFooter2 {
  #container;
  #balanceContainer;
  #balanceTitle;
  #balanceAmount;
  #betContainer;
  #betTitle;
  #betAmount;
  #winContainer;
  #winTitle;
  #winAmount;
  #appWidth;
  #getAppHeight;
  #padding;
  #onBetAmountClick;
  #playTapSound;

  constructor({
    balanceTranslation, // Required, String
    balanceAmount, // Required, String
    betTranslation, // Required, String
    betAmount, // Required, String
    useWinContainer = true,
    winTranslation, // Required if useWinContainer is true, String
    currency, // Required, String
    currencyDisplayEnabled = false,
    isBetAmountClickDisabled = false,
    appWidth = 1080,
    getAppHeight, // Required, Function, should return Number
    padding = 24,
    color = 0xdfe0e5,
    balanceTitleColor, // Optional
    balanceAmountColor, // Optional
    betTitleColor, // Optional
    betAmountColor, // Optional
    winColor, // Optional
    winTitleColor, // Optional
    winAmountColor, // Optional
    titleFontName = 'RobotoLight',
    amountFontName = 'RobotoBold',
    onBetAmountClick, // Required, Function
    playTapSound, // Optional, Function
  }) {
    this.#appWidth = appWidth;
    this.#getAppHeight = getAppHeight;
    this.#padding = padding;
    this.#onBetAmountClick = onBetAmountClick;
    this.#playTapSound = playTapSound;

    this.#container = new Container();

    const titleStyle = { fontName: titleFontName, fontSize: 34 };
    const amountStyle = { fontName: amountFontName, fontSize: 48 };
    const colors = {
      balanceTitleColor: balanceTitleColor || color,
      balanceAmountColor: balanceAmountColor || color,
      betTitleColor: betTitleColor || color,
      betAmountColor: betAmountColor || color,
      winTitleColor: winTitleColor || winColor || color,
      winAmountColor: winAmountColor || winColor || color,
    };

    // Balance amount
    this.#balanceContainer = new Container();
    this.#balanceContainer.x = this.#padding;
    this.#balanceTitle = new BitmapText(this.#getTitleText(balanceTranslation, true, currency), { ...titleStyle, tint: colors.balanceTitleColor });
    this.#balanceAmount = new BitmapText(balanceAmount, { ...amountStyle, tint: colors.balanceAmountColor });
    this.#balanceAmount.anchor.set(0, 0.5);
    this.#balanceAmount.y = this.#balanceTitle.height + this.#balanceAmount.height / 2;
    this.#balanceContainer.addChild(this.#balanceTitle, this.#balanceAmount);
    this.#container.addChild(this.#balanceContainer);

    // Bet amount
    this.#betContainer = new Container();
    this.#betContainer.x = this.#appWidth - this.#padding;
    this.#betTitle = new BitmapText(this.#getTitleText(betTranslation, currencyDisplayEnabled, currency), { ...titleStyle, tint: colors.betTitleColor, align: 'right' });
    this.#betTitle.anchor.set(1, 0);
    this.#betAmount = new BitmapText(betAmount, { ...amountStyle, tint: colors.betAmountColor, align: 'right' });
    this.#betAmount.anchor.set(1, 0);
    this.#betAmount.y = this.#betTitle.height;
    this.#betContainer.addChild(this.#betTitle, this.#betAmount);
    this.#container.addChild(this.#betContainer);

    // Win amount
    if (useWinContainer) {
      this.#winContainer = new Container();
      this.#winContainer.x = this.#appWidth / 2;
      this.#winTitle = new BitmapText(this.#getTitleText(winTranslation, currencyDisplayEnabled, currency), { ...titleStyle, tint: colors.winTitleColor, align: 'center' });
      this.#winTitle.anchor.set(0.5, 0);
      this.#winAmount = new BitmapText('', { ...amountStyle, tint: colors.winAmountColor, align: 'center' });
      this.#winAmount.anchor.set(0.5);
      this.#winAmount.y = this.#winTitle.height + this.#winAmount.height / 2;
      this.#winContainer.addChild(this.#winTitle, this.#winAmount);
      this.#winContainer.visible = false;
      this.#container.addChild(this.#winContainer);

      this.#setBalanceAmountScale();
    }

    this.#setListeners(isBetAmountClickDisabled);
  }

  get container() {
    return this.#container;
  }

  get balanceAmount() {
    return this.#balanceAmount;
  }

  #setListeners(isBetAmountClickDisabled) {
    this.#betContainer.eventMode = isBetAmountClickDisabled ? 'none' : 'static';
    this.#betContainer.alpha = isBetAmountClickDisabled ? 0.5 : 1;
    this.#betContainer.cursor = 'pointer';
    this.#betContainer.on('pointertap', () => {
      this.#playTapSound?.();
      this.#onBetAmountClick();
      triggerAnalyticsEvent({ name: 'BetAmountButtonClick' });
    });
  }

  #getTitleText(text, showCurrency, currency) {
    return `${text.toUpperCase()}${showCurrency ? ` (${currency})` : ''}`;
  }

  #setBalanceAmountScale() {
    const maxWidth = this.#appWidth / 3;
    const balanceAmountWidth = this.#balanceAmount.width / this.#balanceAmount.scale.y;

    if (balanceAmountWidth > maxWidth) {
      this.#balanceAmount.scale.set(maxWidth / balanceAmountWidth);
    } else {
      this.#balanceAmount.scale.set(1);
    }
  }

  #setWinAmountScale() {
    const largerElementWidth = this.#balanceContainer.width;
    const margin = 25;
    const maxWidth = this.#appWidth - (largerElementWidth * 2 + this.#padding * 2 + margin * 2);
    const winAmountWidth = this.#winAmount.width / this.#winAmount.scale.y;

    if (winAmountWidth > maxWidth) {
      this.#winAmount.scale.set(maxWidth / winAmountWidth);
    } else {
      this.#winAmount.scale.set(1);
    }
  }

  #setBetContainerHitArea() {
    const bounds = this.#betContainer.getLocalBounds();

    this.#betContainer.hitArea = new Rectangle(bounds.x, bounds.y, bounds.width, bounds.height);
  }

  setBalanceAmount(value) {
    this.#balanceAmount.text = value;

    if (this.#winContainer) {
      this.#setBalanceAmountScale();

      if (this.#winContainer.visible) {
        // In case it doesn't fit
        this.#setWinAmountScale();
      }
    }
  }

  setBetAmount(value) {
    this.#betAmount.text = value;
    this.#setBetContainerHitArea();
  }

  disableBetAmountClick() {
    this.#betContainer.eventMode = 'none';
    this.#betContainer.alpha = 0.5;
  }

  enableBetAmountClick() {
    this.#betContainer.eventMode = 'static';
    this.#betContainer.alpha = 1;
  }

  showWinAmount(amount) {
    this.#winAmount.text = amount;
    this.#setWinAmountScale();
    this.#winContainer.visible = true;
  }

  showWinAmountWithIncrement(amount, amountFormatFunction, amountFrom = 0, duration = 0.3) {
    const amountToAnimate = { amount: amountFrom };

    gsap.timeline().to(amountToAnimate, {
      amount,
      onStart: () => {
        this.#winContainer.visible = true;
      },
      duration,
      onUpdate: () => {
        this.#winAmount.text = amountFormatFunction(amountToAnimate.amount);
        this.#setWinAmountScale();
      },
    });
  }

  hideWinAmount() {
    this.#winContainer.visible = false;
  }

  get betContainer() {
    return this.#betContainer;
  }

  setPosition() {
    const positionY = this.#getAppHeight() - this.#padding;

    this.#betContainer.y = positionY - this.#betContainer.height;
    this.#setBetContainerHitArea();

    this.#balanceContainer.y = this.#betContainer.y;

    if (this.#winContainer) {
      this.#winContainer.y = this.#betContainer.y;
    }
  }
}
