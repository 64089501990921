import { Notification as BaseNotification } from '@/components/shared/Notification';
import { state } from './State';
import { Button } from './dialogs/Button';

export class Notification extends BaseNotification {
  constructor(params) {
    super({
      getAppHeight: () => state.appHeight,
      getAppWidth: () => state.appWidth,
      buttonComponent: Button,
      iconCloseAsset: state.options.assets.iconClose,
      reloadBackgroundAsset: state.options.assets.reloadModalBackground,
      reloadLogoAsset: state.options.assets.reloadModalLogo,
      fontName: state.options.fontDefaultNormal,
      errorHappenedText: state.options.translations.invalidStateError,
      reloadText: state.options.translations.reload,
      reloadGameToPlayText: state.options.translations.reloadGameToPlay,
      onClose: () => { this.closeNotification(); },
      playTapSound: () => { state.playTapSound(); },
      ...params,
    });
  }

  closeNotification() {
    state.errorDetails = undefined;
    state.components.notification = undefined;
    state.components.header.uiBoost?.enable();

    if (state.isAnyAutoplay) {
      state.components.content.controls.spin();
    } else {
      state.enableAfterSpin();
    }
  }
}
