import { BitmapText, Container } from '@/pixi';
import { Spine } from 'pixi-spine';
import animate from 'gsap';
import { state } from './State';

export class BonusIntroCashLink {
  constructor() {
    this.container = new Container();
    this.container.name = 'BonusIntroCashLink';
    this.container.eventMode = 'none';
    this.container.alpha = 0;

    this.cashLinkIntro = new Spine(state.options.assets.cashLinkIntro.resource.spineData);

    this.autoCloseTimeout = undefined;
    this.autoCloseWait = 5000;

    this.pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.fontCherryWhite,
      fontSize: 45,
    });

    this.container.addChild(this.cashLinkIntro, this.pressAnywhereText);

    this.setListeners();
  }

  setListeners() {
    this.container.on('pointertap', () => this.hide());

    this.cashLinkIntro.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'cashLinkIntroStart') {
          this.cashLinkIntro.state.setAnimation(0, 'cashLinkIntroIdle', true);
        }
      },
    });
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundDimensions = this.cashLinkIntro.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    if (aspectRatio > backgroundAspectRatio) {
      this.cashLinkIntro.scale.set(state.appHeight / backgroundDimensions.height);
    }

    this.cashLinkIntro.x = state.appWidth / 2 - this.cashLinkIntro.width / 2;
    this.cashLinkIntro.y = state.appHeight / 2 - this.cashLinkIntro.height / 2;

    this.pressAnywhereText.x = state.options.calcWidth / 2;
    this.pressAnywhereText.y = state.appHeight - this.pressAnywhereText.height;
    this.pressAnywhereText.anchor.set(0.5);
  }

  show() {
    return new Promise((resolve) => {
      this.showTimeline = animate.timeline();

      this.showTimeline.to(this.container, {
        duration: 0.175,
        pixi: {
          alpha: 1,
        },
        onStart: () => {
          this.cashLinkIntro.state.setAnimation(0, 'cashLinkIntroStart', false);
          state.playSound('cashLinkIntroStart');
          state.setDialogOpen(true);
          state.components.header.uiBoost?.hide();
        },
        onComplete: () => {
          this.container.eventMode = 'static';
          this.autoCloseTimeout = setTimeout(() => this.hide(), this.autoCloseWait);
          state.reelsWrapper.setReelsLogoPosition();
          resolve(true);
        },
      });

      this.showTimeline.play();
    });
  }

  hide() {
    this.hideTimeline = animate.timeline();

    this.hideTimeline.to(this.container, {
      duration: 0.175,
      pixi: {
        alpha: 0,
      },
      onStart: () => {
        this.container.eventMode = 'none';
        clearTimeout(this.autoCloseTimeout);
        state.setDialogOpen(false);
        state.components.header.uiBoost?.show();
      },
      onComplete: async () => {
        this.cashLinkIntro.state.setAnimation(0, 'cashLinkIntroIdle', false);

        /* If there are multiplier symbols on reveal reels. Apply them before bonus autoplay starts */
        await state.reels.reelsCashLink.createApplyMultiplierTimeline().play();

        state.stopSound('cashLinkIntroStart', true);
        state.startBonusAutoplay();
      },
    });

    this.hideTimeline.play();
  }
}
