import { isNil, kebabCase, toLower, template } from 'lodash';
import { getDomain } from '@/utility/Utility';
import { Container } from '../pixi';
import { slotState } from './SlotState';

export class SlotDialogMenuInfo {
  constructor() {
    this.container = new Container();
    this.frameId = 'frameInfo';
    this.frameWidth = 400;
    this.setup();
  }

  getQueryParams() {
    const { config, settings } = slotState.options;
    const { bonusType, prizeCount, multipliers, dynamicDistribution } = config.bonus || {};
    const linesType = toLower(settings.paylinesType);
    const bonusSymbols = config.bonus ? config.bonus.symbolsMin : 0;

    let maxFreeSpinsMultiplier;
    let numOfFreeSpins;

    if (bonusType === 'FreeRounds' && !dynamicDistribution) {
      maxFreeSpinsMultiplier = multipliers[Object.keys(multipliers)[Object.keys(multipliers).length - 1]];
      numOfFreeSpins = prizeCount;
    }

    return new URLSearchParams({
      gamble: false,
      reels: config.reels,
      rows: config.rows,
      numberOfLines: config.activePaylines,
      linesType,
      wild: config.isWildSymbol,
      bonus: config.isBonusSymbol,
      bonusSymbols,
      bonusBuy: config.isBonusBuyEnabled,
      minBetAmount: settings.minBetAmount,
      maxBetAmount: settings.maxBetAmount,
      numOfFreeSpins,
      maxFreeSpinsMultiplier,
    }).toString();
  }

  createElement() {
    let element = document.getElementById(this.frameId);

    if (isNil(element)) {
      const { locale } = slotState.options;
      const name = kebabCase(slotState.options.name);

      const domain = getDomain();
      const rulesUrl = template(process.env.APP_GAME_RULES_URL)({ domain });

      const url = locale === 'en'
        ? `${rulesUrl}/rules/${name}/?${this.getQueryParams()}`
        : `${rulesUrl}/${locale.toLowerCase()}/rules/${name}/?${this.getQueryParams()}`;

      element = document.createElement('iframe');

      element.id = this.frameId;
      element.frameBorder = 0;
      element.src = url;
      element.style.position = 'fixed';
      element.style.display = 'none';
      element.style.width = `${this.frameWidth}px`;
      element.style.transformOrigin = 'top left';
      element.style.zIndex = 1000;
      element.style.userSelect = 'none';

      document.body.append(element);
    }

    return element;
  }

  setup() {
    this.createElement();
  }

  setPosition() {
    const canvas = document.getElementsByTagName('canvas')[0];
    const canvasRect = canvas.getBoundingClientRect();
    const element = this.createElement();
    const scale = canvasRect.width / this.frameWidth;
    const dialogHeadHeight = slotState.dialogMenu.getFullHeadHeight() * slotState.container.scale.y;

    element.style.left = `${canvasRect.left + window.scrollX}px`;
    element.style.top = `${canvasRect.top + dialogHeadHeight + window.scrollY}px`;
    element.style.transform = `scale(${scale})`;
    element.style.height = `${(canvasRect.height - dialogHeadHeight) / scale}px`;
  }

  show() {
    const element = this.createElement();
    element.style.display = 'block';
  }

  hide() {
    const element = this.createElement();
    element.style.display = 'none';
  }
}
