import { Container, utils } from '@/pixi';
import { ReelsWithLogo } from './ReelsWithLogo';
import { Controls } from './Controls';
import { state } from './State';

export class Content {
  #container;
  #reelsWithLogo;
  #controls;

  constructor() {
    this.#container = new Container();
    this.#container.name = 'Content';

    this.#reelsWithLogo = new ReelsWithLogo();
    // Set position and scale before setPosition to not overflow screen
    this.#reelsWithLogo.container.x = 50;
    this.#reelsWithLogo.container.scale.set(0.9);

    this.#controls = new Controls();
    this.#controls.container.x = this.#controls.container.width / 2 + 100; // Set position before setPosition to not overflow screen

    this.#container.addChild(
      this.#reelsWithLogo.container,
      this.#controls.container,
    );
  }

  get container() {
    return this.#container;
  }

  get reelsWithLogo() {
    return this.#reelsWithLogo;
  }

  get controls() {
    return this.#controls;
  }

  setPosition() {
    // Don't set position during intro animation, it will be set after finish
    if (this.reelsWithLogo.reels.isIntroAnimationActive) return;

    // Set controls scale
    if (utils.isMobile.tablet || state.options.size().height < state.options.smallDeviceHeightLimit) {
      this.#controls.container.scale.set(0.8);
    } else {
      this.#controls.container.scale.set(1);
    }

    const topPadding = state.options.uiPadding;
    const footerHeightWithPadding = state.appHeight - state.components.footer.container.getLocalBounds().top;
    const topPaddingWithControlsAndFooterHeight = topPadding + this.#controls.controlsHeight + footerHeightWithPadding;
    const reelsWithLogoHeight = this.#reelsWithLogo.container.height / this.#reelsWithLogo.container.scale.y;
    const margin = 12; // Margin is used between reelsWithLogo and controls
    const fullHeight = topPaddingWithControlsAndFooterHeight + reelsWithLogoHeight + margin;
    const spaceLeftForReelsWithLogo = state.appHeight - topPaddingWithControlsAndFooterHeight - margin;

    // Set reelsWithLogo scale
    if (fullHeight > state.appHeight) {
      this.#reelsWithLogo.container.scale.set(spaceLeftForReelsWithLogo / reelsWithLogoHeight);
    } else {
      this.#reelsWithLogo.container.scale.set(1);
    }

    const reelsWidth = this.#reelsWithLogo.reels.mainReelsSize * this.#reelsWithLogo.container.scale.x;
    // Set reelsWithLogo position, between header and controls
    this.#reelsWithLogo.setPositionY(topPadding + spaceLeftForReelsWithLogo / 2 - this.#reelsWithLogo.container.height / 2);
    this.#reelsWithLogo.container.x = state.appWidth / 2 - reelsWidth / 2;
    this.#reelsWithLogo.reels.setPosition();

    // Set controls position, between reelsWithLogo and footer
    this.#controls.container.x = state.appWidth / 2;
    this.#controls.container.y = this.#reelsWithLogo.getBottomPosition() + margin + ((state.appHeight - (this.#reelsWithLogo.getBottomPosition() + margin + footerHeightWithPadding)) / 2);
  }

  runIntroAnimation() {
    this.#reelsWithLogo.runIntroAnimation();
  }
}
