import { Spine } from 'pixi-spine';
import { state } from './State';
import { audio } from './Audio';

export class Symbol {
  constructor(index) {
    this.options = state.options;
    this.symbolIndex = index;
    this.symbol = undefined;

    this.setup();
  }

  doActionAfterWinAnimation() {
    const { autoplay, bonusGame, bonusGameOutroScreen, content, lastRound } = state;

    if (autoplay.enabled && autoplay.numberOfSpins) {
      state.content.controls.spin();
    }
    if (lastRound.availableFreeRounds) {
      content.controls.spin();
    }
    if (lastRound.availableFreeRounds === 0 && bonusGame.enabled) {
      bonusGameOutroScreen.show();
    }
  }

  loadModel() {
    this.model = new Spine(state.options.resources[`symbol${this.symbolIndex}`].resource.spineData);
  }

  runIdleAnimation() {
    this.model.state.setAnimation(0, 'symbolIdle', false);
  }

  runWinSpinAnimation() {
    if (this.model.state.hasAnimation('symbolWinSpin')) {
      this.model.state.setAnimation(0, 'symbolWinSpin', false);
    }
  }

  runWinAnimation() {
    if (this.symbolIndex === state.lastRound.result.number) {
      this.model.state.setAnimation(0, 'symbolWin', false);
      audio.play(this.options.assets[`soundSymbol${this.symbolIndex}`]);
    }

    this.model.state.addListener({
      complete: () => {
        this.doActionAfterWinAnimation();
      },
    });
  }

  runSpinAnimation() {
    this.model.state.setAnimation(0, 'symbolSpin', false);
  }

  runSpinStopAnimation() {
    if (this.model.state.hasAnimation('symbolSpinStop')) {
      this.model.state.setAnimation(0, 'symbolSpinStop', false);
    }
  }

  setup() {
    this.loadModel();
    this.runIdleAnimation();
    this.setPosition();
  }

  setPosition(x = 0, y = 0) {
    this.model.x = x;
    this.model.y = y;
  }
}
