import { Container } from '@/pixi';
import { Spine } from 'pixi-spine';
import { state } from './State';

export class Background {
  constructor() {
    this.container = new Container();
    this.container.name = 'Background';
    this.container.interactiveChildren = false;

    this.background = new Spine(state.options.assets.background.resource.spineData);
    this.backgroundFree = new Spine(state.options.assets.backgroundFreeSpins.resource.spineData);
    this.bottomGradient = new Spine(state.options.assets.footer.resource.spineData);

    this.background.state.setAnimation(0, 'backgroundIdle', true);
    this.backgroundFree.state.setAnimation(0, 'backgroundIdle', true);

    this.container.addChild(this.background, this.backgroundFree, this.bottomGradient);

    this.setup();
  }

  setup() {
    this.background.visible = true;
    this.backgroundFree.visible = false;
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const background = this.background.visible ? this.background : this.backgroundFree;
    const backgroundDimensions = this.background.visible ? this.background.getLocalBounds() : this.backgroundFree.getLocalBounds();
    const backgroundAspectRatio = backgroundDimensions.height / backgroundDimensions.width;

    // If app aspect ratio is larger then background aspect ratio then set larger background scale
    if (aspectRatio > backgroundAspectRatio) {
      background.scale.set(state.appHeight / backgroundDimensions.height);
      this.bottomGradient.scale.set(background.scale.y);
    } else {
      background.scale.set(1);
      this.bottomGradient.scale.set(1);
    }

    background.x = state.appWidth / 2 - background.width / 2;
    background.y = state.appHeight / 2 - background.height / 2;

    this.bottomGradient.y = state.appHeight - this.bottomGradient.height + 1;
  }

  toggleBonusBackground() {
    this.background.visible = !this.background.visible;
    this.backgroundFree.visible = !this.backgroundFree.visible;

    this.setPosition();
  }
}
