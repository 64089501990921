import { pusher, noSleep } from '@/plugins';
import { registerEventListener } from '@/utility/Utility';
import { Component } from '@/components/shared/Component';
import { Parser } from '@/components/fishyGuy/Parser';
import { Background } from '@/components/fishyGuy/Background';
import { Header } from '@/components/fishyGuy/Header';
import { Content } from '@/components/fishyGuy/Content';
import { Footer } from '@/components/fishyGuy/Footer';
import { Hooks } from '@/components/fishyGuy/Hooks';
import { WinGrading } from '@/components/fishyGuy/WinGrading';
import { LoaderOverlay } from '@/components/fishyGuy/LoaderOverlay';
import { BonusGameScreen } from '@/components/fishyGuy/BonusGameScreen';
import { DialogMenu } from '@/components/fishyGuy/dialogs/DialogMenu';
import { DialogBetAmount } from '@/components/fishyGuy/dialogs/DialogBetAmount';
import { DialogStore } from '@/components/fishyGuy/dialogs/DialogStore';
import { DialogAutoplay } from '@/components/fishyGuy/dialogs/DialogAutoplay';
import { OnboardScreen } from '@/components/fishyGuy/OnboardScreen';
import { Notification } from '@/components/fishyGuy/Notification';
import { state } from '@/components/fishyGuy/State';
import { audio } from '@/components/fishyGuy/Audio';

export class FishyGuy extends Component {
  constructor(params) {
    const { player, freeRounds, bonusWinnings, game } = params.state;
    const { tenantGameId, name, predefinedBetAmounts, formattedPaytable, bonusLevels, currencyDisplayEnabled, lobbyEnabled, rtp, bonusBuyRtp, bonusBuyPrice, collectBuyRtp, ui } = game;
    const startReels = ui.reveal.mergedReels;
    const wildMultiplierSymbol = 11;
    const collectBaseSymbol = 14;
    const collectExtraSymbol = 15;

    const defaults = {
      player,
      tenantGameId,
      name,
      predefinedBetAmounts,
      paytable: formattedPaytable,
      bonusGameDataOnAppStart: { ...freeRounds, ...bonusWinnings },
      collectLevelsData: bonusLevels,
      currency: player?.currency || params.tenantCurrency || 'EUR',
      currencyDisplayEnabled: currencyDisplayEnabled || false,
      lobbyEnabled: lobbyEnabled || false,
      startReels,
      bonusGameMaxCollectLevel: 5,
      rtp,
      bonusBuyRtp,
      bonusBuyPrice,
      collectBuyRtp,
      bonusBuyEnabled: game.bonusBuyEnabled,
      collectBuyEnabled: game.collectBuyEnabled,
      defaultFontFamily: 'Roboto',
      fontNameLight: 'RobotoLight',
      fontNameNormal: 'RobotoNormal',
      fontNameBold: 'RobotoBold',
      // Custom fonts are automatically loaded from assets
      customFont: 'font1',
      customFont2: 'font2',
      isDemo: params.playerToken === undefined,
      mute: true,
      locale: 'en',
      colors: {
        text: 0xdfe0e5,
        theme: 0x1ba2f4,
        themeText: 0xdfe0e5,
      },
      symbols: {
        main: [1, 2, 3, 4, 5, 6, 7, 8],
        wild: [10, wildMultiplierSymbol],
        wildMultiplier: wildMultiplierSymbol,
        bonus: 12,
        cash: 13,
        collectBase: collectBaseSymbol,
        collectExtra: collectExtraSymbol,
        collect: [collectBaseSymbol, collectExtraSymbol],
        trash: 17,
        upgrade: 18,
        tnt: 19,
      },
      calcWidth: 1080,
      uiPadding: 24,
      uiDialogPadding: 48,
      smallDeviceHeightLimit: 600,
      translations: {
        /* eslint-disable max-len */
        aboutTheGameTitle: 'About the Game',
        autoplay: 'Autoplay',
        balance: 'Balance',
        bet: 'Bet',
        bonusFeaturesTitle: 'Bonus features',
        demoMode: 'Demo',
        dialogBetAmountTitle: 'Bet Amount',
        dialogStoreTitle: 'Store',
        dialogSettingsTitle: 'Settings',
        dialogSettingsSoundLabel: 'Sound',
        dialogSettingsLightningLabel: 'Lightning spin',
        dialogMenuGameInfoTitle: 'Game Info',
        first: 'First',
        fourth: 'Fourth',
        fifth: 'Fifth',
        fishyGuyGameInfo: '“Fishy Guy” is a 5-reel and 6-row slot machine with the respin feature. This means that each time a win happens, winning symbols disappear and symbols from the top row and new symbols take their spots. The top row is dedicated to the special symbols. These symbols show the possible amount a player can win. If a winning combination happens, but some of the symbols in the top row don’t fall in the grid, their value increases for the next respin. The game RTP is equal to ${rtp}%.',
        fishyGuyWildSymbolDescription: 'The wild symbol substitutes for all symbols on the reels, except bonus and collect symbols.',
        fishyGuyWildMultiplierSymbolDescription: 'The wild symbol that multiplies the won amount, but no cash values.',
        fishyGuyFishSymbolsDescription: 'Shows the possible cash amount a player can win. Multipliers on the cash symbols increase progressively. They take a random value of ${multiplier1}x your bet to ${multiplier2}x your bet on the fifth level. RIP fish gives you no multiplier. All levels are unlocked in the bonus game, and the maximum multiplier is an astonishing ${multiplier3}x your bet.',
        fishyGuySpecialFishSymbolDescription: 'These special symbols can be unlocked during the bonus game. Once players upgrade their fisherman symbol to level four in the bonus game, special symbols can land on the reels. If the fisherman level upgrades to the fifth level, only special symbols can land on the reels.',
        fishyGuyFishermanSymbolDescription: 'Collects all cash symbols except those placed in the extra row.',
        fishyGuySuperFishermanSymbolDescription: 'Collects all cash symbols from a screen.',
        fishyGuyBonusSymbolDescription: 'Land at least three bonus symbols to trigger a bonus game. The total number of free spins equals the sum of all free spins from bonus symbols.',
        fishyGuyUpgradeLevelSymbolDescription: 'Unlocks new levels in the bonus game by collecting it.',
        fishyGuyBonusFeaturesDescription: 'A player has to land at least three bonus symbols to trigger the bonus game. Each bonus symbol has the number of free spins it grants to a player. The total number of free spins equals the sum of all free spins from bonus symbols. Bonus symbols can’t land on the first and last reel. During the free rounds, instead of extra rows, there are 5 possible levels. ',
        fishyGuyFirstLevelDescription: 'Players are collecting symbols that upgrade their current level. Two upgrade symbols are needed to upgrade the fisherman once. Two extra spins are granted to a player, and the fisherman multiplies each win with two.',
        fishyGuySecondLevelDescription: 'Three more symbols upgrade the fisherman to the second level. Now three extra spins are granted to the player, and the fisherman multiplies each win with three.',
        fishyGuyThirdLevelDescription: 'To upgrade the fisherman to the third level five upgrade symbols are needed. Five extra spins are granted to a player, and the fisherman multiplies each win with five.',
        fishyGuyFourthLevelDescription: 'Ten more upgrade symbols are needed to upgrade the fisherman to the fourth level. Five extra spins are granted to a player, and the fisherman multiplies each win with seven. Now, when the fisherman is strong enough, the special fish symbols are unlocked and they can land on the reels.',
        fishyGuyFifthLevelDescription: 'To reach the last level, players have to collect ten upgrade symbols. Ten extra spins are granted to a player, and all wins are multiplied by ten now. Also, only special fish symbols can land on the reels now.',
        fishyGuyStoreDescription: '- Each option can only be activated in the main game.\n- To buy the bonus round you have to wager ${bonusBuyPrice}x your regular bet. The theoretical RTP of the feature is equal to ${bonusBuyRtp}%.\n- Buy the Fisherman spin to activate the collect feature in the next spin. At least one fisherman lands on the reels, and one winning combination happens. The theoretical RTP of the feature is equal to ${collectBuyRtp}%.',
        fishyGuyHowToPlayDescription: 'Select a bet size, then press the Spin button to begin. To use autoplay, press the Autoplay button and select the desired number of rounds. You can stop autoplay mode anytime by clicking the stop button. You can control the spin speed by selecting one of the predefined spin options:\n- Normal spin: This is the default mode.\n- Lighting spin: In the first spin symbols just fall off on the reel, without any lazy animations. You can activate this option in the settings.\n- Lighting mode: To activate this mode press the spin button for 1 second. The lighting spin is activated, and an autoplay with an infinite number of spins is started. You can always stop this mode by clicking the stop button.',
        fishyGuyOnboardText1: 'CATCH A BIG FISH AND WIN BIG',
        fishyGuyOnboardText2: 'NON-EXPLODING WILDS\nWITH MULTIPLIERS',
        fishyGuyOnboardText3: '3 BONUS SYMBOLS TRIGGER\nEXPLODING SPINS',
        history: 'History',
        historyDemoModeMessage: 'Tickets history not available in Demo mode.',
        historyNoTicketsMessage: 'There is no tickets for selected filter. Try different filters.',
        historySummaryLabel: 'Summary',
        historyTimeLabel: 'Time',
        historyCurrencyLabel: 'Currency',
        historyBetLabel: 'Bet',
        historyWinLabel: 'Win',
        historySelectModalDateRange: 'Date range',
        historySelectToday: 'Today',
        historySelectYesterday: 'Yesterday',
        historySelectModalAccept: 'Accept',
        historySelectModalCancel: 'Cancel',
        holdForLightningMode: 'Hold for lightning mode',
        howToPlayTitle: 'How to Play',
        level: 'Level',
        lossLimit: 'Loss limit',
        paytable: 'Paytable',
        pressAnywhereToContinue: 'Press anywhere to continue',
        respin: 'Respin',
        reload: 'Reload',
        refreshPage: 'Reload game to play.',
        round: 'Round',
        second: 'Second',
        spin: 'Spin',
        spins: 'Spins',
        start: 'Start',
        stopOnAnyWin: 'Stop on any win',
        third: 'Third',
        win: 'Win',
        winLimit: 'Win limit',
        /* Error translations */
        actionError: 'Action error',
        actionSuccess: 'Action success',
        activePromotionError: 'Promotion is currently active',
        closeGame: 'Game can not be continued. Please close game.',
        dbReadError: 'Database error',
        dbSaveError: 'Database error',
        expiredPlayerTokenError: 'Expired player token',
        featureNotAvailableError: 'Feature not available',
        fieldNotFoundError: 'Round validation error',
        gameConfigNotFoundError: 'Invalid game configuration',
        gameMultipeInstancesError: 'Invalid parameters',
        gameNotFoundError: 'Game not found',
        insufficientFundsError: 'Insufficient funds',
        integrationRequestError: 'Integration error',
        integrationRequestServiceError: 'Integration error',
        integrationRequestTimeoutError: 'Integration error',
        integrationRequestUrlError: 'Integration error',
        integrationResponseError: 'Integration error',
        invalidDateRANGE: 'Invalid date range',
        invalidGambleGAME: 'Invalid gamble parameters',
        invalidGameError: 'Invalid game parameters',
        invalidInputError: 'Invalid input',
        invalidJackpotQueue: 'Jackpot error',
        invalidParamError: 'Invalid parameters',
        invalidPlayerError: 'Invalid player',
        invalidPlayerTokenError: 'Invalid player token',
        invalidRoundError: 'Invalid round',
        invalidSignatureError: 'Invalid signature',
        invalidStateError: 'An error happened',
        invalidTransactionCurrencyError: 'Transaction error',
        invalidTransactionError: 'Transaction error',
        invalidTransactionTypeError: 'Transaction error',
        jackpotGamesNotFoundError: 'Jackpot error',
        jackpotNotFoundError: 'Jackpot error',
        notImplementedError: 'Feature not implemented',
        openBonusError: 'Bonus game is currently open',
        openGambleError: 'Gamble game is currently open',
        partnerDeactivatedError: 'Game is deactivated',
        partnerSetupError: 'Invalid setup',
        platformUnavailableError: 'Platform unavailable',
        roundNotFoundError: 'Round not found',
        roundStartGambleError: 'Gamble error',
        tenantCurrencyUnsupportedError: 'Unsupported currency',
        tenantDeactivatedError: 'Game or client is deactivated',
        tenantGamesNotFoundError: 'Invalid parameters',
        tenantMismatchError: 'Invalid parameters',
        tenantNotFoundError: 'Invalid parameters',
        transactionErrorError: 'Transaction error',
        transactionInProgressError: 'Existing transaction is in progress',
        transactionNotFoundError: 'Transaction not found',
        /* eslint-enable max-len */
      },
    };
    const { options } = new Parser(params, defaults);

    super('fishyGuy', options);

    state.create(options);

    audio.setOptions({ mute: options.mute });
    this.audio = audio;
    state.setSoundAmbient();

    this.setup();
    this.setListeners();
    this.resize(); // Will trigger this.onAfterResize

    pusher.init(player?.id, process.env.APP_PUSHER_KEY, tenantGameId);
    noSleep.init();

    state.disableInSpin();
    // enableAfterSpin is called in Reels after symbols are added
  }

  setup() {
    state.components.rootComponent = this;
    state.components.container = this.container;

    // Main components
    state.components.background = new Background();
    state.components.header = new Header();
    state.components.content = new Content();
    state.components.footer = new Footer();

    state.components.hooks = new Hooks();
    state.components.winGrading = new WinGrading();
    state.components.loaderOverlay = new LoaderOverlay();
    state.components.bonusGameIntroScreen = new BonusGameScreen('intro');
    state.components.bonusGameOutroScreen = new BonusGameScreen('outro');
    state.components.onboardScreen = new OnboardScreen();

    // Dialogs
    state.components.dialogMenu = new DialogMenu();
    state.components.dialogBetAmount = new DialogBetAmount();
    state.components.dialogStore = new DialogStore();
    state.components.dialogAutoplay = new DialogAutoplay();

    this.container.addChild(
      state.components.background.container,
      state.components.content.container,
      state.components.hooks.container,
      state.components.header.container,
      state.components.footer.container,
      state.components.winGrading.container,
      state.components.loaderOverlay.container,
      state.components.bonusGameIntroScreen.container,
      state.components.bonusGameOutroScreen.container,
      state.components.onboardScreen.container,
      state.components.dialogMenu.container,
      state.components.dialogBetAmount.container,
      state.components.dialogStore.container,
      state.components.dialogAutoplay.container,
    );
  }

  setListeners() {
    registerEventListener('MuteChanged', (event) => {
      if (event.detail.isSoundOff) {
        state.pauseSoundAmbient();
      } else {
        state.playSoundAmbient();
      }
    });

    registerEventListener('SocketUpdateBalance', (event) => {
      state.setBalanceFromSocket(event.detail.amount);
    });

    registerEventListener('ShowNotification', (event) => {
      state.setErrorDetails(event.detail);
    });

    // Sent from casino-client-slots application
    registerEventListener('ResetNotificationDetails', () => {
      state.setErrorDetails(undefined);
    });

    // Sent from casino-client-slots application
    registerEventListener('UpdateOptions', (event) => {
      state.updateOptions(event.detail);
    });

    // Sent from casino-client-slots application
    registerEventListener('OpenMenu', () => {
      state.components.dialogMenu.show(2);
    });
  }

  onAfterResize() {
    state.components.background.setPosition();
    state.components.header.setPosition();
    state.components.footer.setPosition();
    // Set content position after header and footer because it uses it's positions for calculation
    state.components.content.setPosition();
    state.components.hooks.setPosition();
    state.components.winGrading.setPosition();
    state.components.loaderOverlay.setPosition();
    state.components.bonusGameIntroScreen.setPosition();
    state.components.bonusGameOutroScreen.setPosition();
    state.components.onboardScreen?.setPosition();
    state.components.dialogMenu.setPosition();
    state.components.dialogBetAmount.setPosition();
    state.components.dialogStore.setPosition();
    state.components.dialogAutoplay.setPosition();
    state.components.notification?.setPosition();
  }

  // Called from OnboardScreen
  runIntroAnimation() {
    state.components.background.runIntroAnimation();
    state.components.content.runIntroAnimation();
  }

  openNotification(error) {
    state.components.notification = new Notification(error);
    this.container.addChild(state.components.notification.container);
  }
}
