import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, BitmapText, Circle } from '@/pixi';
import * as api from '@/api/casino';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { SlotSpinSpeedType } from '@/models';
import { Label } from './Label';
import { state } from './State';

export class Controls {
  #container;
  #spinButton;
  #storeButton;
  #autoplayButton;
  #spinCounter;
  #demoLabel;
  #lightningModeLabel;
  #spinNumber = 1;
  #previousLightningSpinState;
  #showLoaderOverlayTimeout = 2500;
  #spinButtonStopAvailable = false;
  #spinButtonAssetHeight;

  constructor() {
    const { spinButton, storeButton, autoplayButton } = state.assets;

    this.#container = new Container();
    this.#container.name = 'Controls';

    this.#spinButton = new Spine(spinButton.resource.spineData);
    this.#setSpinIdleState();
    this.#container.addChild(this.#spinButton);

    this.#spinButtonAssetHeight = this.#spinButton.height;

    if (state.options.bonusBuyEnabled || state.options.collectBuyEnabled) {
      this.#storeButton = new Spine(storeButton.resource.spineData);
      this.#storeButton.x = this.#spinButton.x - this.#spinButton.width / 2 - this.#storeButton.width / 2;
      this.#container.addChild(this.#storeButton);
    }

    this.#autoplayButton = new Spine(autoplayButton.resource.spineData);
    this.#autoplayButton.x = this.#spinButton.x + this.#spinButton.width / 2 + this.#autoplayButton.width / 2;
    this.#runAutoplayStopToPlayAnimation();
    this.#container.addChild(this.#autoplayButton);

    this.#spinCounter = new BitmapText(0, {
      fontName: state.options.customFont,
      fontSize: 175,
    });
    this.#spinCounter.anchor.set(0.51, 0.61);
    this.#spinCounter.visible = false;
    this.#container.addChild(this.#spinCounter);

    if (state.options.isDemo) {
      this.#demoLabel = new Label({ text: state.options.translations.demoMode });
      this.#demoLabel.container.x = this.#spinButton.x - this.#demoLabel.container.width / 2;
      this.#demoLabel.container.y = this.#spinButton.y + this.#spinButton.height / 2 - this.#demoLabel.container.height;
      this.#container.addChild(this.#demoLabel.container);
    } else {
      this.#lightningModeLabel = new Label({ text: state.options.translations.holdForLightningMode });
      this.#lightningModeLabel.container.x = this.#spinButton.x - this.#lightningModeLabel.container.width / 2;
      this.#lightningModeLabel.container.y = this.#spinButton.y + this.#spinButton.height / 2 - this.#lightningModeLabel.container.height;
      this.#lightningModeLabel.setVisible(false);
      this.#container.addChild(this.#lightningModeLabel.container);
    }

    this.#setListeners();
    this.#setWatchers();
  }

  get container() {
    return this.#container;
  }

  get controlsHeight() {
    return this.#spinButtonAssetHeight * this.#container.scale.y;
  }

  get #isSpinEnabled() {
    return this.#spinButton.eventMode === 'static';
  }

  #setListeners() {
    // Spin button
    const timeline = gsap.timeline();
    let allowPointerTap = true;
    let holdForLightningInProgress = false;

    this.#spinButton.eventMode = 'static';
    this.#spinButton.cursor = 'pointer';
    this.#spinButton.hitArea = new Circle(0, 0, this.#spinButton.width / 2);

    this.#spinButton.on('pointerdown', () => {
      if (this.#spinButtonStopAvailable) return;

      allowPointerTap = true;
      holdForLightningInProgress = true;

      timeline.to(this.#spinButton, {
        delay: 0.1,
        duration: 0.667,
        pixi: {
          scale: 1.17,
        },
      }).to(this.#spinButton, {
        duration: 0.167,
        pixi: {
          scale: 0.91,
        },
      }).to(this.#spinButton, {
        duration: 0.167,
        pixi: {
          scale: 1,
          alpha: 0.5,
        },
        onComplete: () => {
          allowPointerTap = false;
          holdForLightningInProgress = false;
          this.#previousLightningSpinState = state.isLightningSpinEnabled;
          state.setLightningSpinState(true);
          state.startAutoplay(10000); // Set infinite autoplay
        },
      });
    });

    this.#spinButton.on('pointertap', async () => {
      if (this.#spinButtonStopAvailable) {
        this.#onSpinStopClick();
      } else if (allowPointerTap) {
        // Reset timeline and spin button
        timeline.clear();
        this.#spinButton.scale.set(1);
        this.#spinButton.alpha = 1;
        holdForLightningInProgress = false;

        this.#onSpinClick();
      }
    });

    // Cancel hold for lightning spin
    this.#spinButton.on('pointerupoutside', () => {
      // Reset timeline and spin button
      timeline.clear();
      this.#spinButton.scale.set(1);
      this.#spinButton.alpha = 1;
      holdForLightningInProgress = false;
    });

    // Spacebar spin
    document.body.onkeyup = (event) => {
      if (event.code === 'Space' && this.#isSpinEnabled && !state.isDialogOpen && !holdForLightningInProgress && !state.errorDetails) {
        if (this.#spinButtonStopAvailable) {
          this.#onSpinStopClick();
        } else {
          this.#onSpinClick();
        }
      }
    };

    // Store button
    if (this.#storeButton) {
      this.#storeButton.eventMode = 'static';
      this.#storeButton.cursor = 'pointer';
      this.#storeButton.hitArea = new Circle(0, 0, this.#storeButton.height / 2);
      this.#storeButton.children[1].eventMode = 'none'; // Disable events on rope
      this.#storeButton.on('pointertap', () => {
        state.playTapSound();
        triggerAnalyticsEvent({ name: 'BuyButtonClick' });
        this.#runOpenStoreAnimation();
        state.components.dialogStore.show();
      });
    }

    // Autoplay button
    this.#autoplayButton.eventMode = 'static';
    this.#autoplayButton.cursor = 'pointer';
    this.#autoplayButton.hitArea = new Circle(0, 0, this.#autoplayButton.height / 2);
    this.#autoplayButton.children[2].eventMode = 'none'; // Disable events on rope
    this.#autoplayButton.on('pointertap', () => {
      state.playTapSound();

      // When clicked on play icon then open dialog
      if (!state.autoplay.enabled) {
        triggerAnalyticsEvent({ name: 'AutoplayButtonClick' });
        this.#runOpenAutoplayAnimation();
        state.components.dialogAutoplay.show();
      // When clicked on stop icon then stop autoplay
      } else {
        triggerAnalyticsEvent({ name: 'AutoplayStopButtonClick' });
        state.stopAutoplay();
      }
    });
  }

  #setWatchers() {
    state.watch('autoplay.enabled', (enabled) => {
      if (enabled) {
        this.#runAutoplayPlayToStopAnimation();
        this.spin();
      } else {
        this.#runAutoplayStopToPlayAnimation();
        this.#setAutoplayButtonState(false);

        // Reset previousLightningSpinState after lightning mode
        if (typeof this.#previousLightningSpinState === 'boolean') {
          state.setLightningSpinState(this.#previousLightningSpinState);
          this.#previousLightningSpinState = undefined;
        }
      }
    });

    state.watch('autoplay.numberOfSpins', (numberOfSpins) => {
      if (numberOfSpins > 0 && numberOfSpins < 200) {
        this.#spinCounter.text = numberOfSpins;
        this.#spinCounter.visible = true;
      } else {
        this.#spinCounter.visible = false;
      }
    });

    state.watch('bonusGame.numberOfSpins', (numberOfSpins) => {
      if (numberOfSpins > 0) {
        this.#spinCounter.text = numberOfSpins;
        this.#spinCounter.visible = true;
      } else {
        this.#spinCounter.visible = false;
      }
    });
  }

  #onSpinClick() {
    triggerAnalyticsEvent({ name: 'SpinButtonClick' });
    this.spin(undefined, true);
  }

  #onSpinStopClick() {
    this.resetSpinButtonStopAvailable();
    state.components.content.reelsWithLogo.reels.speedUpExitAndEnterTimeline();
    triggerAnalyticsEvent({ name: 'SpinButtonStopClick' });
  }

  #setSpinButtonState(enable) {
    this.#spinButton.eventMode = enable ? 'static' : 'none';
    this.#spinButton.alpha = enable ? 1 : 0.5;
  }

  #setStoreButtonState(enable) {
    if (this.#storeButton) {
      this.#storeButton.eventMode = enable ? 'static' : 'none';
      this.#storeButton.children[0].children[0].alpha = enable ? 1 : 0.5;
      this.#storeButton.children[2].children[0].alpha = enable ? 1 : 0.5;
    }
  }

  #setAutoplayButtonState(enable) {
    this.#autoplayButton.eventMode = enable ? 'static' : 'none';
    this.#autoplayButton.children[0].children[0].alpha = enable ? 1 : 0.5;
    this.#autoplayButton.children[1].children[0].alpha = enable ? 1 : 0.5;
    this.#autoplayButton.children[3].children[0].alpha = enable ? 1 : 0.5;
  }

  #setSpinIdleState() {
    this.#spinButton.state.setAnimation(0, 'spinIdle');
  }

  #runSpinAnimation() {
    this.#spinButton.state.setAnimation(0, 'spin');
    state.playSound('soundSpin');
  }

  #runOpenStoreAnimation() {
    this.#storeButton.state.setAnimation(0, 'openStore');
  }

  #runOpenAutoplayAnimation() {
    this.#autoplayButton.state.setAnimation(0, 'openAutoplay');
  }

  #runAutoplayStopToPlayAnimation() {
    this.#autoplayButton.state.setAnimation(0, 'stopToPlay');
  }

  #runAutoplayPlayToStopAnimation() {
    this.#autoplayButton.state.setAnimation(0, 'playToStop');
  }

  async spin(storeBuyResponse, allowSpinButtonStop = false) {
    let response = storeBuyResponse;

    state.disableInSpin();

    // If lightning spin is not enabled then enable back spin button so it can be clicked again to speed up spin
    if (!state.isLightningSpinEnabled && allowSpinButtonStop) {
      this.#spinButtonStopAvailable = true;
      this.#setSpinButtonState(true);
    }

    this.#runSpinAnimation();

    if (!response) {
      // Start loader overlay timeout
      const loaderTimeout = setTimeout(() => {
        state.components.loaderOverlay.show();
      }, this.#showLoaderOverlayTimeout);
      let meta;

      // If not in bonus game, add meta spinType
      if (!state.bonusGame.numberOfSpins) {
        meta = {
          spinType: state.isLightningSpinEnabled ? SlotSpinSpeedType.Lightning : SlotSpinSpeedType.Normal,
        };
      }

      response = await api.createRound(
        state.options.tenantGameId,
        state.options.playerToken,
        {
          betAmount: state.betAmount,
        },
        meta,
      );

      // Cancel loader overlay timeout, hide loader if shown
      clearTimeout(loaderTimeout);
      state.components.loaderOverlay.hide();
    }

    const isBonusGame = response.isFree;
    const isBonusGameWonInThisSpin = response.bonus?.isWon && !isBonusGame;

    // Show lightning label on 7th spin
    if (this.#spinNumber === 7 && this.#lightningModeLabel && !state.isLightningSpinEnabled && !isBonusGame && !isBonusGameWonInThisSpin) { // todo, add promotions
      this.#lightningModeLabel.setVisible(true);
    }

    // Hide lightning label on 8th spin
    if (this.#spinNumber === 8 && this.#lightningModeLabel) {
      this.#lightningModeLabel.setVisible(false, true);
      this.#lightningModeLabel = undefined;
    }

    this.#spinNumber += 1;

    if (response.isError) {
      state.setErrorDetails(response);

      state.stopAutoplay();

      // If not bonus game
      if (state.bonusGame.numberOfSpins === 0) {
        this.resetSpinButtonStopAvailable();
        state.enableAfterSpin();
      }
    } else {
      if (!isBonusGame) {
        state.decreaseBetAmountFromBalance(storeBuyResponse); // todo, don't call for promotions
        state.components.footer.hideWinAmount();
      }

      state.decreaseAutoplayNumberOfSpins();
      state.decreaseBonusGameNumberOfSpins();
      state.setRoundResponse(response);

      await state.components.content.reelsWithLogo.reels.spin(response);

      if (response.winAmount) {
        if (isBonusGame) {
          const amountFrom = response.bonusWinnings.bonusWinAmount - response.winAmount;
          state.components.footer.showWinAmountWithIncrement(response.bonusWinnings.bonusWinAmount, state.getMoneyLabel.bind(state), amountFrom);
        // Show winAmount for spin only if bonus is not won in that spin
        } else if (!isBonusGameWonInThisSpin) {
          state.components.footer.showWinAmount(state.getMoneyLabel(response.winAmount));
          state.setBalanceAmountAfterSpin();
        }
      }

      if (isBonusGameWonInThisSpin) {
        state.stopAutoplay();
        state.components.bonusGameIntroScreen.show(response.bonus.availableRounds);
      }

      state.checkAutoplayConditions();

      // Autoplay
      if (state.autoplay.enabled) {
        // If sufficient funds
        if (state.sufficientFunds) {
          this.spin();
        // If insufficient funds
        } else {
          state.stopAutoplay();
          state.enableAfterSpin();
        }
      // Bonus game
      } else if (isBonusGame) {
        // If there are still available spins
        if (state.bonusGame.numberOfSpins > 0) {
          this.spin();
        // After last spin
        } else {
          state.components.bonusGameOutroScreen.show(response.bonusWinnings.bonusWinAmount);
        }
      // Base game. If bonus game is not won in this spin
      } else if (!isBonusGameWonInThisSpin) {
        state.enableAfterSpin();
      }
    }
  }

  resetSpinButtonStopAvailable() {
    this.#spinButtonStopAvailable = false;
    this.#setSpinButtonState(false);
  }

  disableInSpin() {
    this.#setSpinButtonState(false);
    this.#setStoreButtonState(false);

    if (!state.autoplay.enabled) {
      this.#setAutoplayButtonState(false);
    }
  }

  enableAfterSpin() {
    this.#setSpinButtonState(state.sufficientFunds);
    this.#setAutoplayButtonState(state.sufficientFunds);

    this.#setStoreButtonState(true);
  }
}
