import { ControlsFooter2 } from '@/components/shared/ControlsFooter2';
import { gameUtils } from './GameUtils';
import { state } from './State';

export class Footer extends ControlsFooter2 {
  constructor() {
    super({
      currencyDisplayEnabled: state.options.currencyDisplayEnabled,
      currency: state.options.currency,
      appWidth: state.options.calcWidth,
      padding: state.options.uiPadding,
      balanceAmount: gameUtils.getMoneyLabel(state.balanceAmount),
      betAmount: gameUtils.getMoneyLabel(state.betAmount),
      balanceTranslation: state.options.translations.balance,
      betTranslation: state.options.translations.bet,
      winTranslation: state.options.translations.win,
      color: state.options.colors.text,
      winColor: state.options.colors.theme,
      titleFontName: state.options.fontDefaultLight,
      amountFontName: state.options.fontDefaultBold,
      getAppHeight: () => state.appHeight,
      onBetAmountClick: () => {
        state.components.dialogBetAmount.show();
      },
      playTapSound: () => { state.playTapSound(); },
    });

    this.container.name = 'Footer';

    this.setWatchers();
  }

  setWatchers() {
    state.watch('betAmount', (amount) => {
      this.setBetAmount(gameUtils.getMoneyLabel(amount));
    });

    state.watch('balanceAmount', (amount) => {
      this.setBalanceAmount(gameUtils.getMoneyLabel(amount));
    });

    state.watch('winAmount', (amount) => {
      if (amount) {
        this.showWinAmount(gameUtils.getMoneyLabel(amount));
      } else {
        this.hideWinAmount();
      }
    });
  }

  disableInSpin() {
    this.disableBetAmountClick();
  }

  enableAfterSpin() {
    this.enableBetAmountClick();
  }
}
