import gsap from 'gsap';
import { Container, BitmapText } from '@/pixi';
import { state } from './State';

export class SymbolWinAmount {
  #container;
  #winAmount;
  #multiplier;
  #endAmount;
  #startAmount;
  #maxWidth;

  constructor({ winAmount, startAmount, multiplier, position, maxWidth }) {
    this.#endAmount = winAmount;
    this.#startAmount = startAmount;
    this.#maxWidth = maxWidth;

    this.#container = new Container();
    this.#container.name = 'SymbolWinAmount';

    const winAmountContainer = new Container();

    this.#winAmount = new BitmapText(state.getMoneyLabel(startAmount || winAmount), {
      fontName: state.options.customFont2,
      fontSize: 90,
    });
    this.#winAmount.anchor.set(0.5, 0.62);
    winAmountContainer.addChild(this.#winAmount);

    this.#setWinAmountScale();

    // Add currency below amount
    if (state.options.currencyDisplayEnabled) {
      const currency = new BitmapText(state.options.currency, {
        fontName: state.options.customFont2,
        fontSize: this.#winAmount.fontSize / 2,
      });
      currency.anchor.set(0.5);
      currency.y = this.#winAmount.height / 2;
      winAmountContainer.addChild(currency);
    }

    winAmountContainer.pivot.y = winAmountContainer.height / 2 - this.#winAmount.height / 2;
    this.#container.addChild(winAmountContainer);

    // Add multiplier near amount
    if (multiplier) {
      this.#multiplier = new BitmapText(`x${multiplier}`, {
        fontName: state.options.customFont2,
        fontSize: 65,
      });
      this.#multiplier.anchor.set(0.5, 0.62);
      this.#multiplier.x = winAmountContainer.width / 2 + this.#multiplier.width / 2;
      this.#container.addChild(this.#multiplier);
    }

    this.#container.position = position;
    this.#container.visible = false;
  }

  get container() {
    return this.#container;
  }

  #setWinAmountScale() {
    if (this.#winAmount.width > this.#maxWidth) {
      this.#winAmount.scale.set(this.#maxWidth / (this.#winAmount.width / this.#winAmount.scale.y));
    }
  }

  createShowTimeline() {
    const timeline = gsap.timeline();
    const position = this.#container.y;

    // Add delay so that timeline starts little before end of symbol win animation
    timeline.to(this.#container, {
      delay: 0,
    }, 'delay');

    // Position
    timeline.fromTo(this.#container, {
      pixi: {
        y: position + 55.95,
      },
    }, {
      pixi: {
        y: position - 76.05,
      },
      onStart: () => {
        this.#container.visible = true;
      },
      duration: 1.583,
      ease: 'SymbolWinPosition',
    }, 'show');

    // Scale 1
    timeline.fromTo(this.#container, {
      pixi: {
        scale: 0.24,
      },
    }, {
      pixi: {
        scale: 1.04,
      },
      duration: 0.217,
      ease: 'SymbolWinScale1',
    }, 'show');

    // Scale 2
    timeline.fromTo(this.#container, {
      pixi: {
        scale: 1.04,
      },
    }, {
      delay: 0.217,
      pixi: {
        scale: 0.86,
      },
      duration: 0.667,
      ease: 'SymbolWinScale2',
    }, 'show');

    // Opacity 1
    timeline.fromTo(this.#container, {
      pixi: {
        alpha: 0,
      },
    }, {
      pixi: {
        alpha: 1,
      },
      duration: 0.083,
    }, 'show');

    // Opacity 2
    timeline.fromTo(this.#container, {
      pixi: {
        alpha: 1,
      },
    }, {
      delay: 0.983,
      pixi: {
        alpha: 0,
      },
      duration: 0.600,
      onComplete: () => {
        this.#container.destroy({ children: true });
      },
    }, 'show');

    if (this.#multiplier) {
      const amountAnimate = { amount: this.#startAmount };

      timeline.to(amountAnimate, {
        amount: this.#endAmount,
        delay: 0.350,
        duration: 0.233,
        onUpdate: () => {
          this.#winAmount.text = state.getMoneyLabel(amountAnimate.amount);
          this.#setWinAmountScale();
        },
      }, 'show');
    }

    return timeline;
  }
}
