import { Container } from '@/pixi';
import { Tabs } from '@/components/shared/Tabs';
import { Dialog } from './Dialog';
import { DialogMenuSettings } from './DialogMenuSettings';
import { DialogMenuRules } from './DialogMenuRules';
import { DialogMenuHistory } from './DialogMenuHistory';
import { state } from '../State';

export class DialogMenu extends Dialog {
  constructor() {
    const contentHistory = new DialogMenuHistory();
    const contentRules = new DialogMenuRules();

    const tabs = new Tabs({
      items: [
        {
          title: state.options.translations.dialogSettingsTitle,
          iconAsset: state.options.assets.iconSettings,
          content: new DialogMenuSettings(),
        },
        {
          title: state.options.translations.dialogMenuGameInfoTitle,
          iconAsset: state.options.assets.iconInfo,
          content: contentRules,
          onHide: contentRules.scrollToTop.bind(contentRules),
        },
        {
          title: state.options.translations.dialogMenuHistoryTitle,
          iconAsset: state.options.assets.iconHistory,
          content: contentHistory,
          onShow: contentHistory.onShow.bind(contentHistory),
        },
      ],
      onChange: (item) => {
        state.playTapSound();
        state.components.dialogMenu.updateTitle(item.title);
      },
      colorActive: state.options.colors.theme,
      padding: state.options.uiDialogPadding,
    });

    const content = {
      container: new Container(),
      tabs,
      show: (tabIndex = 0) => {
        tabs.showTab(tabIndex);
      },
      hide: () => {
        tabs.hideActiveTab();
      },
      setPosition: (data) => {
        tabs.setPosition(data);
      },
    };

    content.container.addChild(tabs.container);

    super({
      title: state.options.translations.dialogSettingsTitle,
      content,
    });

    this.container.name = 'DialogMenu';
  }
}
