import { Container, Graphics } from '@/pixi';
import { Spine } from 'pixi-spine';
import { assign, forEach, random } from 'lodash';
import animate from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { triggerEvent } from '@/utility/Utility';
import { Symbol } from './Symbol';
import { state } from './State';
import { audio } from './Audio';

export class Reel {
  constructor() {
    this.container = new Container();
    this.reelContainer = new Container();
    this.options = state.options;
    this.symbols = [];
    this.spinStackSymbols = undefined;
    this.reelRowPosition = undefined;
    this.reelBackground = undefined;
    this.rowHeight = undefined;
    this.revealTimeline = undefined;
    this.reelSpinPullBack = 48;
    this.reelSpinStopPushDown = 28;
    this.reelSpinStopPullBack = 28;
    this.revealStop = 18;
    this.revealStopPushDown = 28;
    this.revealStopPullBack = 10;
    this.rowPadding = this.options.uiPadding * 4;
    this.symbolOffset = 450;
    this.isSpinning = false;
    this.container.name = 'Reel';

    this.createRevealEasings();
    this.createSpinEasings();
    this.setup();
  }

  get reelContainerBounds() {
    return this.container.children[0].getLocalBounds();
  }

  get reelSize() {
    const { height, width } = this.reelBackground;

    return {
      height,
      width,
    };
  }

  animateSymbolsOnSpinStop() {
    this.symbols[1].model.zIndex = 2;
    this.reelContainer.children.sort((a, b) => a.zIndex - b.zIndex);
    this.symbols[0].runIdleAnimation();
    this.symbols[1].runWinAnimation();
    this.symbols[2].runIdleAnimation();
  }

  creteRowsPosition() {
    const center = this.reelBackground.skeleton.findBone('reel');
    this.rowHeight = this.reelSize.height / 3;

    this.reelRowPosition = [
      {
        x: center.worldX,
        y: center.worldY - this.symbolOffset,
      },
      {
        x: center.worldX,
        y: center.worldY,
      },
      {
        x: center.worldX,
        y: center.worldY + this.symbolOffset,
      },
    ];
  }

  createStartSymbols() {
    const startSymbols = [
      new Symbol(random(1, 7)),
      new Symbol(4),
      new Symbol(random(1, 7)),
    ];

    forEach(startSymbols, (symbol, index) => {
      this.symbols.push(symbol);
      this.reelContainer.addChild(symbol.model);
      symbol.setPosition(this.reelRowPosition[index].x, this.reelRowPosition[index].y);
    });
  }

  createMask() {
    const mask = new Graphics().beginFill(0xFF0000).drawRect(
      0,
      this.reelContainerBounds.top,
      this.reelSize.width * 4,
      this.reelSize.height,
    ).endFill();

    this.container.addChild(mask);
    this.container.mask = mask;
  }

  createRevealEasings() {
    CustomEase.create('revealSpin', '0.33, 0.00, 1.00, 1.00');
    CustomEase.create('revealPushDown', '0.33, 0.00, 0.67, 1.00');
    CustomEase.create('revealPullBack', '0.17, 0.17, 0.39, 1.00');
  }

  createSpinEasings() {
    CustomEase.create('spinPullBack', '0.33, 0.00, 0.83, 0.83');
    CustomEase.create('spin', '0.23, 0.04, 1.00, 1.00');
    CustomEase.create('spinStopPushDown', '0.17, 0.17, 0.39, 1.00');
    CustomEase.create('spinStopPullBack', '0.33, 0.00, 0.67, 1.00');
  }

  async setup() {
    this.reelBackground = new Spine(state.options.resources.reel.resource.spineData);
    this.reelBackground.alpha = 0;

    this.creteRowsPosition();
    this.container.addChild(this.reelBackground);
    this.container.addChild(this.reelContainer);
    this.createStartSymbols();
    this.setReelStartPosition();
    this.createMask();
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAspectRatio = state.originalSize.height / state.originalSize.width;

    if (aspectRatio < backgroundAspectRatio) {
      const scaleFactor = 0.75;

      this.container.scale.set(1, scaleFactor);
    }
  }

  setReelStartPosition() {
    const revealBackOut = this.reelSize.height;

    assign(this.reelContainer, {
      y: -(revealBackOut + (this.symbolOffset * 2) + (this.rowPadding * 2)),
    });
  }

  setAfterSpin(response) {
    this.clearSymbols();
    this.resetReelPosition();

    if (state.autoplay.enabled) {
      state.checkAutoplaySettings();
    }

    state.enableAfterSpin();
    this.animateSymbolsOnSpinStop();

    if (this.isTopPrizeSymbol(response.result.type)) {
      state.background.topPrize.animateTopPrizeAmount(response.state.topPrize.amount);
    }
    state.content.controls.updateCashpot(state.lastRound.state.cashPot);
    state.posts.checkPlates(response);
    this.isSpinning = false;
  }

  createStackSymbols(result) {
    const drawnSymbol = result?.number;
    const spinStackSymbols = [
      new Symbol(random(1, 7)),
      new Symbol(random(1, 7)),
      new Symbol(random(1, 7)),
      new Symbol(random(1, 7)),
      new Symbol(drawnSymbol),
      new Symbol(random(1, 7)),
    ];

    forEach(spinStackSymbols, (symbol, index) => {
      this.symbols.push(symbol);
      symbol.setPosition(
        this.reelRowPosition[0].x,
        (this.reelRowPosition[0].y - (this.symbolOffset * (index + 1))),
      );
      this.reelContainer.addChild(symbol.model);
    });
  }

  clearSymbols() {
    this.symbols.splice(0, 6);
    for (let i = 0; i < 6; i++) {
      if (this.reelContainer.children.length > 0) {
        const child = this.reelContainer.children[0];
        child.destroy({ children: true });
      }
    }
  }

  resetReelPosition() {
    const reelRowPositionReversed = [...this.reelRowPosition].reverse();
    assign(this.reelContainer, {
      y: 0,
    });
    this.reelContainer.children.forEach((symbol, index) => {
      assign(symbol, {
        y: reelRowPositionReversed[index].y,
      });
    });
  }

  isTopPrizeSymbol(type) {
    return type.includes('topPrize');
  }

  async spin(response) {
    this.createStackSymbols(response.result);
    this.isSpinning = true;
    const { reelContainer } = this;
    const reelEndPosition = this.symbolOffset * 6;
    const reelTimeline = animate.timeline();

    reelTimeline.to(reelContainer, {
      duration: 0.167,
      ease: 'spinPullBack',
      pixi: {
        y: `-=${this.reelSpinPullBack}`,
      },
    });

    reelTimeline.to(reelContainer, {
      duration: 0.542,
      ease: 'spin',
      pixi: {
        y: reelEndPosition,
      },
      onStart: () => {
        setTimeout(() => {
          audio.play(this.options.assets.soundSpin);
        }, 250);
        forEach(this.symbols, (symbol, index) => {
          if (index === 1) {
            symbol.runWinSpinAnimation();
          } else {
            symbol.runSpinAnimation();
          }
        });
      },
    });

    reelTimeline.to(reelContainer, {
      duration: 0.208,
      ease: 'spinStopPushDown',
      pixi: {
        y: `+=${this.reelSpinStopPushDown}`,
      },
      onStart: () => {
        forEach(this.symbols, (symbol) => {
          symbol.runSpinStopAnimation();
        });
      },
    });

    reelTimeline.to(reelContainer, {
      duration: 0.125,
      ease: 'spinStopPullBack',
      pixi: {
        y: `-=${this.reelSpinStopPullBack}`,
      },
      onComplete: () => {
        this.setAfterSpin(response);
      },
    });
  }

  revealContent() {
    state.footer.show();
    state.header.show();
    triggerEvent('ShowBoostBadge');
  }

  reveal() {
    const { reelContainer } = this;

    return new Promise((resolve) => {
      this.revealTimeline = animate.timeline({
        onComplete: () => {
          this.revealContent();
          this.revealTimeline.kill();
          resolve(true);
        },
      });
      this.revealTimeline.to(reelContainer, {
        duration: 0.375,
        ease: 'revealSpin',
        pixi: {
          y: this.revealStop,
        },
      });

      this.revealTimeline.to(reelContainer, {
        duration: 0.208,
        ease: 'revealPullBack',
        pixi: {
          y: `-=${this.revealStopPushDown}`,
        },
      });

      this.revealTimeline.to(reelContainer, {
        duration: 0.125,
        ease: 'revealPushDown',
        pixi: {
          y: `+=${this.revealStopPullBack}`,
        },
      });

      this.revealTimeline.play();
    });
  }
}
