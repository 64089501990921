import animate, { Power2 } from 'gsap';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { isFunction } from 'lodash';
import { Container, Sprite, BitmapText, Texture } from '@/pixi';

export class Jackpot {
  constructor({
    buttonComponent,
    buttonTextColor = 0x000001,
    buttonTitle,
    congratulationsText,
    fontBold,
    formatValue,
    getAppHeight,
    getAppWidth,
    highlightColor = 0xffe843,
    jackpotIcon,
    jackpotWinAmount,
    jackpotWonText,
    onCollect,
    onShow,
    playJackpotSound,
  }) {
    this.button = undefined;
    this.buttonComponent = buttonComponent;
    this.buttonTextColor = buttonTextColor;
    this.centerLine = undefined;
    this.container = new Container();
    this.dialogPadding = 100;
    this.fontBold = fontBold;
    this.getAppHeight = getAppHeight;
    this.getAppWidth = getAppWidth;
    this.highlightColor = highlightColor;
    this.jackpotDialog = undefined;
    this.jackpotDialogHeight = undefined;
    this.jackpotDialogWidth = undefined;
    this.jackpotIcon = jackpotIcon;
    this.jackpotIconContainer = undefined;
    this.jackpotTimeline = undefined;
    this.jackpotWinAmount = jackpotWinAmount;
    this.overlay = undefined;
    this.startAmount = 0;

    this.formatValue = formatValue;
    this.onCollect = onCollect;
    this.onShow = onShow;
    this.playJackpotSound = playJackpotSound;

    this.congratulationsLabel = undefined;
    this.jackpotWonLabel = undefined;
    this.jackpotWinAmountLabel = undefined;

    this.congratulationsText = congratulationsText;
    this.jackpotWonText = jackpotWonText;
    this.buttonTitle = buttonTitle;

    this.setup();
  }

  setup() {
    this.container.eventMode = 'static';
    this.jackpotTimeline = animate.timeline();

    const appWidth = this.getAppWidth();
    const appHeight = this.getAppHeight();

    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0.3;
    this.overlay.eventMode = 'dynamic';

    this.jackpotDialog = this.createJackpotDialog(appWidth, appHeight, 40);
    this.jackpotDialogWidth = this.jackpotDialog.width;
    this.jackpotDialogHeight = this.jackpotDialog.height;

    this.jackpotIconContainer = new Sprite(this.jackpotIcon.resource);
    this.jackpotDialog.addChild(this.jackpotIconContainer);

    this.centerLine = new Sprite(Texture.WHITE);
    this.centerLine.width = 150;
    this.centerLine.height = 4;
    this.centerLine.tint = this.highlightColor;
    this.jackpotDialog.addChild(this.centerLine);

    this.button = this.createButton();
    this.button.container.eventMode = 'none';
    this.button.container.alpha = 0.5;
    this.jackpotDialog.addChild(this.button.container);

    this.setLabels();

    this.container.addChild(this.overlay, this.jackpotDialog);
  }

  createJackpotDialog(width, height, radius) {
    const dialog = new Graphics();

    dialog.beginTextureFill({ color: '#0F0F0F' });

    dialog.drawRoundedRect(0, 0, width - this.dialogPadding, height / 1.75, radius).endFill();

    return dialog;
  }

  createButton() {
    const ButtonComponent = this.buttonComponent;

    const button = new ButtonComponent({
      width: this.jackpotDialogWidth - this.dialogPadding,
      height: this.jackpotDialogHeight / 8,
      radius: 20,
      textSize: 42,
      text: this.buttonTitle,
      backgroundColor: this.highlightColor,
      textColor: this.buttonTextColor,
      onClick: () => { this.collect(); },
    });

    return button;
  }

  setLabels() {
    this.congratulationsLabel = new BitmapText(`${this.congratulationsText.toUpperCase()}!`, {
      fontName: this.fontBold,
      fontSize: 50,
    });

    this.jackpotWonLabel = new BitmapText(this.jackpotWonText.toUpperCase(), {
      fontName: this.fontBold,
      fontSize: 35,
    });

    this.jackpotWinAmountLabel = new BitmapText(this.formatValue(this.startAmount), {
      fontName: this.fontBold,
      fontSize: 100,
    });

    this.jackpotDialog.addChild(this.congratulationsLabel);
    this.jackpotDialog.addChild(this.jackpotWonLabel);
    this.jackpotDialog.addChild(this.jackpotWinAmountLabel);
  }

  collect() {
    this.hide();
    this.onCollect();
  }

  hide() {
    const onComplete = () => {
      this.container.destroy({ children: true });
    };

    this.jackpotTimeline.fromTo(this.jackpotDialog, {
      pixi: {
        scale: 1,
        alpha: 1,
      },
    }, {
      duration: 0.5,
      ease: Power2.easeOut,
      pixi: {
        scale: 0.5,
        alpha: 0,
      },
      onComplete,
    });
  }

  animateJackpotIconStart() {
    const onComplete = () => {
      this.animateJackpotAmount();
    };

    this.jackpotTimeline.fromTo(this.jackpotIconContainer, {
      pixi: {
        scale: 10,
      },
    }, {
      duration: 0.5,
      ease: Power2.easeOut,
      pixi: {
        scale: 2,
      },
      onComplete,
    });
  }

  animateJackpotIconEnd() {
    const onComplete = () => {
      this.button.container.alpha = 1;
      this.button.container.eventMode = 'static';
    };

    this.jackpotTimeline.fromTo(this.jackpotIconContainer, {
      pixi: {
        scale: 2,
      },
    }, {
      delay: 1,
      duration: 0.5,
      ease: Power2.easeOut,
      pixi: {
        scale: 5,
      },
    }).to(this.jackpotIconContainer, {
      duration: 0.5,
      ease: Power2.easeOut,
      pixi: {
        scale: 2,
      },
      onComplete,
    });
  }

  animateJackpotAmount() {
    const onComplete = () => {
      this.animateJackpotIconEnd();
    };

    const amountAnimate = { amount: this.startAmount };

    this.jackpotTimeline.to(amountAnimate, {
      amount: this.jackpotWinAmount,
      duration: 0.5,
      onUpdate: () => {
        this.jackpotWinAmountLabel.text = this.formatValue(amountAnimate.amount);
      },
      onComplete,
    });
  }

  show() {
    if (isFunction(this.onShow)) {
      this.onShow();
    }

    const onComplete = () => {
      this.animateJackpotIconStart();
    };

    this.playJackpotSound?.();

    this.jackpotTimeline.fromTo(this.jackpotDialog, {
      pixi: {
        scale: 0.5,
        alpha: 0,
      },
    }, {
      duration: 0.25,
      ease: Power2.easeOut,
      pixi: {
        alpha: 1,
        scale: 1,
      },
      onComplete,
    });
  }

  setPosition() {
    const appWidth = this.getAppWidth();
    const appHeight = this.getAppHeight();

    this.overlay.width = appWidth;
    this.overlay.height = appHeight;

    this.jackpotDialog.x = appWidth / 2;
    this.jackpotDialog.y = appHeight / 2;
    this.jackpotDialog.pivot.x = this.jackpotDialogWidth / 2;
    this.jackpotDialog.pivot.y = this.jackpotDialogHeight / 2;

    this.jackpotIconContainer.anchor.set(0.5);
    this.jackpotIconContainer.x = this.jackpotDialogWidth / 2;
    this.jackpotIconContainer.y = this.dialogPadding * 1.75;

    this.congratulationsLabel.anchor.set(0.5);
    this.congratulationsLabel.x = this.jackpotDialogWidth / 2;
    this.congratulationsLabel.y = (this.jackpotDialogHeight / 2) - (this.dialogPadding * 2);

    this.jackpotWonLabel.anchor.set(0.5);
    this.jackpotWonLabel.x = this.jackpotDialogWidth / 2;
    this.jackpotWonLabel.y = (this.jackpotDialogHeight / 2) - this.dialogPadding;

    this.centerLine.anchor.set(0.5);
    this.centerLine.x = this.jackpotDialogWidth / 2;
    this.centerLine.y = (this.jackpotDialogHeight / 2);

    this.jackpotWinAmountLabel.anchor.set(0.5);
    this.jackpotWinAmountLabel.x = (this.jackpotDialogWidth / 2);
    this.jackpotWinAmountLabel.y = (this.jackpotDialogHeight / 2) + this.dialogPadding;

    this.button.container.x = this.jackpotDialogWidth - this.button.width - this.dialogPadding / 2;
    this.button.container.y = this.jackpotDialogHeight - this.button.height * 1.5;
  }
}
