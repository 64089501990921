import { Spine } from 'pixi-spine';
import { Container } from '@/pixi';
import { state } from './State';

export class Logo {
  #container;
  #logo;

  constructor() {
    const { logo } = state.assets;

    this.#container = new Container();
    this.#container.name = 'Logo';

    this.#logo = new Spine(logo.resource.spineData);
    this.#container.addChild(this.#logo);

    this.#setListeners();
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#logo.state.addListener({
      complete: (trackEntry) => {
        // After intro animation is complete run idle animation
        if (trackEntry.animation.name === 'logoIntro') {
          this.#logo.state.setAnimation(0, 'logoIdle', true);
          this.pauseAnimation();
        }
      },
    });
  }

  pauseAnimation() {
    this.#logo.state.timeScale = 0;
  }

  playAnimation() {
    this.#logo.state.timeScale = 1;
  }

  hide() {
    this.#container.alpha = 0.001;
  }

  show() {
    this.#container.alpha = 1;
  }

  runIntroAnimation() {
    this.#logo.state.setAnimation(0, 'logoIntro');
  }
}
