import { assign, defaultTo, isNil } from 'lodash';
import { getUuid } from '../utility/Utility';

let apiBaseUrl;
let demoParams;
let tenantId = '2f285401-94e7-4c17-b59a-859767796e79'; // Default id is of "PRVA FIRMA" tenant

export const getIsDemo = (playerToken) => isNil(playerToken);

export const getUrl = () => defaultTo(apiBaseUrl, 'https://casino-game.staging.de-3.nsoft.cloud');

export const getHeaders = () => ({
  'content-type': 'application/json;charset=UTF-8',
  'x-request-id': getUuid(),
});

export const getNotification = (result) => {
  const notification = {
    isError: result.success === false,
    message: result.message,
    details: result.details,
    ui: result.ui,
  };

  return notification;
};

export const getUnknownErrorNotification = () => {
  const notification = {
    isError: true,
    message: 'NETWORK_ERROR',
  };

  return notification;
};

export const getState = async (baseUrl, tenantGameId, playerToken) => {
  apiBaseUrl = baseUrl || apiBaseUrl;

  const response = await fetch(`${getUrl()}/game/play`, {
    method: 'POST',
    mode: 'cors',
    headers: getHeaders(),
    body: JSON.stringify({
      action: 'getState',
      tenantGameId,
      playerToken,
      isDemo: getIsDemo(playerToken),
      details: {},
    }),
  });

  const result = await response.json();

  return result.details;
};

export const getJackpot = async (jackpotType, currency) => {
  const response = await fetch(`${getUrl()}/public/jackpot?jackpotType=${jackpotType}&tenantId=${tenantId}&currency=${currency}`, {
    method: 'GET',
    mode: 'cors',
  });

  const result = await response.json();

  return result.details;
};

export const changeBet = async (tenantGameId, playerToken, details) => {
  const isDemo = getIsDemo(playerToken);

  if (isDemo && demoParams) {
    assign(details, {
      demoParams,
    });
  }

  try {
    const response = await fetch(`${getUrl()}/game/play`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action: 'changeBet',
        tenantGameId,
        playerToken,
        isDemo,
        details,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    demoParams = result.details.demoParams;

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const cashout = async (tenantGameId, playerToken, details) => {
  const isDemo = getIsDemo(playerToken);

  if (isDemo && demoParams) {
    assign(details, {
      demoParams,
    });
  }

  try {
    const response = await fetch(`${getUrl()}/game/play`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action: 'cashout',
        tenantGameId,
        playerToken,
        isDemo,
        details,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    demoParams = result.details.demoParams;

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const createRound = async (tenantGameId, playerToken, details, meta) => {
  const isDemo = getIsDemo(playerToken);

  if (isDemo && demoParams) {
    assign(details, {
      demoParams,
    });
  }

  try {
    const response = await fetch(`${getUrl()}/game/play`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action: 'createRound',
        tenantGameId,
        playerToken,
        isDemo,
        details,
        meta,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    demoParams = result.details.demoParams;

    if (window.setDummyResult) {
      window.setDummyResult(result);
    }

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const calculateBonusPrices = async (tenantGameId, playerToken, details, action) => {
  const isDemo = getIsDemo(playerToken);

  if (isDemo && demoParams) {
    assign(details, {
      demoParams,
    });
  }

  try {
    const response = await fetch(`${getUrl()}/game/play`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action,
        tenantGameId,
        playerToken,
        isDemo,
        details,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    demoParams = result.details.demoParams;

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const buyBonus = async (tenantGameId, playerToken, action, details) => {
  const isDemo = getIsDemo(playerToken);

  if (isDemo && demoParams) {
    assign(details, {
      demoParams,
    });
  }

  try {
    const response = await fetch(`${getUrl()}/game/play`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action,
        tenantGameId,
        playerToken,
        isDemo,
        details,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    demoParams = result.details.demoParams;

    return result.details;
  } catch (error) {
    return this.getUnknownErrorNotification();
  }
};

export const getLobby = async ({
  currency,
  locale,
  platform,
  token,
  playerToken,
}) => {
  const queryParams = new URLSearchParams({
    tenantId,
    currency,
    locale,
    platform,
    token,
    playerToken,
  }).toString();

  try {
    const response = await fetch(`${getUrl()}/public/lobby?${queryParams}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const getRounds = async ({
  tenantGameId,
  playerToken,
  dateFrom,
  dateTo,
  size = 20,
  page = 1,
}) => {
  const queryParams = new URLSearchParams({
    dateFrom,
    dateTo,
    size,
    page,
  }).toString();

  try {
    const response = await fetch(`${getUrl()}/player?${queryParams}`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action: 'getRounds',
        tenantGameId,
        playerToken,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const getRound = async ({ id, tenantGameId }) => {
  const queryParams = new URLSearchParams({ id, tenantGameId }).toString();

  try {
    const response = await fetch(`${getUrl()}/public/round?${queryParams}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const getBalance = async (tenantGameId, playerToken) => {
  try {
    const response = await fetch(`${getUrl()}/player`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify({
        action: 'getBalance',
        tenantGameId,
        playerToken,
      }),
    });

    const result = await response.json();

    if (response.status >= 400) {
      return getNotification(result);
    }

    return result.details;
  } catch (error) {
    return getUnknownErrorNotification();
  }
};

export const setEnvironment = (environment) => {
  apiBaseUrl = environment.baseUrl;
  tenantId = environment.tenantId;
};
