import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, Ticker } from '@/pixi';
import { Cash } from './Cash';
import { state } from './State';

export class HeaderReelsSymbol {
  #container;
  #symbol;
  #prize;
  #symbolCopy;
  #symbolNumber;
  #cashSymbolLevel;

  constructor({
    symbolNumber,
    position,
    prize,
    cashLevel,
    isStatic = false,
  }) {
    this.#symbolNumber = symbolNumber;
    this.#cashSymbolLevel = cashLevel;

    this.#container = new Container();
    this.#container.name = 'HeaderReelsSymbol';

    this.#symbol = new Spine(this.#symbolAsset.resource.spineData);
    this.#container.addChild(this.#symbol);

    // Cash symbol
    if (prize) {
      this.#addPrizeToSymbol(prize);
    }

    this.#container.position = position;

    if (isStatic) {
      this.#symbol.state.timeScale = 1000;
      this.#setIdleState(false);
    } else {
      this.#container.y = state.components.content.reelsWithLogo.reels.mainReelsPositionY - this.#container.height / 2;

      this.#setListeners();
      this.#runIntroAnimation();
    }
  }

  get #symbolAsset() {
    return this.#symbolNumber === state.options.symbols.cash ? state.assets[`symbolCashHeader${this.#cashSymbolLevel}`] : state.assets.symbolTrashHeader;
  }

  get #outroAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('outro').duration;
  }

  get #winAnimationDuration() {
    return this.#symbol.stateData.skeletonData.findAnimation('win').duration;
  }

  get #collectAnimationDuration() {
    return this.#symbolCopy.stateData.skeletonData.findAnimation('collect').duration;
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#symbol.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'intro') {
          this.#setIdleState();
        }
      },
    });
  }

  #getIntroAnimationDuration(symbol = this.#symbol) {
    return symbol.stateData.skeletonData.findAnimation('intro').duration;
  }

  #addPrizeToSymbol(prize, symbol = this.#symbol) {
    const numberContainer = symbol.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number');
    const fontSize = 42;

    this.#prize = new Cash(prize, symbol.width, fontSize);

    // Remove default number text, and add custom text
    numberContainer.removeChildren();
    numberContainer.addChild(this.#prize.container);
  }

  #createSymbolCopy() {
    this.#symbolCopy = new Spine(this.#symbolAsset.resource.spineData);
    this.#symbolCopy.slotContainers.find((container) => container.children[0]?.attachment?.name === 'Number').children[0].destroy(); // Remove prize
    this.#symbolCopy.visible = false;
  }

  #followHookMovement(hook) {
    this.#symbolCopy.position = hook.bonePosition;
  }

  #runIntroAnimation(symbol = this.#symbol) {
    symbol.state.setAnimation(0, 'intro');
  }

  #setIdleState(loop = true) {
    this.#symbol.state.setAnimation(0, 'idle', loop);
  }

  #runOutroAnimation() {
    this.#symbol.state.setAnimation(0, 'outro');
  }

  #runWinAnimation() {
    this.#symbol.state.setAnimation(0, 'win');
  }

  #runCollectAnimation() {
    this.#symbolCopy.state.setAnimation(0, 'collect');
  }

  setTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: 1,
      },
    }, {
      pixi: {
        alpha: 0.5,
      },
      duration: 0.75,
    });
  }

  resetTransparency() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: this.#container.alpha,
      },
    }, {
      pixi: {
        alpha: 1,
      },
      duration: 0.75,
    });
  }

  createUpdateSymbolTimeline({ symbol, totalPrize, prizeLevel }) {
    const timeline = gsap.timeline();

    this.#symbolNumber = symbol;
    this.#cashSymbolLevel = prizeLevel;

    const newSymbol = new Spine(this.#symbolAsset.resource.spineData);

    timeline.to(this.#container, {
      onStart: () => {
        this.#runOutroAnimation();
      },
      duration: this.#outroAnimationDuration,
    });

    timeline.to(this.#container, {
      onStart: () => {
        // Set prize for new cash symbol
        if (totalPrize) {
          this.#addPrizeToSymbol(totalPrize, newSymbol);
        }
        // Run intro animation and add new symbol to container right before old symbol is destroyed
        this.#runIntroAnimation(newSymbol);
        this.#container.addChildAt(newSymbol, 0);
        // After outro animation is done remove current symbol
        this.#symbol.destroy({ children: true });
        this.#symbol = newSymbol;
        this.#setListeners();
      },
      duration: this.#getIntroAnimationDuration(newSymbol),
    });

    return timeline;
  }

  createCollectTimeline(hook, isLast) {
    const timeline = gsap.timeline();
    const ticker = new Ticker().add(() => { this.#followHookMovement(hook); });
    const hookStartPosition = { x: hook.container.x, y: hook.container.y };

    if (!this.#symbolCopy) {
      this.#createSymbolCopy();
      state.components.hooks.addCashSymbol(this.#symbolCopy);
    }

    timeline.to(this.#container, {
      onStart: () => {
        this.#runWinAnimation();
      },
      duration: this.#winAnimationDuration,
    });

    timeline.to(this.#container, {
      onStart: () => {
        // Set symbolCopy to start position
        this.#symbolCopy.position = hookStartPosition;
        this.#symbolCopy.visible = true;
        // Start ticker, so cash symbol follows hook
        ticker.start();
        // Run collect for cash symbol
        this.#runCollectAnimation();
        // Run intro for new cash symbol
        this.#runIntroAnimation();

        state.playSound('soundCollectCash');

        // Hide prize if last cash symbol
        if (isLast) {
          this.#prize.container.visible = false;
        }
      },
      duration: this.#collectAnimationDuration,
      onComplete: () => {
        ticker.stop();

        if (isLast) {
          state.components.hooks.removeCashSymbol(this.#symbolCopy);
          this.#symbolCopy = undefined;
        // Prepare symbolCopy for new collect
        } else {
          this.#symbolCopy.visible = false;
        }
      },
    });

    return timeline;
  }
}
