import { DialogContentBetAmount } from '@/components/shared/DialogContentBetAmount';
import { gameUtils } from './GameUtils';
import { Dialog } from './Dialog';
import { state } from './State';

export class DialogBetAmount extends Dialog {
  constructor() {
    const content = new DialogContentBetAmount({
      betAmounts: state.options.settings.predefinedBetAmounts,
      getActiveBetAmount: () => state.betAmount,
      getBalanceAmount: () => state.balanceAmount,
      onChange: async (value) => {
        const requestPayload = [
          state.options.tenantGameId,
          state.options.playerToken,
          {
            betAmount: value,
          },
          {},
        ];

        const response = await gameUtils.makeApiRequest('changeBet', requestPayload, false);

        if (response.isError) {
          state.setErrorDetails(response);
        } else {
          state.setBetAmount(value);
          state.background.topPrize.updateTopPrizeAmount(response.state.topPrize.amount);
          if (state.sufficientFunds && state.content.controls.isSpinDisabled) {
            state.content.controls.enableSpin();
          }
        }
      },
      formatBetAmount: (value) => state.getMoneyLabel(value),
      fontName: state.options.fontNameNormal,
      betAmountColor: 0x7d7d7d,
      betAmountColorActive: state.options.colors.theme,
      padding: state.options.uiDialogPadding,
      playTapSound: () => { state.playTapSound(); },
    });

    super({
      title: state.options.translations.dialogBetAmountTitle,
      content,
      isFluid: true,
    });

    this.container.name = 'DialogBetAmount';
  }
}
