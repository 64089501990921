import { random } from 'lodash';
import { Container } from '@/pixi';
import { state } from './State';
import { Symbol } from './Symbol';

export class ReelStack {
  constructor({
    availableSymbols,
    biasedNumber,
    stackSize,
    symbolBoxSize,
    numOfStackedRows,
    reelIndex,
    rowIndex,
    mask,
  }) {
    this.container = new Container();
    this.container.name = 'ReelStack';
    this.container.interactiveChildren = false;

    this.availableSymbols = availableSymbols;
    this.biasedNumber = biasedNumber;
    this.mask = mask;
    this.numOfStackedRows = numOfStackedRows;
    this.reelIndex = reelIndex;
    this.rowIndex = rowIndex;
    this.stackSize = stackSize;
    this.symbolBoxSize = symbolBoxSize;

    this.createReelStack();
    this.setPosition();
  }

  createReelStack() {
    for (let i = 0; i < this.stackSize; i++) {
      const symbol = new Symbol({
        value: this.getRandomNumberWithBias(this.availableSymbols, this.biasedNumber),
      });

      symbol.container.y = this.symbolBoxSize / 2 + (i - this.stackSize) * this.symbolBoxSize;
      symbol.setAnimation('symbolSpin', true);

      this.container.mask = this.mask;

      this.container.addChild(symbol.container);
    }
  }

  /* Biased number will have 50% chance to be returned */
  getRandomNumberWithBias(arr, biasedNumber) {
    if (biasedNumber >= 0) {
      const rand = random(0, 1, true);

      if (rand < 0.5) {
        return biasedNumber;
      }

      return arr.filter((num) => num !== biasedNumber)[random(0, arr.length - 2)];
    }

    return arr[random(0, arr.length - 1)];
  }

  setPosition() {
    this.container.x = this.symbolBoxSize / 2 + this.reelIndex * this.symbolBoxSize + state.options.reelPadding[1];
    this.container.y = this.symbolBoxSize + (this.rowIndex - this.numOfStackedRows) * this.symbolBoxSize + state.options.reelPadding[0] + state.options.reelJackpotHeight;
  }
}
