import { pusher, noSleep } from '@/plugins';
import { Graphics } from '@/pixi';
import { defaultTo } from 'lodash';
import { registerEventListener } from '@/utility/Utility';
import { Component } from '@/components/shared/Component';
import { Parser } from '@/components/cherryBlast/Parser';
import { state } from '@/components/cherryBlast/State';
import { Background } from '@/components/cherryBlast/Background';
import { Header } from '@/components/cherryBlast/Header';
import { Footer } from '@/components/cherryBlast/Footer';
import { Content } from '@/components/cherryBlast/Content';
import { WinGrading } from '@/components/cherryBlast/WinGrading';
import { DialogBetAmount } from '@/components/cherryBlast/dialogs/DialogBetAmount';
import { DialogAutoplay } from '@/components/cherryBlast/dialogs/DialogAutoplay';
import { DialogMenu } from '@/components/cherryBlast/dialogs/DialogMenu';
import { DialogBuy } from '@/components/cherryBlast/dialogs/DialogBuy';
import { Onboard } from '@/components/cherryBlast/Onboard';
import { BonusIntroCashLink } from '@/components/cherryBlast/BonusIntroCashLink';
import { BonusIntroStickyWild } from '@/components/cherryBlast/BonusIntroStickyWild';
import { BonusOutro } from '@/components/cherryBlast/BonusOutro';
import { LoaderOverlay } from '@/components/cherryBlast/LoaderOverlay';
import { audio } from '@/components/cherryBlast/Audio';
import { Notification } from '@/components/cherryBlast/Notification';
import translations from '@/components/cherryBlast/TranslationsDefault';

export class CherryBlast extends Component {
  constructor(params) {
    const { name, tenantGameId } = params.state.game;
    const { settings, config, ui, player } = params.state;

    const defaults = {
      bonusDataOnInit: params.state.state.bonus?.isActive ? params.state.state.bonus : undefined,
      currency: player?.balance?.currency || params.tenantCurrency || 'EUR',
      isDemo: true,
      locale: 'en',
      mute: true,
      name,
      player,
      settings,
      spinSpeedType: 'Normal',
      tenantGameId,
      translations,
      ui,
      colors: {
        text: 0xdfe0e5,
        theme: 0xf52229,
      },
      config: {
        ...config,
        baseGameSymbols: config.symbols,
        cashLinkSymbols: config.bonusList[2].symbols,
        symbolMap: {
          sticky: 'Sticky',
          wild: 10,
          bonus: 12,
          cash: 13,
          multiplier: 16,
          empty: 23,
          jackpot: {
            mini: 24,
            mega: 25,
            grand: 26,
          },
        },
      },

      /* UI and Settings state */
      autoPlayEnabled: defaultTo(ui.autoPlayEnabled, false),
      bonusBuyEnabled: defaultTo(ui.bonusBuyEnabled, false),
      currencyDisplayEnabled: defaultTo(ui.currencyDisplayEnabled, false),
      lobbyEnabled: defaultTo(ui.lobbyEnabled, false),

      /* Dimensions */
      calcWidth: 1080,
      reelJackpotHeight: 130,
      reelPadding: [30, 0], // y = 30, x = 0
      smallDeviceHeightLimit: 600,
      uiDialogPadding: 48,
      uiPadding: 24,

      /* Font names */
      fontCherryGold: 'cherryGold',
      fontCherryNumbers: 'customFontNumbers',
      fontCherryWhite: 'cherryWhite',
      fontDefault: 'Roboto',
      fontDefaultBold: 'RobotoBold',
      fontDefaultLight: 'RobotoLight',
      fontDefaultNormal: 'RobotoNormal',
    };

    const { options } = new Parser(params, defaults);

    super('cherryBlast', options);

    state.create(options);
    audio.setOptions({ mute: options.mute });
    noSleep.init();
    pusher.init(player?.id, process.env.APP_PUSHER_KEY, tenantGameId);

    /* Play sound ambient if audio is not muted */
    state.playSoundAmbient();

    /* Content mask that can be used on elements that are larger than canvas */
    this.contentMask = undefined;

    this.audio = audio;

    this.createContentMask();
    this.setup();
    this.setListeners();
    this.resize();
  }

  setup() {
    state.components.root = this;
    state.components.container = this.container;

    state.components.background = new Background();
    state.components.header = new Header();
    state.components.footer = new Footer();
    state.components.content = new Content();
    state.components.winGrading = new WinGrading();
    state.components.dialogBetAmount = new DialogBetAmount();
    state.components.dialogAutoplay = new DialogAutoplay();
    state.components.dialogMenu = new DialogMenu();
    state.components.dialogBuy = new DialogBuy();
    state.components.bonusIntroCashLink = new BonusIntroCashLink();
    state.components.bonusIntroStickyWild = new BonusIntroStickyWild();
    state.components.bonusOutro = new BonusOutro();
    state.components.onboard = new Onboard();
    state.components.loaderOverlay = new LoaderOverlay();

    this.container.addChild(
      state.components.background.container,
      state.components.header.container,
      state.components.footer.container,
      state.components.content.container,
      state.components.winGrading.container,
      state.components.dialogBetAmount.container,
      state.components.dialogAutoplay.container,
      state.components.dialogMenu.container,
      state.components.dialogBuy.container,
      state.components.bonusIntroCashLink.container,
      state.components.bonusIntroStickyWild.container,
      state.components.bonusOutro.container,
      state.components.onboard.container,
      state.components.loaderOverlay.container,
    );

    /* Enable controls on onboard close */
    state.disableInSpin();
  }

  createContentMask() {
    const { width, height } = state.options.size();

    if (this.contentMask) {
      /* Redraw existing mask */
      this.contentMask.clear()
        .beginFill(0x000000)
        .drawRect(0, 0, width, height)
        .endFill();
    } else {
      /* Create new content mask */
      this.contentMask = new Graphics()
        .beginFill(0x000000)
        .drawRect(0, 0, width, height)
        .endFill();
      this.contentMask.visible = false;
      this.container.addChild(this.contentMask);
    }
  }

  setListeners() {
    registerEventListener('MuteChanged', (event) => {
      if (event.detail.isSoundOff) {
        state.stopSoundAmbientBase();
      } else {
        state.playSoundAmbientBase();
      }
    });

    registerEventListener('SocketUpdateBalance', (event) => {
      state.setBalanceFromSocket(event.detail.amount);
    });

    registerEventListener('ShowNotification', (event) => {
      state.setErrorDetails(event.detail);
    });

    // Sent from casino-client-slots application
    registerEventListener('ResetNotificationDetails', () => {
      state.setErrorDetails(undefined);
    });

    // Sent from casino-client-slots application
    registerEventListener('UpdateOptions', (event) => {
      state.updateOptions(event.detail);
    });

    // Sent from casino-client-slots application
    registerEventListener('OpenMenu', () => {
      state.components.dialogMenu.show(2);
    });
  }

  onAfterResize() {
    this.createContentMask(); /* Recalculate mask on every resize */
    state.components.background.setPosition();
    state.components.header.setPosition();
    state.components.footer.setPosition();
    state.components.content.setPosition();
    state.components.winGrading.setPosition();
    state.components.dialogBetAmount.setPosition();
    state.components.dialogAutoplay.setPosition();
    state.components.dialogMenu.setPosition();
    state.components.dialogBuy.setPosition();
    state.components.bonusIntroCashLink.setPosition();
    state.components.bonusIntroStickyWild.setPosition();
    state.components.bonusOutro.setPosition();
    state.components.loaderOverlay.setPosition();
    state.components.notification?.setPosition();
    state.components.onboard?.setPosition();
  }

  openNotification(error) {
    state.components.notification = new Notification(error);
    this.container.addChild(state.components.notification.container);
  }
}
