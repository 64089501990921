/* eslint-disable no-await-in-loop */
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Spine } from 'pixi-spine';
import { Container, Graphics, BitmapText } from '@/pixi';
import { sleep, getPaylineCenter } from '@/utility/Utility';
import { Symbol } from './Symbol';
import { SymbolWinAmount } from './SymbolWinAmount';
import { HeaderReelsSymbol } from './HeaderReelsSymbol';
import { BonusGameCollectLevels } from './BonusGameCollectLevels';
import { Hook } from './Hook';
import { state } from './State';

export class Reels {
  #container;
  #background;
  #bonusGameBackground;
  #mainReelsMask;
  #bonusGameCollectLevels;
  #exitTimeline;
  #exitTimelinesData = [];
  #headerSymbolsComponents = [];
  #bonusGameExitTimelinesData = [];
  #columnsLength = 5;
  #mainReelsRowsLenght = 5;
  #zIndexOverSymbol = 50;
  #exitAndEnterSpeedUpTriggered = false;

  constructor() {
    const { reels, bonusReels } = state.assets;

    this.#container = new Container();
    this.#container.name = 'Reels';
    this.#container.sortableChildren = true;

    this.#background = new Spine(reels.resource.spineData);
    this.#bonusGameBackground = new Spine(bonusReels.resource.spineData);
    this.#bonusGameBackground.visible = false;
    this.#container.addChild(this.#background, this.#bonusGameBackground);

    this.#createSymbolWinCustomEases();
    this.#setListeners();
  }

  get #symbolBoxSize() {
    return this.mainReelsSize / this.#columnsLength;
  }

  get container() {
    return this.#container;
  }

  get mainReelsSize() {
    return this.#background.width;
  }

  get mainReelsPositionY() {
    this.#background.getLocalBounds(); // It doesn't work without this for some reason

    return this.#background.children[0].getLocalBounds().top + 3;
  }

  get isIntroAnimationActive() {
    return this.#background.state.queue.animState.tracks[0]?.animation?.name === 'reelsIntro';
  }

  #setListeners() {
    this.#background.state.addListener({
      complete: async (trackEntry) => {
        if (trackEntry.animation.name === 'reelsIntro') {
          // After intro animation is complete run idle animation
          this.#background.state.setAnimation(0, 'reelsIdle');
          // Adjust positioning after intro, in case application was resized during intro
          state.components.rootComponent.resize();

          // Add reels mask right before symbols are added
          this.#container.addChild(this.#mainReelsMask);

          // If bonus game is active
          if (state.options.bonusGameDataOnAppStart?.availableRounds) {
            this.#addSymbols(this.#addStartSymbols(true));
            state.components.bonusGameIntroScreen.show(state.options.bonusGameDataOnAppStart.availableRounds);
          } else {
            await this.#addSymbols(this.#addStartSymbols());
            state.enableAfterSpin();
          }
        }
      },
    });
  }

  #createSymbolWinCustomEases() {
    CustomEase.create('SymbolWinPosition', '0.20, 0.00, 0.68, 1.00');
    CustomEase.create('SymbolWinScale1', '0.33, 0.00, 0.67, 1.00');
    CustomEase.create('SymbolWinScale2', '0.33, 0.00, 0.83, 1.00');
  }

  #createMask() {
    const width = this.mainReelsSize / this.#container.parent.scale.x; // Screen width
    const height = this.mainReelsSize;
    const x = -(width - this.mainReelsSize) / 2;
    const y = this.mainReelsPositionY;

    if (!this.#mainReelsMask) {
      this.#mainReelsMask = new Graphics().beginFill(0x000000).drawRect(x, y, width, height).endFill();
    } else {
      this.#mainReelsMask.clear().beginFill(0x000000).drawRect(x, y, width, height).endFill();
    }
  }

  #addStartSymbols(isBonusGame) {
    const mergedReels = isBonusGame ? state.options.bonusGameDataOnAppStart.reelWindow : state.options.startReels;

    return { mergedReels, bonus: { isWon: isBonusGame }, collectCash: {} };
  }

  async #addSymbols({ mergedReels, numOfSteps, symbolWinnings, collectCash, bonus, winAmount, winGrades }) {
    const symbolsMatrix = [[], [], [], [], []];
    const enterTimeline = gsap.timeline({ paused: true });
    const collectBaseSymbols = [];
    const collectExtraSymbols = [];

    // Create symbols. Create enter timelines
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = 0; rowIndex < mergedReels[columnIndex].length - 1; rowIndex++) {
        const symbolObject = mergedReels[columnIndex][rowIndex];
        let component = null;

        // Don't create component for cash symbol that won't land in main reels
        if (!this.#isCashSymbolOnlyFromHeaderReels(symbolObject)) {
          component = this.#createSymbol(symbolObject, columnIndex, rowIndex);
          component.container.mask = this.#mainReelsMask;
          this.#container.addChild(component.container);
        }

        symbolsMatrix[columnIndex].push({
          ...symbolObject,
          component,
        });

        // Add collect symbol
        const symbolsMatrixObject = symbolsMatrix[columnIndex][rowIndex];
        if (state.options.symbols.collectBase === symbolsMatrixObject.symbol && collectCash.prizePerBaseSymbol) {
          symbolsMatrixObject.winAmount = collectCash.prizePerBaseSymbol;
          collectBaseSymbols.push(symbolsMatrixObject);
        } else if (state.options.symbols.collectExtra === symbolsMatrixObject.symbol && collectCash.prizePerExtraSymbol) {
          symbolsMatrixObject.winAmount = collectCash.prizePerExtraSymbol;
          collectExtraSymbols.push(symbolsMatrixObject);
        }

        // Create enter timeline
        if (rowIndex < this.#mainReelsRowsLenght) {
          const symbolEnterPositionY = this.#getSymbolEnterPositionY(rowIndex);
          const delay = state.isLightningSpinEnabled || this.#exitAndEnterSpeedUpTriggered ? 0 : this.#getSymbolDelay(rowIndex, columnIndex);

          enterTimeline.add(component.createEnterTimeline(symbolEnterPositionY), `enter+=${delay}`);
        }
      }

      // Add symbols enter sound/s
      const playSoundOnlyOnce = state.isLightningSpinEnabled || this.#exitAndEnterSpeedUpTriggered;
      if (columnIndex === 0 || !playSoundOnlyOnce) {
        const delay = this.#getSymbolDelay(0, columnIndex) + 0.2; // 0.2 is enter timeline positionY animation duration

        enterTimeline.add(() => {
          if (!state.isBonusGameIntroScreenOpen) {
            state.playSound('soundSymbolsEnter');
          }
        }, `enter+=${delay}`);
      }

      // Don't create component for symbol in last row because it won't be shown in main reels
      const lastSymbolObject = mergedReels[columnIndex][mergedReels[columnIndex].length - 1];
      symbolsMatrix[columnIndex].push({
        ...lastSymbolObject,
        component: null,
      });

      // Create component for header symbol only once, it will be updated later using methods
      if (!this.#headerSymbolsComponents[columnIndex]) {
        const symbolObject = symbolsMatrix[columnIndex][this.#mainReelsRowsLenght];

        enterTimeline.add(() => {
          const component = this.#createHeaderSymbol(symbolObject, columnIndex);
          this.#headerSymbolsComponents.push(component);
          this.#container.addChild(component.container);
        }, 'enter');
      }
    }

    // Set start z indexes
    this.#setSymbolsZIndexes(symbolsMatrix);
    // Call enter timelines
    await enterTimeline.play();
    // Reset after enter
    this.#exitAndEnterSpeedUpTriggered = false;

    // Win and move timelines. They don't happen in last step
    for (let step = 1; step < numOfSteps; step++) {
      const winTimeline = gsap.timeline({ paused: true });
      const winningSymbols = {};
      const wildSymbols = [];
      const moveTimeline = gsap.timeline({ paused: true });
      const moveDelay = 0.5; // Header reel symbol outro animation is 0.75 sec, so add delay here
      let isThereAnyCashSymbolFallingFromHeaderReel = false;

      for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
        for (let rowIndex = 0; rowIndex < symbolsMatrix[columnIndex].length; rowIndex++) {
          const symbolObject = symbolsMatrix[columnIndex][rowIndex];
          const symbolsBelow = symbolsMatrix[columnIndex].slice(0, rowIndex);
          const numOfBelowSymbolsToDestroy = symbolsBelow.filter((x) => x.removeAtStep === step).length;
          const numOfBelowSymbolsAfterDestroy = symbolsBelow.filter((x) => !x.removeAtStep || x.removeAtStep !== step).length;

          if (symbolObject.removeAtStep === step) {
            // removeAtStep for cash symbol is used for prize change in header reels
            if (state.options.symbols.cash === symbolObject.symbol) {
              symbolObject.isDestroyed = true; // Used for this.#removeDestroyedSymbolsFromMatrix
            } else {
              winTimeline.add(symbolObject.component.createWinTimeline(), 'win');

              if (!winningSymbols[symbolObject.symbol]) {
                winningSymbols[symbolObject.symbol] = [];
              }

              winningSymbols[symbolObject.symbol].push({
                ...symbolObject,
                columnIndex,
                rowIndex,
              });
            }
          // Add move timeline if there are below symbols to destroy, only for symbol that will land in reels
          } else if (numOfBelowSymbolsToDestroy && numOfBelowSymbolsAfterDestroy < this.#mainReelsRowsLenght) {
            const symbolEnterPositionY = this.#getSymbolEnterPositionY(rowIndex, numOfBelowSymbolsToDestroy);

            moveTimeline.add(symbolObject.component.createMoveTimeline(symbolEnterPositionY), `move+=${moveDelay}`);

            if (!isThereAnyCashSymbolFallingFromHeaderReel) {
              isThereAnyCashSymbolFallingFromHeaderReel = symbolObject.symbol === state.options.symbols.cash && symbolObject.rootStep === step;
            }
          }

          // Add header reel symbol move timeline
          if (numOfBelowSymbolsAfterDestroy === this.#mainReelsRowsLenght && numOfBelowSymbolsToDestroy) {
            moveTimeline.add(this.#headerSymbolsComponents[columnIndex].createUpdateSymbolTimeline(symbolObject), 'move');
          }

          // Add wild symbol for potential win timeline
          if (state.options.symbols.wild.includes(symbolObject.symbol) && symbolObject.rootStep <= step) {
            wildSymbols.push(symbolObject);
          }
        }
      }

      const moveSoundName = isThereAnyCashSymbolFallingFromHeaderReel ? 'soundCashDrop' : 'soundSymbolsEnter';
      moveTimeline.add(() => { state.playSound(moveSoundName); }, `move+=${moveDelay + 0.25}`); // 0.25 is symbol move timeline positionY animation duration

      if (winTimeline.getChildren().length) {
        const symbolsWinAmountTimeline = gsap.timeline({ paused: true });

        // Create symbol win amount for each group of winning symbols
        Object.values(winningSymbols).forEach((symbols) => {
          const symbolWinAmount = this.#createSymbolWinAmount(symbols, symbolWinnings, step);
          symbolWinAmount.container.zIndex = this.#zIndexOverSymbol;
          this.#container.addChild(symbolWinAmount.container);
          symbolsWinAmountTimeline.add(symbolWinAmount.createShowTimeline(), 'winAmount');
        });

        // Add wild symbol win timelines to win timeline
        wildSymbols.forEach((symbol) => { winTimeline.add(symbol.component.createWinTimeline(false), 'win'); });

        // Play win sound
        const isThereAnyMultiplierSymbol = wildSymbols.some(({ symbol }) => symbol === state.options.symbols.wildMultiplier);
        const winSoundName = isThereAnyMultiplierSymbol ? 'soundSymbolWinMultiplier' : 'soundSymbolWin';
        winTimeline.add(() => { state.playSound(winSoundName); }, 'win');

        state.components.content.reelsWithLogo.logo.playAnimation();
        symbolsWinAmountTimeline.play();
        await winTimeline.play();
      }

      // After win timeline adjust symbolsMatrix and z-indexes
      this.#removeDestroyedSymbolsFromMatrix(symbolsMatrix);
      this.#setSymbolsZIndexes(symbolsMatrix);

      await moveTimeline.play();
    }

    // Collect timeline
    if (collectCash.isWon) {
      const collectTimeline = gsap.timeline({ paused: true });
      const collectSymbols = [...collectBaseSymbols, ...collectExtraSymbols];
      const isThereAnyBaseCollectSymbol = Boolean(collectBaseSymbols.length);

      collectTimeline.add(() => { this.#configureReelsBeforeCollect(symbolsMatrix, isThereAnyBaseCollectSymbol); });
      // Sounds, will reset in Symbol.createCollectIntroTimeline
      collectTimeline.add(() => {
        state.playSound('soundCollectLoop', true);
        state.setSoundVolume('soundAmbient', 0.5);
      });

      for (let i = 0; i < collectSymbols.length; i++) {
        const isLastCollectSymbol = i === collectSymbols.length - 1;
        // Base collect symbol only collects cash symbols from main reels, extra from header reels also
        const rowLength = collectSymbols[i].symbol === state.options.symbols.collectBase ? this.#mainReelsRowsLenght : this.#mainReelsRowsLenght + 1;
        let delayForCashCollect = 0;

        // On first extra collect symbol show header cash symbols
        if (collectSymbols[i] === collectExtraSymbols[0] && isThereAnyBaseCollectSymbol) {
          collectTimeline.add(() => { this.#showHeaderReelsCashSymbolsOnFirstExtraSymbolCollect(symbolsMatrix); }, `collect${i}`);
        }

        for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
          for (let rowIndex = rowLength - 1; rowIndex >= 0; rowIndex--) {
            const symbolObject = symbolsMatrix[columnIndex][rowIndex];

            if (state.options.symbols.cash === symbolObject.symbol) {
              // If header symbol : main reels symbol
              const component = rowIndex === this.#mainReelsRowsLenght ? this.#headerSymbolsComponents[columnIndex] : symbolObject.component;
              const hook = new Hook(component.container.position);

              const hookCollectTimeline = hook.createCollectAnimationTimeline();
              const symbolCollectTimeline = component.createCollectTimeline(hook, isLastCollectSymbol);

              collectTimeline.add([hookCollectTimeline, symbolCollectTimeline], `collect${i}+=${delayForCashCollect}`);

              delayForCashCollect += 0.5;
            }
          }
        }

        // Add collect symbol intro animation
        const collectAmountAnimationDuration = delayForCashCollect + 0.25;
        collectTimeline.add(collectSymbols[i].component.createCollectIntroTimeline(collectSymbols[i].winAmount, collectAmountAnimationDuration, isLastCollectSymbol), `collect${i}`);

        // Add collect symbol outro animation
        collectTimeline.add(collectSymbols[i].component.createCollectOutroTimeline());
      }

      collectTimeline.add(this.#createResetReelsConfigAfterCollectTimeline(symbolsMatrix));

      state.components.content.reelsWithLogo.logo.playAnimation();
      await collectTimeline.play();
    }

    // Prepare data for exit timeline, will be called on next spin
    this.#prepareDataForExitTimeline(symbolsMatrix);

    // Bonus timeline
    if (bonus.isWon) {
      const bonusTimeline = gsap.timeline({ paused: true });
      let delay = 0;

      for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
        for (let rowIndex = 0; rowIndex < this.#mainReelsRowsLenght; rowIndex++) {
          const symbolObject = symbolsMatrix[columnIndex][rowIndex];

          if (symbolObject.symbol === state.options.symbols.bonus) {
            bonusTimeline.add(symbolObject.component.createWinTimeline(false), `win+=${delay}`);
            delay += 0.6;
          } else {
            bonusTimeline.add(() => { symbolObject.component.setTransparency(); }, 'win');
          }
        }
      }

      state.components.content.reelsWithLogo.logo.playAnimation();
      await bonusTimeline.play();
    // Show win amount
    } else if (winAmount) { // Don't show win amount for spin where bonus game is won
      // Wait for win grading to finish, don't wait for normal win
      if (winGrades) {
        await state.components.winGrading.show(winAmount);
      } else {
        state.components.winGrading.show(winAmount);
      }
    }

    state.components.content.reelsWithLogo.logo.pauseAnimation();
  }

  async #addBonusGameSymbols({ mergedReels, numOfSteps, collectCash }) {
    const symbolsMatrix = [[], [], [], [], []];
    const enterTimeline = gsap.timeline({ paused: true });
    const collectSymbols = [];
    const upgradeSymbols = [];
    const isFirstBonusGameSpin = state.bonusGame.totalNumberOfSpins === state.bonusGame.numberOfSpins + 1;

    // Create symbols. Create enter timelines
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = 0; rowIndex < mergedReels[columnIndex].length; rowIndex++) {
        const symbolObject = mergedReels[columnIndex][rowIndex];

        const component = this.#createSymbol(symbolObject, columnIndex, rowIndex, true);
        component.container.mask = this.#mainReelsMask;
        this.#container.addChild(component.container);

        symbolsMatrix[columnIndex].push({
          ...symbolObject,
          component,
        });

        // Create enter timeline
        if (rowIndex < this.#mainReelsRowsLenght) {
          const symbolEnterPositionY = this.#getSymbolEnterPositionY(rowIndex);
          const delay = isFirstBonusGameSpin ? this.#getSymbolDelay(rowIndex, columnIndex) : 0;

          enterTimeline.add(component.createEnterTimeline(symbolEnterPositionY), `enter+=${delay}`);
        }

        // Add collect symbol
        if (state.options.symbols.collectBase === symbolObject.symbol && collectCash.prizePerBaseSymbol) { // Bonus game doesn't have collectExtra symbol
          const symbolsMatrixObject = symbolsMatrix[columnIndex][rowIndex];
          symbolsMatrixObject.winAmount = collectCash.prizePerBaseSymbol;
          collectSymbols.push(symbolsMatrixObject);
        }

        // Add upgrade symbol
        if (state.options.symbols.upgrade === symbolObject.symbol && this.#bonusGameCollectLevels.activeLevel < state.options.bonusGameMaxCollectLevel) {
          upgradeSymbols.push(symbolsMatrix[columnIndex][rowIndex]);
        }
      }

      // Add sound for every column on first bonus game spin, later add only one sound
      if (isFirstBonusGameSpin || columnIndex === 0) {
        const delay = this.#getSymbolDelay(0, columnIndex) + 0.2; // 0.2 is enter timeline positionY animation duration
        enterTimeline.add(() => {
          state.playSound('soundSymbolsEnter');
        }, `enter+=${delay}`);
      }
    }

    // Set start z indexes
    this.#setSymbolsZIndexes(symbolsMatrix);
    // Call enter timelines
    await enterTimeline.play();

    // Win and move timelines. They don't happen in last step
    for (let step = 1; step < numOfSteps; step++) {
      const winTimeline = gsap.timeline({ paused: true });
      const moveTimeline = gsap.timeline({ paused: true });
      let isThereAnyNewCashSymbolFalling = false;

      for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
        for (let rowIndex = 0; rowIndex < symbolsMatrix[columnIndex].length; rowIndex++) {
          const symbolObject = symbolsMatrix[columnIndex][rowIndex];
          const symbolsBelow = symbolsMatrix[columnIndex].slice(0, rowIndex);
          const numOfBelowSymbolsToDestroy = symbolsBelow.filter((x) => x.removeAtStep === step).length;
          const numOfBelowSymbolsAfterDestroy = symbolsBelow.filter((x) => !x.removeAtStep || x.removeAtStep !== step).length;

          if (symbolObject.removeAtStep === step) {
            winTimeline.add(symbolObject.component.createWinTimeline(), 'win');
          // Add move timeline if there are below symbols to destroy, only for symbol that will land in reels
          } else if (numOfBelowSymbolsToDestroy && numOfBelowSymbolsAfterDestroy < this.#mainReelsRowsLenght) {
            const symbolEnterPositionY = this.#getSymbolEnterPositionY(rowIndex, numOfBelowSymbolsToDestroy);
            moveTimeline.add(symbolObject.component.createMoveTimeline(symbolEnterPositionY), 'move');
          }

          if (!isThereAnyNewCashSymbolFalling) {
            isThereAnyNewCashSymbolFalling = symbolObject.symbol === state.options.symbols.cash && symbolObject.rootStep - 1 === step;
          }
        }
      }

      winTimeline.add(() => { state.playSound('soundTntSymbolWin'); }, 'win');

      if (isThereAnyNewCashSymbolFalling) {
        moveTimeline.add(() => { state.playSound('soundCashDrop'); }, `move+=${0.25}`); // 0.25 is symbol move timeline positionY animation duration
      }

      await winTimeline.play();

      // After win timeline adjust symbolsMatrix and z-indexes
      this.#removeDestroyedSymbolsFromMatrix(symbolsMatrix);
      this.#setSymbolsZIndexes(symbolsMatrix);

      await moveTimeline.play();
    }

    if (collectCash.isWon) {
      const collectTimeline = gsap.timeline({ paused: true });

      collectTimeline.add(() => { this.#configureReelsBeforeCollect(symbolsMatrix, true, true); });
      // Sounds, will reset in Symbol.createCollectIntroTimeline
      collectTimeline.add(() => {
        state.playSound('soundCollectLoop', true);
        state.setSoundVolume('soundBonusAmbient', 0.5);
      });

      for (let i = 0; i < collectSymbols.length; i++) {
        const isLastCollectSymbol = i === collectSymbols.length - 1;
        let delayForCashCollect = 0;

        for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
          for (let rowIndex = this.#mainReelsRowsLenght - 1; rowIndex >= 0; rowIndex--) {
            const symbolObject = symbolsMatrix[columnIndex][rowIndex];

            if (state.options.symbols.cash === symbolObject.symbol) {
              const hook = new Hook(symbolObject.component.container.position);
              const hookCollectTimeline = hook.createCollectAnimationTimeline();
              const symbolCollectTimeline = symbolObject.component.createCollectTimeline(hook, isLastCollectSymbol);

              collectTimeline.add([hookCollectTimeline, symbolCollectTimeline], `collect${i}+=${delayForCashCollect}`);

              delayForCashCollect += 0.5;
            }
          }
        }

        // Add collect symbol intro animation
        const collectAmountAnimationDuration = delayForCashCollect + 0.25;
        const multiplier = state.options.collectLevelsData[this.#bonusGameCollectLevels.activeLevel]?.multiplier;
        collectTimeline.add(collectSymbols[i].component.createCollectIntroTimeline(collectSymbols[i].winAmount, collectAmountAnimationDuration, isLastCollectSymbol, multiplier), `collect${i}`);

        // Add collect symbol outro animation
        collectTimeline.add(collectSymbols[i].component.createCollectOutroTimeline());
      }

      collectTimeline.add(this.#createResetReelsConfigAfterCollectTimeline(symbolsMatrix, true));

      await collectTimeline.play();
    }

    // Run upgrade symbols and collect levels timelines
    if (upgradeSymbols.length) {
      const { numOfUpgradeSymbolsLeftToCollectLevel } = this.#bonusGameCollectLevels;
      let { nextLevel } = this.#bonusGameCollectLevels;
      // Split upgrade symbols into groups
      // One for next level
      const symbolsPerCollectLevel = [upgradeSymbols.splice(0, numOfUpgradeSymbolsLeftToCollectLevel)];
      // and one for each next level
      while (upgradeSymbols.length && nextLevel < state.options.bonusGameMaxCollectLevel) {
        nextLevel += 1;
        const { upgradeSymbolsRequired } = state.options.collectLevelsData[nextLevel];
        symbolsPerCollectLevel.push(upgradeSymbols.splice(0, upgradeSymbolsRequired));
      }

      for (let i = 0; i < symbolsPerCollectLevel.length; i++) {
        if (symbolsPerCollectLevel[i].length) {
          const upgradeSymbolsTimeline = gsap.timeline({ paused: true });
          let delay = 0;

          upgradeSymbolsTimeline.add(() => { state.playSound('soundUpgradeSymbolWin'); });

          for (let j = 0; j < symbolsPerCollectLevel[i].length; j++) {
            const upgradeSymbolTimeline = gsap.timeline();
            const { component } = symbolsPerCollectLevel[i][j];
            const position = {
              x: this.#getSymbolPositionX(this.#bonusGameCollectLevels.activeLevel),
              y: this.#bonusGameCollectLevels.container.y,
            };

            upgradeSymbolTimeline.add(component.createWinTimeline(false));
            upgradeSymbolTimeline.add(component.createUpgradeTimeline(position));

            upgradeSymbolsTimeline.add(upgradeSymbolTimeline, `upgrade+=${delay}`);
            delay += 0.5;
          }

          upgradeSymbolsTimeline.play();
          await sleep(2500); // 2000ms win timeline duration + 500ms upgrade timeline duration = first upgrade symbol timeline duration
          await this.#bonusGameCollectLevels.addUpgradeSymbols(symbolsPerCollectLevel[i].length);
        }
      }
    }

    // Prepare data for exit timeline, will be called on next spin
    this.#prepareDataForExitTimeline(symbolsMatrix, true);
  }

  #createSymbol(symbolObject, columnIndex, rowIndex, isBonusGame) {
    const position = {
      x: this.#getSymbolPositionX(columnIndex),
      y: this.#getSymbolStartPositionY(rowIndex),
    };

    return new Symbol({
      symbolNumber: symbolObject.symbol,
      position,
      prize: symbolObject.totalPrize,
      cashLevel: symbolObject.prizeLevel,
      multiplier: symbolObject.multiplier,
      numOfBonusRounds: symbolObject.numOfFreeRounds,
      collectLevel: this.#bonusGameCollectLevels?.activeLevel,
      symbolBoxSize: this.#symbolBoxSize,
      isBonusGame,
    });
  }

  #createHeaderSymbol(symbolObject, columnIndex) {
    const position = { x: this.#getSymbolPositionX(columnIndex) };

    return new HeaderReelsSymbol({
      symbolNumber: symbolObject.symbol,
      position,
      prize: symbolObject.totalPrize,
      cashLevel: symbolObject.prizeLevel,
    });
  }

  #createSymbolWinAmount(symbols, symbolWinnings, step) {
    const position = this.#getWinAmountPosition(symbols);
    const symbolObject = symbols.find((symbol) => symbol.columnIndex === position.columnIndex && symbol.rowIndex === position.rowIndex);
    const amountObject = symbolWinnings.find((obj) => obj.stepOrderNum === step && obj.symbol === symbolObject.symbol);

    return new SymbolWinAmount({
      winAmount: amountObject.totalWinAmount,
      startAmount: amountObject.winAmountWithoutMultiplier,
      multiplier: amountObject.sumOfWildMultipliers,
      position: symbolObject.component.container.position,
      maxWidth: this.#symbolBoxSize,
    });
  }

  #getSymbolPositionX(columnIndex) {
    return this.#symbolBoxSize / 2 + this.#symbolBoxSize * columnIndex;
  }

  #getSymbolStartPositionY(rowIndex) {
    return this.mainReelsPositionY - this.#symbolBoxSize * (rowIndex + 1);
  }

  #getSymbolEnterPositionY(rowIndex, numOfBelowSymbolsToDestroy = 0) {
    return this.mainReelsPositionY + this.#symbolBoxSize / 2 + this.#symbolBoxSize * (this.#mainReelsRowsLenght - 1 - rowIndex + numOfBelowSymbolsToDestroy);
  }

  #getSymbolExitPositionY(rowIndex) {
    return this.mainReelsPositionY + this.mainReelsSize + this.#symbolBoxSize * (this.#mainReelsRowsLenght - rowIndex);
  }

  #getSymbolDelay(rowIndex, columnIndex) {
    const getDelay = (rIndex, cIndex) => (((cIndex + 1) + (rIndex + 1)) * 0.15) + ((this.#mainReelsRowsLenght - rIndex) * 0.075);
    const lowestDelay = getDelay(0, 0);

    return getDelay(rowIndex, columnIndex) - lowestDelay;
  }

  #isCashSymbolOnlyFromHeaderReels(symbolObject) {
    return symbolObject.symbol === state.options.symbols.cash && Boolean(symbolObject.removeAtStep);
  }

  #setSymbolsZIndexes(symbolsMatrix) {
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = 0; rowIndex < this.#mainReelsRowsLenght; rowIndex++) {
        const symbol = symbolsMatrix[columnIndex][rowIndex].component;

        // Bottom right symbol has biggest z-index
        symbol.setZIndex((columnIndex + 1) * (this.#mainReelsRowsLenght - rowIndex));
      }
    }
  }

  #removeDestroyedSymbolsFromMatrix(symbolsMatrix) {
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = symbolsMatrix[columnIndex].length - 1; rowIndex >= 0; rowIndex--) {
        const symbolObject = symbolsMatrix[columnIndex][rowIndex];

        if (symbolObject.component?.isDestroyed || (this.#isCashSymbolOnlyFromHeaderReels(symbolObject) && symbolObject.isDestroyed)) {
          symbolsMatrix[columnIndex].splice(rowIndex, 1);
        }
      }
    }
  }

  #getWinAmountPosition(symbols) {
    const payline = [[], [], [], [], []];

    // Add indexes of symbols to payline
    symbols.forEach(({ columnIndex, rowIndex }) => {
      payline[columnIndex].push(rowIndex);
    });

    const center = getPaylineCenter(payline, this.#columnsLength, this.#mainReelsRowsLenght);

    return {
      columnIndex: center[0],
      rowIndex: center[1],
    };
  }

  #configureReelsBeforeCollect(symbolsMatrix, isThereAnyBaseCollectSymbol, isBonusGame = false) {
    const rowsLength = isBonusGame ? this.#mainReelsRowsLenght : this.#mainReelsRowsLenght + 1;

    // Bonus game
    if (isBonusGame) {
      const nextLevel = this.#bonusGameCollectLevels.activeLevel === state.options.bonusGameMaxCollectLevel ? state.options.bonusGameMaxCollectLevel : this.#bonusGameCollectLevels.activeLevel + 1;
      this.#bonusGameBackground.state.setAnimation(0, `level${nextLevel}CollectIntro`);
      this.#bonusGameCollectLevels.setTransparency();
    // Base game
    } else {
      this.#background.state.setAnimation(0, 'reelsCollectIntro');
    }

    // Set transparency for symbols not used in collect
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
        const symbolObject = symbolsMatrix[columnIndex][rowIndex];
        // If header symbol : main reels symbol
        const component = rowIndex === this.#mainReelsRowsLenght ? this.#headerSymbolsComponents[columnIndex] : symbolObject.component;
        const isCashOrCollect = state.options.symbols.cash === symbolObject.symbol || (state.options.symbols.collect.includes(symbolObject.symbol) && symbolObject.winAmount);
        // For header reels set transparency for cash symbols if there is base collect symbol/s
        const setTransparency = !(isCashOrCollect && (rowIndex < this.#mainReelsRowsLenght || (rowIndex === this.#mainReelsRowsLenght && !isThereAnyBaseCollectSymbol)));

        if (setTransparency) {
          component.setTransparency();
        }
      }
    }
  }

  #showHeaderReelsCashSymbolsOnFirstExtraSymbolCollect(symbolsMatrix) {
    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      const symbolObject = symbolsMatrix[columnIndex][this.#mainReelsRowsLenght];

      if (state.options.symbols.cash === symbolObject.symbol) {
        this.#headerSymbolsComponents[columnIndex].resetTransparency();
      }
    }
  }

  #createResetReelsConfigAfterCollectTimeline(symbolsMatrix, isBonusGame = false) {
    const timeline = gsap.timeline();

    timeline.to(this.#container, {
      onStart: () => {
        const rowsLength = isBonusGame ? this.#mainReelsRowsLenght : this.#mainReelsRowsLenght + 1;

        // Bonus game
        if (isBonusGame) {
          const nextLevel = this.#bonusGameCollectLevels.activeLevel === state.options.bonusGameMaxCollectLevel ? state.options.bonusGameMaxCollectLevel : this.#bonusGameCollectLevels.activeLevel + 1;
          this.#bonusGameBackground.state.setAnimation(0, `level${nextLevel}CollectOutro`);
          this.#bonusGameCollectLevels.resetTransparency();
        // Base game
        } else {
          this.#background.state.setAnimation(0, 'reelsCollectOutro');
        }

        // Reset symbols transparency
        for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
          for (let rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
            const symbolObject = symbolsMatrix[columnIndex][rowIndex];
            // If header symbol : main reels symbol
            const component = rowIndex === this.#mainReelsRowsLenght ? this.#headerSymbolsComponents[columnIndex] : symbolObject.component;

            if (component.container.alpha < 1) {
              component.resetTransparency();
            }
          }
        }
      },
      duration: 0.75,
    });

    return timeline;
  }

  #prepareDataForExitTimeline(symbolsMatrix, isBonusGame) {
    const timelinesData = isBonusGame ? this.#bonusGameExitTimelinesData : this.#exitTimelinesData;

    for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
      for (let rowIndex = 0; rowIndex < this.#mainReelsRowsLenght; rowIndex++) {
        const symbolComponent = symbolsMatrix[columnIndex][rowIndex].component;
        const symbolExitPositionY = this.#getSymbolExitPositionY(rowIndex);
        const delay = this.#getSymbolDelay(rowIndex, columnIndex);

        timelinesData.push({ symbolComponent, symbolExitPositionY, delay });
      }
    }
  }

  #createExitTimeline(isBonusGame) {
    const exitTimeline = gsap.timeline({ paused: true });
    const timelinesData = isBonusGame ? this.#bonusGameExitTimelinesData : this.#exitTimelinesData;

    timelinesData.forEach(({ symbolComponent, symbolExitPositionY, delay }) => {
      const realDelay = isBonusGame || state.isLightningSpinEnabled ? 0 : delay;
      exitTimeline.add(symbolComponent.createExitTimeline(symbolExitPositionY), `exit+=${realDelay}`);
    });

    return exitTimeline;
  }

  #setBaseGameSymbolsVisibility(visible) {
    this.#headerSymbolsComponents.forEach((component) => {
      component.container.visible = visible; // eslint-disable-line no-param-reassign
    });

    this.#exitTimelinesData.forEach(({ symbolComponent }) => {
      symbolComponent.container.visible = visible; // eslint-disable-line no-param-reassign
    });
  }

  #createBonusGameCollectLevels() {
    this.#bonusGameCollectLevels = new BonusGameCollectLevels({
      levelOnStart: state.options.bonusGameDataOnAppStart?.level,
      numberOfUpgradeSymbolsOnStart: state.options.bonusGameDataOnAppStart?.upgradeSymbols,
      symbolBoxSize: this.#symbolBoxSize,
    });
    this.#bonusGameCollectLevels.container.y = this.#bonusGameBackground.getLocalBounds().top + this.#bonusGameBackground.children[0].height / 2;
    this.#bonusGameCollectLevels.container.zIndex = this.#zIndexOverSymbol;
    this.#container.addChild(this.#bonusGameCollectLevels.container);
  }

  #destroyBonusGameSymbols() {
    this.#bonusGameExitTimelinesData.forEach(({ symbolComponent }) => {
      symbolComponent.destroy();
    });
    this.#bonusGameExitTimelinesData = [];

    this.#bonusGameCollectLevels.destroy();
    this.#bonusGameCollectLevels = undefined;
  }

  onBonusGameStart() {
    this.#background.alpha = 0.001;
    this.#bonusGameBackground.visible = true;
    this.#setBaseGameSymbolsVisibility(false);
    this.#createBonusGameCollectLevels();
    // Run intro animation for bonus game reel?
    if (!state.options.bonusGameDataOnAppStart?.upgradeSymbols) {
      this.#bonusGameBackground.state.setAnimation(0, 'intro');
    }
  }

  showBonusGameExtraSpinsText(extraSpins) {
    const bonusGameExtraSpinsText = new BitmapText(`+${extraSpins} ${state.options.translations.spins}`, {
      fontName: state.options.customFont,
      fontSize: 150,
    });
    bonusGameExtraSpinsText.anchor.set(0.5, 0.62);
    bonusGameExtraSpinsText.x = this.mainReelsSize / 2;
    bonusGameExtraSpinsText.y = this.mainReelsPositionY + this.mainReelsSize / 2;
    bonusGameExtraSpinsText.zIndex = this.#zIndexOverSymbol;

    gsap.timeline().fromTo(bonusGameExtraSpinsText, {
      pixi: { alpha: 0 },
    }, {
      pixi: { alpha: 1 },
      onStart: () => {
        this.#container.addChild(bonusGameExtraSpinsText);
        state.playSound('soundSpinsAdded');
      },
      duration: 0.2,
    }).fromTo(bonusGameExtraSpinsText, {
      pixi: { alpha: 1 },
    }, {
      pixi: { alpha: 0 },
      delay: 1,
      duration: 0.2,
      onComplete: () => {
        bonusGameExtraSpinsText.destroy();
      },
    });
  }

  onBonusGameEnd() {
    this.#background.alpha = 1;
    this.#bonusGameBackground.visible = false;
    this.#setBaseGameSymbolsVisibility(true);
    this.#destroyBonusGameSymbols();
  }

  runBonusGameReelsNextAnimation(level) {
    this.#bonusGameBackground.state.setAnimation(0, `level${level}Next`);
  }

  runIntroAnimation() {
    this.#background.state.setAnimation(0, 'reelsIntro');
  }

  speedUpExitAndEnterTimeline() {
    this.#exitAndEnterSpeedUpTriggered = true;
    // If speed up is triggered while exit timeline is animating, finish exit timeline
    this.#exitTimeline?.progress(1);
  }

  async spin(response) {
    const isBonusGame = response.isFree;

    if (isBonusGame) {
      // If bonus game exit symbols exist. False only on first bonus spin
      if (this.#bonusGameExitTimelinesData.length) {
        const exitTimeline = this.#createExitTimeline(true);

        await exitTimeline.play();
        this.#bonusGameExitTimelinesData = [];
      }

      // Add new symbols
      await this.#addBonusGameSymbols(response);
    } else {
      const headerSymbolsChangeTimeline = gsap.timeline({ paused: true });
      this.#exitTimeline = this.#createExitTimeline();

      // Create header symbols change timelines
      for (let columnIndex = 0; columnIndex < this.#columnsLength; columnIndex++) {
        const symbolObject = response.mergedReels[columnIndex][this.#mainReelsRowsLenght];
        headerSymbolsChangeTimeline.add(this.#headerSymbolsComponents[columnIndex].createUpdateSymbolTimeline(symbolObject), 'change');
      }

      // Run timelines
      headerSymbolsChangeTimeline.play();
      // If speed up is triggered before exit timeline has started, finish exit timeline
      if (this.#exitAndEnterSpeedUpTriggered) {
        this.#exitTimeline.progress(1);
      } else {
        state.playSound('soundSymbolsExit');
        await this.#exitTimeline.play();
      }
      state.components.content.controls.resetSpinButtonStopAvailable();
      this.#exitTimelinesData = [];
      this.#exitTimeline = undefined;

      // Add new symbols
      await this.#addSymbols(response);
    }
  }

  setPosition() {
    this.#createMask();
  }
}
