import { pusher, noSleep } from '@/plugins';
import { defaultTo } from 'lodash';
import { Component } from '@/components/shared/Component';
import { state } from '@/components/goldOfEgypt/State';
import { Header } from '@/components/goldOfEgypt/Header';
import { Footer } from '@/components/goldOfEgypt/Footer';
import { Content } from '@/components/goldOfEgypt/Content';
import { Reel } from '@/components/goldOfEgypt/Reel';
import { Dialog } from '@/components/goldOfEgypt/Dialog';
import { DialogMenu } from '@/components/goldOfEgypt/DialogMenu';
import { BonusGameIntroScreen } from '@/components/goldOfEgypt/BonusGameIntroScreen';
import { BonusGameOutroScreen } from '@/components/goldOfEgypt/BonusGameOutroScreen';
import { DialogAutoplay } from '@/components/goldOfEgypt/DialogAutoplay';
import { DialogBetAmount } from '@/components/goldOfEgypt/DialogBetAmount';
import { Background } from '@/components/goldOfEgypt/Background';
import { LoaderOverlay } from '@/components/goldOfEgypt/LoaderOverlay';
import { Posts } from '@/components/goldOfEgypt/Posts';
import { OnboardScreen } from '@/components/goldOfEgypt/OnboardScreen';
import { Notification } from '@/components/goldOfEgypt/Notification';
import { Parser } from '@/components/goldOfEgypt/Parser';
import { audio } from '@/components/goldOfEgypt/Audio';
import { registerEventListener } from '../utility/Utility';

export class GoldOfEgypt extends Component {
  constructor(params) {
    const { config, tenantGameId, settings, name } = params.state.game;
    const { player } = params.state;

    const { options } = new Parser(params, {
      autoPlayEnabled: defaultTo(settings.autoPlayEnabled, true),
      bonusBuyEnabled: defaultTo(settings.bonusBuyEnabled, true),
      config,
      calcWidth: 1080,
      currency: player?.currency || params.tenantCurrency || 'EUR',
      currencyDisplayEnabled: defaultTo(settings.currencyDisplayEnabled, false),
      defaultFontFamily: 'Roboto',
      fontNameBold: 'RobotoBold',
      fontNameLight: 'RobotoLight',
      fontNameNormal: 'RobotoNormal',
      customFont1: 'font1',
      customFont2: 'font2',
      customFont3: 'font3',
      customFont4: 'font4',
      customFont5: 'font5',
      isDemo: true,
      lobbyEnabled: defaultTo(settings.lobbyEnabled, false),
      locale: 'en',
      colors: {
        text: 0xdfe0e5,
        theme: 0xffff78,
      },
      uiPadding: 24,
      uiDialogPadding: 48,
      mute: true,
      name,
      settings,
      tenantGameId,
      translations: {
        /* eslint-disable max-len */
        balance: 'Balance',
        bet: 'Bet',
        buyLabel: 'Buy',
        buyOptionLabel: 'Buy option',
        collect: 'Collect',
        cancel: 'Cancel',
        demoLabel: 'Demo',
        dialogAutoplayStartButton: 'Start',
        dialogAutoplayStopLabel: 'Stop on any win',
        dialogAutoplayTitle: 'Autoplay',
        dialogAutoplayWinLabel: 'Win limit',
        dialogBetAmountTitle: 'Bet Amount',
        dialogMenuGameInfoTitle: 'Game info',
        dialogMenuHistoryTitle: 'History',
        dialogSettingsSoundLabel: 'Sound',
        dialogSettingsSpacebarLabel: 'Use spacebar for spin',
        dialogSettingsTitle: 'Settings',
        dialogStoreTitle: 'Store',
        directWinTitle: 'Direct win',
        goldOfEgyptBuyDescription: 'You can buy 20 non-risk spins, and dead symbols can’t land during this mode. It increases your chance of claiming the top prize. The price depends on the top prize amount you’ve collected. The higher the top prize amount, the higher the price.',
        goldOfEgyptDeadFirstSymbolDescription: 'First dead symbol decreases each tower’s level by one, but it doesn’t reset the collected cashpot amount.',
        goldOfEgyptDeadSecondSymbolDescription: 'Second dead symbol resets all towers and the cashpot to zero.',
        goldOfEgyptDeadSymbolsTitle: 'Dead symbols',
        goldOfEgyptDialogBetAmountDisabledTooltip: 'Collect cashpot to change bet.',
        goldOfEgyptDirectWinDescription: 'Every time you land a symbol whose tower is full, you get paid directly the highest symbol cash amount.',
        goldOfEgyptGameInfoDescription: 'The game features a simple layout with a single reel and one stop, designed for straightforward gameplay. Above the reel, the paytable is prominently displayed, providing clear visibility of potential winnings. In addition to this, the cashpot shows the cumulative winnings from each level of the tower, giving players a comprehensive view of their gains. In both demo and real modes, your current balance is consistently visible in the balance area. The bet amount, which can be adjusted only when the cashpot is empty, is displayed in the bet area. Players have the option to select from predefined bet amounts, all of which are listed in real currency. Wins are accumulated in the cashpot, except for the Direct Win and Top Prize, which are directly credited to your balance if you win them. You can transfer your wins from the cashpot to your balance at any time by pressing the collect button. For those interested in a more automated experience, the autoplay options panel allows you to select the number of spins. Once you initiate the autoplay session by pressing the start button, you can halt the spins at any moment using the stop autoplay button. The Win Limit feature displays the autoplay cashpot limit, enabling you to set a target amount at which the autoplay will stop. In the event of an internet disconnection during gameplay, simply log back into the website and reopen the game. This will allow you to resume play from the exact point where the game was interrupted. Please note, any malfunction during gameplay voids all bets, ensuring fairness and compliance with gaming regulations.',
        goldOfEgyptIntroSlide1FirstLine: 'Collect multuipliers for',
        goldOfEgyptIntroSlide1SecondLine: 'Reward',
        goldOfEgyptIntroSlide2FirstLine: 'Level up',
        goldOfEgyptIntroSlide2SecondLine: 'with',
        goldOfEgyptIntroSlide3FirstLine: 'Watch out for',
        goldOfEgyptIntroSlide3SecondLine: 'Traps',
        goldOfEgyptLandingBasicSymbolDescription: 'Every time the symbol lands on the screen, it highlights a cash level on its tower. Only the last symbol level is calculated into the cashpot amount.',
        goldOfEgyptLandingBasicSymbolTitle: 'Landing a basic symbol',
        goldOfEgyptPlayerGetsPaidDescription: 'All cash is collected in the cashpot. You can collect the cash placed in the cashpot anytime, or keep playing to win the top prize.',
        goldOfEgyptPlayerGetsPaidTitle: 'When a player gets paid',
        goldOfEgyptRtpDescription: 'The game RTP is ${RTP}, but it depends on user strategy.',
        goldOfEgyptRtpTitle: 'Game RTP',
        goldOfEgyptSpinsWithoutTrapsLabel: 'Spins without traps',
        goldOfEgyptTopPrizeDescription: 'Landing the Top Up symbol will increase the value of the top prize by certain amount. When all towers are highlighted you get the top prize amount.',
        goldOfEgyptTopPrizeTitle: 'Top prize',
        goldOfEgyptTowersDescription: 'Each of the two towers corresponds to a symbol. Blue symbol fills left, and purple fills the right tower.',
        goldOfEgyptTrashSymbolDescription: 'No paying symbol.',
        goldOfEgyptTrashSymbolTitle: 'Trash symbol',
        goldOfEgyptWildFirstSymbolDescription: 'First wild symbol highlights one level on each tower when it lands on the reel. The cashpot amount increases for those levels.',
        goldOfEgyptWildSecondSymbolDescription: 'Second wild symbol highlights two levels on each tower when it lands on the reel. The cashpot amount increases for those levels.',
        historyBetLabel: 'Bet',
        historyCurrencyLabel: 'Currency',
        historyDemoModeMessage: 'Tickets history not available in Demo mode.',
        historyNoTicketsMessage: 'There is no tickets for selected filter. Try different filters.',
        historyRound: 'Round',
        historySelectModalAccept: 'Accept',
        historySelectModalCancel: 'Cancel',
        historySelectModalDateRange: 'Date range',
        historySelectToday: 'Today',
        historySelectYesterday: 'Yesterday',
        historySummaryLabel: 'Summary',
        historyTimeLabel: 'Time',
        historyWinLabel: 'Win',
        howToPlayTitle: 'How to play',
        mainFeaturesTitle: 'Main features',
        potAmountLabel: 'Pot amount',
        pressAnywhereToContinue: 'Press anywhere to continue',
        reload: 'Reload',
        reloadGameToPlay: 'Reload game to play',
        sound: 'Sound',
        spins: 'Spins',
        start: 'Start',
        towersTitle: 'Towers',
        wildSymbolsTitle: 'Wild symbols',
        win: 'Win',
        winSymbolsTitle: 'Win symbols',
        youWon: 'You won',
        /* Error translations */
        actionError: 'Action error',
        actionSuccess: 'Action success',
        activePromotionError: 'Promotion is currently active',
        closeGame: 'Game can not be continued. Please close game.',
        dbReadError: 'Database error',
        dbSaveError: 'Database error',
        expiredPlayerTokenError: 'Expired player token',
        featureNotAvailableError: 'Feature not available',
        fieldNotFoundError: 'Round validation error',
        gameConfigNotFoundError: 'Invalid game configuration',
        gameMultipeInstancesError: 'Invalid parameters',
        gameNotFoundError: 'Game not found',
        insufficientFundsError: 'Insufficient funds',
        integrationRequestError: 'Integration error',
        integrationRequestServiceError: 'Integration error',
        integrationRequestTimeoutError: 'Integration error',
        integrationRequestUrlError: 'Integration error',
        integrationResponseError: 'Integration error',
        invalidDateRANGE: 'Invalid date range',
        invalidGambleGAME: 'Invalid gamble parameters',
        invalidGameError: 'Invalid game parameters',
        invalidInputError: 'Invalid input',
        invalidJackpotQueue: 'Jackpot error',
        invalidParamError: 'Invalid parameters',
        invalidPlayerError: 'Invalid player',
        invalidPlayerTokenError: 'Invalid player token',
        invalidRoundError: 'Invalid round',
        invalidSignatureError: 'Invalid signature',
        invalidStateError: 'An error happened',
        invalidTransactionCurrencyError: 'Transaction error',
        invalidTransactionError: 'Transaction error',
        invalidTransactionTypeError: 'Transaction error',
        jackpotGamesNotFoundError: 'Jackpot error',
        jackpotNotFoundError: 'Jackpot error',
        notImplementedError: 'Feature not implemented',
        openBonusError: 'Bonus game is currently open',
        openGambleError: 'Gamble game is currently open',
        partnerDeactivatedError: 'Game is deactivated',
        partnerSetupError: 'Invalid setup',
        platformUnavailableError: 'Platform unavailable',
        roundNotFoundError: 'Round not found',
        roundStartGambleError: 'Gamble error',
        tenantCurrencyUnsupportedError: 'Unsupported currency',
        tenantDeactivatedError: 'Game or client is deactivated',
        tenantGamesNotFoundError: 'Invalid parameters',
        tenantMismatchError: 'Invalid parameters',
        tenantNotFoundError: 'Invalid parameters',
        transactionErrorError: 'Transaction error',
        transactionInProgressError: 'Existing transaction is in progress',
        transactionNotFoundError: 'Transaction not found',
      },
    });

    super(name, options);
    state.create(options);
    audio.setOptions({ mute: options.mute });
    this.audio = audio;
    this.setOriginalSize();
    this.createComponents();
    this.setListeners();
    this.resize();
    pusher.init(player?.id, process.env.APP_PUSHER_KEY, tenantGameId);
    noSleep.init();
  }

  createComponents() {
    state.rootComponent = this;
    state.container = this.container;
    state.originalSize = this.originalSize;
    state.background = new Background();
    state.posts = new Posts();
    state.content = new Content();
    state.header = new Header();
    state.footer = new Footer();
    state.reel = new Reel();
    state.bonusGameIntroScreen = new BonusGameIntroScreen();
    state.bonusGameOutroScreen = new BonusGameOutroScreen();
    state.loaderOverlay = new LoaderOverlay();
    state.dialogAutoplay = new DialogAutoplay();
    state.dialogBetAmount = new DialogBetAmount();
    state.onboardScreen = new OnboardScreen();

    const dialogMenuContent = new DialogMenu();

    state.dialogMenu = new Dialog({
      title: state.options.translations.dialogSettingsTitle,
      content: dialogMenuContent,
      onBeforeClose: () => {
        dialogMenuContent.hide();
      },
    });

    state.container.addChild(
      state.background.container,
      state.posts.container,
      state.header.container,
      state.content.container,
      state.footer.container,
      state.reel.container,
      state.bonusGameIntroScreen.container,
      state.bonusGameOutroScreen.container,
      state.dialogAutoplay.container,
      state.dialogMenu.container,
      state.dialogBetAmount.container,
      state.onboardScreen.container,
      state.loaderOverlay.container,
    );
  }

  onAfterResize() {
    state.background.setPosition();
    state.header.setPosition();
    state.footer.setPosition();
    state.footer.setTooltip();
    state.content.setPosition();
    state.loaderOverlay.setPosition();
    state.dialogMenu.setPosition();
    state.bonusGameIntroScreen.setPosition();
    state.bonusGameOutroScreen.setPosition();
    state.dialogAutoplay.setPosition();
    state.dialogBetAmount.setPosition();
    state.onboardScreen?.setPosition();
    state.posts.setPosition();
    state.reel.setPosition();
    state.notification?.setPosition();
  }

  setListeners() {
    registerEventListener('MuteChanged', (event) => {
      if (event.detail.isSoundOff) {
        state.stopSoundAmbient();
      } else {
        state.setSoundAmbient();
      }
    });

    registerEventListener('CloseDialog', () => {
      if (state.activeDialog) {
        state.activeDialog.hide();
      }
    });

    registerEventListener('ShowNotification', (event) => {
      state.setErrorDetails(event.detail);
    });

    registerEventListener('SocketUpdateBalance', (event) => {
      state.setBalanceFromSocket(event.detail.amount);
    });

    // Sent from wrapper application
    registerEventListener('ResetNotificationDetails', () => {
      state.setErrorDetails(undefined);
    });

    // Sent from wrapper application
    registerEventListener('UpdateOptions', (event) => {
      state.updateOptions(event.detail);
    });

    // Sent from wrapper application
    registerEventListener('OpenMenu', () => {
      state.dialogMenu.show(2);
    });
  }

  openNotification(error) {
    state.notification = new Notification(error);
    this.container.addChild(state.notification.container);
  }

  // set original size since background is larger and scale is not ok
  setOriginalSize() {
    this.originalSize = {
      width: 1080,
      height: 1920,
    };
  }
}
