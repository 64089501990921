import { Container, Sprite, Texture } from '@/pixi';
import { Loader } from '@/components/shared/Loader';
import { state } from './State';

export class LoaderOverlay {
  constructor() {
    this.container = new Container();
    this.container.name = 'LoaderOverlay';
    this.container.eventMode = 'dynamic';
    this.container.visible = false;

    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.alpha = 0.4;
    this.background.width = state.options.calcWidth;

    this.loader = new Loader({});
    this.loader.container.x = state.options.calcWidth / 2;

    this.container.addChild(this.background, this.loader.container);
  }

  show() {
    this.container.visible = true;

    state.header.container.alpha = 0.3;
    state.content.container.alpha = 0.3;
    state.footer.container.alpha = 0.3;
    state.header.uiBoost.disable('0.3');
  }

  hide() {
    if (this.container.visible) {
      state.header.container.alpha = 1;
      state.content.container.alpha = 1;
      state.footer.container.alpha = 1;
      state.header.uiBoost.enable();

      this.container.visible = false;
    }
  }

  setPosition() {
    this.background.height = state.appHeight;
    this.loader.container.y = state.appHeight / 2;
  }
}
