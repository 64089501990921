import { Container, Circle, BitmapText, Sprite, Texture } from '@/pixi';
import { triggerAnalyticsEvent } from '@/utility/Utility';
import { Spine } from 'pixi-spine';
import { state } from './State';
import { audio } from './Audio';

export class OnboardScreen {
  constructor() {
    this.container = new Container();
    this.container.name = 'OnboardScreen';

    this.options = state.options;
    this.dialogAnimationDelay = 1100;
    this.slideAnimationDisplayDelay = 1400;
    this.textContainer = undefined;

    this.initModels();
    this.setup();
  }

  close() {
    state.playTapSound();
    this.container.destroy({ children: true });
    state.onboardScreen = undefined;
    state.background.startAnimation();
  }

  initModels() {
    this.paper = new Spine(state.options.resources.onboardPaper.resource.spineData);
    this.roll = new Spine(state.options.resources.onboardRoll.resource.spineData);
    this.slides = new Spine(state.options.resources.onboardSlides.resource.spineData);
    this.textContainer = new Spine(state.options.resources.onboardTextPlaceholder.resource.spineData);
    this.soundIcon = new Spine(state.options.resources.sound.resource.spineData);
  }

  removeText() {
    const textContainer = this.textContainer.children[0];
    textContainer.children[1].destroy();
    textContainer.children[1].destroy();
  }

  runRollAnimation(event) {
    this.roll.state.setAnimation(0, event, false);
  }

  runPaperAnimation(event) {
    this.paper.state.setAnimation(0, event, false);
  }

  runTextAnimation(event) {
    this.textContainer.state.setAnimation(0, event, false);
  }

  runSoundIconAnimation(isSoundOff) {
    if (isSoundOff) {
      this.soundIcon.state.setAnimation(0, 'off', false);
    } else {
      this.soundIcon.state.setAnimation(0, 'on', false);
    }
  }

  runSlidesAnimation(event) {
    this.slides.state.setAnimation(0, event, false);
    this.slides.state.addListener({
      complete: (entry) => {
        if (entry.animation.name === 'slide1') {
          this.slides.state.setAnimation(0, 'slide2', false);
          this.removeText();
          this.setText(
            this.options.translations.goldOfEgyptIntroSlide2FirstLine.toUpperCase(),
            this.options.translations.goldOfEgyptIntroSlide2SecondLine.toUpperCase(),
          );
        }
        if (entry.animation.name === 'slide2') {
          this.slides.state.setAnimation(0, 'slide3', false);
          this.removeText();
          this.setText(
            this.options.translations.goldOfEgyptIntroSlide3FirstLine.toUpperCase(),
            this.options.translations.goldOfEgyptIntroSlide3SecondLine.toUpperCase(),
          );
        }
        if (entry.animation.name === 'slide3') {
          this.slides.state.setAnimation(0, 'slide1', false);
          this.removeText();
          this.setText(
            this.options.translations.goldOfEgyptIntroSlide1FirstLine.toUpperCase(),
            this.options.translations.goldOfEgyptIntroSlide1SecondLine.toUpperCase(),
          );
        }
      },
    });
  }

  setBackground() {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.alpha = 0;
    this.background.eventMode = 'static';
    this.background.cursor = 'pointer';
    this.background.on('pointertap', this.close.bind(this));
  }

  setup() {
    this.container.eventMode = 'static';
    this.container.cursor = 'pointer';
    this.container.on('pointertap', this.close.bind(this));

    this.setBackground();
    this.container.addChild(this.background);
    this.container.addChild(this.paper);
    this.container.addChild(this.roll);
    this.container.addChild(this.textContainer);

    this.setIntroAnimations();
  }

  setSoundIcon() {
    const { height } = this.options.size();
    const dialogHeight = this.paper.skeleton.slots[1].attachment.region.height;

    this.container.addChild(this.soundIcon);

    this.soundIcon.y = dialogHeight + height / 4 - this.options.uiPadding;
    this.soundIcon.state.setAnimation(0, state.options.mute ? 'off' : 'on', false);
    this.soundIcon.eventMode = 'static';
    this.soundIcon.cursor = 'pointer';
    this.soundIcon.hitArea = new Circle(0, 0, this.soundIcon.width / 2 - 10);
    this.soundIcon.on('pointertap', this.toggleSound.bind(this));
  }

  setOnboardLabel() {
    const { height } = this.options.size();
    const dialogHeight = this.paper.skeleton.slots[1].attachment.region.height;

    this.onboardLabel = new BitmapText(this.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.customFont2,
      fontSize: 40,
    });

    this.onboardLabel.y = dialogHeight + height / 4 + this.options.uiPadding * 3;
    this.onboardLabel.x -= this.onboardLabel.width / 2;

    this.container.addChild(this.onboardLabel);
  }

  setIntroAnimations() {
    setTimeout(() => {
      this.runRollAnimation('intro');
      this.runPaperAnimation('intro');
      this.runTextAnimation('idle');
    }, this.dialogAnimationDelay);
    setTimeout(() => {
      this.container.addChild(this.slides);
      this.runSlidesAnimation('slide1');
      this.setText(
        this.options.translations.goldOfEgyptIntroSlide1FirstLine.toUpperCase(),
        this.options.translations.goldOfEgyptIntroSlide1SecondLine.toUpperCase(),
      );
      this.setSoundIcon();
      this.setOnboardLabel();
    }, this.slideAnimationDisplayDelay);
  }

  setText(firstLine, secondLine) {
    const firstLineText = new BitmapText(firstLine, {
      fontName: state.options.customFont2,
      fontSize: 20,
    });
    const secondLineText = new BitmapText(secondLine, {
      fontName: state.options.customFont2,
      fontSize: 40,
    });
    const textContainer = this.textContainer.children[0];

    firstLineText.anchor.set(0.5);
    firstLineText.scale.y = this.textContainer.children[0].children[0].scale.y;
    firstLineText.y += state.options.uiPadding;

    secondLineText.anchor.set(0.5);
    secondLineText.scale.y = this.textContainer.children[0].children[0].scale.y;
    secondLineText.y -= state.options.uiPadding / 2;

    textContainer.addChild(firstLineText, secondLineText);
  }

  setPosition() {
    const { width, height } = this.options.size();
    const scale = this.container.parent.scale.y;
    const backgroundWidth = width / scale;
    const backgroundHeight = height / scale;
    const aspectRatio = height / width;

    this.background.width = state.appWidth + (state.appWidth / 2);
    this.background.height = state.appHeight + (state.appHeight / 2);
    this.background.anchor.set(0.5);

    this.container.scale.set(aspectRatio * 0.55);
    this.container.x = backgroundWidth - (backgroundWidth / 2);
    this.container.y = backgroundHeight - (backgroundHeight / 2) - this.options.uiPadding * 5;
  }

  toggleSound(e) {
    e.stopPropagation();

    audio.toggleMute();

    triggerAnalyticsEvent({ name: audio.isSoundOff ? 'SoundButtonOnClick' : 'SoundButtonOffClick' });
    state.playTapSound();
    this.runSoundIconAnimation(audio.isSoundOff);
  }
}
