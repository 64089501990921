import { Label as BaseLabel } from '@/components/shared/Label';
import { state } from './State';

export class Label extends BaseLabel {
  constructor(params) {
    super({
      backgroundColor: state.options.colors.theme,
      textColor: state.options.colors.themeText,
      fontName: state.options.fontNameNormal,
      ...params,
    });
  }
}
