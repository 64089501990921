import { Container, BitmapText } from '@/pixi';
import animate from 'gsap';
import { Spine } from 'pixi-spine';
import { gameUtils } from './GameUtils';
import { state } from './State';
import { Cash } from './Cash';

export class Logo {
  constructor() {
    this.container = new Container();
    this.container.name = 'Logo';
    this.container.interactiveChildren = false;

    this.logo = new Spine(state.options.assets.logo.resource.spineData);

    this.cashLabel = new Cash({
      cash: 0,
      fontSize: 50,
    });

    this.container.addChild(this.logo);

    this.setListeners();
  }

  get logoCashLinkCollectAnimationDuration() {
    return this.logo.stateData.skeletonData.findAnimation('logoCashLinkCollect').duration;
  }

  get logoCashLinkX2AnimationDuration() {
    return this.logo.stateData.skeletonData.findAnimation('logoCashLinkX2').duration;
  }

  setListeners() {
    this.logo.state.addListener({
      complete: (trackEntry) => {
        if (trackEntry.animation.name === 'logoCashLinkIdle') {
          setTimeout(() => this.setFillPositionText(), 0);
        }
      },
    });
  }

  setFillPositionText() {
    const slot = this.logo.skeleton.findSlot('text_fill_positions');
    const text = new BitmapText(state.options.translations.fillPositions.toUpperCase(), {
      fontName: state.options.fontCherryGold,
      fontSize: 70,
    });

    text.scale.set(gameUtils.getTextScaleX(text, slot.attachment.width));
    text.anchor.set(0.5, 0.41);

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(text);
  }

  setWinAmountText() {
    const slot = this.logo.skeleton.findSlot('cash_link_collect_value_label');

    this.cashLabel = new Cash({
      cash: 0,
      fontSize: 50,
      maxWidth: slot.attachment.width * 0.80,
    });

    slot.currentSprite.removeChildren();
    slot.currentSprite.addChild(this.cashLabel.container);
  }

  logoWinAnimation() {
    this.logo.state.setAnimation(0, 'logoWin');
  }

  logoIdleAnimation() {
    this.logo.state.setAnimation(0, 'logoIdle');
  }

  logoCashLinkIdleAnimation() {
    this.logo.state.setAnimation(0, 'logoCashLinkIdle');
  }

  logoCashLinkX2Animation() {
    this.logo.state.setAnimation(0, 'logoCashLinkX2');
  }

  logoCashLinkWaitingAnimation() {
    this.logo.state.setAnimation(0, 'logoCashLinkWaiting', true);
  }

  logoCashLinkCollectAnimation() {
    this.setWinAmountText();
    this.logo.state.setAnimation(0, 'logoCashLinkCollect');
  }

  createLogoCollectTimeline() {
    const timeline = animate.timeline();

    timeline.to(this.container, {
      onStart: () => {
        this.logoCashLinkCollectAnimation();
      },
      duration: this.logoCashLinkCollectAnimationDuration,
    });

    return timeline;
  }
}
