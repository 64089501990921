import { Container, BitmapText } from '@/pixi';
import { SlotSpinSpeedType } from '@/models';
import { Checkbox } from './Checkbox';
import { audio } from '../Audio';
import { state } from '../State';

export class DialogMenuSettings {
  constructor() {
    this.container = new Container();

    this.labelStyle = {
      fontName: state.options.fontDefaultNormal,
      fontSize: 36,
      tint: state.options.colors.text,
    };

    this.setup();
  }

  setup() {
    this.soundLabel = new BitmapText(state.options.translations.dialogSettingsSoundLabel, this.labelStyle);
    this.soundCheckbox = new Checkbox({ onChange: () => { audio.toggleMute(); } });

    this.lightningLabel = new BitmapText(state.options.translations.dialogSettingsLightningLabel, this.labelStyle);
    this.lightningCheckbox = new Checkbox({ onChange: () => this.setSpinSpeedType(SlotSpinSpeedType.Lightning) });

    this.container.addChild(
      this.soundLabel,
      this.soundCheckbox.container,
      this.lightningLabel,
      this.lightningCheckbox.container,
    );
  }

  setPosition({ width }) {
    this.soundCheckbox.container.x = width - this.soundCheckbox.container.width - state.options.uiDialogPadding;
    this.soundCheckbox.container.y = state.options.uiDialogPadding;

    this.soundLabel.x = state.options.uiDialogPadding;
    this.soundLabel.y = this.soundCheckbox.container.y + ((this.soundCheckbox.container.height / 2) - (this.soundLabel.height / 2));

    this.lightningCheckbox.container.x = width - this.lightningCheckbox.container.width - state.options.uiDialogPadding;
    this.lightningCheckbox.container.y = this.soundCheckbox.container.y + this.soundCheckbox.container.height + state.options.uiDialogPadding;

    this.lightningLabel.x = state.options.uiDialogPadding;
    this.lightningLabel.y = this.lightningCheckbox.container.y + ((this.lightningCheckbox.container.height / 2) - (this.lightningLabel.height / 2));
  }

  setSpinSpeedType(type) {
    if (type === SlotSpinSpeedType.Lightning && this.lightningCheckbox.isActive) {
      state.setSpinSpeedType(SlotSpinSpeedType.Lightning);
    } else {
      state.setSpinSpeedType(SlotSpinSpeedType.Normal);
    }
  }

  setData() {
    this.soundCheckbox.set(!audio.isSoundOff);
    this.lightningCheckbox.set(state.isLightningSpin);
  }
}
