import { DialogContentAutoplay } from '@/components/shared/DialogContentAutoplay';
import { Dialog } from './Dialog';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { gameUtils } from '../GameUtils';
import { state } from '../State';

export class DialogAutoplay extends Dialog {
  constructor() {
    const content = new DialogContentAutoplay({
      padding: state.options.uiDialogPadding,
      fontName: state.options.fontDefaultNormal,
      color: state.options.colors.text,
      highlightColor: state.options.colors.theme,
      stopLabelText: state.options.translations.dialogAutoplayStopLabel,
      lossLimitLabelText: state.options.translations.dialogAutoplayLossLabel,
      winLimitLabelText: state.options.translations.dialogAutoplayWinLabel,
      startButtonText: state.options.translations.dialogAutoplayStartButton,
      formatValue: (value) => gameUtils.getMoneyLabel(value, state.options.currencyDisplayEnabled),
      getBetAmount: () => state.betAmount,
      onStart: (selectedSpinValue, stopOnAnyWin, lossLimit, winLimit) => {
        state.startAutoplay(selectedSpinValue, stopOnAnyWin, lossLimit, winLimit);
      },
      buttonParams: Button.getDefaultParams(),
      checkboxParams: Checkbox.getDefaultParams(),
    });

    super({
      title: state.options.translations.dialogAutoplayTitle,
      content,
      isFluid: true,
    });
  }
}
