import { Dialog } from './Dialog';
import { DialogContentStore } from './DialogContentStore';
import { state } from '../State';

export class DialogStore extends Dialog {
  constructor() {
    super({
      title: state.options.translations.dialogStoreTitle,
      content: new DialogContentStore(),
      backgroundImageAsset: state.options.assets.storeBackground,
    });

    this.container.name = 'DialogStore';
  }
}
