import { ControlsFooter2 } from '@/components/shared/ControlsFooter2';
import { state } from './State';

export class Footer extends ControlsFooter2 {
  constructor() {
    super({
      balanceTranslation: state.options.translations.balance,
      balanceAmount: state.getBalanceAmountMoneyLabel(),
      betTranslation: state.options.translations.bet,
      betAmount: state.getBetAmountMoneyLabel(),
      winTranslation: state.options.translations.win,
      currency: state.options.currency,
      currencyDisplayEnabled: state.options.currencyDisplayEnabled,
      appWidth: state.options.calcWidth,
      getAppHeight: () => state.appHeight,
      padding: state.options.uiPadding,
      color: state.options.colors.text,
      winColor: state.options.colors.theme,
      titleFontName: state.options.fontNameLight,
      amountFontName: state.options.fontNameBold,
      onBetAmountClick: () => {
        state.components.dialogBetAmount.show();
      },
      playTapSound: () => { state.playTapSound(); },
    });

    this.container.name = 'Footer';

    this.#setWatchers();
  }

  #setWatchers() {
    state.watch('betAmount', () => {
      this.setBetAmount(state.getBetAmountMoneyLabel());
    });

    state.watch('balanceAmount', () => {
      this.setBalanceAmount(state.getBalanceAmountMoneyLabel());
    });
  }

  disableInSpin() {
    this.disableBetAmountClick();
  }

  enableAfterSpin() {
    this.enableBetAmountClick();
  }
}
