import gsap from 'gsap';
import { Spine } from 'pixi-spine';
import { Container, BitmapText } from '@/pixi';
import { triggerAnalyticsEvent, registerEventListener } from '@/utility/Utility';
import { state } from './State';
import { audio } from './Audio';

export class OnboardScreen {
  #container;
  #background;
  #soundIcon;
  #pressAnywhereText;
  #slidesContainer;
  #slides = [];
  #waitForFullscreenTransitionToFinish = false;

  constructor() {
    const { onboardBackground, onboardSound } = state.assets;

    this.#container = new Container();
    this.#container.name = 'OnboardScreen';

    this.#background = new Spine(onboardBackground.resource.spineData);
    this.#background.scale.set(0.9); // Set scale before setPosition to not overflow screen
    this.#container.addChild(this.#background);

    this.#soundIcon = new Spine(onboardSound.resource.spineData);
    this.#soundIcon.x = this.#soundIcon.width; // Set position before setPosition to not overflow screen
    this.#runSoundIconAnimation(audio.isSoundOff);
    this.#container.addChild(this.#soundIcon);

    this.#pressAnywhereText = new BitmapText(state.options.translations.pressAnywhereToContinue.toUpperCase(), {
      fontName: state.options.customFont,
      fontSize: 60,
    });
    this.#pressAnywhereText.anchor.set(0.5);
    // Set position and scale before setPosition to not overflow screen
    this.#pressAnywhereText.scale.set(0.1);
    this.#pressAnywhereText.x = 100;
    this.#container.addChild(this.#pressAnywhereText);

    this.#slidesContainer = new Container();

    for (let i = 1; i <= 3; i++) {
      const slide = new Spine(state.assets[`onboardSlide${i}`].resource.spineData);

      this.#slides.push(slide);
      this.#slidesContainer.addChild(slide);

      // Add custom text to slide
      const textContainer = slide.slotContainers.find((container) => container.children[0]?.attachment?.name === `text${i}`);
      const text = new BitmapText(state.options.translations[`fishyGuyOnboardText${i}`], {
        fontName: state.options.customFont,
        fontSize: 60,
        align: 'center',
      });

      // Scale text to fit?
      const textMaxWidth = textContainer.width;
      if (text.width > textMaxWidth) {
        text.scale.set(textMaxWidth / text.width);
      }

      text.scale.y *= -1; // Adjust to textContainer
      text.anchor.set(0.5);

      textContainer.removeChildren();
      textContainer.addChild(text);
    }

    // Set position and scale before setPosition to not overflow screen
    this.#slidesContainer.scale.set(0.5);
    this.#slidesContainer.x = this.#slidesContainer.width / 2;
    this.#container.addChild(this.#slidesContainer);

    this.#runSlideAnimation();
    this.#setListeners();
  }

  get container() {
    return this.#container;
  }

  #setListeners() {
    this.#container.eventMode = 'static';
    this.#container.cursor = 'pointer';
    this.#container.on('pointertap', this.#close.bind(this));

    this.#soundIcon.eventMode = 'static';
    this.#soundIcon.cursor = 'pointer';
    this.#soundIcon.on('pointertap', this.#toggleMute.bind(this));

    registerEventListener('FullscreenSet', () => {
      this.#waitForFullscreenTransitionToFinish = true;
    });
  }

  #runSoundIconAnimation(isSoundOff) {
    const animationName = isSoundOff ? 'soundOff' : 'soundOn';
    this.#soundIcon.state.setAnimation(0, animationName);
  }

  #runSlideAnimation() {
    this.#slides.forEach((slide) => {
      slide.state.setAnimation(0, 'slide', true);
    });
  }

  #toggleMute(e) {
    e.stopPropagation();

    triggerAnalyticsEvent({ name: audio.isSoundOff ? 'SoundButtonOnClick' : 'SoundButtonOffClick' });

    audio.toggleMute();
    state.playTapSound();
    this.#runSoundIconAnimation(audio.isSoundOff);

    this.#waitForFullscreenTransitionToFinish = false;
  }

  #close() {
    state.playTapSound();

    if (this.#waitForFullscreenTransitionToFinish) {
      const callback = () => {
        setTimeout(() => {
          this.#closeCallback();
        }, 0);
        document.removeEventListener('fullscreenchange', callback);
      };

      document.addEventListener('fullscreenchange', callback);
    } else {
      this.#closeCallback();
    }
  }

  #closeCallback() {
    gsap.timeline().fromTo(this.#container, {
      pixi: {
        alpha: 1,
      },
    }, {
      onStart: () => {
        // Disable click
        this.#container.eventMode = 'none';
        this.#soundIcon.eventMode = 'none';

        // Start game intro
        state.components.rootComponent.runIntroAnimation();
        state.components.header.uiBoost?.show();
      },
      pixi: {
        alpha: 0,
      },
      duration: 0.75,
      onComplete: () => {
        // Close onboard screen
        this.#container.destroy({ children: true, texture: true });
        state.components.onboardScreen = undefined;
      },
    });
  }

  setPosition() {
    const aspectRatio = state.appHeight / state.appWidth;
    const backgroundAttachment = this.#background.spineData.defaultSkin.attachments[0].background;
    const backgroundAttachmentWidth = backgroundAttachment.width * backgroundAttachment.scaleX;
    const backgroundAttachmentHeight = backgroundAttachment.height * backgroundAttachment.scaleY;
    const backgroundAspectRatio = backgroundAttachmentHeight / backgroundAttachmentWidth;
    const pressAnywhereTextMargin = 75;

    // If app aspect ratio is larger then background aspect ratio then set larger background scale
    if (aspectRatio > backgroundAspectRatio) {
      this.#background.scale.set(state.appHeight / backgroundAttachmentHeight);
    } else {
      this.#background.scale.set(1);
    }

    // Adjust slides scale to fit
    if (aspectRatio < 1.777) {
      this.#slidesContainer.scale.set(aspectRatio * 0.5);
    } else {
      this.#slidesContainer.scale.set(1);
    }

    // Scale pressAnywhereText to fit to screen?
    const pressAnywhereMaxWidth = state.appWidth - 40; // Padding is 20
    const pressAnywhereWidth = this.#pressAnywhereText.width / this.#pressAnywhereText.scale.y;
    if (pressAnywhereWidth > pressAnywhereMaxWidth) {
      this.#pressAnywhereText.scale.set(pressAnywhereMaxWidth / pressAnywhereWidth);
    } else {
      this.#pressAnywhereText.scale.set(1);
    }

    // Set positions
    this.#background.x = state.appWidth / 2 - this.#background.width / 2;
    this.#background.y = state.appHeight - this.#background.height;
    this.#soundIcon.x = state.appWidth / 2;
    this.#soundIcon.y = state.appHeight - this.#soundIcon.height * 1.35 * this.#background.scale.y;
    this.#pressAnywhereText.x = state.appWidth / 2;
    this.#pressAnywhereText.y = state.appHeight - this.#pressAnywhereText.height / 2 - pressAnywhereTextMargin * this.#background.scale.y;
    this.#slidesContainer.x = state.appWidth / 2;
  }
}
